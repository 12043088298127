import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
  query contacts($filter: ContactFilter!, $where: ContactWhereFilter) {
    contacts(filter: $filter, where: $where) {
      count
      data {
        id
        homePhone
        mainPhone
        userInfo {
          firstName
          lastName
          email
          phoneNo
        }
        location {
          addressLine1
          city
          state
          zipCode
          country
        }
        web
        isActive
        preferredContact
        contactType
      }
    }
  }
`;

export const GET_CONTACT = gql`
  query contact($id: ID!) {
    contact(where: { id: $id }) {
      id
      homePhone
      mainPhone
      userInfo {
        firstName
        lastName
        email
        phoneNo
      }
      location {
        addressLine1
        city
        state
        zipCode
        country
      }
      web
      isActive
      preferredContact
      contactType
      contactTypeData {
        id
        label
        key
      }
      preferredContactData {
        id
        label
        key
      }
    }
  }
`;

export const CONTACT_FILTER = gql`
  query contactFilters($filter: ContactFilter!) {
    contactFilters(filter: $filter) {
      count
      data {
        userInfo {
          firstName
          lastName
          email
          phoneNo
        }
        homePhone
        mainPhone
        web
        isActive
        preferredContact
        contactType
      }
    }
  }
`;

export const USER = gql`
  query users($filter: UserFilter!) {
    users(filter: $filter) {
      count
      data {
        firstName
        lastName
        email
        phoneNo
        roles
        uuid
        isActive
        tenantId

        profileImage {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const USER_FILTER = gql`
  query userFilters($filter: UserFilter!) {
    userFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        email
        phoneNo
        isActive
      }
    }
  }
`;

export const LOCATION_FILTER = gql`
  query locationFilters($filter: LocationFilter!) {
    locationFilters(filter: $filter) {
      count
      data {
        id
        referenceType
        referenceId
        type
        referenceKey
        isActive
        addressLine1
        addressLine2
        addressLine3
        city
        county
        state
        country
        zipCode
        tenantId
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query customers(
    $filter: CustomerFilter!
    $where: CustomerWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    customers(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        customerType
        user {
          firstName
          lastName
          email
          phoneNo
          isActive
        }
        isActive
        location {
          addressLine1
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const CUSTOMER_FILTER = gql`
  query customerFilters($filter: CustomerFilter!) {
    customerFilters(filter: $filter) {
      count
      data {
        id
        customerType
        fax
        userId
        tollFree
        web
        csmId
        salesManagerId
        technician
        industry
        descriptionBusiness
        isActive
      }
    }
  }
`;

export const GET_PROPERTIES = gql`
  query properties(
    $filter: PropertyFilter!
    $where: PropertyWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    properties(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        name
        builtYear
        propertyType
        isActive
        location {
          addressLine1
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const PROPERTY_FILTER = gql`
  query propertyFilters($filter: PropertyFilter!) {
    propertyFilters(filter: $filter) {
      count
      data {
        name
        builtYear
        isActive
        propertyType
        location {
          addressLine1
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const GET_CONTACT_TYPES = gql`
  query contactTypes(
    $filter: ContactTypeFilter!
    $where: ContactTypeWhereFilter
  ) {
    contactTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_PREFERRED_CONTACT = gql`
  query preferredContactMethods(
    $filter: PreferredContactMethodFilter!
    $where: PreferredContactMethodWhereFilter
  ) {
    preferredContactMethods(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const PROPERTY_TYPES_FILTER = gql`
  query propertyTypes(
    $filter: PropertyTypeFilter!
    $where: PropertyTypeWhereFilter
  ) {
    propertyTypes(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;
