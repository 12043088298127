import { Tree } from 'antd';
import React from 'react';
import './styles/TreeComponent.less';

const TreeComponent = ({
  data = [],
  checkedKeys = [],
  setCheckedKeys,
  setCheckedObject,
  onCheckParent,
  ...rest
}) => {
  const onCheck = (checkedKeysValue, event) => {
    if (setCheckedKeys) {
      setCheckedKeys(checkedKeysValue);
    }
    if (setCheckedObject) {
      setCheckedObject(event?.checkedNodes);
    }
  };

  return (
    <Tree
      className="common-tree"
      checkable
      autoExpandParent
      onCheck={onCheckParent || onCheck}
      checkedKeys={checkedKeys}
      selectable={false}
      treeData={data}
      {...rest}
    />
  );
};

export default TreeComponent;
