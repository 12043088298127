import { Form, Radio } from 'antd';
import React from 'react';

const RenderRadio = ({ radioData = null }) => {
  const { config = {}, key = '' } = radioData;
  const {
    BOOLEAN: {
      booleanDefaultValue = false,
      trueValue = 'true',
      falseValue = 'false'
    } = {}
  } = config || null;

  return (
    <Form.Item name={['dynamicFields', key]} initialValue={booleanDefaultValue}>
      <Radio.Group className="common-radio fill-width d-flex">
        <Radio value>{trueValue}</Radio>
        <Radio value={false}>{falseValue}</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default RenderRadio;
