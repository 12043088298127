import { gql } from '@apollo/client';

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_LINE_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_SUB_AREAS = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lineOfBusinessId
      }
    }
  }
`;

export const GET_PRODUCT_RANKING_REGIONS = gql`
  query regionsProductRankingConfigData(
    $filter: RegionFilter!
    $where: RegionsWhereFilter
  ) {
    regionsProductRankingConfigData(filter: $filter, where: $where) {
      count
      data {
        id
        name
        parentName
      }
    }
  }
`;

export const GET_QUESTIONNAIRES = gql`
  query questionnaires(
    $filter: QuestionnaireFilter!
    $where: QuestionnaireWhereFilter!
  ) {
    questionnaires(filter: $filter, where: $where) {
      count
      data {
        id
        title
      }
    }
  }
`;

export const GET_BROWSERS = gql`
  query browsers($filter: BrowserFilter!, $where: BrowserWhereFilter) {
    browsers(filter: $filter, where: $where) {
      count
      data {
        key
        label
      }
    }
  }
`;

export const GET_DEVICES = gql`
  query devices($filter: DeviceFilter!, $where: DeviceWhereFilter) {
    devices(filter: $filter, where: $where) {
      count
      data {
        key
        label
      }
    }
  }
`;

export const GET_CPQ_ANALYTICS_STATS = gql`
  query cpqAnalyticsStats($where: AnalyticsWhere) {
    cpqAnalyticsStats(where: $where) {
      startedQuestionnaire {
        count
        percent
        price
      }
      providedContactInfo {
        count
        percent
        price
      }
      totalAppointments {
        count
        percent
        price
      }
      # commented for now. will use in future
      #totalSold {
      #count
      #percent
      #price
      #}
      data {
        statsTitle
        timePeriod
        questionnaire
        count
      }
    }
  }
`;
