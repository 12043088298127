import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductCreate from './pages/ProductCreate';
import ProductDocument from './pages/ProductDocument';
import ProductEdit from './pages/ProductEdit';
import ProductGallery from './pages/ProductGallery';
import ProductItem from './pages/ProductItem';
import ProductList from './pages/ProductList';
import ProductView from './pages/ProductView';
import './productModule.less';

const ProductWrapper = () => {
  return (
    <div className="product-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PACKAGES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_PRODUCT} showNoAccess>
              <ProductList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_CREATE']}
              showNoAccess
            >
              <ProductCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductEdit {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/view/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductView {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/view${ROUTES?.PRODUCTS}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductItem {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/view${ROUTES?.GALLERY}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductGallery {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/view${ROUTES?.DOCUMENTS}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductDocument {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductItem {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/edit${ROUTES?.GALLERY}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductGallery {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PACKAGES}/edit${ROUTES?.DOCUMENTS}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
              showNoAccess
            >
              <ProductDocument {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ProductWrapper;
