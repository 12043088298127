import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import PricingModifierForm from '../components/PricingModifierForm';

function PricingModifierCreate() {
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRICING_MODIFIERS} />
          <span className="portal-header">Add Quote Modifier</span>
        </div>
      </Portal>
      <PricingModifierForm />
    </Card>
  );
}

export default withRouter(PricingModifierCreate);
