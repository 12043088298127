import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_USER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import UserCreate from './pages/UserCreate';
import UserEdit from './pages/UserEdit';
import UserList from './pages/UserList';
import './userModule.less';

const UserWrapper = () => {
  return (
    <div className="user-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.USERS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_USER} showNoAccess>
              <UserList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.USERS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_USER_CREATE']}
              showNoAccess
            >
              <UserCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.USERS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_USER_UPDATE', 'FET_USER_VIEW']}
              showNoAccess
            >
              <UserEdit {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default UserWrapper;
