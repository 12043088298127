import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, SKIP_RECORD } from '../../../common/constants';
import {
  dateFormatWithoutTime,
  formValidatorRules
} from '../../../common/utils';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { FETCH_STATE_CITY } from '../../signup/graphql/Query';
import {
  PROPERTY_FINISHED_BASEMENTS,
  PROPERTY_FINISHED_BASEMENTS_CEILINGS,
  PROPERTY_HEAT_SOURCES,
  PROPERTY_TYPES_FILTER,
  PROPERTY_WATER_HOOKUPS
} from '../graphql/Queries';

let stateScrollDebounce;
let cityScrollDebounceJob;
let PropertyTypesScrollDebounceJob;
let PropertyHeatSourcesScrollDebounceJob;
let PropertyWaterHookupsScrollDebounceJob;
let PropertyFinishedBasementsScrollDebounceJob;
let PropertyFinishedBasementCeilingsScrollDebounceJob;

const {
  required,
  requiredWhiteSpaceAllowed,
  zipCode,
  address
} = formValidatorRules;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC'
};

const PropertyTypesFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const PropertyHeatSourcesFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const PropertyWaterHookupsFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const PropertyFinishedBasementsFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const PropertyFinishedBasementCeilingsFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const { Option } = SelectComponent;

const PropertyForm = (props) => {
  const { propertyData, handleSubmit, isSubmit } = props;
  const {
    state: { globalDateFormat },
    dispatch
  } = useContext(AppContext);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [callAsync, setCallAsync] = useState(false);
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);

  const [propertyTypesLoading, setPropertyTypesLoading] = useState(false);
  const [propertyTypesSearchFlag, setPropertyTypesSearchFlag] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [propertyHeatSourcesLoading, setPropertyHeatSourcesLoading] = useState(
    false
  );
  const [
    propertyHeatSourcesSearchFlag,
    setPropertyHeatSourcesSearchFlag
  ] = useState(false);
  const [propertyHeatSources, setPropertyHeatSources] = useState([]);

  const [
    propertyWaterHookupsLoading,
    setPropertyWaterHookupsLoading
  ] = useState(false);
  const [
    propertyWaterHookupsSearchFlag,
    setPropertyWaterHookupsSearchFlag
  ] = useState(false);
  const [propertyWaterHookups, setPropertyWaterHookups] = useState([]);

  const [
    propertyFinishedBasementsLoading,
    setPropertyFinishedBasementsLoading
  ] = useState(false);
  const [
    propertyFinishedBasementsSearchFlag,
    setPropertyFinishedBasementsSearchFlag
  ] = useState(false);
  const [propertyFinishedBasements, setPropertyFinishedBasements] = useState(
    []
  );

  const [
    propertyFinishedBasementCeilingsLoading,
    setPropertyFinishedBasementCeilingsLoading
  ] = useState(false);
  const [
    propertyFinishedBasementCeilingsSearchFlag,
    setPropertyFinishedBasementCeilingsSearchFlag
  ] = useState(false);
  const [
    propertyFinishedBasementCeilings,
    setPropertyFinishedBasementCeilings
  ] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [getPropertyTypes] = useLazyQuery(PROPERTY_TYPES_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.propertyTypes?.data?.length < SKIP_RECORD);
      if (propertyTypesSearchFlag) {
        setPropertyTypes([...res?.propertyTypes?.data]);
      } else {
        setPropertyTypes([...propertyTypes, ...res?.propertyTypes?.data]);
      }
      setPropertyTypesLoading(false);
    },
    onError() {
      setPropertyTypesLoading(false);
    }
  });

  const onPropertyTypesScroll = (event) => {
    setPropertyTypesSearchFlag(false);
    if (PropertyTypesScrollDebounceJob) {
      PropertyTypesScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    PropertyTypesScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setPropertyTypesLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getPropertyTypes({
          variables: {
            filter: {
              ...PropertyTypesFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    PropertyTypesScrollDebounceJob();
  };

  const handlePropertyTypesChange = (value) => {
    setPropertyTypesSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPropertyTypesLoading(true);
      getPropertyTypes({
        variables: {
          filter: {
            ...PropertyTypesFilter,
            search: value
          }
        }
      });
    } else {
      setPropertyTypesLoading(true);
      getPropertyTypes({
        variables: {
          filter: {
            ...PropertyTypesFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPropertyTypesHandler = useCallback(
    debounce(handlePropertyTypesChange, 500),
    []
  );

  const handlePropertyTypesClear = () => {
    form?.setFieldsValue({
      PropertyType: null
    });
    setPropertyTypes([]);
    getPropertyTypes({
      variables: { filter: PropertyTypesFilter, where: { isActive: true } }
    });
  };

  const handlePropertyTypesBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [getPropertyHeatSources] = useLazyQuery(PROPERTY_HEAT_SOURCES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.propertyHeatSources?.data?.length < SKIP_RECORD);
      if (propertyHeatSourcesSearchFlag) {
        setPropertyHeatSources([...res?.propertyHeatSources?.data]);
      } else {
        setPropertyHeatSources([
          ...propertyHeatSources,
          ...res?.propertyHeatSources?.data
        ]);
      }
      setPropertyHeatSourcesLoading(false);
    },
    onError() {
      setPropertyHeatSourcesLoading(false);
    }
  });

  const onPropertyHeatSourcesScroll = (event) => {
    setPropertyHeatSourcesSearchFlag(false);
    if (PropertyHeatSourcesScrollDebounceJob) {
      PropertyHeatSourcesScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    PropertyHeatSourcesScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setPropertyHeatSourcesLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getPropertyHeatSources({
          variables: {
            filter: {
              ...PropertyHeatSourcesFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    PropertyHeatSourcesScrollDebounceJob();
  };

  const handlePropertyHeatSourcesChange = (value) => {
    setPropertyHeatSourcesSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPropertyHeatSourcesLoading(true);
      getPropertyHeatSources({
        variables: {
          filter: {
            ...PropertyHeatSourcesFilter,
            search: value
          }
        }
      });
    } else {
      setPropertyHeatSourcesLoading(true);
      getPropertyHeatSources({
        variables: {
          filter: {
            ...PropertyHeatSourcesFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPropertyHeatSourcesHandler = useCallback(
    debounce(handlePropertyHeatSourcesChange, 500),
    []
  );

  const handlePropertyHeatSourcesClear = () => {
    form?.setFieldsValue({
      heatSource: null
    });
    setPropertyHeatSources([]);
    getPropertyHeatSources({
      variables: {
        filter: PropertyHeatSourcesFilter,
        where: { isActive: true }
      }
    });
  };

  const handlePropertyHeatSourcesBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [getPropertyWaterHookups] = useLazyQuery(PROPERTY_WATER_HOOKUPS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.propertyWaterHookUps?.data?.length < SKIP_RECORD);
      if (propertyWaterHookupsSearchFlag) {
        setPropertyWaterHookups([...res?.propertyWaterHookups?.data]);
      } else {
        setPropertyWaterHookups([
          ...propertyWaterHookups,
          ...res?.propertyWaterHookUps?.data
        ]);
      }
      setPropertyWaterHookupsLoading(false);
    },
    onError() {
      setPropertyWaterHookupsLoading(false);
    }
  });

  const onPropertyWaterHookupsScroll = (event) => {
    setPropertyWaterHookupsSearchFlag(false);
    if (PropertyWaterHookupsScrollDebounceJob) {
      PropertyWaterHookupsScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    PropertyWaterHookupsScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setPropertyWaterHookupsLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getPropertyWaterHookups({
          variables: {
            filter: {
              ...PropertyWaterHookupsFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    PropertyWaterHookupsScrollDebounceJob();
  };

  const handlePropertyWaterHookupsChange = (value) => {
    setPropertyWaterHookupsSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPropertyWaterHookupsLoading(true);
      getPropertyWaterHookups({
        variables: {
          filter: {
            ...PropertyWaterHookupsFilter,
            search: value
          }
        }
      });
    } else {
      setPropertyWaterHookupsLoading(true);
      getPropertyWaterHookups({
        variables: {
          filter: {
            ...PropertyWaterHookupsFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPropertyWaterHookupsHandler = useCallback(
    debounce(handlePropertyWaterHookupsChange, 500),
    []
  );

  const handlePropertyWaterHookupsClear = () => {
    form?.setFieldsValue({
      waterHookUp: null
    });
    setPropertyWaterHookups([]);
    getPropertyWaterHookups({
      variables: {
        filter: PropertyWaterHookupsFilter,
        where: { isActive: true }
      }
    });
  };

  const handlePropertyWaterHookupsBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [getPropertyFinishedBasements] = useLazyQuery(
    PROPERTY_FINISHED_BASEMENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setIsEnd(res?.propertyFinishedBasements?.data?.length < SKIP_RECORD);
        if (propertyFinishedBasementsSearchFlag) {
          setPropertyFinishedBasements([
            ...res?.propertyFinishedBasements?.data
          ]);
        } else {
          setPropertyFinishedBasements([
            ...propertyFinishedBasements,
            ...res?.propertyFinishedBasements?.data
          ]);
        }
        setPropertyFinishedBasementsLoading(false);
      },
      onError() {
        setPropertyFinishedBasementsLoading(false);
      }
    }
  );

  const onPropertyFinishedBasementsScroll = (event) => {
    setPropertyFinishedBasementsSearchFlag(false);
    if (PropertyFinishedBasementsScrollDebounceJob) {
      PropertyFinishedBasementsScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    PropertyFinishedBasementsScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setPropertyFinishedBasementsLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getPropertyFinishedBasements({
          variables: {
            filter: {
              ...PropertyFinishedBasementsFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    PropertyFinishedBasementsScrollDebounceJob();
  };

  const handlePropertyFinishedBasementsChange = (value) => {
    setPropertyFinishedBasementsSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPropertyFinishedBasementsLoading(true);
      getPropertyFinishedBasements({
        variables: {
          filter: {
            ...PropertyFinishedBasementsFilter,
            search: value
          }
        }
      });
    } else {
      setPropertyFinishedBasementsLoading(true);
      getPropertyFinishedBasements({
        variables: {
          filter: {
            ...PropertyFinishedBasementsFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPropertyFinishedBasementsHandler = useCallback(
    debounce(handlePropertyFinishedBasementsChange, 500),
    []
  );

  const handlePropertyFinishedBasementsClear = () => {
    form?.setFieldsValue({
      propertyFinishedBasement: null
    });
    setPropertyFinishedBasements([]);
    getPropertyFinishedBasements({
      variables: {
        filter: PropertyFinishedBasementsFilter,
        where: { isActive: true }
      }
    });
  };

  const handlePropertyFinishedBasementsBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [getPropertyFinishedBasementCeilings] = useLazyQuery(
    PROPERTY_FINISHED_BASEMENTS_CEILINGS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setIsEnd(
          res?.propertyFinishedBasementCeilings?.data?.length < SKIP_RECORD
        );
        if (propertyFinishedBasementCeilingsSearchFlag) {
          setPropertyFinishedBasementCeilings([
            ...res?.propertyFinishedBasementCeilings?.data
          ]);
        } else {
          setPropertyFinishedBasementCeilings([
            ...propertyFinishedBasementCeilings,
            ...res?.propertyFinishedBasementCeilings?.data
          ]);
        }
        setPropertyFinishedBasementCeilingsLoading(false);
      },
      onError() {
        setPropertyFinishedBasementCeilingsLoading(false);
      }
    }
  );

  const onPropertyFinishedBasementCeilingsScroll = (event) => {
    setPropertyFinishedBasementCeilingsSearchFlag(false);
    if (PropertyFinishedBasementCeilingsScrollDebounceJob) {
      PropertyFinishedBasementCeilingsScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    PropertyFinishedBasementCeilingsScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setPropertyFinishedBasementCeilingsLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getPropertyFinishedBasementCeilings({
          variables: {
            filter: {
              ...PropertyFinishedBasementCeilingsFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    PropertyFinishedBasementCeilingsScrollDebounceJob();
  };

  const handlePropertyFinishedBasementCeilingsChange = (value) => {
    setPropertyFinishedBasementCeilingsSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPropertyFinishedBasementCeilingsLoading(true);
      getPropertyFinishedBasementCeilings({
        variables: {
          filter: {
            ...PropertyFinishedBasementCeilingsFilter,
            search: value
          }
        }
      });
    } else {
      setPropertyFinishedBasementCeilingsLoading(true);
      getPropertyFinishedBasementCeilings({
        variables: {
          filter: {
            ...PropertyFinishedBasementCeilingsFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPropertyFinishedBasementCeilingsHandler = useCallback(
    debounce(handlePropertyFinishedBasementCeilingsChange, 500),
    []
  );

  const handlePropertyFinishedBasementCeilingsClear = () => {
    form?.setFieldsValue({
      propertyFinishedCeiling: null
    });
    setPropertyFinishedBasementCeilings([]);
    getPropertyFinishedBasementCeilings({
      variables: {
        filter: PropertyFinishedBasementCeilingsFilter,
        where: { isActive: true }
      }
    });
  };

  const handlePropertyFinishedBasementCeilingsBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    }
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: propertyData?.state
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    getPropertyTypes({
      variables: {
        filter: PropertyTypesFilter,
        where: { isActive: true }
      }
    });
    getPropertyHeatSources({
      variables: {
        filter: PropertyHeatSourcesFilter,
        where: { isActive: true }
      }
    });
    getPropertyWaterHookups({
      variables: {
        filter: PropertyWaterHookupsFilter,
        where: { isActive: true }
      }
    });
    getPropertyFinishedBasements({
      variables: {
        filter: PropertyFinishedBasementsFilter,
        where: { isActive: true }
      }
    });
    getPropertyFinishedBasementCeilings({
      variables: {
        filter: PropertyFinishedBasementCeilingsFilter,
        where: { isActive: true }
      }
    });
    if (!propertyData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (propertyData?.country) {
      setDisableState(false);
    }
    if (propertyData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(propertyData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState
        }
      }
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE'
            }
          }
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState
            }
          }
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value
          }
        }
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value
          }
        }
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE'
          }
        }
      });
    }
  };

  const handleSelectCountry = (value) => {
    if (value) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state')
          }
        }
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state')
          }
        }
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null
    });
    setDisableState(true);
    setDisableCity(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter
      }
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  return (
    <div>
      <Portal portalId="header-right-content">
        <Button
          className="common-button discard-button"
          icon={<DeleteIconComponent />}
          size="small"
          id="property-table-discard-btn"
          onClick={() => history?.goBack()}
        >
          Discard Changes
        </Button>
        <Button
          className="common-button"
          icon={<img src={SaveIcon} alt="save-icon" width={12} />}
          size="small"
          htmlType="submit"
          id="property-table-save-btn"
          loading={isSubmit}
          type="primary"
          onClick={form?.submit}
        >
          Save
        </Button>
      </Portal>
      <Form
        form={form}
        initialValues={propertyData}
        layout="vertical"
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider " />
        <Row gutter={16} className="required-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Name' }]}
              name="name"
              label="Name"
            >
              <InputComponent allowClear placeholder="Enter name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyType"
              label="Type"
              rules={[{ ...required, message: 'Please Select Property Type' }]}
            >
              <SelectComponent
                placeholder="Select Property Type"
                allowClear
                notFoundContent={
                  propertyTypesLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePropertyTypesBlur}
                onSearch={debouncedPropertyTypesHandler}
                onClear={handlePropertyTypesClear}
                onPopupScroll={onPropertyTypesScroll}
              >
                {map(propertyTypes, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="builtYear"
              label="Built Year"
              rules={[
                {
                  ...required,
                  message: 'Please Enter Built Year',
                  type: 'object'
                }
              ]}
            >
              <DatePickerComponent
                disableFutureDate
                disabledDate
                placeholder="Enter Built Year"
                picker="year"
                format="YYYY"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Is Active'
                }
              ]}
            >
              <SelectComponent placeholder="Enter Status" allowClear>
                <Option key="active" value>
                  Active
                </Option>
                <Option key="inactive" value={false}>
                  InActive
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              rules={[
                { ...required, message: 'Please Enter Address' },
                address
              ]}
            >
              <InputComponent allowClear placeholder="Enter Address" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ ...required, message: 'Please Select Country' }]}
            >
              <SelectComponent
                placeholder="Select Country"
                onSelect={handleSelectCountry}
                onClear={handleCountryClear}
                allowClear
              >
                <Option key="USA" value="USA">
                  USA
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ ...required, message: 'Please Select State' }]}
            >
              <SelectComponent
                placeholder="Select State"
                disabled={disableState}
                allowClear
                notFoundContent={
                  stateLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedStateHandler}
                onClear={handleStateClear}
                onSelect={handleStateSelect}
                onPopupScroll={onStateScroll}
                onBlur={handleStateBlur}
              >
                {map(states, (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ ...required, message: 'Please Select City' }]}
            >
              <SelectComponent
                placeholder="Select City"
                disabled={disableCity}
                notFoundContent={
                  cityLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedCityHandler}
                onPopupScroll={onCityScroll}
                onClear={handleCityClear}
                onBlur={handleCityBlur}
              >
                {cities?.map((city) => (
                  <Option key={city?.id} value={city?.name}>
                    {city?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="zipCode"
              rules={[
                zipCode,
                { ...required, message: 'Please Enter Postal Code' }
              ]}
              label="Postal Code"
            >
              <NumberComponent placeholder="Enter Postal code" />
            </Form.Item>
          </Col>
        </Row>
        <span className="form-divider-text optional-divider">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="propertyValue" label="Property Value">
              <PriceComponent placeholder="Enter Property Value" isPrice />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="floor" label="Floor">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Floor"
                decimalScale={false}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="internalSqFt" label="Internal Sq. Ft">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Internal Sq. Ft"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lotSqFt" label="Lot sq. ft">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Lot sq. ft"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lotsFrontWidth" label="Lots Front Width">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Lots Front Width"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lotsRightWidth" label="Lots Right Width">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Lots Right Width"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lotsLeftWidth" label="Lots Left Width">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Lots Left Width"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lotsBackWidth" label="Lots Back Width">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter Lots Back Width"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="pinNumber" label="Pin Number">
              <NumberComponent allowClear placeholder="Enter Pin Number" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="heatSource" label="Heat Source">
              <SelectComponent
                placeholder="Select Heat Source"
                allowClear
                notFoundContent={
                  propertyHeatSourcesLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePropertyHeatSourcesBlur}
                onSearch={debouncedPropertyHeatSourcesHandler}
                onClear={handlePropertyHeatSourcesClear}
                onPopupScroll={onPropertyHeatSourcesScroll}
              >
                {map(propertyHeatSources, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="waterHookUp" label="Water Hook Up">
              <SelectComponent
                placeholder="Select Water Hook Up"
                allowClear
                notFoundContent={
                  propertyWaterHookupsLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePropertyWaterHookupsBlur}
                onSearch={debouncedPropertyWaterHookupsHandler}
                onClear={handlePropertyWaterHookupsClear}
                onPopupScroll={onPropertyWaterHookupsScroll}
              >
                {map(propertyWaterHookups, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="noBaths" label="No. of Baths">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter No. of Baths"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="noHalfBaths" label="No. of 1/2 Baths">
              <PriceComponent
                showPrefix={false}
                placeholder="Enter No. of 1/2 Baths"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyFinishedBasement"
              label="Finished Basement"
            >
              <SelectComponent
                placeholder="Select Finished Basement"
                allowClear
                notFoundContent={
                  propertyFinishedBasementsLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePropertyFinishedBasementsBlur}
                onSearch={debouncedPropertyFinishedBasementsHandler}
                onClear={handlePropertyFinishedBasementsClear}
                onPopupScroll={onPropertyFinishedBasementsScroll}
              >
                {map(propertyFinishedBasements, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyFinishedCeiling"
              label="Finished Basement Ceiling"
            >
              <SelectComponent
                placeholder="Select Finished Basement Ceiling"
                allowClear
                notFoundContent={
                  propertyFinishedBasementCeilingsLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePropertyFinishedBasementCeilingsBlur}
                onSearch={debouncedPropertyFinishedBasementCeilingsHandler}
                onClear={handlePropertyFinishedBasementCeilingsClear}
                onPopupScroll={onPropertyFinishedBasementCeilingsScroll}
              >
                {map(propertyFinishedBasementCeilings, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyLastConfirmed"
              label="Property Information last confirmed on"
            >
              <DatePickerComponent
                disableFutureDate
                disabledDate
                placeholder="Enter Property Information last confirmed on"
                format={dateFormatWithoutTime(globalDateFormat)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="lockBoxCode" label="Lock Box Code">
              <InputComponent allowClear placeholder="Enter Lock Box Code" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyManagementCompany"
              label="Project Management Company"
            >
              <InputComponent
                allowClear
                placeholder="Enter Project Management Company"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="propertyManagerPhone"
              label="Property Manager Phone"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('should be a valid number');
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="thirdPartyProvider" label="Third Party Provider">
              <InputComponent
                allowClear
                placeholder="Enter Third Party Provider"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="historyDistrict" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                History District
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="elevator" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Elevator
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="centralAir" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Central Air
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="centralHeat" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Central Heat
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="cornerLot" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Corner Lot
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="parking" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Parking
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="taxParcel" valuePropName="checked">
              <Checkbox className="common-checkbox property-checkbox">
                Tax Parcel
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PropertyForm;
