/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify',
  JOBS: '/jobs',
  NOTIFICATION: '/notification',
  PROFILE: '/profile',
  CUSTOMERS: '/customers',
  EMPLOYEES: '/employees',
  IMPORTS: '/imports',
  ONBOARDING: '/onboarding',
  MANUFACTURERS: '/manufacturers',
  PRODUCT_CATEGORIES: '/product-categories',
  CAMPAIGNS: '/campaigns',
  LINE_OF_BUSINESSES: '/line-of-businesses',
  SUB_AREAS: '/sub-areas',
  ROLES: '/roles',
  RETAILERS: '/retailers',
  REGIONS: '/regions',
  PRODUCTS: '/products',
  PACKAGES: '/packages',
  PRICING_MODIFIERS: '/quote-modifiers',
  QUESTIONNAIRES: '/questionnaires',
  QUESTIONNAIRES_PRIMARY: '/questionnaires/builder',
  QUESTIONNAIRES_SECONDARY: '/questionnaires/path',
  POSTAl_CODE: '/postal-code',
  CUSTOMER_GOAL: '/customer-goal',
  CURRENT_PRODUCT: '/current-product',
  NEW_PRODUCT: '/new-product',
  QUOTES: '/quotes',
  SCHEDULED_APPOINTMENT: '/scheduled-appointment',
  CONTACT_ME: '/contact-me',
  UPGRADE: '/upgrade',
  THANK_YOU: '/thank-you',
  PROPERTIES: '/properties',
  CONTACTS: '/contacts',
  TENANT_SETTING: '/tenant-setting',
  BUSINESS_INFORMATION: '/business-information',
  HOURS_OF_OPERATION: '/hours-of-operation',
  MY_ACCOUNT_SETTING: '/my-account',
  PLAN_SETTING: '/plan-setting',
  QUOTE_VIEWER: '/quote-viewer',
  DASHBOARD: '/dashboard',
  QUESTIONNAIRE_RESULT_VALIDATION: '/questionnaire-result-validation',
  API_SETTING: '/api-setting',
  GALLERY: '/gallery',
  DOCUMENTS: '/documents',
  PERMISSIONS: '/permissions',
  NO_ACCESS: '/no-access',
  PRODUCT_PRICING: '/product-pricing',
  VERIFY_EMAIL: '/verify-email',
  INDUSTRIES: '/industries',
  DYNAMIC_FIELDS: '/dynamic-fields',
  PAGE: '/page',
  IMPORT_EXPORT_QUEUE: '/import-export-queue'
};

/*  Modules */
export const MODULES = {
  USERS_MANAGEMENT: 'User Management',
  USERS: 'Users',
  JOBS: 'Jobs',
  NOTIFICATION: 'Notifications',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  USER_ACCOUNT: 'User Account',
  CUSTOMERS: 'Customers',
  EMPLOYEES: 'Employees',
  IMPORTS: 'Imports',
  ONBOARDING: 'OnBoarding',
  CATALOG: 'Catalog',
  MANUFACTURERS: 'Manufacturers',
  PRODUCT_CATEGORIES: 'Categories',
  LINE_OF_BUSINESSES: 'Lines of Business',
  SUB_AREAS: 'Sub Areas',
  ROLES: 'Roles',
  RETAILERS: 'Retailers',
  REGIONS: 'Regions',
  PRODUCTS: 'Products',
  PACKAGES: 'Packages',
  PRICING_MODIFIERS: 'Quote Modifiers',
  MY_QUESTIONNAIRES: 'My Questionnaires',
  QUESTIONNAIRES: 'Questionnaires',
  PROPERTIES: 'Properties',
  SALES: 'Sales',
  CONTACTS: 'Contacts',
  SETTING: 'Settings',
  TENANT_SETTING: 'Tenant',
  BUSINESS_INFORMATION: 'Business Information',
  HOURS_OF_OPERATION: 'Hours Of Operation',
  MY_ACCOUNT_SETTING: 'My Account',
  PLAN_SETTING: 'Plans',
  CUSTOMER_TYPES: 'Customer Types',
  QUOTE_VIEWER: 'Quote Viewer',
  CAMPAIGNS: 'Campaigns',
  QUESTIONNAIRE_RESULT_VALIDATION: 'Questionnaire Results Validation',
  QRV: 'QRV',
  DASHBOARD: 'Dashboard',
  API_SETTING: 'API Setting',
  PERMISSIONS: 'Permissions',
  PRODUCT_PRICING: 'Product Pricing',
  INDUSTRIES: 'Industries',
  DYNAMIC_FIELDS: 'Dynamic Fields',
  IMPORT_EXPORT_QUEUE: 'Import/Export Queue'
};

/* primary color config for js files */

export const PRIMARY_COLOR = '#1d2228';

/* signup steps */
export const STEPS = {
  ADMINISTRATOR: 'Administrator',
  BUSINESS_INFORMATION: 'Business Info',
  PLAN: 'Plan',
  SUCCESS: 'Success'
};
/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const SIGNUP_AUTH = 'SIGNUP_AUTH';

/* LocalStorage */
export const PACKAGE_RANKINGS = 'PACKAGE_RANKINGS';
export const QUESTIONNAIRE_RESULT_VALIDATION =
  'QUESTIONNAIRE_RESULT_VALIDATION';
export const DASHBOARD = 'DASHBOARD';
export const PRODUCT_PRICING = 'PRODUCT_PRICING';
export const DYNAMIC_SELECTED_COLUMN = 'DYNAMIC_SELECTED_COLUMN';

/* cpq common string */
export const SAME_DAY_TIME = 'Time to schedule same day service';

export const ROLES = {
  ADMIN: 'Admin',
  USER: 'User',
  SALES: 'Sales'
};

export const ROLE_KEYS = {
  TENANT_ADMIN: 'TENANT_ADMIN',
  SALES_MANAGER: 'SALES_MANAGER',
  DISPATCH_MANAGER: 'DISPATCH_MANAGER',
  BILLING_MANAGER: 'BILLING_MANAGER',
  CSR: 'CSR',
  ISR: 'ISR',
  PARTNER: 'PARTNER',
  CUSTOMER: 'CUSTOMER',
  TECHNICIAN: 'TECHNICIAN'
};

/*  Google Upload Action */

export const READ = 'read';
export const WRITE = 'write';

/* allow file types  */

export const ALLOW_TYPES = ['application/vnd.ms-excel', 'text/csv'];

export const ALLOW_IMPORT_TYPES = ['csv'];

export const ALLOW_FILE_TYPES = [
  'docx',
  'doc',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'csv',
  'xlsx'
];

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg'];

/* Date and time */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_CPQ = 'yyyy-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

/* Table Height */
export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 230;

export const REGEX = {
  NAME: /^[a-zA-Z\s():;`~'",._\-[\]]*$/i,
  ADDRESS: /^[a-z0-9 ,.'-]+$/i,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  LOWER_CASE: /[a-z]/g,
  UPPER_CASE: /[A-Z]/g,
  DIGIT: /[0-9]/g,
  SPECIAL_CHAR: /[`!@#$%^&*]/g,
  ZIPCODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
  SUB_DOMAIN: /^[a-z0-9-]+(?:-[a-z0-9]+)*$/,
  DATE_FORMAT: /^[lL]{0,4}$/,
  HTML: /<[a-z/][\s\S]*>/i,
  NO_SPECIAL_CHAR: /^(\d|\w)+$/gim,
  NON_NUMERIC_STARTING_NO_SPACE_IN_BETWEEN_NO_SPECIAL_CHAR: /^[^0-9\s\W][^\s\W]*$/gim,
  GTM_CODE: /^GTM-[A-Z0-9]{6,8}$/i,
  HOTJAR_CODE: /^[0-9]{6,8}$/,
  NOT_ALLOWED_CHARS_IN_PASSWORD: /[^A-Za-z\d!@#$%^&*]/
};

export const WHOM_TO_SHOW_SIDE_PANEL = [
  ROUTES?.DASHBOARD,
  ROUTES?.QUESTIONNAIRES,
  ROUTES?.PRODUCT_PRICING,
  ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION,
  ROUTES?.JOBS
];

export const USER_PERMISSION = [
  'read',
  'write',
  'update',
  'delete',
  'showNotification'
];

export const IMPORT_STATUS = {
  VERIFIED: 'Verified',
  INPROGRESS: 'In Progress',
  FAILED: 'Failed'
};

export const SMALL_TABLET = 1000;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;

export const SKIP_RECORD = 20;
export const CONTACT_NUMBER = '8669288545';
export const EMAIL_ADDRESS = 'contact@drftps.com';

export const CPQ_MAX_LIMIT = 10;

export const CPQ_URL = 'path.pro';
export const PORTAL_WIDTH = 768;
export const HORIZONTAL_PADDING = 40;

export const importObj = {
  REGION: {
    fileObj: {
      key: 'assets/imports/regions-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/regions-sample.csv`,
      name: 'regions-sample.csv'
    }
  },
  BRAND: {
    fileObj: {
      key: 'assets/imports/retailers-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/retailers-sample.csv`,
      name: 'retailers-sample.csv'
    }
  },
  MANUFACTURER: {
    fileObj: {
      key: 'assets/imports/manufacturers-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/manufacturers-sample.csv`,
      name: 'manufacturers-sample.csv'
    }
  },
  PRODUCT_CATEGORY: {
    fileObj: {
      key: 'assets/imports/product-categories-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/product-categories-sample.csv`,
      name: 'product-categories-sample.csv'
    }
  },
  PRODUCT_ITEM: {
    fileObj: {
      key: 'assets/imports/products-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/products-sample.csv`,
      name: 'products-sample.csv'
    }
  },
  PRODUCT: {
    fileObj: {
      key: 'assets/imports/packages-sample.csv',
      url: `https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/assets/imports/packages-sample.csv`,
      name: 'packages-sample.csv'
    }
  }
};

// Permissions

export const PERMISSION_DASHBOARD = [
  'FET_DASHBOARD_LIST',
  'FET_DASHBOARD_EXPORT'
];

export const PERMISSION_QUESTIONNAIRE = [
  'FET_QUESTIONNAIRE_CREATE',
  'FET_QUESTIONNAIRE_UPDATE',
  'FET_QUESTIONNAIRE_DELETE',
  'FET_QUESTIONNAIRE_VIEW',
  'FET_QUESTIONNAIRE_LIST'
];

export const PERMISSION_QUOTE_VIEW = [
  'FET_QUOTE_VIEW_LIST',
  'FET_QUOTE_VIEW_EXPORT',
  'FET_QUOTE_VIEW_VIEW'
];

export const PERMISSION_PRODUCT_ITEM = [
  'FET_PRODUCT_ITEM_CREATE',
  'FET_PRODUCT_ITEM_UPDATE',
  'FET_PRODUCT_ITEM_DELETE',
  'FET_PRODUCT_ITEM_VIEW',
  'FET_PRODUCT_ITEM_LIST',
  'FET_PRODUCT_ITEM_IMPORT',
  'FET_PRODUCT_ITEM_EXPORT'
];

export const PERMISSION_PRODUCT = [
  'FET_PRODUCT_CREATE',
  'FET_PRODUCT_UPDATE',
  'FET_PRODUCT_DELETE',
  'FET_PRODUCT_VIEW',
  'FET_PRODUCT_LIST',
  'FET_PRODUCT_IMPORT',
  'FET_PRODUCT_EXPORT'
];

export const PERMISSION_PRODUCT_PRICING = [
  'FET_PRODUCT_PRICING_UPDATE',
  'FET_PRODUCT_PRICING_LIST',
  'FET_PRODUCT_PRICING_IMPORT',
  'FET_PRODUCT_PRICING_EXPORT'
];

export const PERMISSION_PRODUCT_RANKING = [
  'FET_PRODUCT_RANKING_UPDATE',
  'FET_PRODUCT_RANKING_LIST'
];

export const PERMISSION_PRICING_MODIFIER = [
  'FET_PRICING_MODIFIER_CREATE',
  'FET_PRICING_MODIFIER_UPDATE',
  'FET_PRICING_MODIFIER_DELETE',
  'FET_PRICING_MODIFIER_VIEW',
  'FET_PRICING_MODIFIER_LIST'
];

export const PERMISSION_PRODUCT_CATEGORY = [
  'FET_PRODUCT_CATEGORY_CREATE',
  'FET_PRODUCT_CATEGORY_UPDATE',
  'FET_PRODUCT_CATEGORY_DELETE',
  'FET_PRODUCT_CATEGORY_VIEW',
  'FET_PRODUCT_CATEGORY_LIST',
  'FET_PRODUCT_CATEGORY_IMPORT',
  'FET_PRODUCT_CATEGORY_EXPORT'
];

export const PERMISSION_CAMPAIGNS = [
  'FET_CAMPAIGN_CREATE',
  'FET_CAMPAIGN_UPDATE',
  'FET_CAMPAIGN_DELETE',
  'FET_CAMPAIGN_VIEW',
  'FET_CAMPAIGN_LIST',
  'FET_CAMPAIGN_IMPORT',
  'FET_CAMPAIGN_EXPORT'
];

export const PERMISSION_MANUFACTURER = [
  'FET_MANUFACTURER_CREATE',
  'FET_MANUFACTURER_UPDATE',
  'FET_MANUFACTURER_DELETE',
  'FET_MANUFACTURER_VIEW',
  'FET_MANUFACTURER_LIST',
  'FET_MANUFACTURER_IMPORT',
  'FET_MANUFACTURER_EXPORT'
];

export const PERMISSION_REGION = [
  'FET_REGION_CREATE',
  'FET_REGION_UPDATE',
  'FET_REGION_DELETE',
  'FET_REGION_VIEW',
  'FET_REGION_LIST',
  'FET_REGION_IMPORT',
  'FET_REGION_EXPORT'
];

export const PERMISSION_RETAILER = [
  'FET_BRAND_CREATE',
  'FET_BRAND_UPDATE',
  'FET_BRAND_DELETE',
  'FET_BRAND_VIEW',
  'FET_BRAND_LIST',
  'FET_BRAND_IMPORT',
  'FET_BRAND_EXPORT'
];

export const PERMISSION_USER = [
  'FET_USER_CREATE',
  'FET_USER_UPDATE',
  'FET_USER_DELETE',
  'FET_USER_VIEW',
  'FET_USER_LIST',
  'FET_USER_IMPORT',
  'FET_USER_EXPORT'
];

export const PERMISSION_ROLE = [
  'FET_ROLE_CREATE',
  'FET_ROLE_UPDATE',
  'FET_ROLE_VIEW',
  'FET_ROLE_LIST'
];

export const PERMISSION_USER_PERMISSION = [
  'FET_USER_PERMISSION_UPDATE',
  'FET_USER_PERMISSION_LIST'
];

export const PERMISSION_LOB = [
  'FET_LINE_OF_BUSINESS_CREATE',
  'FET_LINE_OF_BUSINESS_UPDATE',
  'FET_LINE_OF_BUSINESS_VIEW',
  'FET_LINE_OF_BUSINESS_LIST'
];

export const PERMISSION_SUBAREA = [
  'FET_SUBAREA_CREATE',
  'FET_SUBAREA_UPDATE',
  'FET_SUBAREA_VIEW',
  'FET_SUBAREA_LIST'
];

export const PERMISSION_DYNAMIC_FIELDS = [
  'FET_DYNAMIC_FIELD_CREATE',
  'FET_DYNAMIC_FIELD_UPDATE',
  'FET_DYNAMIC_FIELD_DELETE',
  'FET_DYNAMIC_FIELD_LIST',
  'FET_DYNAMIC_FIELD_VIEW',
  'FET_DYNAMIC_FIELD_IMPORT',
  'FET_DYNAMIC_FIELD_EXPORT'
];

export const PERMISSION_TENANT_SETTING = [
  'FET_TENANT_SETTINGS_UPDATE',
  'FET_TENANT_SETTINGS_VIEW'
];

export const PERMISSION_API_SETTING = [
  'FET_API_SETTINGS_UPDATE',
  'FET_API_SETTINGS_VIEW'
];

export const PERMISSION_HOURS_OF_OPERATION_SETTING = [
  'FET_HOURS_OF_OPERATION_SETTINGS_UPDATE',
  'FET_HOURS_OF_OPERATION_SETTINGS_VIEW'
];

export const PERMISSION_RETAILER_SETTING = [
  'FET_RETAILER_SETTINGS_UPDATE',
  'FET_RETAILER_SETTINGS_VIEW'
];

export const PERMISSION_PLAN_SETTING = ['FET_PLAN_SETTINGS_VIEW'];

export const PERMISSION_MY_ACCOUNT_SETTING = [
  'FET_MY_ACCOUNT_SETTINGS_VIEW',
  'FET_MY_ACCOUNT_SETTINGS_UPDATE'
];

export const PERMISSION_INDUSTRY = [
  'FET_INDUSTRY_UPDATE',
  'FET_INDUSTRY_LIST',
  'FET_INDUSTRY_VIEW'
];

export const PERMISSION_IMPORT_EXPORT_QUEUE = ['FET_IMPORT_EXPORT_QUEUE_LIST'];

// static plan details
export const PLAN_DETAILS = {
  title: 'Free Trial',
  description:
    'Make more money in less time using configurable price quoting tools',
  firstBullet: 'All-in-one pricing engine',
  secondBullet: 'More sales at less cost improve your margins',
  thirdBullet: 'Powerful real-time result dashboard'
};

export const exportOptions = [
  {
    label: 'Selected',
    title: 'Selected',
    key: 'SELECTED'
  },
  {
    label: 'All',
    title: 'All',
    key: 'ALL'
  }
];

// dynamic fields module

// dynamic fields type
export const DYNAMIC_FIELDS_DATA = [
  {
    key: 'PICK_LIST',
    label: 'Picklist',
    title: 'Picklist'
  },
  {
    key: 'TEXT',
    label: 'Text',
    title: 'Text'
  },
  {
    key: 'NUMBER',
    label: 'Number',
    title: 'Number'
  },
  {
    key: 'DATE_TIME',
    label: 'Date & Time',
    title: 'Date & Time'
  },
  {
    key: 'BOOLEAN',
    label: 'Boolean',
    title: 'Boolean'
  },
  {
    key: 'UPLOAD',
    label: 'Upload File',
    title: 'Upload File'
  }
];

export const DYNAMIC_TEXT_TYPE = [
  {
    key: 'SINGLE_LINE',
    label: 'Single Line'
  },
  {
    key: 'MULTI_LINE',
    label: 'Multiline'
  },
  {
    key: 'RICH_TEXT',
    label: 'Rich Text'
  }
];

export const DYNAMIC_PICK_LIST_TYPE = [
  {
    key: 'TEXT',
    label: 'Text'
  },
  {
    key: 'NUMBER',
    label: 'Number'
  }
];

export const DYNAMIC_DATE_TIME_TYPE = [
  {
    name: 'date',
    key: 'DATE',
    label: 'Date'
  },
  {
    name: 'time',
    key: 'TIME',
    label: 'Time'
  }
];

export const DYNAMIC_DATE_TYPE = [
  {
    key: 'EXACT',
    label: 'Exact'
  },
  {
    key: 'RANGE',
    label: 'Range'
  }
];

export const DYNAMIC_BOOLEAN_DEFAULT_CHECKED = [
  {
    key: true,
    label: 'Default'
  },
  {
    key: false,
    label: 'Default'
  }
];

export const DYNAMIC_DATE_ALLOW_TYPE = [
  {
    name: 'allowPast',
    key: 'ALLOW_PAST',
    label: 'Allow Past',
    tooltip:
      'Allow Users/Customers to enter dates that have occurred in the past.'
  },
  {
    name: 'allowFuture',
    key: 'ALLOW_FUTURE',
    label: 'Allow Future',
    tooltip:
      'Allow Users/Customers to enter dates that will occur in the future.'
  }
];

export const DYNAMIC_DATE_DEFAULT_VALUE = [
  {
    key: 'TODAY',
    label: 'Today'
  },
  {
    key: 'TOMORROW',
    label: 'Tomorrow'
  }
];

export const DYNAMIC_UPLOAD_FILE_TYPES = [
  {
    label: 'Docs',
    value: 'DOCS'
  },
  {
    label: 'Image',
    value: 'IMAGE'
  },
  {
    label: 'Audio',
    value: 'AUDIO'
  },
  {
    label: 'Video',
    value: 'VIDEO'
  }
];

export const DYNAMIC_UPLOAD_TYPE_DOCS = [
  {
    label: 'PDF',
    value: 'PDF'
  },
  {
    label: 'DOCX',
    value: 'DOCX'
  },
  {
    label: 'TXT',
    value: 'TXT'
  }
];

export const DYNAMIC_UPLOAD_TYPE_IMAGES = [
  {
    label: 'PNG',
    value: 'PNG'
  },
  {
    label: 'JPEG',
    value: 'JPEG'
  },
  {
    label: 'GIF',
    value: 'GIF'
  }
];

export const DYNAMIC_UPLOAD_TYPE_AUDIO = [
  {
    label: 'MP3',
    value: 'MP3'
  },
  {
    label: 'WAV',
    value: 'WAV'
  }
];

export const DYNAMIC_UPLOAD_TYPE_VIDEO = [
  {
    label: 'MP4',
    value: 'MP4'
  }
];

export const DYNAMIC_FIELDS_PROPS = [
  {
    name: 'required',
    key: 'REQUIRED',
    label: 'Required'
  },
  {
    name: 'readOnly',
    key: 'READ_ONLY',
    label: 'Read Only'
  },
  {
    name: 'hidden',
    key: 'HIDDEN',
    label: 'Hidden'
  },
  {
    name: 'hideLabel',
    key: 'HIDE_LABEL',
    label: 'Hide Label'
  }
];

// dynamic builder module
export const DAYS_OF_WEEK = [
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
  { label: 'Sunday', value: 'SUNDAY' }
];

export const DaysOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

export const DATE_TIME_VALIDATION = {
  ALLOW_PAST: 'ALLOW_PAST',
  ALLOW_FUTURE: 'ALLOW_FUTURE'
};

export const WARRANTY_UOM = [
  {
    label: 'Days',
    value: 'DAYS'
  },
  {
    label: 'Months',
    value: 'MONTHS'
  },
  {
    label: 'Years',
    value: 'YEARS'
  },
  {
    label: 'LifeTime',
    value: 'LIFETIME'
  }
];

export const QUESTIONNAIRE_TYPE = {
  ROQ_BUILDER: 'ROQ_BUILDER',
  ROQ_PATH: 'ROQ_PATH'
};

export const MODAL_WIDTH = 1500;

export const CONDITIONS = [
  {
    label: 'Equal to',
    value: 'EQUAL_TO'
  },
  {
    label: 'Not Equal to',
    value: 'NOT_EQUAL_TO'
  },
  { label: 'Contains', value: 'CONTAINS' },
  {
    label: 'Does Not Contain',
    value: 'NOT_CONTAINS'
  }
];

export const CONDITIONS_KEYS = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  GREATER_LESS_RANGE: 'GREATER_LESS_RANGE',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL'
};

export const NUMERIC_CONDITIONS = [
  {
    label: 'Equal to',
    value: 'EQUAL_TO'
  },
  {
    label: 'Not Equal to',
    value: 'NOT_EQUAL_TO'
  },
  {
    label: 'Greater than / Less than / Range',
    value: 'GREATER_LESS_RANGE'
  }
];

export const DYNAMIC_FIELDS_DATA_KEYS = {
  PICK_LIST: 'PICK_LIST',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE_TIME: 'DATE_TIME',
  UPLOAD: 'UPLOAD',
  BOOLEAN: 'BOOLEAN',
  ENUM_PICK_LIST: 'ENUM_PICK_LIST'
};

export const CUSTOM_WIDGET_PROPS = [
  {
    name: 'required',
    key: 'REQUIRED',
    label: 'Required'
  },
  {
    name: 'readOnly',
    key: 'READ_ONLY',
    label: 'Read Only'
  },
  {
    name: 'hideLabel',
    key: 'HIDE_LABEL',
    label: 'Hide Label'
  }
];

export const UNITS_OF_MEASURE = [
  { label: 'Pieces (pcs)', value: 'pcs' },
  { label: 'Volume - Cubic Meter (m3)', value: 'm3' },
  { label: 'Volume - Cubic Inch (in3)', value: 'in3' },
  { label: 'Volume - Cubic Centimeter (cm3)', value: 'cm3' },
  { label: 'Volume - Cubic Feet (ft3)', value: 'ft3' },
  { label: 'Volume - Cubic Yard (yd3)', value: 'yd3' },
  { label: 'Meter (m)', value: 'm' },
  { label: 'Inches (In)', value: 'in' },
  { label: 'Kilometer (km)', value: 'km' },
  { label: 'Yard (yd)', value: 'yd' },
  { label: 'Feet (ft)', value: 'ft' },
  { label: 'Mass - Gram (gm)', value: 'gm' },
  { label: 'Mass - Pound (lbs)', value: 'lbs' },
  { label: 'Mass - Kilogram (kg)', value: 'kg' }
];

export const DEFAULT_STAIRS_DEPTH_OPTIONS = [
  {
    label: '3 Inch (0.25 Feet)',
    value: 0.25
  },
  {
    label: '4 Inch (0.33 Feet)',
    value: 0.33
  },
  {
    label: '5 Inch',
    value: 0.41666666
  },
  {
    label: '6 Inch (0.5 Feet)',
    value: 0.5
  }
];

export const DIMENSION_LOB_COMMON = 'common';
export const DIMENSION_LOB_STAIRS = 'stairs';

export const DIMENSION_TABS = {
  common: [
    'MAP_ENTRY',
    'MEASUREMENT_TIP_FOR_MAP_ENTRY',
    'MANUAL_ENTRY',
    'MEASUREMENT_TIP_FOR_MANUAL_ENTRY',
    'HIGHLIGHT_YOUR_AREA'
  ],
  stairs: ['STEPS', 'TOTAL_RISE']
};

export const WIDGET_TYPE = {
  POSTAL:
    'The postal code widget enables tenants to configure it to accept both postal code and full address input from customers.',
  PROJECT:
    'The project widget enables tenants to configure the appearance of lines of businesses, service types, and their display.',
  QUOTES:
    'The quote widget is utilized to configure the appearance of quotes with various options',
  CART:
    'The cart widget offers various options to control the appearance of multiple quotes.',
  APPOINTMENT:
    'This widget empowers tenants to control the appearances of customer address, appointment dates and slots, as well as appointment methods, including contact details or credit card information.',
  CONTACT: 'This widget enables tenants to manage contact form elements.',
  CONTENT:
    'The content widget empowers tenants to add informative messages in the form of text to customers.',
  SYSTEM:
    'The system field widget enables tenants to create questions related to system fields such as product, packages, properties, etc.',
  CUSTOM:
    'This widget enables tenants to create various types of custom fields such as picklist, text, number, date, etc.',
  DIMENSION:
    'The dimension widget allows tenants to configure input fields to collect dimensions from customers. These dimensions are utilized in calculations to determine pricing.',
  ADDON:
    'This widget is designed to configure the appearance of add-on products on the page. It enables customers to select add-on products in conjunction with their chosen package.',
  SUPPORT: 'This widget enables tenants to manage contact form elements.',
  CUSTOMER_INFO:
    'This widget enables tenants to manage contact info of users form elements.'
};

export const SETTING_MODAL_MENUS = [
  { key: 'general', label: 'General' },
  { key: 'business-hours', label: 'Business Hours' },
  { key: 'branding', label: 'Branding' },
  { key: 'email-notification', label: 'Notification Email' },
  { key: 'quote-config', label: 'Results Sort Settings' },
  { key: 'analytics-config', label: 'Advance Options' }
];

export const PRODUCT_CONFIG_SORT_ON_OPTION = [
  { label: 'Package Price', value: 'sellingPrice' },
  { label: 'Cost Per Day', value: 'costPerDay' },
  { label: 'Package Ranking', value: 'score' }
];

export const PRODUCT_CONFIG_SORT_BY_OPTION = [
  { label: 'Low to High', value: 'ASC' },
  { label: 'High to Low', value: 'DESC' }
];

export const PICK_LIST_STYLE = [
  { label: 'Tiles', value: 'TILES' },
  { label: 'List', value: 'LIST' },
  { label: 'Dropdown', value: 'DROPDOWN' }
];

// system field module
export const SYSTEM_FIELDS_MODULE = [
  { label: 'Product', value: 'ProductItem' },
  { label: 'Package', value: 'Product' },
  { label: 'Property', value: 'Property' },
  { label: 'Lead', value: 'Lead' }
];

// system field module
export const SYSTEM_FIELDS_MODULE_OBJECT = {
  PRODUCT: { label: 'Product', value: 'ProductItem' },
  PACKAGE: { label: 'Package', value: 'Product' },
  PROPERTY: { label: 'Property', value: 'Property' },
  LEAD: { label: 'Lead', value: 'Lead' }
};

// system field module
export const SYSTEM_FIELDS_TYPE = [
  { label: 'Static', value: 'STATIC' },
  { label: 'Dynamic', value: 'DYNAMIC' }
];

// SYSTEM_FIELDS_TYPE and SYSTEM_FIELDS_TYPES_OBJECT having the same values but difference between them is SYSTEM_FIELDS_TYPE is array and used as OPTION in SELECT on other hand  SYSTEM_FIELDS_TYPES_OBJECT uses for IF statement

// system field module
export const SYSTEM_FIELDS_TYPES_OBJECT = {
  STATIC: { label: 'Static', value: 'STATIC' },
  DYNAMIC: { label: 'Dynamic', value: 'DYNAMIC' }
};

export const SINGLE_LINE_TEXT = 'SINGLE_LINE';
export const MULTI_LINE_TEXT = 'MULTI_LINE';
export const RICH_TEXT_TYPE = 'RICH_TEXT';

export const QUOTE_MODIFIER_CONDITION = {
  EQUAL_TO: 'Equal to',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'Greater than',
  NOT_EQUAL_TO: 'Not equal to',
  RANGE: 'Between'
};

export const QUOTE_MODIFIER_NUMERIC_CONDITIONS = [
  {
    label: 'Equal to',
    value: 'EQUAL'
  },
  {
    label: 'Not Equal to',
    value: 'NOT_EQUAL'
  },
  {
    label: 'Greater than / Less than / Range',
    value: 'GREATER_LESS_RANGE'
  }
];

export const QUOTE_MODIFIER_CONDITION_ATTRIBUTE = {
  PMC_UNIT_INPUT_PARAMETER: 'PMC_UNIT_INPUT_PARAMETER',
  PMC_REGION: 'PMC_REGION',
  PMC_BRAND: 'PMC_BRAND',
  PMC_MANUFACTURER: 'PMC_MANUFACTURER'
};

export const POLICY_DEFAULT_VALUE = {
  termsOfUseLabel: 'Terms of Use',
  termsOfUseUrl: 'https://www.path.pro/terms-of-use',
  termsOfUseUrlCheck: false,
  privacyPolicyLabel: 'Privacy Policy',
  privacyPolicyUrl: 'https://www.path.pro/privacy-policy',
  privacyPolicyUrlCheck: false
};
