import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Form, Switch } from 'antd';
import React from 'react';
import { strippedString } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';

const { Panel } = Collapse;

const CustomerInfoForm = ({ form }) => {
  const enableAddressInfo = Form?.useWatch(
    ['widgetConfiguration', 'addressInfo', 'enable'],
    form
  );
  const addressInfoDescriptionCheck = Form?.useWatch(
    ['widgetConfiguration', 'addressInfo', 'description', 'enable'],
    form
  );
  const isPhoneCheck = Form?.useWatch(
    ['widgetConfiguration', 'personalInfo', 'phone', 'enable'],
    form
  );

  return (
    <Collapse
      className="common-collapse questionnaire-collapse"
      expandIconPosition="end"
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
      defaultActiveKey={['1', '2']}
      ghost
    >
      <Panel forceRender header="Personal Info" key="1">
        <Form.Item
          name={['widgetConfiguration', 'personalInfo', 'name', 'labelText']}
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please Enter Name'
            }
          ]}
        >
          <InputComponent placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          name={['widgetConfiguration', 'personalInfo', 'email', 'labelText']}
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please Enter Email'
            }
          ]}
        >
          <InputComponent placeholder="Enter Email" />
        </Form.Item>
        <div className="d-flex justify-between align-center">
          <span className="switch-logo">Phone</span>
          <Form.Item
            name={['widgetConfiguration', 'personalInfo', 'phone', 'enable']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
        <Form.Item
          name={['widgetConfiguration', 'personalInfo', 'phone', 'labelText']}
          rules={[
            {
              required: true,
              message: 'Please Enter Phone'
            }
          ]}
          hidden={!isPhoneCheck}
        >
          <InputComponent placeholder="Enter Phone" />
        </Form.Item>
      </Panel>
      <Panel forceRender header="Address Info" key="2">
        {/* Enable */}
        <div className="d-flex justify-between align-center">
          <span className="switch-logo">Enable</span>
          <Form.Item
            name={['widgetConfiguration', 'addressInfo', 'enable']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
        <div>
          {/* Title */}
          <Form.Item
            name={['widgetConfiguration', 'addressInfo', 'title', 'labelText']}
            label="Title Label"
            hidden={!enableAddressInfo}
            rules={[
              {
                required: true,
                message: 'Please Enter Title'
              }
            ]}
          >
            <InputComponent placeholder="Address" />
          </Form.Item>

          {/* Description */}
          <div className="d-flex justify-between align-center">
            {enableAddressInfo && (
              <span className="switch-logo">Description</span>
            )}
            <Form.Item
              name={[
                'widgetConfiguration',
                'addressInfo',
                'description',
                'enable'
              ]}
              className="mb-0"
              valuePropName="checked"
              hidden={!enableAddressInfo}
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'addressInfo',
              'description',
              'labelText'
            ]}
            hidden={!addressInfoDescriptionCheck || !enableAddressInfo}
            rules={[
              {
                validator(_, value) {
                  if (
                    enableAddressInfo &&
                    addressInfoDescriptionCheck &&
                    (!value || !strippedString(value)?.length)
                  ) {
                    return Promise?.reject(
                      new Error('Please Enter Description')
                    );
                  }
                  return Promise?.resolve();
                }
              }
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </div>
      </Panel>
    </Collapse>
  );
};

export default CustomerInfoForm;
