import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Collapse, Empty, Form, Radio, Switch } from 'antd';
import { debounce, isEmpty, map, omit, startCase } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import {
  DYNAMIC_FIELDS_DATA_KEYS,
  PICK_LIST_STYLE,
  SINGLE_LINE_TEXT,
  SKIP_RECORD,
  SYSTEM_FIELDS_MODULE,
  SYSTEM_FIELDS_MODULE_OBJECT,
  SYSTEM_FIELDS_TYPE,
  SYSTEM_FIELDS_TYPES_OBJECT
} from '../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';
import LoaderComponent from '../../../../../../components/LoaderComponent';
import SelectComponent from '../../../../../../components/SelectComponent';
import { GET_DYNAMIC_FIELDS } from '../../../../../dynamicFields/graphql/Queries';
import {
  GET_SYSTEM_FIELD_CONFIG,
  GET_SYSTEM_FIELD_MAPPING
} from '../../../../graphql/Queries';
import SystemBooleanForm from '../component/boolean/form';
import SystemNumberFieldForm from '../component/number/form';
import SystemPickListForm from '../component/pickList/form';
import SystemTextFieldForm from '../component/text/form';

const { Option } = SelectComponent;
const { Panel } = Collapse;
const { requiredWhiteSpaceAllowed } = formValidatorRules;

const initialSystemFieldMappingFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'label',
  sortBy: 'ASC',
  distinct: true,
  search: ''
};

let mapperScrollDebounceJob;

const SystemWidgetForm = ({
  form,
  fieldObject,
  setFieldObject,
  systemFieldData,
  setSystemFieldData,
  refetchSystemFields = false,
  setRefetchSystemFields,
  setDisableBtn,
  widgetConfiguration = null,
  showCtaButton
}) => {
  const { id } = useParams();
  const location = useLocation();
  const isCreate = location?.pathname?.includes('create');
  const { state } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]);
  const selectedSystemFieldId = Form?.useWatch(
    [
      'widgetConfiguration',
      'selectedSystemFieldBlock',
      'selectedSystemFieldId'
    ],
    form
  );

  const [searchValue, setSearchValue] = useState('');

  const [
    systemFieldMapperSearchFlag,
    setSystemFieldMapperSearchFlag
  ] = useState(false);

  const [dynamicFieldDataIsEnd, setDynamicDataIsEnd] = useState(false);
  const [dynamicFieldDebounceCall, setDynamicFieldsDebounceCall] = useState(0);
  const [fieldCategory, setFieldCategory] = useState(
    fieldObject?.fieldCategory || null
  );
  const entityName = Form?.useWatch(
    ['widgetConfiguration', 'selectedSystemFieldBlock', 'entityName'],
    form
  );

  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'description'],
    form
  );

  const isMultiSelectionEnabled = Form?.useWatch(
    ['widgetConfiguration', 'multiSelection'],
    form
  );

  const hideContinueButton = Form?.useWatch(
    ['widgetConfiguration', 'hideContinueButton'],
    form
  );

  const [dynamicFields] = useLazyQuery(GET_DYNAMIC_FIELDS, {
    fetchPolicy: 'network-only',
    onError() {
      setLoading(false);
    },
    onCompleted(res) {
      setDynamicFieldsData(res?.dynamicFields?.data);
      setDynamicDataIsEnd(res?.dynamicFields?.data?.length < SKIP_RECORD);
      if (systemFieldMapperSearchFlag) {
        setDynamicFieldsData([...res?.dynamicFields?.data]);
      } else {
        setDynamicFieldsData([
          ...dynamicFieldsData,
          ...res?.dynamicFields?.data
        ]);
      }
      setRefetchSystemFields(false);
      setLoading(false);
    }
  });

  const [getSystemFieldMapping] = useLazyQuery(GET_SYSTEM_FIELD_MAPPING, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicDataIsEnd(res?.systemFieldMappings?.data?.length < SKIP_RECORD);
      if (systemFieldMapperSearchFlag) {
        setDynamicFieldsData([...res?.systemFieldMappings?.data]);
      } else {
        setDynamicFieldsData([
          ...dynamicFieldsData,
          ...res?.systemFieldMappings?.data
        ]);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });
  const [fetchSystemFieldConfig] = useLazyQuery(GET_SYSTEM_FIELD_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      form?.setFieldsValue({
        widgetConfiguration: {
          ...widgetConfiguration,
          selectedSystemFieldBlock: {
            entityName,
            fieldCategory
          },
          displayStyle:
            res?.getSystemFieldConfiguration?.data?.config?.displayStyle,
          images: res?.getSystemFieldConfiguration?.data?.config?.images,
          imagePreview:
            res?.getSystemFieldConfiguration?.data?.config?.imagePreview,
          optionDescriptions:
            res?.getSystemFieldConfiguration?.data?.config?.optionDescriptions,
          multiSelection:
            res?.getSystemFieldConfiguration?.data?.config?.multiSelection,
          description:
            res?.getSystemFieldConfiguration?.data?.config?.description,
          descriptionText:
            res?.getSystemFieldConfiguration?.data?.config?.descriptionText,
          otherConfig: map(
            res?.getSystemFieldConfiguration?.data?.config?.otherConfig,
            (item) => {
              const newItem = {
                ...item,
                blockDescription: item?.description,
                blockId: item?.blockId || item?.blockKey // added this or condition for ENUM_PICK_LIST as we don't have a blockId. instead of blockId we get blockKey
              };
              if (!newItem?.image?.url) {
                return omit(newItem, ['image']);
              }
              return {
                ...newItem,
                image: [newItem?.image]
              };
            }
          ),
          label: res?.getSystemFieldConfiguration?.data?.label,
          required: res?.getSystemFieldConfiguration?.data?.config?.required,
          toolTipCheck:
            res?.getSystemFieldConfiguration?.data?.config?.toolTipCheck,
          toolTipText:
            res?.getSystemFieldConfiguration?.data?.config?.toolTipText,
          trueValue: res?.getSystemFieldConfiguration?.data?.config?.trueValue,
          falseValue:
            res?.getSystemFieldConfiguration?.data?.config?.falseValue,
          booleanDefaultValue:
            res?.getSystemFieldConfiguration?.data?.config?.booleanDefaultValue,
          fieldConfig: {
            [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
              textType: SINGLE_LINE_TEXT,
              textLength: {
                minLength: 1,
                maxLength: 255
              }
            }
          }
        }
      });
      setSystemFieldData({ ...res?.getSystemFieldConfiguration?.data });
      setConfigLoading(false);
    },
    onError() {
      setConfigLoading(false);
    }
  });

  const onSystemCategoryChange = (value) => {
    const categoryClone = form?.getFieldValue([
      'widgetConfiguration',
      'selectedSystemFieldBlock',
      'fieldCategory'
    ]);
    setSystemFieldMapperSearchFlag(true);
    setSearchValue(value);
    setLoading(true);
    if (categoryClone === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value) {
      getSystemFieldMapping({
        variables: {
          filter: {
            ...initialSystemFieldMappingFilter,
            ...(value && { search: value })
          },
          where: {
            entityName: form?.getFieldValue([
              'widgetConfiguration',
              'selectedSystemFieldBlock',
              'entityName'
            ]),
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    } else {
      dynamicFields({
        variables: {
          filter: {
            ...initialSystemFieldMappingFilter,
            ...(value && { search: value })
          },
          where: {
            fieldType: [
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
              DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
            ],
            status: ['PUBLISH'],
            lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
            industry: [state?.cpqQuestionObject?.industry],
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCategoryHandler = useCallback(
    debounce(onSystemCategoryChange, 500),
    []
  );
  const onCategoryScroll = (event) => {
    setSystemFieldMapperSearchFlag(false);
    if (mapperScrollDebounceJob) {
      mapperScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    mapperScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !dynamicFieldDataIsEnd) {
        setLoading(true);
        setDynamicFieldsDebounceCall((prevState) => prevState + 1);
        if (fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value) {
          getSystemFieldMapping({
            variables: {
              filter: {
                ...initialSystemFieldMappingFilter,
                skip: (dynamicFieldDebounceCall + 1) * SKIP_RECORD,
                search: searchValue
              },
              where: {
                entityName,
                questionnaireId: id,
                ...(!isCreate &&
                  fieldObject?.selectedSystemFieldId && {
                    selectedSystemFieldId: fieldObject?.selectedSystemFieldId
                  })
              }
            }
          });
        } else {
          dynamicFields({
            variables: {
              filter: {
                ...initialSystemFieldMappingFilter,
                skip: (dynamicFieldDebounceCall + 1) * SKIP_RECORD,
                search: searchValue
              },
              where: {
                fieldType: [
                  DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                  DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
                  DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
                  DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
                ],
                status: ['PUBLISH'],
                lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
                industry: [state?.cpqQuestionObject?.industry],
                questionnaireId: id,
                ...(!isCreate &&
                  fieldObject?.selectedSystemFieldId && {
                    selectedSystemFieldId: fieldObject?.selectedSystemFieldId
                  })
              }
            }
          });
        }
      }
    }, 500);
    mapperScrollDebounceJob();
  };

  const handleCategoryClear = () => {
    form?.setFieldsValue({
      widgetConfiguration: null
    });
    form?.setFieldsValue({
      widgetConfiguration: {
        selectedSystemFieldBlock: {
          entityName,
          fieldCategory
        }
      }
    });
    setDynamicFieldsData([]);
    if (fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value) {
      dynamicFields({
        variables: {
          filter: {
            ...initialSystemFieldMappingFilter
          },
          where: {
            fieldType: [
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
              DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
            ],
            status: ['PUBLISH'],
            lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
            industry: [state?.cpqQuestionObject?.industry],
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    } else {
      getSystemFieldMapping({
        variables: {
          filter: {
            ...initialSystemFieldMappingFilter
          },
          where: {
            entityName,
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    }
  };

  const handleSystemFieldModuleChange = (value) => {
    setDynamicFieldsData([]);
    form?.setFieldsValue({
      widgetConfiguration: null
    });

    setFieldCategory(SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value);

    form?.setFieldsValue({
      widgetConfiguration: {
        selectedSystemFieldBlock: {
          entityName: value,
          fieldCategory: SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
        }
      }
    });

    setFieldObject({
      entityName: value,
      fieldCategory: SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
    });
    setDynamicFieldsData([]);
    setSearchValue('');
    setDynamicFieldsDebounceCall(0);
    setDynamicDataIsEnd(false);
  };

  const onSystemFieldClear = () => {
    setDynamicFieldsData([]);
    setSearchValue('');
    setDynamicFieldsDebounceCall(0);
    setDynamicDataIsEnd(false);
    form?.setFieldsValue({
      widgetConfiguration: null
    });
    setFieldObject(null);
  };

  const handleTypeChange = (e) => {
    setDynamicFieldsData([]);
    setSearchValue('');
    setDynamicFieldsDebounceCall(0);
    setDynamicDataIsEnd(false);
    setConfigLoading(true);
    const entityClone = entityName;
    const fieldCategoryClone = e?.target?.value;
    setFieldCategory(e?.target?.value);
    const selectedCategory =
      widgetConfiguration?.selectedSystemFieldBlock?.fieldCategory;
    if (fieldCategoryClone === selectedCategory) {
      const fieldObjectClone = {
        selectedSystemFieldType:
          widgetConfiguration?.selectedSystemFieldBlock
            ?.selectedSystemFieldType,
        selectedSystemFieldLabel:
          widgetConfiguration?.selectedSystemFieldBlock
            ?.selectedSystemFieldLabel,
        selectedSystemFieldId:
          widgetConfiguration?.selectedSystemFieldBlock?.selectedSystemFieldId,
        entityName: widgetConfiguration?.selectedSystemFieldBlock?.entityName,
        fieldCategory:
          widgetConfiguration?.selectedSystemFieldBlock?.fieldCategory
      };
      form?.setFieldsValue({
        widgetConfiguration: {
          ...widgetConfiguration,
          displayStyle: widgetConfiguration?.displayStyle,
          images: widgetConfiguration?.images,
          imagePreview: widgetConfiguration?.imagePreview,
          optionDescriptions: widgetConfiguration?.optionDescriptions,
          multiSelection: widgetConfiguration?.multiSelection,
          description: widgetConfiguration?.description,
          descriptionText: widgetConfiguration?.descriptionText,
          otherConfig: map(widgetConfiguration?.otherConfig, (item) => {
            const newItem = {
              ...item,
              blockDescription: item?.description,
              blockId: item?.blockId || item?.blockKey // added this or condition for ENUM_PICK_LIST as we don't have a blockId. instead of blockId we get blockKey
            };
            if (!newItem?.image?.url) {
              return omit(newItem, ['image']);
            }
            return {
              ...newItem,
              image: [newItem?.image]
            };
          }),
          label: widgetConfiguration?.label,
          required: widgetConfiguration?.required,
          toolTipCheck: widgetConfiguration?.toolTipCheck,
          toolTipText: widgetConfiguration?.toolTipText,
          trueValue: widgetConfiguration?.trueValue,
          falseValue: widgetConfiguration?.falseValue,
          booleanDefaultValue: widgetConfiguration?.booleanDefaultValue,
          fieldConfig: {
            [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
              textType: SINGLE_LINE_TEXT,
              textLength: {
                minLength: 1,
                maxLength: 255
              }
            }
          }
        }
      });
      setFieldObject(fieldObjectClone);
      setSystemFieldData({ ...widgetConfiguration });
      setConfigLoading(false);
    } else {
      form?.setFieldsValue({
        widgetConfiguration: null
      });
      form?.setFieldsValue({
        widgetConfiguration: {
          selectedSystemFieldBlock: {
            entityName: entityClone,
            fieldCategory: fieldCategoryClone
          }
        }
      });

      setFieldObject({
        ...fieldObject,
        entityName: entityClone,
        fieldCategory: fieldCategoryClone,
        selectedSystemFieldType: null,
        selectedSystemFieldId: null
      });
      setConfigLoading(false);
    }
  };

  useEffect(() => {
    if (refetchSystemFields) {
      setLoading(true);
      dynamicFields({
        variables: {
          filter: {
            skip: 0
          },
          where: {
            fieldType: [
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
              DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
            ],
            status: ['PUBLISH'],
            lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
            industry: [state?.cpqQuestionObject?.industry],
            questionnaireId: id,
            ...(!isCreate && selectedSystemFieldId)
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchSystemFields]);

  useEffect(() => {
    setActiveKey(selectedSystemFieldId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystemFieldId]);

  const handleCategoryChange = (value, ...rest) => {
    if (value) {
      setConfigLoading(true);
      const selectedType = rest?.[0]?.['data-type'];

      const fieldObjectClone = {
        selectedSystemFieldId: value,
        selectedSystemFieldType: selectedType,
        selectedSystemFieldLabel: rest?.[0]?.['data-label'],
        entityName,
        fieldCategory
      };

      setFieldObject({ ...fieldObjectClone });
      fetchSystemFieldConfig({
        variables: {
          where: {
            systemFields: {
              systemFieldId: value,
              systemFieldType: selectedType
            },
            entityName,
            fieldCategory
          }
        }
      });
    } else {
      setFieldObject(null);
    }
  };

  useEffect(() => {
    if (entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value) {
      if (fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value) {
        getSystemFieldMapping({
          variables: {
            filter: {
              ...initialSystemFieldMappingFilter
            },
            where: {
              entityName,
              questionnaireId: id,
              ...(!isCreate &&
                fieldObject?.selectedSystemFieldId && {
                  selectedSystemFieldId: fieldObject?.selectedSystemFieldId
                })
            }
          }
        });
      } else {
        setLoading(true);
        dynamicFields({
          variables: {
            filter: {
              skip: 0
            },
            where: {
              fieldType: [
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
                DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
                DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
              ],
              status: ['PUBLISH'],
              lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
              industry: [state?.cpqQuestionObject?.industry],
              questionnaireId: id,
              ...(!isCreate &&
                fieldObject?.selectedSystemFieldId && {
                  selectedSystemFieldId: fieldObject?.selectedSystemFieldId
                })
            }
          }
        });
      }
    } else if (entityName) {
      setLoading(true);
      getSystemFieldMapping({
        variables: {
          filter: {
            ...initialSystemFieldMappingFilter
          },
          where: {
            entityName,
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityName, fieldCategory]);

  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">System Field Widget</span>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'selectedSystemFieldBlock', 'entityName']}
        rules={[
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Please Select Module'
          }
        ]}
      >
        <SelectComponent
          allowClear
          onChange={handleSystemFieldModuleChange}
          className="system-widget-select"
          placeholder="Select Module"
          options={SYSTEM_FIELDS_MODULE}
          onClear={onSystemFieldClear}
        />
      </Form.Item>
      {entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value && (
        <Form.Item
          name={[
            'widgetConfiguration',
            'selectedSystemFieldBlock',
            'fieldCategory'
          ]}
          rules={[
            {
              ...requiredWhiteSpaceAllowed,
              message: 'Please Select Type'
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            options={SYSTEM_FIELDS_TYPE}
            onChange={handleTypeChange}
          />
        </Form.Item>
      )}
      <Form.Item
        name={[
          'widgetConfiguration',
          'selectedSystemFieldBlock',
          'selectedSystemFieldId'
        ]}
        rules={[
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Please Select Category'
          }
        ]}
      >
        <SelectComponent
          allowClear
          disabled={!(entityName || fieldCategory)}
          onChange={handleCategoryChange}
          className="system-widget-select"
          placeholder="Select Category"
          onPopupScroll={onCategoryScroll}
          onSearch={debouncedCategoryHandler}
          onClear={handleCategoryClear}
          notFoundContent={
            loading ? (
              <LoaderComponent size="small" setHeight={10} />
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )
          }
        >
          {dynamicFieldsData?.map((field) => (
            <Option
              key={field?.id}
              value={field?.id}
              data-type={field?.fieldType}
              data-label={field?.label}
            >
              {startCase(field?.label)}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>
      {entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
        fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value &&
        isEmpty(dynamicFieldsData) &&
        !loading && (
          <>
            <span>No System Fields Available.</span>
            <br />
            <span className="mt-8 d-block">Reason could be:</span>
            <span>
              1. Either you have used all fields in <b>Global Conditions</b>
            </span>
            <br />
            <span>
              2. <b>Created Pages</b> with all fields
            </span>
            <br />
            <span>
              3. You need to <b>create </b> Dynamic Field
            </span>
          </>
        )}
      <LoaderComponent spinning={configLoading} setHeight={75}>
        {(fieldObject?.selectedSystemFieldType ===
          DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST ||
          fieldObject?.selectedSystemFieldType ===
            DYNAMIC_FIELDS_DATA_KEYS?.ENUM_PICK_LIST) && (
          <div id="pick-list-form-items">
            <Form.Item
              label="Display Style"
              name={['widgetConfiguration', 'displayStyle']}
              className="mb-0"
            >
              <Radio.Group className="common-radio">
                {map(PICK_LIST_STYLE, (item) => {
                  return (
                    <Radio key={item?.value} value={item?.value}>
                      {item?.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Required</span>
              <Form.Item
                name={['widgetConfiguration', 'required']}
                className="mb-0"
                valuePropName="checked"
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Images</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'images']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Images Preview</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'imagePreview']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Option Descriptions</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'optionDescriptions']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Descriptions</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'description']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'descriptionText']}
              hidden={!showDescription}
              rules={[
                {
                  required: showDescription,
                  message: 'Please Enter Description'
                }
              ]}
            >
              <InputComponent placeholder="Enter Description" />
            </Form.Item>
            {fieldObject?.selectedSystemFieldType ===
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Multiselection</span>
                <Form.Item
                  valuePropName="checked"
                  className="mb-0"
                  name={['widgetConfiguration', 'multiSelection']}
                >
                  <Switch
                    disabled={hideContinueButton || !showCtaButton}
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
            )}
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Hide Continue Button</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'hideContinueButton']}
              >
                <Switch
                  disabled={!!isMultiSelectionEnabled}
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {fieldObject?.selectedSystemFieldType && (
          <Collapse
            collapsible
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            expandIconPosition="end"
            className="common-collapse questionnaire-collapse custom-collapse"
            activeKey={activeKey}
            onChange={(keys) => setActiveKey(keys)}
            ghost
          >
            <Panel header="Settings" key={selectedSystemFieldId} forceRender>
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
                <SystemPickListForm
                  form={form}
                  setDisableBtn={setDisableBtn}
                  systemFieldData={systemFieldData}
                  setSystemFieldData={setSystemFieldData}
                />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.ENUM_PICK_LIST && (
                <SystemPickListForm
                  form={form}
                  setDisableBtn={setDisableBtn}
                  systemFieldData={systemFieldData}
                  setSystemFieldData={setSystemFieldData}
                />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT && (
                <SystemTextFieldForm
                  form={form}
                  systemFieldData={systemFieldData}
                  setSystemFieldData={setSystemFieldData}
                />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN && (
                <SystemBooleanForm form={form} />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.NUMBER && (
                <SystemNumberFieldForm form={form} />
              )}
            </Panel>
          </Collapse>
        )}
      </LoaderComponent>
    </>
  );
};

export default SystemWidgetForm;
