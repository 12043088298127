import { CloseOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { filter, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import ExpandIconComponent from '../../../app/components/iconComponents/ExpandIconComponent';
import PlusIconComponent from '../../../app/components/iconComponents/PlusIconComponent';
import {
  displayZipCodes,
  getElementFromDocumentId,
  isDocumentIdExist
} from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';

const RegionModal = ({
  btnHide = false,
  open = false,
  setModalVisible,
  zip = [],
  setZip,
  btnDisabled = true,
  regionLoading = false,
  zipMarkers = [],
  setZipMarkers
}) => {
  const [value, setValue] = useState('');

  const onClickAdd = () => {
    if (value) {
      const zipCopy = [...zip];
      if (value?.length > 2 && value?.length < 6 && parseInt(value, 10) !== 0) {
        const DuplicateRecords = filter(zip, (item) => {
          const zipValue = displayZipCodes(item).toString();
          const searchValue = displayZipCodes(value).toString();
          if (zipValue === searchValue) {
            return true;
          }
          return false;
        });
        if (!DuplicateRecords?.length) {
          zipCopy?.unshift(value?.padStart(5, '0'));
          setValue('');
          setZip(zipCopy);
        } else {
          message?.destroy();
          message?.error('Already Postal code is there.');
        }
      } else {
        message?.destroy();
        message?.error('Not a US Postal code.');
      }
    } else {
      message?.destroy();
      message?.error('Enter a Postal code.');
    }
  };

  const keysDown = (e) => {
    switch (e?.which) {
      case 13:
      case 176:
        if (isDocumentIdExist('add-zip')) {
          const addZipId = getElementFromDocumentId('add-zip');
          addZipId?.click();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (open) {
      const loading = setInterval(function () {
        // eslint-disable-next-line no-undef
        if (isDocumentIdExist('postal-code-search')) {
          const postalCodeSearch = getElementFromDocumentId(
            'postal-code-search'
          );
          postalCodeSearch?.focus();
          clearInterval(loading);
        }
      }, 100);
      // eslint-disable-next-line no-undef
      document?.addEventListener('keydown', keysDown);
      return () => {
        // eslint-disable-next-line no-undef
        document?.removeEventListener('keydown', keysDown);
      };
    }
    setValue('');
  }, [open]);

  const handleRemove = (zipCode) => {
    const zipCopy = filter(zip, (item) => item !== zipCode);
    const zipMarkerCopy = filter(
      zipMarkers,
      (item) => item?.zipCode?.toString() !== zipCode
    );
    if (zipCopy?.length < 1) {
      setModalVisible(false);
    }
    setZip(zipCopy);
    setZipMarkers(zipMarkerCopy);
  };

  const zipResults =
    value?.length > 0
      ? filter(zip, (item) => {
          const zipValue = displayZipCodes(item)?.toString();
          if (zipValue?.indexOf(value) !== -1) {
            return true;
          }
          return false;
        })
      : zip;

  const handleChangeInput = (e) => {
    setValue(e?.target?.value);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <ModalComponent
      width={646}
      wrapClassName="region-modal"
      open={open}
      footer={null}
      closable={false}
      destroyOnClose
      focusTriggerAfterClose
      onCancel={handleCancel}
    >
      <div>
        <div className="d-flex align-center">
          <NumberComponent
            value={value}
            id="postal-code-search"
            onChange={handleChangeInput}
            placeholder="Search for a Postal Code..."
            className="region-modal-input"
          />
          <div>
            <span className="plus-icon">
              <PlusIconComponent onClick={onClickAdd} id="add-zip" />
            </span>
            <span className="modal-icon">
              <ExpandIconComponent onClick={() => setModalVisible(false)} />
            </span>
          </div>
        </div>
        <div className="d-flex flex-wrap tags-section">
          {map(zipResults, (item) => {
            return (
              <span key={item} className="modal-zip-tags">
                {displayZipCodes(item)}
                <CloseOutlined
                  className="close-icon"
                  onClick={() => handleRemove(item)}
                />
              </span>
            );
          })}
        </div>
        {!btnHide && (
          <div className="d-flex justify-center">
            <Button
              htmlType="submit"
              loading={regionLoading}
              type="primary"
              className="region-cta-button"
              disabled={!zip?.length || btnDisabled}
              onClick={() => setModalVisible(false)}
            >
              Add
            </Button>
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default RegionModal;
