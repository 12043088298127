import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import NotificationList from './pages/NotificationList';

const NotificationWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.NOTIFICATION} component={NotificationList} />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default NotificationWrapper;
