import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_QUOTE_VIEW, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import QuoteViewerLeadView from './pages/QuoteViewerLeadView';
import QuoteViewerList from './pages/QuoteViewerList';
import QuoteViewerView from './pages/QuoteViewerView';
import './quoteViewerModule.less';

const QuoteViewerWrapper = () => {
  return (
    <div className="quote-viewer-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.QUOTE_VIEWER}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_QUOTE_VIEW}
              showNoAccess
            >
              <QuoteViewerList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.QUOTE_VIEWER}/view/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_QUOTE_VIEW_VIEW']}
              showNoAccess
            >
              <QuoteViewerView {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.QUOTE_VIEWER}/view/lead/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_QUOTE_VIEW_VIEW']}
              showNoAccess
            >
              <QuoteViewerLeadView {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default QuoteViewerWrapper;
