import { gql } from '@apollo/client';

export const UPDATE_ROLE = gql`
  mutation updateRole($where: RoleWhereInput!, $data: RoleInput!) {
    updateRole(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
      status
      message
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($data: RoleInput!) {
    createRole(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
      status
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($where: RoleWhereInput!) {
    deleteRole(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
