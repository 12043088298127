import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      firstName
      lastName
      email
      roles
    }
  }
`;
