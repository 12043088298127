import React, { useState } from 'react';
import ModalComponent from '../ModalComponent';

// use ctrl + shift + ? for open/close modal
const HotKeysModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div id="info-div-id" onClick={() => setIsModalVisible(!isModalVisible)}>
      <ModalComponent title="Shortcuts" open={isModalVisible}>
        <div>
          <p>Ctrl+Alt+I for Open Import Modal </p>
          <p>Ctrl+Shift+S for Submit Form Data</p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default HotKeysModal;
