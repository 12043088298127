import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Popconfirm,
  Popover,
  Radio,
  Tag
} from 'antd';
import { debounce, filter, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import TableComponent from '../../../components/TableComponent';
import {
  DELETE_DYNAMIC_LIST_ITEM,
  UPDATE_DYNAMIC_LIST_ITEM
} from '../graphql/Mutations';
import { GET_DYNAMIC_FIELD_LIST_ITEMS } from '../graphql/Queries';

let pickListTableScroll = null;

const PickListTable = ({
  match: { params: { id } = {} } = {},
  limit = 20,
  handlePickListModalVisible,
  setRefetchData,
  refetchData,
  setHasPickListData
}) => {
  const {
    state: { pageSize, permissions, filterData }
  } = useContext(AppContext);

  const [pickListItems, setPickListItems] = useState([]);
  const [pickListItemsLoading, setPickListItemsLoading] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [pickListFilter, setPickListFilter] = useState({
    sortOn: 'createdAt',
    sortBy: 'DESC'
  });
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterList, setFilterList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [externalFetchLoading, setExternalFetchLoading] = useState(false);

  const [updateDynamicListItem] = useMutation(UPDATE_DYNAMIC_LIST_ITEM, {
    onError: () => {}
  });

  const [deleteDynamicListItem] = useMutation(DELETE_DYNAMIC_LIST_ITEM, {
    onError: () => {}
  });

  const [dynamicFieldListItems] = useLazyQuery(GET_DYNAMIC_FIELD_LIST_ITEMS, {
    onCompleted: (res) => {
      setHasPickListData(res?.dynamicFieldListItems?.count > 0);
      setIsEnd(res?.dynamicFieldListItems?.data?.length < limit);
      if (scrollFlag) {
        setPickListItems([
          ...pickListItems,
          ...res?.dynamicFieldListItems?.data
        ]);
      } else {
        setPickListItems(res?.dynamicFieldListItems?.data);
      }
      setScrollFlag(false);
      setPickListItemsLoading(false);
      setRefetchData(false);
      setExternalFetchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setPickListItemsLoading(false);
    }
  });

  const getData = () => {
    setPickListItemsLoading(true);
    dynamicFieldListItems({
      variables: {
        filter: pickListFilter,
        where: { ...filters, fieldId: id }
      }
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'isActive':
          setFilterList([true, false]);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document
      ?.querySelector('.ant-table-body')
      ?.addEventListener('scroll', (event) => {
        if (pickListTableScroll) {
          pickListTableScroll?.cancel();
        }
        const { target } = event;
        const { scrollTop, scrollHeight, offsetHeight } = target || {};
        pickListTableScroll = debounce(() => {
          const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
          if (scrollTop > 0 && scrolledToBottom && !isEnd) {
            setScrollFlag(true);
            setPickListItemsLoading(true);
            dynamicFieldListItems({
              variables: {
                filter: {
                  ...pickListFilter,
                  skip: pickListItems?.length,
                  limit
                },
                where: { fieldId: id }
              }
            });
          } else {
            setScrollFlag(false);
          }
        }, 500);
        pickListTableScroll();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (refetchData) {
      setExternalFetchLoading(true);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchData]);

  const onChangeDefault = async (record) => {
    await updateDynamicListItem({
      variables: {
        data: {
          default: !record?.default
        },
        where: {
          id: record?.id
        }
      }
    });
    getData();
  };

  const handleDeleteListItem = async (record) => {
    const response = await deleteDynamicListItem({
      variables: { where: { id: record?.id, deleteAllOption: false } }
    });
    if (response?.data?.deleteDynamicListItem) {
      getData();
    }
  };

  const handleListItemStatus = async (record) => {
    const editData = {
      isActive: !record?.isActive
    };
    const response = await updateDynamicListItem({
      variables: { data: { ...editData }, where: { id: record?.id } }
    });
    if (response?.data?.updateDynamicListItem) {
      getData();
    }
  };

  const renderActionButtons = (pickListRecord) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl
          allowedPermissions={[
            'FET_DYNAMIC_FIELD_UPDATE',
            'FET_DYNAMIC_FIELD_VIEW'
          ]}
        >
          <Button
            id="pick-list-table-edit-btn"
            className="b-0"
            onClick={() => handlePickListModalVisible(true, pickListRecord)}
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_DYNAMIC_FIELD_DELETE']}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteListItem(pickListRecord)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="pick-list-table-status-btn" className="b-0">
              Remove
            </Button>
          </Popconfirm>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_DYNAMIC_FIELD_UPDATE']}>
          <Popconfirm
            title={`Are you sure to ${
              pickListRecord?.isActive ? 'Mark Inactive' : 'Mark Active'
            }?`}
            onConfirm={() => handleListItemStatus(pickListRecord)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="pick-list-table-status-btn" className="b-0">
              {pickListRecord?.isActive ? 'Mark Inactive' : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleResetRole = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    setPickListItemsLoading(true);
    dynamicFieldListItems({
      variables: {
        filter: { sortOn: 'createdAt', sortBy: 'DESC' },
        where: { ...filtersCopy, fieldId: id }
      }
    });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const getFilterData = (confirm) => {
    setPickListItemsLoading(true);
    dynamicFieldListItems({
      variables: {
        filter: { ...pickListFilter },
        where: { ...filtersCopyState, fieldId: id }
      }
    });
    setFilters(filtersCopyState);
    if (confirm) {
      confirm();
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span title={item === true ? 'Active' : 'Inactive'}>
                      {item === true ? 'Active' : 'Inactive'}
                    </span>
                  </Tag>
                );
              })}
            </div>
          )}
          <div className="filter-checkboxes">
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleResetRole(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 150,
      dataIndex: 'name',
      key: 'name',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (name, record) => {
        return (
          <>
            <img
              src={record?.image?.url || placeholderImage}
              alt="dynamic-fields-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={name}>{name}</span>
          </>
        );
      }
    },
    {
      title: 'LABEL',
      ellipsis: true,
      width: 130,
      dataIndex: 'label',
      key: 'label',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'label' && sortedInfo?.order
    },
    {
      title: 'VALUE',
      width: 150,
      ellipsis: true,
      dataIndex: 'value',
      key: 'value',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'value' && sortedInfo?.order
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      className: 'max-width-column',
      key: 'description',
      ellipsis: true,
      render: (description) => <span>{description || '-'}</span>
    },
    {
      title: 'DEFAULT',
      width: 90,
      dataIndex: 'default',
      align: 'center',
      key: 'default',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'default' && sortedInfo?.order,
      render: (checked, record) => (
        <Radio
          className="common-radio"
          disabled={
            !checkPermissions(permissions, ['FET_DYNAMIC_FIELD_UPDATE'])
          }
          checked={checked}
          onClick={() => onChangeDefault(record)}
        />
      )
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'isActive',
      key: 'isActive',
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    checkPermissions(permissions, [
      'FET_DYNAMIC_FIELD_UPDATE',
      'FET_DYNAMIC_FIELD_DELETE',
      'FET_DYNAMIC_FIELD_VIEW'
    ]) && {
      title: (
        <>
          <AccessControl allowedPermissions={['FET_DYNAMIC_FIELD_UPDATE']}>
            <Button
              className="common-button"
              size="small"
              id="add-btn"
              type="primary"
              onClick={() => handlePickListModalVisible(true)}
            >
              Add
            </Button>
          </AccessControl>
        </>
      ),
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (_, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const handleTableChange = (pagination, tableFilter, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.column) {
      setPickListFilter({
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      setPickListItemsLoading(true);
      dynamicFieldListItems({
        variables: {
          filter: {
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          where: { ...filters, fieldId: id }
        }
      });
    } else {
      setPickListFilter({
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      setPickListItemsLoading(true);
      dynamicFieldListItems({
        variables: {
          filter: {
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          where: { ...filters, fieldId: id }
        }
      });
    }
  };

  if (externalFetchLoading) {
    return <LoaderComponent setHeight={5} />;
  }

  return (
    <div className="common-table pick-list-table">
      {pageSize && (
        <TableComponent
          isSearch={false}
          fullHeight={false}
          scroll={pickListItems?.length > 0 ? { x: 'max-content', y: 400 } : {}}
          loadingData={pickListItemsLoading}
          columns={[...columns?.filter((item) => item !== false)] || []}
          data={pickListItems}
          rowKey={(obj) => obj?.id}
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export default withRouter(PickListTable);
