import React, { useEffect, useState } from 'react';
import { PhotoshopPicker } from 'react-color';

const ColorPickerComponent = (props) => {
  const [pickerColor, setPickerColor] = useState('');
  const { initialColor = '#123456', onOk, onCancel, ...rest } = props;

  useEffect(() => {
    setPickerColor(initialColor);
  }, [initialColor]);

  const handleChange = (color) => {
    setPickerColor(color.hex);
  };

  const onAccept = () => {
    if (onOk) {
      onOk(pickerColor);
    }
  };

  const onClickCancel = () => {
    if (onCancel) {
      onCancel(false);
    }
  };

  return (
    <PhotoshopPicker
      onAccept={onAccept}
      onCancel={onClickCancel}
      color={pickerColor}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default ColorPickerComponent;
