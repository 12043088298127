import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { find, map } from 'lodash';
import React, { useEffect } from 'react';
import SelectComponent from '../../../../components/SelectComponent';
import './renderSelect.less';

const { Option } = SelectComponent;

const RenderSelect = ({ selectData = null, form, editData = null }) => {
  const {
    config,
    label = '',
    tooltip = '',
    key = '',
    dynamicFieldListItems = []
  } = selectData;
  const {
    PICK_LIST: {
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired
      } = null
    } = {}
  } = config || null;

  useEffect(() => {
    if (editData?.[key] || editData?.[key] === null) {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: editData?.[key]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key]}
      rules={[
        {
          required: fieldRequired,
          message: `Please Select ${label}`
        }
      ]}
      initialValue={find(dynamicFieldListItems, (item) => item?.default)?.value}
      hidden={hidden}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />
        }
      }
      className={`dynamic-render-select ${tooltip && 'label-with-tooltip'}${
        hidden ? 'd-none' : ''
      }`}
    >
      <SelectComponent
        disabled={fieldReadOnly}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.children ?? '')
            ?.toLowerCase()
            ?.includes(input?.toLowerCase())
        }
      >
        {map(dynamicFieldListItems, (item) => (
          <Option key={item?.id} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </SelectComponent>
    </Form.Item>
  );
};

export default RenderSelect;
