import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Popconfirm, Tag } from 'antd';
import { debounce, filter, find, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import DeleteIcon from '../../../assets/delete-red.svg';
import FilterIcon from '../../../assets/filter.svg';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { REMOVE_PROPERTY } from '../graphql/Mutations';
import {
  GET_PROPERTIES,
  PROPERTY_FILTER,
  PROPERTY_TYPES_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;

const PropertyTable = ({ match: { params: { id } = {} } = {} }) => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialPropertyFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const location = useLocation();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [propertyFilter, setPropertyFilter] = useState(initialPropertyFilter);
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [fetchPropertyData, { loading, data }] = useLazyQuery(GET_PROPERTIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.properties?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [propertyFilters] = useLazyQuery(PROPERTY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [propertyTypes] = useLazyQuery(PROPERTY_TYPES_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyTypes?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [removeProperty] = useMutation(REMOVE_PROPERTY, {
    onError() {}
  });

  useEffect(() => {
    fetchPropertyData({
      variables: {
        salesWhere: {
          joinedFrom: 'CONTACT',
          id
        },
        filter: propertyFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchPropertyData({
      variables: {
        salesWhere: {
          joinedFrom: 'CONTACT',
          id
        },
        filter: { ...propertyFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setPropertyFilter({
      ...propertyFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'propertyType':
          propertyTypes({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['label', 'key']
              }
            }
          });
          break;

        default:
          propertyFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'propertyType':
            propertyTypes({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: 0,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['label', 'key']
                }
              }
            });
            break;
          default:
            propertyFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    if (dataIndex === 'builtYear') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    fetchPropertyData({
      variables: {
        salesWhere: {
          joinedFrom: 'CONTACT',
          id
        },
        filter: {
          ...propertyFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setPropertyFilter({
      ...propertyFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchPropertyData({
        variables: {
          salesWhere: {
            joinedFrom: 'CONTACT',
            id
          },
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchPropertyData({
        variables: {
          salesWhere: {
            joinedFrom: 'CONTACT',
            id
          },
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'propertyType':
        propertyTypes({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: filterSearch,
              distinct: true,
              getDBField: ['label', 'key']
            }
          }
        });
        break;
      default:
        propertyFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };
  const onRangePickerChange = (values, dataIndex) => {
    if (values) {
      const rangeObj = {
        from: moment(values?.[0])?.format('YYYY'),
        to: moment(values?.[1])?.format('YYYY')
      };
      const filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: rangeObj
      };
      setFiltersCopyState(filtersCopy);
    }
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'builtYear') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format="YYYY"
              picker="year"
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'YYYY')
                    ]
                  : ''
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span
                          title={
                            find(filterList, ['key', item])?.label ||
                            item?.toString()
                          }
                        >
                          {find(filterList, ['key', item])?.label ||
                            item?.toString()}
                        </span>
                      )}
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="filter-checkbox-section"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="common-checkbox"
                      >
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteProperty = async (editPropertyData) => {
    const response = await removeProperty({
      variables: {
        where: {
          id: editPropertyData?.id
        },
        salesWhere: {
          joinedFrom: 'CONTACT',
          id
        }
      }
    });
    if (response) {
      fetchPropertyData({
        variables: {
          salesWhere: {
            joinedFrom: 'CONTACT',
            id
          },
          filter: propertyFilter
        }
      });
    }
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      className: 'max-width-column'
    },
    {
      title: 'TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType',
      width: 200,
      ellipsis: true,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'propertyType' && sortedInfo?.order,
      ...filterPopup('propertyType')
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      className: 'max-width-column',
      render: (text, record) => {
        return (
          <span key={record?.id}>
            {`${record?.location?.addressLine1} ${record?.location?.city},  ${record?.location?.state} ${record?.location?.zipCode}`}
          </span>
        );
      }
    },
    {
      title: 'BUILTYEAR',
      dataIndex: 'builtYear',
      key: 'builtYear',
      ellipsis: true,
      width: 200,
      ...filterPopup('builtYear')
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 200,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    !location?.pathname?.includes('/view') && {
      dataIndex: 'id',
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (propertyId, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteProperty(record)}
            okText="Yes"
            cancelText="No"
          >
            <img
              className="contact-delete-icon"
              src={DeleteIcon}
              alt="delete-icon"
              onClick={(event) => {
                event?.stopPropagation();
              }}
            />
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading}
            columns={[...columns?.filter((item) => item !== false)]}
            setHeight={282}
            data={data?.properties?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PropertyTable);
