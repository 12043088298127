import Icon from '@ant-design/icons';
import React from 'react';

const expandIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4142 9L16 14.5858V11H18V18H11V16H14.5858L9 10.4142L3.41421 16H7V18H0V11H2V14.5858L7.58579 9L2 3.41421V7H0V0H7V2H3.41421L9 7.58579L14.5858 2H11V0H18V7H16V3.41421L10.4142 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

const ExpandIconComponent = (props) => (
  <Icon component={expandIcon} {...props} />
);

export default ExpandIconComponent;
