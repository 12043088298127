import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import QuoteViewerDetail from '../components/QuoteViewerDetail';
import { GET_LEAD } from '../graphql/Queries';

function QuoteViewerView(props) {
  const { match: { params: { id } = {} } = {} } = props;
  const [loading, setLoading] = useState(true);

  const { data: { lead } = {} } = useQuery(GET_LEAD, {
    variables: { where: { id } },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.QUOTE_VIEWER} />
          <span className="portal-header">Leads</span>
        </div>
      </Portal>

      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <QuoteViewerDetail data={lead} />
      )}
    </Card>
  );
}

export default QuoteViewerView;
