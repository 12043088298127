import { gql } from '@apollo/client';

export const CREATE_DYNAMIC_FIELD = gql`
  mutation createDynamicField($data: CreateDynamicFieldInput!) {
    createDynamicField(data: $data) {
      id
      status
      message
    }
  }
`;

export const UPDATE_DYNAMIC_FIELD = gql`
  mutation updateDynamicField(
    $where: DynamicFieldWhereInput!
    $data: UpdateDynamicFieldInput!
  ) {
    updateDynamicField(where: $where, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_DYNAMIC_FIELD = gql`
  mutation deleteDynamicField($where: DynamicFieldWhereInput!) {
    deleteDynamicField(where: $where) {
      message
      status
    }
  }
`;

export const CREATE_DYNAMIC_LIST_ITEM = gql`
  mutation createDynamicListItem($data: DynamicListItemInput!) {
    createDynamicListItem(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_DYNAMIC_LIST_ITEM = gql`
  mutation updateDynamicListItem(
    $where: DynamicListItemWhereInput!
    $data: UpdateDynamicListItem!
  ) {
    updateDynamicListItem(where: $where, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_DYNAMIC_LIST_ITEM = gql`
  mutation deleteDynamicListItem($where: DynamicListItemDeleteWhereInput!) {
    deleteDynamicListItem(where: $where) {
      status
      message
    }
  }
`;
