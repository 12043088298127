import { gql } from '@apollo/client';

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($where: ProductWhereInput!, $data: ProductInput!) {
    updateProduct(where: $where, data: $data) {
      data {
        id
        productCode
        refData
        title
        productType
        price
        sku
        sellingPrice
        lineOfBusinessId
        subAreaId
        isActive
        brands
        regions
        description
        startData
        endDate
        tenantId
      }
      status
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductInput!) {
    createProduct(data: $data) {
      data {
        id
        productCode
        refData
        title
        productType
        price
        sku
        sellingPrice
        lineOfBusinessId
        subAreaId
        isActive
        brands
        regions
        description
        startData
        endDate
        tenantId
      }
      status
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($where: ProductWhereInput!) {
    deleteProduct(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CHANGE_ITEM_RANKING = gql`
  mutation changeItemRanking(
    $data: PriorityInput!
    $where: ProductWhereInput!
  ) {
    changeItemRanking(data: $data, where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
