import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  PERMISSION_DASHBOARD,
  PERMISSION_PRODUCT_PRICING,
  PERMISSION_PRODUCT_RANKING,
  PERMISSION_QUESTIONNAIRE,
  ROUTES,
  WHOM_TO_SHOW_SIDE_PANEL
} from '../../../common/constants';
import { getElementFromDocumentId } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DashboardPanel from './components/DashboardPanel';
import JobsPanel from './components/JobsPanel';
import ProductPricingPanel from './components/ProductPricingPanel';
import ProductRankingPanel from './components/ProductRankingPanel';
import './sidepanel.less';

const SidePanel = ({ history }) => {
  const { state } = useContext(AppContext);
  const location = useLocation();

  const attachSidePanel = (pathname, pathsToWhichHideSidePanel) => {
    if (!pathname) {
      return false;
    }

    for (
      let pathIndex = 0;
      pathIndex < pathsToWhichHideSidePanel?.length;
      // eslint-disable-next-line no-plusplus
      pathIndex++
    ) {
      const path = pathsToWhichHideSidePanel[pathIndex];

      if (pathname?.indexOf(path) !== -1) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (state?.params?.sp === 'false') {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.remove(
        'side-panel-open'
      );
    } else {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.add(
        'side-panel-open'
      );
    }
  }, [state?.params]);

  if (attachSidePanel(location?.pathname, WHOM_TO_SHOW_SIDE_PANEL)) {
    if (state?.params?.sp === 'false') {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.remove(
        'side-panel-open'
      );
    } else {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.add(
        'side-panel-open'
      );
    }
  }

  function hideSidePanel() {
    const urlSearchObj = new URLSearchParams(history?.location?.search);
    urlSearchObj?.set('sp', 'false');
    history?.replace(
      `${history?.location?.pathname}?${urlSearchObj?.toString()}`
    );
  }

  if (!attachSidePanel(location?.pathname, WHOM_TO_SHOW_SIDE_PANEL)) {
    return null;
  }

  return (
    <>
      <div className="side-panel-container">
        <Switch>
          <Route
            path={ROUTES?.DASHBOARD}
            render={() => (
              <AccessControl allowedPermissions={PERMISSION_DASHBOARD}>
                <DashboardPanel />
                <div className="side-panel-backdrop" onClick={hideSidePanel} />
              </AccessControl>
            )}
          />
          <Route
            path={`${ROUTES?.QUESTIONNAIRES_PRIMARY}/:id${ROUTES?.PAGE}/:pageId?`}
            render={() => {
              return (
                <AccessControl allowedPermissions={PERMISSION_QUESTIONNAIRE}>
                  <div id="page-sequence-content" />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </AccessControl>
              );
            }}
          />
          <Route
            path={`${ROUTES?.QUESTIONNAIRES_SECONDARY}/:id${ROUTES?.PAGE}/:pageId?`}
            render={() => {
              return (
                <AccessControl allowedPermissions={PERMISSION_QUESTIONNAIRE}>
                  <div id="page-sequence-content" />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </AccessControl>
              );
            }}
          />
          <Route
            path={ROUTES?.PRODUCT_PRICING}
            render={() => (
              <AccessControl allowedPermissions={PERMISSION_PRODUCT_PRICING}>
                <ProductPricingPanel />
                <div className="side-panel-backdrop" onClick={hideSidePanel} />
              </AccessControl>
            )}
          />
          <Route
            path={ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}
            render={() => (
              <AccessControl allowedPermissions={PERMISSION_PRODUCT_RANKING}>
                <ProductRankingPanel />
                <div className="side-panel-backdrop" onClick={hideSidePanel} />
              </AccessControl>
            )}
          />
          <Route path={`${ROUTES?.JOBS}/:id?`} render={() => <JobsPanel />} />
        </Switch>
      </div>
    </>
  );
};

export default withRouter(SidePanel);
