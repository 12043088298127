import { useLazyQuery, useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ContactForm from '../components/ContactForm';
import ContactModal from '../components/ContactModal';
import CardWrapper from '../components/ContactTabs';
import { CREATE_CONTACT } from '../graphql/Mutations';
import { GET_CONTACTS } from '../graphql/Queries';

const initialContactFilter = {
  sortOn: 'createdAt',
  sortBy: 'DESC'
};

function ContactCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [duplicateData, setDuplicateData] = useState([]);
  const [createContact] = useMutation(CREATE_CONTACT, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const sendToDB = async () => {
    const newFormValues = {
      userInput: {
        firstName: formValues?.firstName,
        lastName: formValues?.lastName,
        phoneNo: formValues?.phoneNo,
        email: formValues?.email,
        isActive: formValues?.isActive
      },
      homePhone: formatPhoneNumberWithoutMask(formValues?.homePhone),
      mainPhone: formatPhoneNumberWithoutMask(formValues?.mainPhone),
      web: formValues?.web,
      preferredContact: formValues?.preferredContact,
      contactType: formValues?.contactType,
      isActive: formValues?.isActive,
      locationInput: {
        addressLine1: formValues?.addressLine1,
        city: formValues?.city,
        state: formValues?.state,
        zipCode: formValues?.zipCode,
        country: formValues?.country
      }
    };

    const variables = newFormValues;
    try {
      const response = await createContact({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createContact) {
        const { id } = response?.data?.createContact?.data;
        history?.push(`${ROUTES?.CONTACTS}/edit${ROUTES?.CUSTOMERS}/${id}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const [fetchContactData] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.contacts?.data?.length > 0) {
        setShowModal(true);
        setDuplicateData(res?.contacts?.data);
        setSubmitLoading(false);
      } else {
        setShowModal(false);
        sendToDB();
      }
    },
    onError() {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = (values) => {
    setSubmitLoading(true);
    setFormValues(values);
    fetchContactData({
      variables: {
        filter: { ...initialContactFilter, skip: 0 },
        where: {
          duplicateCheck: true,
          name: [`${values?.firstName} ${values?.lastName}`],
          email: [values?.email],
          isActive: [values?.isActive],
          homePhone: [values?.homePhone],
          mainPhone: [values?.mainPhone],
          web: [values?.web],
          preferredContact: [values?.preferredContact],
          contactType: [values?.contactType]
        }
      }
    });
  };

  const initialValues = {};

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CONTACTS} />
          <span className="portal-header">Add Contact</span>
        </div>
      </Portal>
      <CardWrapper>
        {showModal && (
          <ContactModal
            showModal={showModal}
            setShowModal={setShowModal}
            duplicateData={duplicateData}
            onOk={sendToDB}
          />
        )}
        <ContactForm
          contactData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ContactCreate);
