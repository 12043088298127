import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import CampaignForm from '../components/CampaignForm';
import { CREATE_CAMPAIGN } from '../graphql/Mutations';

function CampaignCreate(props) {
  const { history = null, location = null } = props;
  const [submitLoading, setSubmitLoading] = useState(false);

  const [createProductCategoryMutate] = useMutation(CREATE_CAMPAIGN, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = newFormValues;

    try {
      const response = await createProductCategoryMutate({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createCampaign) {
        history?.push(ROUTES.CAMPAIGNS, { ...location?.state });
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: ''
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CAMPAIGNS} />
          <span className="portal-header">Add Campaign</span>
        </div>
      </Portal>
      <CampaignForm
        campaignData={initialValues}
        handleSubmit={handleSubmit}
        isSubmit={submitLoading}
      />
    </Card>
  );
}

export default withRouter(CampaignCreate);
