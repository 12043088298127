import { gql } from '@apollo/client';

export const UPDATE_MANUFACTURER = gql`
  mutation updateManufacturer(
    $where: ManufacturerWhereInput!
    $data: ManufacturerInput!
  ) {
    updateManufacturer(where: $where, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_MANUFACTURER = gql`
  mutation createManufacturer($data: ManufacturerInput!) {
    createManufacturer(data: $data) {
      status
      message
    }
  }
`;

export const DELETE_MANUFACTURER = gql`
  mutation deleteManufacturer($where: ManufacturerWhereInput!) {
    deleteManufacturer(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
