import React from 'react';

const AddOnWidgetComponent = ({ widgetConfig = null }) => {
  return (
    <div className="content-page">
      {widgetConfig?.title && (
        <div className="mt-37">
          {widgetConfig?.title && widgetConfig?.title?.length > 0 && (
            <div className="bold-label">
              <span>{widgetConfig?.title}</span>
            </div>
          )}
          {widgetConfig?.subTitle && widgetConfig?.subTitle?.length > 0 && (
            <div className="bold-label mt-4 content-font-size">
              <span>{widgetConfig?.subTitle}</span>
            </div>
          )}
          {widgetConfig?.descriptionValue &&
            widgetConfig?.descriptionValue?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <div
                  className="editor-render"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.descriptionValue
                  }}
                />
              </div>
            )}
        </div>
      )}
      {widgetConfig?.footerBlock?.enable && (
        <div className="mt-37">
          {widgetConfig?.footerBlock?.titleCheck &&
            widgetConfig?.footerBlock?.titleText?.length > 0 && (
              <div className="bold-label">
                <span>{widgetConfig?.footerBlock?.titleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.subTitleCheck &&
            widgetConfig?.footerBlock?.subTitleText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <span>{widgetConfig?.footerBlock?.subTitleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.descriptionCheck &&
            widgetConfig?.footerBlock?.descriptionText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <div
                  className="editor-render"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.footerBlock?.descriptionText
                  }}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default AddOnWidgetComponent;
