import { gql } from '@apollo/client';

export const GET_DYNAMIC_FIELDS = gql`
  query dynamicFields(
    $filter: DynamicFieldFilter!
    $where: DynamicFieldWhereFilter
  ) {
    dynamicFields(filter: $filter, where: $where) {
      count
      data {
        id
        industryId
        fieldType
        key
        sortOrder
        lineOfBusinessIds
        status
        name
        label
        tooltip
        industry {
          id
          label
        }
        config
        lineOfBusinesses {
          id
          label
        }
      }
    }
  }
`;

export const GET_DYNAMIC_FIELD = gql`
  query dynamicField($where: DynamicFieldWhereInput!) {
    dynamicField(where: $where) {
      id
      industryId
      fieldType
      key
      sortOrder
      lineOfBusinessIds
      label
      tooltip
      status
      config
      name
      placeholder
      description
      industry {
        id
        label
      }
      lineOfBusinesses {
        id
        label
      }
    }
  }
`;

export const DYNAMIC_FIELD_FILTER = gql`
  query dynamicFieldFilters($filter: DynamicFieldFilter!) {
    dynamicFieldFilters(filter: $filter) {
      count
      data {
        status
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobSaData {
          id
          label
          key
        }
      }
    }
  }
`;

export const GET_DYNAMIC_FIELD_LIST_ITEMS = gql`
  query dynamicFieldListItems(
    $filter: DynamicListItemFilter!
    $where: DynamicFieldListWhereInput!
  ) {
    dynamicFieldListItems(filter: $filter, where: $where) {
      count
      data {
        id
        fieldId
        image {
          url
          key
          name
          extension
          contentType
        }
        label
        name
        value
        default
        isActive
        description
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_DYNAMIC_FIELD_TIME_FORMATS = gql`
  query dynamicFieldTimeFormats {
    dynamicFieldTimeFormats {
      data {
        dateFormats
        timeFormats
        dateTimeFormats
      }
    }
  }
`;
