import { Card } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import LineOfBusinessList from '../../lineOfBusinesses/pages/LineOfBusinessList';
import IndustryForm from '../components/IndustryForm';

const IndustryEdit = () => {
  const { id } = useParams();

  return (
    <div className="split-screen-section">
      <Card className="mb-24 semi-height-card card-body-padding">
        <IndustryForm industryId={id} isEdit />
      </Card>
      <Card className="semi-height-card card-body-padding">
        <LineOfBusinessList />
      </Card>
    </div>
  );
};

export default IndustryEdit;
