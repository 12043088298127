import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import EditorComponent from '../../../../components/EditorComponent';
import InputComponent from '../../../../components/InputComponent';

const { TextArea } = InputComponent;
const RenderText = ({ textData, form, editData = null }) => {
  const { config, label = '', tooltip = '', key = '' } = textData;
  const {
    TEXT: {
      default: defaultText,
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired
      } = {},
      textLength: { maxLength, minLength } = {},
      textType = ''
    } = {}
  } = config || {};

  useEffect(() => {
    if (editData?.[key] || editData?.[key] === null) {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: editData?.[key]
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const renderText = () => {
    if (textType === 'MULTI_LINE') {
      return (
        <TextArea
          readOnly={fieldReadOnly}
          maxLength={maxLength}
          minLength={minLength}
          showCount
          rows={3}
          className="common-textarea"
        />
      );
    }
    if (textType === 'RICH_TEXT') {
      return <EditorComponent readOnly={fieldReadOnly} />;
    }
    return (
      <InputComponent
        readOnly={fieldReadOnly}
        maxLength={maxLength}
        minLength={minLength}
        showCount
      />
    );
  };

  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key]}
      rules={[
        { required: fieldRequired, message: 'Please Enter Text' },
        {
          max: maxLength,
          message: `Please Enter Max ${maxLength} Characters`
        },
        {
          min: minLength,
          message: `Please Enter Min ${minLength} Characters`
        }
      ]}
      initialValue={defaultText}
      hidden={hidden}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />
        }
      }
      className={`${tooltip && 'label-with-tooltip'}${hidden ? 'd-none' : ''}`}
    >
      {renderText()}
    </Form.Item>
  );
};

export default RenderText;
