import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import PriceComponent from '../../../../../../../../components/PriceComponent';

const CustomNumberPreview = ({ widgetConfig }) => {
  const [form] = Form?.useForm();
  const minNumber = widgetConfig?.config?.range?.minNumber;
  const maxNumber = widgetConfig?.config?.range?.maxNumber;
  const label = widgetConfig?.config?.label || ' ';
  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const defaultCheck = widgetConfig?.config?.defaultCheck;
  const defaultValue = widgetConfig?.config?.default;
  const descriptionCheck = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const decimal = widgetConfig?.config?.decimalPlace;

  useEffect(() => {
    if (defaultCheck) {
      form?.setFieldsValue({
        customNumber: defaultValue
      });
    } else {
      form?.setFieldsValue({
        customNumber: null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, defaultCheck]);

  return (
    <div className="mt-37">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          customNumber: defaultCheck ? defaultValue : ''
        }}
      >
        <Form.Item
          name="customNumber"
          label={hideLabel ? ' ' : label}
          rules={[{ required, message: 'Please Enter Number' }]}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />
            }
          }
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          <PriceComponent
            decimalValue={decimal}
            maxValue={maxNumber}
            minValue={minNumber}
            isPrice={false}
            prefix=""
            value={defaultValue}
            disabled
            placeholder={`Enter your ${label}`}
          />
        </Form.Item>
      </Form>
      {descriptionCheck && descriptionValue && (
        <span className="thin-label">{descriptionValue}</span>
      )}
    </div>
  );
};

export default CustomNumberPreview;
