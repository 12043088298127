import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Radio, Switch } from 'antd';
import React from 'react';
import InputComponent from '../../../../../../components/InputComponent';

const ManualEntry = ({ form = null, handleChangeDefaultForOtherTabs }) => {
  const showVolumeTooltip = Form?.useWatch(
    ['widgetConfiguration', 'common', 'manualEntry', 'volumeToolTipCheck'],
    form
  );
  const showDepthTooltip = Form?.useWatch(
    ['widgetConfiguration', 'common', 'manualEntry', 'depthToolTipCheck'],
    form
  );

  const showAreaTooltip = Form?.useWatch(
    ['widgetConfiguration', 'common', 'manualEntry', 'areaToolTipCheck'],
    form
  );

  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'common', 'manualEntry', 'descriptionCheck'],
    form
  );

  return (
    <>
      <Form.Item
        label="Title"
        name={['widgetConfiguration', 'common', 'manualEntry', 'title']}
        rules={[
          {
            required: true,
            message: 'Please Enter Title'
          }
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'common', 'manualEntry', 'default']}
        valuePropName="checked"
      >
        <Radio
          className="common-radio"
          value="default"
          onChange={() => handleChangeDefaultForOtherTabs('MANUAL_ENTRY')}
        >
          Default
        </Radio>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'manualEntry',
            'descriptionCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'common',
          'manualEntry',
          'descriptionText'
        ]}
        className="mb-0"
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description'
          }
        ]}
      >
        <InputComponent placeholder="Enter Description" />
      </Form.Item>
      {/* area section */}
      <Form.Item
        label="Area"
        name={['widgetConfiguration', 'common', 'manualEntry', 'areaText']}
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Area'
          }
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Area" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'manualEntry',
            'areaToolTipCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'common',
          'manualEntry',
          'areaToolTipText'
        ]}
        hidden={!showAreaTooltip}
        rules={[
          {
            required: showAreaTooltip,
            message: 'Please Enter Area Tooltip'
          }
        ]}
      >
        <InputComponent placeholder="Enter Area Tooltip" />
      </Form.Item>
      {/* depth section */}
      <Form.Item
        label="Depth"
        name={['widgetConfiguration', 'common', 'manualEntry', 'depthText']}
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Depth'
          }
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Depth" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'manualEntry',
            'depthToolTipCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'common',
          'manualEntry',
          'depthToolTipText'
        ]}
        hidden={!showDepthTooltip}
        rules={[
          {
            required: showDepthTooltip,
            message: 'Please Enter Depth Tooltip'
          }
        ]}
      >
        <InputComponent placeholder="Enter Depth Tooltip" />
      </Form.Item>
      {/* volume section */}
      <Form.Item
        label="Volume"
        name={['widgetConfiguration', 'common', 'manualEntry', 'volumeText']}
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Volume'
          }
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Volume" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'manualEntry',
            'volumeToolTipCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'common',
          'manualEntry',
          'volumeToolTipText'
        ]}
        hidden={!showVolumeTooltip}
        rules={[
          {
            required: showVolumeTooltip,
            message: 'Please Enter Volume Tooltip'
          }
        ]}
      >
        <InputComponent placeholder="Enter Volume Tooltip" />
      </Form.Item>
    </>
  );
};

export default ManualEntry;
