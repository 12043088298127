import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import StepProcess from '../pages/StepProcess';
import UploadLogo from './UploadLogo';

const AddLogo = () => {
  const { getOnboardingData } = useContext(AppContext);
  const data = getOnboardingData();
  return (
    <StepProcess>
      <div className="add-logo">
        <span className="steps-content-title">
          {data?.user?.firstName}, let's add your business logo
        </span>
        <span className="logo-description steps-content-description">
          Preferred dimensions are 150 x 150 px
        </span>
        <span className="steps-content-description">
          You can upload different logo sizes later in the Tenant Portal.
        </span>
        <UploadLogo folder="logo" />
      </div>
    </StepProcess>
  );
};

export default AddLogo;
