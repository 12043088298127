import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React, { useCallback } from 'react';
import {
  DYNAMIC_FIELDS_DATA_KEYS,
  DYNAMIC_TEXT_TYPE,
  MULTI_LINE_TEXT,
  REGEX,
  RICH_TEXT_TYPE,
  SINGLE_LINE_TEXT
} from '../../../../../../../../common/constants';
import InputComponent from '../../../../../../../../components/InputComponent';
import NumberComponent from '../../../../../../../../components/NumberComponent';

const SystemTextFieldForm = ({
  form = null,
  systemFieldData = null,
  setSystemFieldData
}) => {
  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'toolTipCheck'],
    form
  );

  const textType = Form?.useWatch(
    [
      'widgetConfiguration',
      'fieldConfig',
      DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
      'textType'
    ],
    form
  );

  const updateMax = useCallback(() => {
    if (textType === SINGLE_LINE_TEXT) {
      return 255;
    }
    if (textType === MULTI_LINE_TEXT) {
      return 1000;
    }
    return 5000;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, textType]);

  const max = updateMax();

  const handleChangeTextType = (e) => {
    switch (e?.target?.value) {
      case SINGLE_LINE_TEXT:
        form?.setFieldsValue({
          widgetConfiguration: {
            fieldConfig: {
              [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
                textLength: { minLength: 1, maxLength: 255 },
                textType: SINGLE_LINE_TEXT
              }
            }
          }
        });
        setSystemFieldData({
          ...systemFieldData,
          fieldConfig: {
            ...systemFieldData?.widgetConfiguration?.config,
            [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
              ...systemFieldData?.widgetConfiguration?.config?.[
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT
              ],
              textLength: { minLength: 1, maxLength: 255 },
              textType: SINGLE_LINE_TEXT
            }
          }
        });
        break;
      case MULTI_LINE_TEXT:
        form?.setFieldsValue({
          widgetConfiguration: {
            fieldConfig: {
              [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
                textLength: { minLength: 1, maxLength: 1000 },
                textType: MULTI_LINE_TEXT
              }
            }
          }
        });
        setSystemFieldData({
          ...systemFieldData,
          fieldConfig: {
            ...systemFieldData?.widgetConfiguration?.config,
            [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
              ...systemFieldData?.widgetConfiguration?.config?.[
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT
              ],
              textLength: { minLength: 1, maxLength: 1000 },
              textType: MULTI_LINE_TEXT
            }
          }
        });
        break;
      case RICH_TEXT_TYPE:
        form?.setFieldsValue({
          widgetConfiguration: {
            fieldConfig: {
              [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
                textLength: { minLength: 1, maxLength: 5000 },
                textType: RICH_TEXT_TYPE
              }
            }
          }
        });
        setSystemFieldData({
          ...systemFieldData,
          fieldConfig: {
            ...systemFieldData?.widgetConfiguration?.config,
            [DYNAMIC_FIELDS_DATA_KEYS?.TEXT]: {
              ...systemFieldData?.widgetConfiguration?.config?.[
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT
              ],
              textLength: { minLength: 1, maxLength: 5000 },
              textType: RICH_TEXT_TYPE
            }
          }
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Required</span>
        <Form.Item
          name={['widgetConfiguration', 'required']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'fieldConfig',
          DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
          'textType'
        ]}
        className="d-flex mt-10"
      >
        <Radio.Group
          className="common-radio custom-radio"
          onChange={handleChangeTextType}
        >
          {map(DYNAMIC_TEXT_TYPE, (item) => (
            <Radio key={item?.key} value={item?.key}>
              {item?.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row className="d-flex justify-between">
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            name={[
              'widgetConfiguration',
              'fieldConfig',
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              'textLength',
              'minLength'
            ]}
            dependencies={[
              [
                'widgetConfiguration',
                'fieldConfig',
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                'textType'
              ],
              [
                'widgetConfiguration',
                'fieldConfig',
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                'textLength',
                'maxLength'
              ]
            ]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const maxLength = getFieldValue([
                    'widgetConfiguration',
                    'fieldConfig',
                    DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                    'textLength',
                    'maxLength'
                  ]);
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 1) {
                    return Promise?.reject(
                      new Error('Minimum character length should be 1')
                    );
                  }
                  if (value && Number(value) > max) {
                    return Promise?.reject(
                      new Error(`Maximum character length should be ${max}`)
                    );
                  }
                  if (maxLength && value && Number(value) > maxLength) {
                    return Promise?.reject(
                      new Error(`Please Enter value less than ${maxLength}`)
                    );
                  }
                  return Promise?.resolve();
                }
              })
            ]}
            label="Min. Character Length"
          >
            <NumberComponent
              min={1}
              max={updateMax()}
              allowClear
              placeholder="Enter Min. Char. Length"
            />
          </Form.Item>
        </Col>
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 1) {
                    return Promise?.reject(
                      new Error('Minimum character length should be 1')
                    );
                  }
                  if (value && Number(value) > max) {
                    return Promise?.reject(
                      new Error(`Maximum character length should be ${max}`)
                    );
                  }
                  const minLength = getFieldValue([
                    'widgetConfiguration',
                    'fieldConfig',
                    DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                    'textLength',
                    'minLength'
                  ]);
                  if (minLength && value && Number(value) < minLength) {
                    return Promise?.reject(
                      new Error(`Please Enter value greater than ${minLength}`)
                    );
                  }
                  return Promise?.resolve();
                }
              })
            ]}
            name={[
              'widgetConfiguration',
              'fieldConfig',
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              'textLength',
              'maxLength'
            ]}
            label="Max. Character Length"
            dependencies={[
              [
                'widgetConfiguration',
                'fieldConfig',
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                'textType'
              ],
              [
                'widgetConfiguration',
                'fieldConfig',
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
                'textLength',
                'minLength'
              ]
            ]}
          >
            <NumberComponent
              min={1}
              max={updateMax()}
              allowClear
              placeholder="Enter Max. Char. Length"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={['widgetConfiguration', 'label']}
        label="Label"
        rules={[
          {
            required: true,
            message: 'Please Enter Label'
          }
        ]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'toolTipCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'toolTipText']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value'
          }
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
    </div>
  );
};

export default SystemTextFieldForm;
