import { Card } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import RegionForm from '../components/RegionForm';

function RegionEdit(props) {
  const { history = null, location = null } = props;

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Card className="full-height-card card-body-padding">
      <RegionForm isEdit />
    </Card>
  );
}

export default withRouter(RegionEdit);
