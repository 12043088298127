import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo-login.svg';
import { ROUTES } from '../../common/constants';
import { fetchStep, formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { LOGIN } from './graphql/Mutations';

const { requiredWhiteSpaceAllowed, required, email } = formValidatorRules;

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const {
    initializeAuth,
    getCurrentUser,
    dispatch,
    defaultPageSize
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {
      history?.replace(ROUTES?.LOGIN);
      setLoading(false);
    }
  });

  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
    fetchStep({
      dispatch,
      setLoading: true,
      changeRoute: true,
      getCurrentUser
    });
  }

  const onFinish = async (values) => {
    try {
      const response = await loginMutate({
        variables: { data: { ...values } }
      });

      const accessToken = response?.data?.webLogin?.authToken;
      const userData = response?.data?.webLogin?.user;
      if (accessToken && successCallback) {
        successCallback(accessToken, userData);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const query = getQuery();
  useEffect(() => {
    if (query?.get('token')) {
      defaultPageSize();
      onFinish({ token: query?.get('token') });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return (
    <>
      <div className="gx-login-container">
        <LoaderComponent
          spinning={loginLoading}
          wrapperClassName="gx-login-content"
        >
          <div className="gx-login-header gx-text-center">
            <img src={logo} alt="path.pro-logo" className="mb-4" width={250} />
          </div>
          <Form
            name="Login"
            autoComplete="off"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="email" rules={[requiredWhiteSpaceAllowed, email]}>
              <InputComponent
                placeholder="Email"
                prefix={<UserOutlined />}
                aria-label="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[required]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <InputComponent.Password
                className="password-input"
                prefix={<LockOutlined />}
                placeholder="Password"
                aria-label="Password"
              />
            </Form.Item>
            <Form.Item className="gx-text-center">
              <Button
                type="primary"
                className="common-button login-button fill-width font-size-14"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
            <Form.Item className="d-flex reset-password">
              <Link to={ROUTES?.RESET}>Forgot password ?</Link>
            </Form.Item>
            <Form.Item className="d-flex reset-password">
              <Link to={ROUTES?.SIGNUP}>Register here</Link>
            </Form.Item>
          </Form>
        </LoaderComponent>
      </div>
    </>
  );
};

export default Login;
