import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ProductPricingTable from '../components/ProductPricingTable';

const ProductPricingList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.PRODUCT_PRICING}</span>
      </Portal>
      {pageSize && <ProductPricingTable />}
    </Card>
  );
};

export default ProductPricingList;
