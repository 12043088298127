import { gql } from '@apollo/client';

export const CREATE_HOLIDAY = gql`
  mutation createHoliday($data: HolidayInput!) {
    createHoliday(data: $data) {
      message
      status
    }
  }
`;

export const REMOVE_HOLIDAY = gql`
  mutation deleteHoliday($where: HolidayWhereInput!) {
    deleteHoliday(where: $where) {
      status
      message
    }
  }
`;

export const UPDATE_HOURS_OF_OPERATION = gql`
  mutation updateHoursOperations($data: ActionsInput!) {
    updateHoursOperations(data: $data) {
      message
      status
    }
  }
`;
