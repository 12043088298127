import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, TimePicker } from 'antd';
import { isArray } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { DATE_TIME_VALIDATION } from '../../../../../../../../common/constants';
import DatePickerComponent from '../../../../../../../../components/DatePickerComponent';
import RangePickerComponent from '../../../../../../../../components/RangePickerComponent';

const { RangePicker } = TimePicker;

const CustomDateTimePreview = ({ widgetConfig }) => {
  const [form] = Form?.useForm();
  const dateTimeType = widgetConfig?.config?.dateTimeType;
  const dateConfig = widgetConfig?.config?.dateConfig;
  const label = widgetConfig?.config?.label || ' ';
  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const descriptionCheck = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const dateDefaultValue = widgetConfig?.config?.dateDefaultValue;
  const format = widgetConfig?.config?.format;
  const rangeDefaultValue = widgetConfig?.config?.rangeDefaultValue;
  const allowDateTime = widgetConfig?.config?.allowDateTime;
  const dateTimeBooleanValue = widgetConfig?.config?.dateTimeBooleanValue;
  const timeDefaultValue = widgetConfig?.config?.timeDefaultValue;

  const handleRendering = () => {
    if (isArray(dateTimeType)) {
      if (dateTimeType?.includes('DATE') && dateTimeType?.includes('TIME')) {
        if (dateConfig?.exactDate) {
          if (dateTimeBooleanValue) {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return (
            <DatePickerComponent
              showTime
              dateTimeFormat={format}
              disabled
              use12Hours
            />
          );
        }
        if (rangeDefaultValue) {
          if (allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)) {
            form?.setFieldsValue({
              dateTime: [
                moment()?.startOf('day'),
                moment()?.add(rangeDefaultValue, 'days')?.endOf('day')
              ]
            });
          } else {
            form?.setFieldsValue({
              dateTime: [
                moment()?.startOf('day'),
                moment()?.add(-rangeDefaultValue, 'days')?.endOf('day')
              ]
            });
          }
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePickerComponent
            showTime
            className="common-range-picker fill-width"
            popupClassName="common-range-picker"
            disabled
            dateTimeFormat={format}
          />
        );
      }
      if (dateTimeType?.includes('DATE')) {
        if (dateConfig?.exactDate) {
          if (dateDefaultValue === 'TODAY') {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else if (dateDefaultValue === 'TOMORROW') {
            form?.setFieldsValue({
              dateTime: moment()?.add(1, 'days')
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return <DatePickerComponent disabled dateFormat={format} />;
        }
        if (rangeDefaultValue) {
          if (isArray(allowDateTime)) {
            if (allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)) {
              form?.setFieldsValue({
                dateTime: [moment(), moment()?.add(rangeDefaultValue, 'days')]
              });
            } else {
              form?.setFieldsValue({
                dateTime: [moment(), moment()?.add(-rangeDefaultValue, 'days')]
              });
            }
          } else if (allowDateTime?.ALLOW_FUTURE) {
            form?.setFieldsValue({
              dateTime: [moment(), moment()?.add(rangeDefaultValue, 'days')]
            });
          } else {
            form?.setFieldsValue({
              dateTime: [moment(), moment()?.add(-rangeDefaultValue, 'days')]
            });
          }
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePickerComponent
            className="common-range-picker fill-width"
            popupClassName="common-range-picker"
            disabled
          />
        );
      }
      if (dateTimeType?.includes('TIME')) {
        if (dateConfig?.exactDate) {
          if (dateTimeBooleanValue) {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return (
            <TimePicker
              use12Hours
              className="common-time-picker fill-width custom-picker"
              disabled
              format={format}
            />
          );
        }
        if (timeDefaultValue) {
          form?.setFieldsValue({
            dateTime: [moment(timeDefaultValue[0]), moment(timeDefaultValue[1])]
          });
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePicker
            className="common-range-picker custom-picker"
            use12Hours
            format={format}
            disabled
          />
        );
      }
    } else {
      if (dateTimeType?.DATE && dateTimeType?.TIME) {
        if (dateConfig === 'EXACT') {
          if (dateTimeBooleanValue) {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return (
            <DatePickerComponent
              showTime
              dateTimeFormat={format}
              disabled
              use12Hours
            />
          );
        }
        if (rangeDefaultValue) {
          if (allowDateTime?.ALLOW_FUTURE) {
            form?.setFieldsValue({
              dateTime: [
                moment()?.startOf('day'),
                moment()?.add(rangeDefaultValue, 'days')?.endOf('day')
              ]
            });
          } else {
            form?.setFieldsValue({
              dateTime: [
                moment()?.startOf('day'),
                moment()?.add(-rangeDefaultValue, 'days')?.endOf('day')
              ]
            });
          }
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePickerComponent
            showTime
            className="common-range-picker fill-width"
            popupClassName="common-range-picker"
            disabled
            dateTimeFormat={format}
          />
        );
      }
      if (dateTimeType?.DATE) {
        if (dateConfig === 'EXACT') {
          if (dateDefaultValue === 'TODAY') {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else if (dateDefaultValue === 'TOMORROW') {
            form?.setFieldsValue({
              dateTime: moment()?.add(1, 'days')
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return <DatePickerComponent disabled dateFormat={format} />;
        }
        if (rangeDefaultValue) {
          if (allowDateTime?.ALLOW_FUTURE) {
            form?.setFieldsValue({
              dateTime: [moment(), moment()?.add(rangeDefaultValue, 'days')]
            });
          } else {
            form?.setFieldsValue({
              dateTime: [moment(), moment()?.add(-rangeDefaultValue, 'days')]
            });
          }
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePickerComponent
            className="common-range-picker fill-width"
            popupClassName="common-range-picker"
            disabled
          />
        );
      }
      if (dateTimeType?.TIME) {
        if (dateConfig === 'EXACT') {
          if (dateTimeBooleanValue) {
            form?.setFieldsValue({
              dateTime: moment()
            });
          } else {
            form?.setFieldsValue({
              dateTime: null
            });
          }
          return (
            <TimePicker
              use12Hours
              format={format}
              className="common-time-picker fill-width custom-picker"
              disabled
            />
          );
        }
        if (timeDefaultValue) {
          form?.setFieldsValue({
            dateTime: [moment(timeDefaultValue[0]), moment(timeDefaultValue[1])]
          });
        } else {
          form?.setFieldsValue({
            dateTime: null
          });
        }
        return (
          <RangePicker
            className="common-range-picker custom-picker"
            use12Hours
            format={format}
            disabled
          />
        );
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderPicker = useCallback(handleRendering, [
    dateConfig,
    dateDefaultValue,
    allowDateTime,
    dateTimeType,
    format,
    dateTimeBooleanValue
  ]);

  return (
    <div className="mt-37">
      <Form form={form} layout="vertical">
        <Form.Item
          name="dateTime"
          label={hideLabel ? ' ' : label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          {renderPicker()}
        </Form.Item>
      </Form>
      {descriptionCheck && descriptionValue && (
        <span className="thin-label">{descriptionValue}</span>
      )}
    </div>
  );
};

export default CustomDateTimePreview;
