import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductCategoryForm from '../components/ProductCategoryForm';
import { UPDATE_PRODUCT_CATEGORY } from '../graphql/Mutations';
import { GET_PRODUCT_CATEGORY } from '../graphql/Queries';

function ProductCategoryEdit(props) {
  const {
    match: { params: { id } = {} } = {},
    history = null,
    location = null
  } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const { data: { productCategory } = {} } = useQuery(GET_PRODUCT_CATEGORY, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.productCategory) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateProductCategoryMutate] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    delete newFormValues?.productCategoryCode;

    const variables = {
      data: newFormValues,
      where: { id: productCategory?.id }
    };

    try {
      const response = await updateProductCategoryMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateProductCategory) {
        setSubmitLoading(false);
        history?.push(ROUTES?.PRODUCT_CATEGORIES, { ...location?.state });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.PRODUCT_CATEGORIES} />;
  }

  const initialValues = {
    ...productCategory,

    description: productCategory?.description || '',
    isActive: productCategory?.isActive
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCT_CATEGORIES} />
          {productCategory && (
            <span className="portal-header">{productCategory?.title}</span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <ProductCategoryForm
          isEdit
          productCategoryData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default withRouter(ProductCategoryEdit);
