import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CustomerPropertyModal from '../components/CustomerPropertyModal';
import CustomerPropertyTable from '../components/CustomerPropertyTable';
import CustomerTabs from '../components/CustomerTabs';
import { GET_CUSTOMER } from '../graphql/Queries';

const CustomerProperty = ({ match: { params: { id } = {} } = {}, history }) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const { data: { customer } = {} } = useQuery(GET_CUSTOMER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <Card className="full-height-card card-body-padding">
      {showModal && (
        <CustomerPropertyModal
          showModal={showModal}
          setShowModal={setShowModal}
          productId={id}
        />
      )}
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CUSTOMERS} />
          {customer && (
            <span className="portal-header">
              {customer?.user?.firstName} {customer?.user?.lastName}
            </span>
          )}
        </div>
      </Portal>
      {!location?.pathname?.includes('/view') && (
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="property-table-add-btn"
            type="primary"
            onClick={handleShowModal}
          >
            Add Property
          </Button>
        </Portal>
      )}
      {location?.pathname?.includes('/view') && (
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            onClick={() =>
              history?.push(
                `${ROUTES?.CUSTOMERS}/edit${ROUTES?.PROPERTIES}/${id}`
              )
            }
          >
            Edit
          </Button>
        </Portal>
      )}
      <CustomerTabs>
        {loading ? (
          <LoaderComponent />
        ) : (
          pageSize && <CustomerPropertyTable showModal={showModal} />
        )}
      </CustomerTabs>
    </Card>
  );
};

export default withRouter(CustomerProperty);
