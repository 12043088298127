import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React from 'react';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import QuoteViewerLeadDetail from '../components/QuoteViewerLeadDetail';
import { GET_QUOTE } from '../graphql/Queries';

const QuoteViewerLeadView = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const { data: { quote } = {}, loading } = useQuery(GET_QUOTE, {
    variables: { where: { id } },
    onCompleted: () => {},
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="portal-header">Quotes</span>
        </div>
      </Portal>
      <QuoteViewerLeadDetail quote={quote} loading={loading} />
    </Card>
  );
};

export default QuoteViewerLeadView;
