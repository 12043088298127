import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  PERMISSION_QUESTIONNAIRE,
  QUESTIONNAIRE_TYPE,
  ROUTES
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './myQuestionnaireModule.less';
import DynamicRouteWrapper from './pages/DynamicRouteWrapper';
import QuestionnaireList from './pages/QuestionnaireList';
import SecondaryQuestionnaireList from './pages/SecondaryQuestionnaireList';

const MyQuestionnaireWrapper = () => {
  return (
    <div className="questionnaire-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.QUESTIONNAIRES_PRIMARY}
          render={(props) => (
            <AccessControl
              allowedPermissions={[...PERMISSION_QUESTIONNAIRE]}
              showNoAccess
            >
              <QuestionnaireList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES?.QUESTIONNAIRES_SECONDARY}
          render={(props) => (
            <AccessControl
              allowedPermissions={[...PERMISSION_QUESTIONNAIRE]}
              showNoAccess
            >
              <SecondaryQuestionnaireList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES_PRIMARY}/:id${ROUTES?.PAGE}`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <DynamicRouteWrapper
                {...props}
                type={QUESTIONNAIRE_TYPE?.ROQ_BUILDER}
              />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES_SECONDARY}/:id${ROUTES?.PAGE}`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <DynamicRouteWrapper
                {...props}
                type={QUESTIONNAIRE_TYPE?.ROQ_PATH}
              />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES?.QUESTIONNAIRES}
          render={() => <Redirect to={ROUTES?.QUESTIONNAIRES_PRIMARY} />}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default MyQuestionnaireWrapper;
