import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_API_TOKEN = gql`
  query getRestApiToken {
    getRestApiToken {
      data {
        token
        status
      }
    }
  }
`;
