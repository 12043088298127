import { gql } from '@apollo/client';

export const CREATE_QUESTIONNAIRES = gql`
  mutation createQuestionnaire($data: QuestionnaireInput!) {
    createQuestionnaire(data: $data) {
      message
      status
      data {
        id
        title
        pageKey
      }
    }
  }
`;

export const UPDATE_QUESTIONNAIRES = gql`
  mutation updateQuestionnaire(
    $data: QuestionnaireInput!
    $where: QuestionnaireWhereInput!
  ) {
    updateQuestionnaire(data: $data, where: $where) {
      message
      status
      data {
        id
        title
      }
    }
  }
`;

export const DELETE_QUESTIONNAIRES = gql`
  mutation deleteQuestionnaire($where: QuestionnaireWhereInput!) {
    deleteQuestionnaire(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CREATE_PAGE_CONFIG = gql`
  mutation createPageConfig($data: PageConfigInput!) {
    createPageConfig(data: $data) {
      message
      status
      data {
        pageKey
      }
    }
  }
`;

export const UPDATE_PAGE_CONFIG = gql`
  mutation updatePageConfig(
    $data: PageConfigInput!
    $where: PageConfigWhereInput!
  ) {
    updatePageConfig(data: $data, where: $where) {
      message
      status
      data {
        id
        pageKey
      }
    }
  }
`;

export const DELETE_PAGE_CONFIG = gql`
  mutation deletePageConfig($where: PageConfigWhereInput!) {
    deletePageConfig(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CHANGE_PAGE_ORDER = gql`
  mutation changePageOrder(
    $data: changePageOrderInput!
    $where: changePageOrderWhere!
  ) {
    changePageOrder(data: $data, where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const COPY_QUESTIONNAIRE = gql`
  mutation copyQuestionnaire($where: QuestionnaireWhereInput!) {
    copyQuestionnaire(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const REMOVE_ADD_ON_PRODUCT = gql`
  mutation RemoveAddOnProduct($where: RemoveAddOnProductInput!) {
    removeAddOnProduct(where: $where) {
      message
      status
    }
  }
`;
