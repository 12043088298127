import { gql } from '@apollo/client';

export const IMPORT_FILES = gql`
  query importFiles($limit: Int, $skip: Int, $query: String, $sortBy: SortBy) {
    importFiles(limit: $limit, skip: $skip, query: $query, sortBy: $sortBy) {
      count
      importFiles {
        id
        user {
          firstName
          lastName
        }
        importType
        importedById
        fileObj
        errorFileObj
        importFileStatus
        tenantId
        createdAt
        updatedAt
      }
    }
  }
`;

export const IMPORT_ITEMS = gql`
  query importItems(
    $limit: Int
    $skip: Int
    $where: ImportFileItemWhereInput!
    $sortBy: SortBy
  ) {
    importItems(limit: $limit, skip: $skip, where: $where, sortBy: $sortBy) {
      count
      importItems {
        id
        importType
        importedFileId
        importedData
        importStatus
        tenantId
        createdAt
        updatedAt
      }
    }
  }
`;

export const IMPORT_ITEM = gql`
  query importItem($where: ImportFileWhereInput!) {
    importItem(where: $where) {
      id
      importedFileId
      importedData
      importStatus
      tenantId
      importedFile {
        importType
        importFileType {
          webConfig
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const IMPORT_TYPES = gql`
  query importTypes($filter: ImportTypeFilter!) {
    importTypes(filter: $filter) {
      count
      data {
        id
        label
        key
        webConfig
        isActive
        sampleFile {
          url
          key
          name
          extension
          contentType
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
