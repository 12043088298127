import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MANAGE_PERMISSION = gql`
  mutation managePermission($data: PermissionInput!) {
    managePermission(data: $data) {
      message
      status
    }
  }
`;
