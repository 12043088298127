import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import MyAccountSettingForm from '../components/MyAccountSettingForm';
import { GET_PROFILE } from '../graphql/Queries';

const MyAccountSettingPage = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [fetchTenant] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);

      const tenantObj = {
        id: res?.getProfile?.id,
        roles: res?.getProfile?.roles,
        firstName: res?.getProfile?.firstName,
        lastName: res?.getProfile?.lastName,
        email: res?.getProfile?.email
      };
      setUserData(tenantObj);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchTenant({
      variables: {
        where: {
          id: currentUser?.tenantId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">My Account</span>
        </div>
      </Portal>

      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <MyAccountSettingForm initialValues={userData} />
      )}
    </Card>
  );
};

export default MyAccountSettingPage;
