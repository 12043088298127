import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_IMPORT_EXPORT_QUEUE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './importExportQueue.less';
import ImportExportQueueList from './pages/ImportExportQueueList';

const importExportQueueWrapper = () => {
  return (
    <div className="import-export-queue-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.IMPORT_EXPORT_QUEUE}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_IMPORT_EXPORT_QUEUE}
              showNoAccess
            >
              <ImportExportQueueList {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default importExportQueueWrapper;
