import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import VerifyTable from '../components/VerifyTable';

const VerifyImport = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);

  return (
    <Card className="full-height-card">{pageSize && <VerifyTable />}</Card>
  );
};

export default VerifyImport;
