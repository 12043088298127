import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import TenantSettingForm from '../components/TenantSettingForm';
import { GET_TENANT } from '../graphql/Queries';

const TenantSettingPage = () => {
  const [loading, setLoading] = useState(true);
  const [tenantData, setTenantData] = useState({});
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [fetchTenant] = useLazyQuery(GET_TENANT, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);

      const links = res?.tenant?.socialMediaLinks;

      const tenantObj = {
        ...res?.tenant,
        name: res?.tenant?.name,
        tagLine: res?.tenant?.tagLine,
        phoneNumber: res?.tenant?.phoneNo,
        helpPageChecked: res?.tenant?.helpPage?.checked,
        helpPage: res?.tenant?.helpPage?.key,
        liveChat: res?.tenant?.liveChat?.key,
        liveChatChecked: res?.tenant?.liveChat?.checked,
        zipCode: res?.tenant?.location?.zipCode,
        street: res?.tenant?.location?.addressLine1,
        city: res?.tenant?.location?.city,
        state: res?.tenant?.location?.state,
        timeFormat: res?.tenant?.timeFormat
      };

      links?.forEach((element) => {
        tenantObj[element?.title] = element?.url;
      });

      setTenantData(tenantObj);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchTenant({
      variables: {
        where: {
          id: currentUser?.tenantId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">Tenant</span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <TenantSettingForm initialValue={tenantData} />
      )}
    </Card>
  );
};

export default withRouter(TenantSettingPage);
