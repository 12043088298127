import { gql } from '@apollo/client';

export const CHANGE_PRODUCT_RANKING = gql`
  mutation changeProductRanking(
    $data: ProductRankingData!
    $where: RankingConfigInput!
  ) {
    changeProductRanking(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_SCORE = gql`
  mutation qrvUpdateScore(
    $data: QRVUpdateScoreInput!
    $where: ProductWhereInput!
  ) {
    qrvUpdateScore(data: $data, where: $where) {
      message
      status
    }
  }
`;
