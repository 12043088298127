import { UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useEffect } from 'react';
import {
  DIMENSION_LOB_COMMON,
  DIMENSION_LOB_STAIRS,
  DIMENSION_TABS
} from '../../../../../../common/constants';
import PanelHeader from '../../../previewComponents/globalComponents/PanelHeader';
import HighLightYourArea from '../components/HighLightYourArea';
import ManualEntry from '../components/ManualEntry';
import MapEntryResult from '../components/MapEntryResult';
import MeasurementTipsForManualEntry from '../components/MeasurementTipsForManualEntry';
import MeasurementTipsForMap from '../components/MeasurementTipsForMap';
import StepsForm from '../components/StepsForm';
import TotalRiseForm from '../components/TotalRiseForm';
import '../preview/dimensionWidget.less';

const { Panel } = Collapse;

const DimensionWidgetForm = ({
  form = null,
  setSelectedDimensionTab,
  selectedDimensionTab = 'common',
  formValues = null,
  setFormValues,
  setSelectedDimensionProject,
  selectedDimensionProject = null
}) => {
  useEffect(() => {
    if (DIMENSION_TABS?.common?.includes(selectedDimensionTab)) {
      setSelectedDimensionProject(DIMENSION_LOB_COMMON);
    }
    if (DIMENSION_TABS?.stairs?.includes(selectedDimensionTab)) {
      setSelectedDimensionProject(DIMENSION_LOB_STAIRS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensionTab]);

  const handleChangeDefault = (stairsOption = '') => {
    const stairsCheck = stairsOption === 'STEPS';
    form?.setFieldsValue({
      widgetConfiguration: {
        stairs: {
          steps: {
            defaultCheck: stairsCheck
          },
          totalRise: {
            defaultCheck: !stairsCheck
          }
        }
      }
    });
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        stairs: {
          ...formValues?.widgetConfiguration?.stairs,
          steps: {
            ...formValues?.widgetConfiguration?.stairs?.steps,
            defaultCheck: stairsCheck
          },
          totalRise: {
            ...formValues?.widgetConfiguration?.stairs?.totalRise,
            defaultCheck: !stairsCheck
          }
        }
      }
    });
  };

  const handleChangeDefaultForOtherTabs = (option = '') => {
    const mapEntryCheck = option === 'MAP_ENTRY';
    form?.setFieldsValue({
      widgetConfiguration: {
        common: {
          mapEntry: {
            default: mapEntryCheck
          },
          manualEntry: {
            default: !mapEntryCheck
          }
        }
      }
    });
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        common: {
          ...formValues?.widgetConfiguration?.common,
          mapEntry: {
            ...formValues?.widgetConfiguration?.common?.mapEntry,
            defaultCheck: mapEntryCheck
          },
          manualEntry: {
            ...formValues?.widgetConfiguration?.common?.manualEntry,
            defaultCheck: !mapEntryCheck
          }
        }
      }
    });
  };

  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">Dimensions</span>
      </div>

      <Collapse
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        ghost
        className="common-collapse questionnaire-collapse"
      >
        <Panel
          forceRender
          key="common"
          header={
            <PanelHeader
              title="Others"
              formSelectedKey={DIMENSION_LOB_COMMON}
              previewSelectedKey={selectedDimensionProject}
              setPreviewSelectedKey={setSelectedDimensionProject}
            />
          }
        >
          <Collapse
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse questionnaire-collapse"
            ghost
          >
            <Panel
              forceRender
              key="MAP_ENTRY"
              header={
                <PanelHeader
                  title="Map Entry"
                  formSelectedKey="MAP_ENTRY"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <MapEntryResult
                form={form}
                handleChangeDefaultForOtherTabs={
                  handleChangeDefaultForOtherTabs
                }
              />
            </Panel>
            <Panel
              forceRender
              key="HIGHLIGHT_YOUR_AREA"
              header={
                <PanelHeader
                  title="HighLight your area"
                  formSelectedKey="HIGHLIGHT_YOUR_AREA"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <HighLightYourArea form={form} />
            </Panel>
            <Panel
              forceRender
              key="MEASUREMENT_TIP_FOR_MAP_ENTRY"
              header={
                <PanelHeader
                  title="Measurement Tips - Map Entry"
                  formSelectedKey="MEASUREMENT_TIP_FOR_MAP_ENTRY"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <MeasurementTipsForMap form={form} />
            </Panel>
            <Panel
              forceRender
              key="MANUAL_ENTRY"
              header={
                <PanelHeader
                  title="Manual Entry"
                  formSelectedKey="MANUAL_ENTRY"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <ManualEntry
                form={form}
                handleChangeDefaultForOtherTabs={
                  handleChangeDefaultForOtherTabs
                }
              />
            </Panel>
            <Panel
              forceRender
              key="MEASUREMENT_TIP_FOR_MANUAL_ENTRY"
              header={
                <PanelHeader
                  title="Measurement Tips For Manual Entry"
                  formSelectedKey="MEASUREMENT_TIP_FOR_MANUAL_ENTRY"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <MeasurementTipsForManualEntry />
            </Panel>
          </Collapse>
        </Panel>

        <Panel
          forceRender
          key="stairs"
          header={
            <PanelHeader
              title="Stairs"
              formSelectedKey={DIMENSION_LOB_STAIRS}
              previewSelectedKey={selectedDimensionProject}
              setPreviewSelectedKey={setSelectedDimensionProject}
            />
          }
        >
          <Collapse
            className="common-collapse questionnaire-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            ghost
          >
            <Panel
              forceRender
              key="STEPS"
              header={
                <PanelHeader
                  title="Steps"
                  formSelectedKey="STEPS"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <StepsForm
                form={form}
                handleChangeDefault={handleChangeDefault}
              />
            </Panel>
            <Panel
              forceRender
              key="TOTAL_RISE"
              header={
                <PanelHeader
                  title="Total Rise"
                  formSelectedKey="TOTAL_RISE"
                  previewSelectedKey={selectedDimensionTab}
                  setPreviewSelectedKey={setSelectedDimensionTab}
                />
              }
            >
              <TotalRiseForm
                form={form}
                handleChangeDefault={handleChangeDefault}
              />
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default DimensionWidgetForm;
