import { Modal } from 'antd';
import React, { useEffect } from 'react';
import LoaderComponent from './LoaderComponent';
import './styles/ModalComponent.less';

const ModalComponent = (props) => {
  const {
    centered = true,
    maskClosable = false,
    loading,
    children,
    open = '',
    title = '',
    wrapClassName = '',
    ...rest
  } = props;

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line no-undef
      const modalContainer = document.querySelector('.ant-modal');
      modalContainer?.setAttribute(
        'aria-labelledby',
        `${title || wrapClassName}`
      );
      modalContainer?.setAttribute('aria-label', `${title || wrapClassName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      className="modal"
      centered={centered} // centered modal
      maskClosable={maskClosable} // whether to close the modal when clicked outside the modal
      open={open}
      title={title}
      wrapClassName={wrapClassName}
      {...rest}
    >
      {loading ? <LoaderComponent /> : <>{children}</>}
    </Modal>
  );
};

export default ModalComponent;

ModalComponent.confirm = Modal?.confirm;
