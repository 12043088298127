import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query roles($filter: RoleFilter!, $where: RolesWhereFilter) {
    roles(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_ROLE = gql`
  query role($id: ID!) {
    role(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
      tenantId
      referenceId
    }
  }
`;

export const ROLE_FILTER = gql`
  query roleFilters($filter: RoleFilter!) {
    roleFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;
