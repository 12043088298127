import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Form } from 'react-formio/lib/components';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import { UPDATE_FILE_ITEM } from '../graphql/Mutations';
import { IMPORT_ITEM } from '../graphql/Queries';

const EditForm = () => {
  const params = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});

  const [updateFileItem] = useMutation(UPDATE_FILE_ITEM, {
    onCompleted: () => {
      history?.push(`${ROUTES?.IMPORTS}/${params?.fileId}`);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const handleSubmit = (values) => {
    const submitData = {
      ...values.data
    };
    delete submitData.submit;
    updateFileItem({
      variables: {
        data: {
          importedData: submitData
        },
        where: {
          id: params?.itemId
        }
      }
    });
  };

  const { data } = useQuery(IMPORT_ITEM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: params?.itemId
      }
    },
    onCompleted: (res) => {
      if (res?.importItem) {
        const {
          importItem: {
            importedData = {},
            importedFile: {
              importFileType: { webConfig = {} }
            }
          }
        } = res;
        if (res?.importItem?.importedFile?.importFileType) {
          setFormData(webConfig);
        }
        if (res?.importItem?.importedData) {
          const defaultValues = {
            data: {
              ...importedData
            }
          };
          setInitialValues(defaultValues);
        }
      }
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="portal-header">
            Edit {data?.importItem?.importedFile?.importType}
          </span>
        </div>
      </Portal>
      <Form
        form={formData}
        submission={initialValues}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default EditForm;
