import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_RANKING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductRankingPage from './pages/ProductRankingPage';
import './productRanking.less';

const ProductRankingWrapper = () => {
  return (
    <div className="product-ranking-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_RANKING}
              showNoAccess
            >
              <ProductRankingPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ProductRankingWrapper;
