import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, PageHeader } from 'antd';
import { forEach } from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import { GET_PRODUCT_SUB_ITEMS } from '../../productItems/graphql/Queries';
import ProductItemComponent from '../components/ProductItemComponent';
import ProductItemTable from '../components/ProductItemTable';
import ProductTabs from '../components/ProductTabs';
import { GET_PRODUCT } from '../graphql/Queries';

const ProductItemList = (props) => {
  const {
    match: { params: { id } = {} } = {},
    history = null,
    location = null
  } = props;
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [productItemData, setProductItemData] = useState([]);

  const [fetchProductItemData, { loading: productItemLoading }] = useLazyQuery(
    GET_PRODUCT_SUB_ITEMS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const dataCopy = [];
        const selectedKey = [];
        forEach(res?.productSubItems?.data, (item) => {
          dataCopy?.push({
            ...item,
            editable: false,
            tempQuantity: item?.quantity
          });
          if (item?.isPrimary) {
            selectedKey?.push(item?.id);
          }
        });
        setProductItemData(dataCopy);
        setSelectedKeys(selectedKey);
        setCount(res?.productSubItems?.count);
      },
      onError() {}
    }
  );

  const { data: { product } = {} } = useQuery(GET_PRODUCT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <div className="product-item">
      <Card className="full-height-card card-body-padding">
        <Portal portalId="header-left-content">
          <div className="d-flex align-center">
            <GoBackButton customLink={ROUTES?.PACKAGES} />
            {product && <span className="portal-header">{product?.title}</span>}
          </div>
        </Portal>
        <AccessControl allowedPermissions={['FET_PRODUCT_UPDATE']}>
          {location?.pathname?.includes('/view') && (
            <Portal portalId="header-right-content">
              <Button
                className="common-button"
                icon={<img src={EditIcon} alt="save-icon" width={12} />}
                size="small"
                type="primary"
                onClick={() =>
                  history?.push(
                    `${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/${id}`,
                    {
                      ...location?.state
                    }
                  )
                }
              >
                Edit
              </Button>
            </Portal>
          )}
        </AccessControl>
        <ProductTabs>
          <div className="product-tab">
            {loading ? (
              <LoaderComponent />
            ) : (
              <ProductItemTable
                selectedKeys={selectedKeys}
                productItemData={productItemData}
                setProductItemData={setProductItemData}
                fetchProductItemData={fetchProductItemData}
                loading={productItemLoading}
              />
            )}
          </div>
          <div>
            <PageHeader
              title="Add Product Items"
              ghost={false}
              className="add-product-title"
            />
            <div className="add-product-table product-tab">
              <ProductItemComponent
                product={product}
                refetch={fetchProductItemData}
                count={count}
              />
            </div>
          </div>
        </ProductTabs>
      </Card>
    </div>
  );
};

export default withRouter(ProductItemList);
