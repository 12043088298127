import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  UpOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Collapse, Popconfirm, notification } from 'antd';
import { forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { checkPermissions, formatPrice } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import PriceComponent from '../../../components/PriceComponent';
import TableComponent from '../../../components/TableComponent';
import { UPDATE_SCORE } from '../graphql/Mutations';
import { QRV_RANKING_QUALITY_LISTING } from '../graphql/Queries';

const { Panel } = Collapse;

const ProductRankingCollapseListing = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [goodSectionLoading, setGoodSectionLoading] = useState(false);
  const [betterSectionLoading, setBetterSectionLoading] = useState(false);
  const [bestSectionLoading, setBestSectionLoading] = useState(false);

  const {
    state: { productRankingConfig, permissions, showNotificationWarning }
  } = useContext(AppContext);

  const [showPopConfirmCheckBtn, setShowPopConfirmCheckBtn] = useState(false);
  const [productRankingData, setProductRankingData] = useState(null);

  const [productQRVRankingQualityListing] = useLazyQuery(
    QRV_RANKING_QUALITY_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        let updatedRankingData;
        forEach(res?.qrvListing, (productQualityItem, key) => {
          const checkForArray = Array?.isArray(productQualityItem);
          if (checkForArray) {
            const filteredArray = productQualityItem?.map((item) => {
              return {
                ...item,
                editable: false,
                tempScore: item?.score
              };
            });
            updatedRankingData = {
              ...updatedRankingData,
              [key]: filteredArray
            };
          }
        });
        setProductRankingData({ ...updatedRankingData });
        setLoading(false);
      },
      onError: () => setLoading(false)
    }
  );

  const [updateScore] = useMutation(UPDATE_SCORE, {
    onCompleted: () => {
      productQRVRankingQualityListing({
        variables: {
          where: {
            subArea: productRankingConfig?.subArea?.id,
            region: productRankingConfig?.region?.id,
            brand: productRankingConfig?.brand?.id,
            lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
            industry: productRankingConfig?.industry?.id,
            customFilters: productRankingConfig?.customFilters,
            stock: productRankingConfig?.stock,
            labourWarranty: productRankingConfig?.labourWarranty,
            productItemWarranty: productRankingConfig?.productItemWarranty,
            sellingPrice: productRankingConfig?.sellingPrice,
            manufacturer: productRankingConfig?.manufacturer,
            unitOfMeasure: productRankingConfig?.unitOfMeasure,
            productCategory: productRankingConfig?.productCategory,
            manageInventory: productRankingConfig?.manageInventory,
            asset: productRankingConfig?.asset,
            allowPriceChange: productRankingConfig?.allowPriceChange,
            orderFilters: productRankingConfig?.orderFilters
          }
        }
      });
      setGoodSectionLoading(false);
      setBetterSectionLoading(false);
      setBestSectionLoading(false);
    },
    onError: () => {
      setGoodSectionLoading(false);
      setBetterSectionLoading(false);
      setBestSectionLoading(false);
    }
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_PRODUCT_RANKING_LIST'
    ]);
    if (
      productRankingConfig &&
      permitted &&
      !showNotificationWarning &&
      productRankingConfig?.industry?.id &&
      productRankingConfig?.brand?.id &&
      productRankingConfig?.lineOfBusiness?.id &&
      productRankingConfig?.subArea?.id &&
      productRankingConfig?.submit
    ) {
      setLoading(true);
      productQRVRankingQualityListing({
        variables: {
          where: {
            subArea: productRankingConfig?.subArea?.id,
            region: productRankingConfig?.region?.id,
            brand: productRankingConfig?.brand?.id,
            lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
            industry: productRankingConfig?.industry?.id,
            customFilters: productRankingConfig?.customFilters,
            orderFilters: productRankingConfig?.orderFilters,
            staticFilters: productRankingConfig?.staticFilters
          }
        }
      });
    } else {
      setLoading(false);
    }
    if (showNotificationWarning) {
      notification?.warn({
        className: 'top-notification',
        description:
          'Please create/select some Lines of Business, Service Types, Regions, Products, and Packages for a seamless experience.'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRankingConfig]);

  const handleEdit = (index, productQuality) => {
    let selectedProductQualityData;
    switch (productQuality) {
      case 'GOOD':
        selectedProductQualityData = productRankingData?.good;
        break;
      case 'BETTER':
        selectedProductQualityData = productRankingData?.better;
        break;
      case 'BEST':
        selectedProductQualityData = productRankingData?.best;
        break;
      default:
        break;
    }
    selectedProductQualityData[index].editable = true;

    setProductRankingData({
      ...productRankingData,
      ...selectedProductQualityData
    });
  };

  const handleChangeScoreValue = (value, index, productQuality) => {
    let selectedProductQualityData;
    switch (productQuality) {
      case 'GOOD':
        selectedProductQualityData = productRankingData?.good;
        break;
      case 'BETTER':
        selectedProductQualityData = productRankingData?.better;
        break;
      case 'BEST':
        selectedProductQualityData = productRankingData?.best;
        break;
      default:
        break;
    }
    selectedProductQualityData[index].tempScore = value;
    setShowPopConfirmCheckBtn(true);
    setProductRankingData({
      ...productRankingData,
      ...selectedProductQualityData
    });
  };

  const handleChangeScore = async (record) => {
    switch (record?.productQuality) {
      case 'GOOD':
        setGoodSectionLoading(true);
        break;
      case 'BETTER':
        setBetterSectionLoading(true);
        break;
      case 'BEST':
        setBestSectionLoading(true);

        break;
      default:
        break;
    }
    await updateScore({
      variables: {
        data: {
          score: record?.tempScore
        },
        where: {
          id: record?.id
        }
      }
    });
    setShowPopConfirmCheckBtn(false);
  };

  const handleCloseScore = (index, productQuality) => {
    setShowPopConfirmCheckBtn(false);
    let selectedProductQualityData;
    switch (productQuality) {
      case 'GOOD':
        selectedProductQualityData = productRankingData?.good;
        break;
      case 'BETTER':
        selectedProductQualityData = productRankingData?.better;
        break;
      case 'BEST':
        selectedProductQualityData = productRankingData?.best;
        break;
      default:
        break;
    }
    selectedProductQualityData[index].editable = false;
    selectedProductQualityData[index].tempScore =
      selectedProductQualityData?.[index]?.score;

    setProductRankingData({
      ...productRankingData,
      ...selectedProductQualityData
    });
  };

  const columns = [
    {
      title: 'RANK',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
      ellipsis: true,
      width: 100,
      className: 'max-width-column'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ellipsis: true,
      width: 150,
      className: 'max-width-column'
    },
    {
      title: 'PACKAGE',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      className: 'max-width-column'
    },
    {
      title: 'PACKAGE RANKING',
      dataIndex: 'score',
      key: 'score',
      ellipsis: true,
      width: 130,
      className: 'max-width-column',
      render: (score = 1, record, index) => {
        if (!record?.editable) {
          return (
            <div>
              <span className="quantity-text">{score}</span>
              {!location?.pathname?.includes('/view') && (
                <EditOutlined
                  className="edit-icon"
                  onClick={() => handleEdit(index, record?.productQuality)}
                />
              )}
            </div>
          );
        }

        return (
          <div>
            <PriceComponent
              className="quantity-component"
              value={record?.tempScore}
              prefix=""
              decimalValue={2}
              isAllowed={({ value }) => {
                if (Number(value) < 0 || Number(value) > 100) {
                  return false;
                }
                return true;
              }}
              allowNegative={false}
              onChange={(value) =>
                handleChangeScoreValue(value, index, record?.productQuality)
              }
            />
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempQuantity < 1}
                className="check-icon"
                onClick={() => handleChangeScore(record)}
              />
            ) : (
              <Popconfirm
                title="do you like to save these changes?"
                onConfirm={() => handleChangeScore(record)}
                onCancel={() => handleCloseScore(index, record?.productQuality)}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempQuantity < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseScore(index, record?.productQuality)}
            />
          </div>
        );
      }
    },
    {
      title: 'STOCK',
      dataIndex: 'stock',
      key: 'stock',
      ellipsis: true,
      align: 'center',
      width: 100,
      className: 'max-width-column',
      render: (stock) => stock || '-'
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      ellipsis: true,
      width: 150,
      className: 'max-width-column'
    },
    {
      title: 'WARRANTY',
      dataIndex: 'productItemWarranty',
      key: 'productItemWarranty',
      ellipsis: true,
      width: 150,
      className: 'max-width-column',
      render: (productItemWarranty) =>
        productItemWarranty ? `${productItemWarranty} years` : '-'
    },
    {
      title: 'COST PER DAY',
      dataIndex: 'costPerDay',
      fixed: 'right',
      key: 'costPerDay',
      ellipsis: true,
      align: 'right',
      width: 130,
      render: (costPerDay) => (costPerDay ? formatPrice(costPerDay) : '-')
    },
    {
      title: 'PACKAGE PRICE',
      dataIndex: 'sellingPrice',
      fixed: 'right',
      key: 'sellingPrice',
      ellipsis: true,
      align: 'right',
      width: 130,
      render: (sellingPrice) => (sellingPrice ? formatPrice(sellingPrice) : '-')
    }
  ];

  const panelHeader = (title = '') => {
    return <span className="pl-24 font-size-16">{title}</span>;
  };

  return (
    <div className="product-ranking-listing">
      <AccessControl
        allowedPermissions={['FET_PRODUCT_RANKING_LIST']}
        showNoAccess
      >
        <Collapse
          bordered={false}
          defaultActiveKey={['GOOD', 'BETTER', 'BEST']}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          className="common-collapse"
          ghost
        >
          <Panel forceRender header={panelHeader('Good')} key="GOOD">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading || goodSectionLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={productRankingData?.good || []}
              rowKey="id"
            />
          </Panel>
          <Panel forceRender header={panelHeader('Better')} key="BETTER">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading || betterSectionLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={productRankingData?.better || []}
              rowKey="id"
            />
          </Panel>
          <Panel forceRender header={panelHeader('Best')} key="BEST">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading || bestSectionLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={productRankingData?.best || []}
              rowKey="id"
            />
          </Panel>
        </Collapse>
      </AccessControl>
    </div>
  );
};

export default ProductRankingCollapseListing;
