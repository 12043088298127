import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_TENANT_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import TenantSettingPage from './pages/TenantSettingPage';
import './tenantSettingModule.less';

const TenantSettingWrapper = () => {
  return (
    <div className="tenant-setting-wrapper">
      <Switch>
        <Route
          exact
          path={ROUTES?.TENANT_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_TENANT_SETTING}
              showNoAccess
            >
              <TenantSettingPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default TenantSettingWrapper;
