import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_INDUSTRY, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import LineOfBusinessEdit from '../lineOfBusinesses/pages/LineOfBusinessEdit';
import LineOfBusinessView from '../lineOfBusinesses/pages/LineOfBusinessView';
import SubAreaEdit from '../lineOfBusinesses/pages/SubAreaEdit';
import SubAreaView from '../lineOfBusinesses/pages/SubAreaView';
import './industry.less';
import IndustryEdit from './pages/IndustryEdit';
import IndustryList from './pages/IndustryList';
import IndustryView from './pages/IndustryView';

const IndustryWrapper = () => {
  return (
    <div className="industry-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.INDUSTRIES}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_INDUSTRY}
              showNoAccess
            >
              <IndustryList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_UPDATE']}
              showNoAccess
            >
              <IndustryEdit {...props} />{' '}
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/view/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_VIEW']}
              showNoAccess
            >
              <IndustryView {...props} />{' '}
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/edit/:id/edit/:lobId/edit/:subId`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_UPDATE']}
              showNoAccess
            >
              <SubAreaEdit {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/view/:id/view/:lobId/view/:subId`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_VIEW']}
              showNoAccess
            >
              <SubAreaView {...props} />{' '}
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/edit/:id/edit/:lobId`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_UPDATE']}
              showNoAccess
            >
              <LineOfBusinessEdit {...props} />{' '}
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.INDUSTRIES}/view/:id/view/:lobId`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_INDUSTRY_VIEW']}
              showNoAccess
            >
              <LineOfBusinessView {...props} />{' '}
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default IndustryWrapper;
