import React from 'react';

const JobList = () => {
  return (
    <div>
      <h1>All job details</h1>
    </div>
  );
};

export default JobList;
