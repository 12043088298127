import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation updateProductCategory(
    $where: ProductCategoryWhereInput!
    $data: ProductCategoryInput!
  ) {
    updateProductCategory(where: $where, data: $data) {
      data {
        id
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
      status
      message
    }
  }
`;

export const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory($data: ProductCategoryInput!) {
    createProductCategory(data: $data) {
      data {
        id
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
      status
      message
    }
  }
`;

export const DELETE_PRODUCT_CATEGORY = gql`
  mutation deleteProductCategory($where: ProductCategoryWhereInput!) {
    deleteProductCategory(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
