import { Input } from 'antd';
import React from 'react';

const TextAreaComponent = (props) => {
  const { TextArea } = Input;
  const { name = '' } = props;

  return (
    <TextArea rows={2} allowClear placeholder={`Enter ${name}`} {...props} />
  );
};

export default TextAreaComponent;
