import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CampaignForm from '../components/CampaignForm';
import { UPDATE_CAMPAIGN } from '../graphql/Mutations';
import { GET_CAMPAIGN } from '../graphql/Queries';

function CampaignEdit(props) {
  const {
    match: { params: { id } = {} } = {},
    history = null,
    location = null
  } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const { data: { campaign } = {} } = useQuery(GET_CAMPAIGN, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.campaign) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateCampaignMutate] = useMutation(UPDATE_CAMPAIGN, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = {
      data: newFormValues,
      where: { id: campaign?.id }
    };

    try {
      const response = await updateCampaignMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateCampaign) {
        setSubmitLoading(false);
        history?.push(ROUTES.CAMPAIGNS, { ...location?.state });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES.CAMPAIGNS} />;
  }

  const initialValues = {
    ...campaign,
    status: campaign?.status
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CAMPAIGNS} />
          {campaign && <span className="portal-header">{campaign?.name}</span>}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <CampaignForm
          isEdit
          campaignData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default withRouter(CampaignEdit);
