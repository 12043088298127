import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './contactModule.less';
import ContactCreate from './pages/ContactCreate';
import ContactEdit from './pages/ContactEdit';
import ContactList from './pages/ContactList';
import ContactView from './pages/ContactView';
import CustomerList from './pages/Customer';
import PropertyList from './pages/Property';

const ContactWrapper = () => {
  // commented because permission not needed now
  // const { getCurrentUserRole } = useContext(AppContext);
  // const userRoles = getCurrentUserRole();
  // const { TENANT_ADMIN } = ROLE_KEYS;
  // const isTenantAdmin = userRoles?.includes(TENANT_ADMIN);

  // NEED TO CHANGE ROUTES FOR CUSTOMERS
  return (
    <div className="contact-module">
      <Switch>
        {/* {isTenantAdmin && ( */}
        <>
          <Route exact path={ROUTES?.CONTACTS} component={ContactList} />
          <Route path={`${ROUTES?.CONTACTS}/add`} component={ContactCreate} />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/edit/:id`}
            component={ContactEdit}
          />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/view/:id`}
            component={ContactView}
          />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/edit${ROUTES?.CUSTOMERS}/:id`}
            component={CustomerList}
          />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/view${ROUTES?.CUSTOMERS}/:id`}
            component={CustomerList}
          />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/edit${ROUTES?.PROPERTIES}/:id`}
            component={PropertyList}
          />
          <Route
            exact
            path={`${ROUTES?.CONTACTS}/view${ROUTES?.PROPERTIES}/:id`}
            component={PropertyList}
          />
        </>
        {/* )} */}
      </Switch>
    </div>
  );
};

export default ContactWrapper;
