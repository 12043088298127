import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
  query campaigns($filter: CampaignFilter!, $where: CampaignsWhereFilter) {
    campaigns(filter: $filter, where: $where) {
      count
      data {
        name
        type
        status
        code
        id
        industryId
        lineOfBusinessId
        subAreaId
        campaignIndustry {
          id
          label
        }
        campaignLob {
          id
          label
        }
        campaignSubArea {
          id
          label
        }
        subRegion
      }
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query campaign($id: ID!) {
    campaign(where: { id: $id }) {
      name
      type
      status
      code
      id
      industryId
      lineOfBusinessId
      subAreaId
      industry {
        id
        label
      }
      lineOfBusiness {
        id
        label
      }
      subArea {
        id
        label
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        key
      }
    }
  }
`;
