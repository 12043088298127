import { gql } from '@apollo/client';

export const UPDATE_PROPERTY = gql`
  mutation updateProperty($where: PropertyWhereInput!, $data: PropertyInput!) {
    updateProperty(where: $where, data: $data) {
      data {
        id
        name
        builtYear
        isActive
        propertyValue
        floor
        historyDistrict
        elevator
        internalSqFt
        lotSqFt
        cornerLot
        lotsFrontWidth
        lotsRightWidth
        lotsLeftWidth
        lotsBackWidth
        parking
        taxParcel
        pinNumber
        centralAir
        centralHeat
        heatSource
        waterHookUp
        noBaths
        noHalfBaths
        finishedBasement
        finishedBasementCeiling
        propertyLastConfirmed
        lockBoxCode
        propertyManagementCompany
        propertyManagerPhone
        thirdPartyProvider
        propertyType
        propertyFinishedBasement
        propertyFinishedCeiling
        tenantId
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY = gql`
  mutation createProperty($data: PropertyInput!) {
    createProperty(data: $data) {
      data {
        id
        name
        builtYear
        isActive
        propertyValue
        floor
        historyDistrict
        elevator
        internalSqFt
        lotSqFt
        cornerLot
        lotsFrontWidth
        lotsRightWidth
        lotsLeftWidth
        lotsBackWidth
        parking
        taxParcel
        pinNumber
        centralAir
        centralHeat
        heatSource
        waterHookUp
        noBaths
        noHalfBaths
        finishedBasement
        finishedBasementCeiling
        propertyLastConfirmed
        lockBoxCode
        propertyManagementCompany
        propertyManagerPhone
        thirdPartyProvider
        propertyType
        propertyFinishedBasement
        propertyFinishedCeiling
        tenantId
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty($where: PropertyWhereInput!) {
    deleteProperty(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CREATE_SALES_JUNCTION = gql`
  mutation createSalesJunction(
    $data: SalesJunctionInput!
    $where: PropertyWhereInput!
  ) {
    createSalesJunction(data: $data, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const REMOVE_CONTACT_REFERENCE = gql`
  mutation removeContactReference(
    $salesWhere: SalesWhereFetchInput!
    $where: ContactWhereInput!
  ) {
    removeContactReference(salesWhere: $salesWhere, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const REMOVE_CUSTOMER_REFERENCE = gql`
  mutation removeCustomerReference(
    $salesWhere: SalesWhereFetchInput!
    $where: CustomerWhereInput!
  ) {
    removeCustomerReference(salesWhere: $salesWhere, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const UPDATE_PRIMARY_AUTHORIZE_CONTACT = gql`
  mutation updatePrimaryAndAuthorizedContact(
    $where: PrimaryAndAuthorizedWhereInput!
  ) {
    updatePrimaryAndAuthorizedContact(where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;
