import React from 'react';

const BooleanUnchecked = ({ selected = false }) => {
  const color = selected ? 'white' : '#1890ff';

  return (
    <svg
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.2646 30.3789L33.1681 41.4754L31.5215 43.2062L33.1681 44.8528L45.9533 32.0676L44.2646 30.3789Z"
        fill={color}
      />
      <path
        d="M31.5 32.0889L42.5965 43.1854L44.3273 44.832L45.9739 43.1854L33.1887 30.4002L31.5 32.0889Z"
        fill={color}
      />
      <circle
        cx="38.5"
        cy="37.3789"
        r="23.25"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default BooleanUnchecked;
