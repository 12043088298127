import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Radio, Row, Switch } from 'antd';
import { some } from 'lodash';
import React, { useState } from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { uploadImageNormalize } from '../../../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const DragHandle = sortableHandle(() => <MenuOutlined className="drag-row" />);

const SortableItem = sortableElement(
  ({ value = null, onRadioChange, lastCheckedIndex = -1, remove }) => {
    const { key = null, name = null, ...restField } = value;

    return (
      <Row
        className="mt-10 dragging-class"
        align="middle"
        justify="space-between"
        key={key}
        gutter={4}
      >
        <Col span={1}>
          <DragHandle />
        </Col>
        <Col span={8}>
          <Form.Item
            {...restField}
            name={[name, 'image']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
          >
            <DraggerUploadComponent className="common-upload">
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>
            </DraggerUploadComponent>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            {...restField}
            name={[name, 'default']}
            className="mb-0"
            valuePropName="checked"
          >
            <Radio
              onChange={() => onRadioChange(name)}
              checked={name === lastCheckedIndex}
              className="common-radio"
            >
              Default
            </Radio>
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, 'label']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please Enter Label'
              }
            ]}
          >
            <InputComponent placeholder="Enter Label *" />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, 'blockDescription']}
            className="mt-10 mb-0"
            rules={[
              {
                max: 20,
                message: `Please Enter Less Than 20 Characters`
              }
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button
            danger
            onClick={(e) => {
              e?.stopPropagation();
              remove(name);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const CustomPickListForm = ({
  form = null,
  setFormValues,
  formValues = null,
  setDisableBtn
}) => {
  const [lastCheckedIndex, setLastCheckedIndex] = useState(-1);

  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form
  );

  const isReadonly = Form?.useWatch(
    ['widgetConfiguration', 'config', 'rules', 'readOnly'],
    form
  );

  const radioArray = Form.useWatch(
    ['widgetConfiguration', 'config', 'listItems'],
    form
  );

  const listItems = Form.useWatch(
    ['widgetConfiguration', 'config', 'listItems'],
    form
  );

  const onRadioChange = (index) => {
    setLastCheckedIndex(index);
    const newOptionList = radioArray?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          default: true
        };
      }
      return {
        ...item,
        default: false
      };
    });

    form?.setFieldValue(
      ['widgetConfiguration', 'config', 'listItems'],
      newOptionList
    );
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        config: {
          ...formValues?.widgetConfiguration?.config,
          listItems: newOptionList
        }
      }
    });
  };

  const checkListItems = (rule, value) => {
    if (!value || value?.length === 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject(new Error('At least one option is required'));
    }
    const hasDefaultSelect = some(value, (item) => item?.default);

    if (isReadonly && !hasDefaultSelect) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject(
        new Error(
          'One option should be default select as it is read only field'
        )
      );
    }

    return Promise?.resolve();
  };

  const onSortEnd = ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex && listItems) {
      const optionsCopy = [...listItems];

      optionsCopy?.splice(newIndex, 0, optionsCopy?.splice(oldIndex, 1)?.[0]);

      form?.setFieldValue(
        ['widgetConfiguration', 'config', 'listItems'],
        optionsCopy
      );
      setFormValues({
        ...formValues,
        widgetConfiguration: {
          ...formValues?.widgetConfiguration,
          config: {
            ...formValues?.widgetConfiguration?.config,
            listItems: [...optionsCopy]
          }
        }
      });
      setDisableBtn(false);
    }
  };

  return (
    <>
      <div>
        <Form.Item
          name={['widgetConfiguration', 'config', 'label']}
          label="Label"
          rules={[
            {
              required: true,
              message: 'Please Enter Label'
            }
          ]}
        >
          <InputComponent name="label" />
        </Form.Item>
        <div className="d-flex justify-between align-center">
          <span className="switch-logo">Tooltip</span>
          <Form.Item
            name={['widgetConfiguration', 'config', 'tooltip']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltipValue']}
          hidden={!showTooltip}
          rules={[
            {
              required: showTooltip,
              message: 'Please Enter Tooltip Value'
            }
          ]}
        >
          <InputComponent placeholder="Enter Tooltip Value" />
        </Form.Item>
        <div className="d-flex justify-between align-center">
          <span className="switch-logo font-500">Description</span>
          <Form.Item
            name={['widgetConfiguration', 'config', 'optionsDescription']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
      </div>
      <Form.List
        name={['widgetConfiguration', 'config', 'listItems']}
        rules={[{ validator: checkListItems }]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <SortableContainer
              useDragHandle
              onSortEnd={onSortEnd}
              helperClass="pick-list-draggable-option"
            >
              {fields?.map((field, index) => {
                const { key = null } = field;
                return (
                  <SortableItem
                    key={key}
                    value={field}
                    index={index}
                    lastCheckedIndex={lastCheckedIndex}
                    remove={remove}
                    onRadioChange={onRadioChange}
                  />
                );
              })}
            </SortableContainer>
            <Button
              type="primary"
              className="fill-width mt-10"
              onClick={() => add({ default: false })}
            >
              Add Option
            </Button>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </>
  );
};

export default CustomPickListForm;
