import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ROUTES } from './common/constants';
import { fetchStep } from './common/utils';
import keysDown from './components/HotKeys/HotKeys';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ChangePassword from './modules/auth/ChangePassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ResetPassword from './modules/auth/ResetPassword';
import VerifyEmail from './modules/auth/VerifyEmail';
import SignupWrapper from './modules/signup';

const Routes = () => {
  const {
    initializeAuth,
    defaultPageSize,
    getToken,
    getCurrentUser,
    dispatch,
    state: { loading }
  } = useContext(AppContext);

  const idToken = getToken();

  useEffect(() => {
    defaultPageSize();
    if (idToken) {
      fetchStep({
        dispatch,
        setLoading: true,
        changeRoute: true,
        getCurrentUser,
        initializeAuth,
        getToken,
        queryToken:
          // eslint-disable-next-line no-undef
          window?.location?.search?.split('?token=')?.[1]
      });
    } else {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    }

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', keysDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', keysDown);
    };
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWidth = () => {
    // eslint-disable-next-line no-undef
    dispatch({ type: 'SET_WIDTH', data: window?.innerWidth });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateWidth);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateWidth);
  });

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  Spin?.setDefaultIndicator(<LoadingOutlined className="font-size-32" spin />);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <PublicRoute path={ROUTES?.SIGNUP} component={SignupWrapper} />
          <PublicRoute exact path={ROUTES?.RESET} component={ResetPassword} />
          <PublicRoute exact path={ROUTES?.LOGIN} component={Login} />
          <PublicRoute exact path={ROUTES?.VERIFY} component={ChangePassword} />
          <Route exact path={ROUTES?.VERIFY_EMAIL} component={VerifyEmail} />
          <PrivateRoute exact path={ROUTES?.LOGOUT} component={Logout} />
          <PrivateRoute path={ROUTES?.MAIN} component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
