import { gql } from '@apollo/client';

export const FETCH_PLAN = gql`
  query subscriptionPlans($filter: SubscriptionPlansFilter!) {
    subscriptionPlans(filter: $filter) {
      count
      data {
        id
        name
        description
        features
        price
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;

export const CHECK_SUBDOMAIN = gql`
  query checkSubDomain($subDomain: String!) {
    checkSubDomain(subDomain: $subDomain)
  }
`;
