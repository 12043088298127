import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import PublicRoute from '../../PublicRoute';
import { ROUTES } from '../../common/constants';
import Administrator from './component/Administrator';
import BusinessInfo from './component/BusinessInfo';
import Plan from './component/Plan';
import Success from './component/Success';
import './signup.less';

const SignupWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.SIGNUP}
        render={() => <Redirect to={`${ROUTES?.SIGNUP}/administrator`} />}
      />
      <PublicRoute
        path={`${ROUTES?.SIGNUP}/administrator`}
        component={Administrator}
      />
      <PrivateRoute
        fromSignup
        path={`${ROUTES?.SIGNUP}/business-info`}
        component={BusinessInfo}
      />
      <PrivateRoute
        fromSignup
        path={`${ROUTES?.SIGNUP}/plan`}
        component={Plan}
      />
      <PrivateRoute
        fromSignup
        path={`${ROUTES?.SIGNUP}/success`}
        component={Success}
      />
    </Switch>
  );
};

export default SignupWrapper;
