import { Avatar } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { formatDate } from '../common/utils';

const AvatarComponent = (props) => {
  const {
    small,
    large,
    imageUrl = '',
    firstName = '',
    lastName = '',
    date = '',
    ...rest
  } = props;

  const {
    state: { globalDateFormat }
  } = useContext(AppContext);

  let size;
  let className;

  if (small) {
    size = 'small';
    className = 'width-22';
  } else if (large) {
    size = 'large';
    className = 'width-40';
  } else {
    size = 'default';
    className = 'width-32';
  }

  return (
    <div className="d-flex align-center gx-text-capitalize">
      <Avatar size={size} src={imageUrl} className={className} {...rest}>
        {lastName
          ? `${firstName.charAt(0)} ${lastName.charAt(0)}`
          : firstName && firstName.charAt(0)}
      </Avatar>
      <div className={date ? 'd-flex flex-vertical' : ''}>
        <span
          title={`${capitalize(firstName)} ${capitalize(lastName)}`}
          className="line-clamp"
        >
          {firstName} {lastName}
        </span>
        {date && (
          <span
            title={formatDate(date, globalDateFormat)}
            className="date-time line-clamp"
          >
            {formatDate(date, globalDateFormat)}
          </span>
        )}
      </div>
    </div>
  );
};

export default AvatarComponent;
