import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = ({
  component: Component,
  fromSignup = false,
  ...rest
}) => {
  const { getToken, isSignupAuthenticate } = useContext(AppContext);
  const idToken = getToken();
  const signupAuthenticate = isSignupAuthenticate();
  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken || (!signupAuthenticate && fromSignup) ? (
          <Redirect to={fromSignup ? ROUTES?.SIGNUP : ROUTES?.LOGIN} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
export default PrivateRoute;
