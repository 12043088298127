import { UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Checkbox,
  Collapse,
  Divider,
  Empty,
  Radio,
  Space,
  TimePicker
} from 'antd';
import { debounce, find, get, isArray, map, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { DASHBOARD, QUESTIONNAIRE_TYPE } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import {
  GET_BROWSERS,
  GET_DEVICES,
  GET_INDUSTRIES,
  GET_LINE_OF_BUSINESSES,
  GET_PRODUCT_RANKING_REGIONS,
  GET_QUESTIONNAIRES,
  GET_SUB_AREAS
} from '../graphql/Queries';

const { Panel } = Collapse;

const { RangePicker } = TimePicker;

const LIMIT = 50;

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
let regionScrollDebounceJob;
let questionScrollDebounceJob;
let deviceScrollDebounceJob;
let browserScrollDebounceJob;

const industryFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC'
};

const lineOfBusinessFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true
};

const subAreaFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true
};

const regionFilter = {
  skip: 0,
  limit: LIMIT
};

const questionFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'title',
  sortBy: 'ASC'
};

const deviceFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'order',
  sortBy: 'ASC'
};

const browserFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'order',
  sortBy: 'ASC'
};

const updateData = (data = null) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(DASHBOARD, JSON?.stringify(data));
};

const getData = (key = '') => {
  // eslint-disable-next-line no-undef
  const data = JSON?.parse(localStorage?.getItem(DASHBOARD)) || null;
  if (key?.length > 0) {
    return get(data, key);
  }
  return data;
};

const ExtraLoaderComponent = ({ loading = false }) => {
  return <LoaderComponent spinning={loading} size="small" setHeight={2} />;
};

const DashboardPanelComponent = () => {
  const {
    dispatch,
    state: { dashboardConfig }
  } = useContext(AppContext);

  const [industriesLoading, setIndustriesLoading] = useState(true);
  const [industriesData, setIndustriesData] = useState([]);
  const [industriesIsEnd, setIndustriesIsEnd] = useState(false);
  const [industriesInitialCall, setIndustriesInitialCall] = useState(true);
  const [industriesDebounceCall, setIndustriesDebounceCall] = useState(0);

  const [lineOfBusinessesLoading, setLineOfBusinessesLoading] = useState(false);
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessesIsEnd, setLineOfBusinessesIsEnd] = useState(false);
  const [
    lineOfBusinessesInitialCall,
    setLineOfBusinessesInitialCall
  ] = useState(true);
  const [
    lineOfBusinessesDebounceCall,
    setLineOfBusinessesDebounceCall
  ] = useState(0);

  const [subAreasLoading, setSubAreasLoading] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [subAreasIsEnd, setSubAreasIsEnd] = useState(false);
  const [subAreasInitialCall, setSubAreasInitialCall] = useState(true);
  const [subAreasDebounceCall, setSubAreasDebounceCall] = useState(0);

  const [regionsLoading, setRegionsLoading] = useState(true);
  const [regionsData, setRegionsData] = useState([]);
  const [regionsIsEnd, setRegionsIsEnd] = useState(false);
  const [regionsInitialCall, setRegionsInitialCall] = useState(true);
  const [regionsDebounceCall, setRegionsDebounceCall] = useState(0);

  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionsIsEnd, setQuestionsIsEnd] = useState(false);
  const [questionsInitialCall, setQuestionsInitialCall] = useState(true);
  const [questionsDebounceCall, setQuestionsDebounceCall] = useState(0);

  const [devicesLoading, setDevicesLoading] = useState(true);
  const [devicesData, setDevicesData] = useState([]);
  const [devicesIsEnd, setDevicesIsEnd] = useState(false);
  const [devicesInitialCall, setDevicesInitialCall] = useState(true);
  const [devicesDebounceCall, setDevicesDebounceCall] = useState(0);

  const [browsersLoading, setBrowsersLoading] = useState(true);
  const [browsersData, setBrowsersData] = useState([]);
  const [browsersIsEnd, setBrowsersIsEnd] = useState(false);
  const [browsersInitialCall, setBrowsersInitialCall] = useState(true);
  const [browsersDebounceCall, setBrowsersDebounceCall] = useState(0);

  const [timeOfDayData] = useState([
    {
      id: '1',
      title: 'Working hours only'
    },
    {
      id: '2',
      title: 'Before cut-off time'
    },
    {
      id: '3',
      title: 'After cut-off time'
    },
    {
      id: '4',
      title: 'All'
    }
  ]);

  const handleChangeFilter = (value, objKey, objData = []) => {
    let configCopy;
    switch (objKey) {
      case 'industry':
        configCopy = {
          ...dashboardConfig,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label
          }
        };
        delete configCopy?.lineOfBusiness;
        delete configCopy?.subArea;
        setLineOfBusinessesInitialCall(true);
        setSubAreasInitialCall(true);
        break;
      case 'lineOfBusiness':
        configCopy = {
          ...dashboardConfig,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
            key: find(objData, (item) => item?.id === value)?.key
          }
        };
        delete configCopy?.subArea;
        setSubAreasInitialCall(true);
        break;
      case 'subArea':
        configCopy = {
          ...dashboardConfig,
          [objKey]: map(value, (item) => {
            return {
              id: item,
              label: find(objData, (data) => data?.id === item)?.label
            };
          })
        };
        break;
      case 'region':
        configCopy = {
          ...dashboardConfig,
          [objKey]: map(value, (item) => {
            return {
              id: item,
              name: find(regionsData, (data) => data?.id === item)?.parentName
                ? `${
                    find(regionsData, (data) => data?.id === item)?.parentName
                  } / ${find(regionsData, (data) => data?.id === item)?.name}`
                : find(regionsData, (data) => data?.id === item)?.name,
              parentName: find(regionsData, (data) => data?.id === item)
                ?.parentName
            };
          })
        };
        break;
      case 'question':
        configCopy = {
          ...dashboardConfig,
          [objKey]: map(value, (item) => {
            return {
              id: item,
              title: find(questionsData, (data) => data?.id === item)?.title
            };
          })
        };
        break;
      case 'timeOfDay':
        configCopy = {
          ...dashboardConfig,
          [objKey]: {
            id: value,
            title: find(timeOfDayData, (data) => data?.id === value)?.title,
            date: null
          }
        };
        break;
      case 'device':
        configCopy = {
          ...dashboardConfig,
          [objKey]: map(value, (item) => {
            return {
              key: item,
              label: find(devicesData, (data) => data?.key === item)?.label
            };
          })
        };
        break;
      case 'browser':
        configCopy = {
          ...dashboardConfig,
          [objKey]: map(value, (item) => {
            return {
              key: item,
              label: find(browsersData, (data) => data?.key === item)?.label
            };
          })
        };
        break;

      default:
        break;
    }
    dispatch({
      type: 'SET_DASHBOARD_CONFIG',
      data: configCopy
    });
    updateData(configCopy);
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustriesIsEnd(res?.industries?.data?.length < LIMIT);
      if (industriesInitialCall) {
        const industryData = getData('industry');
        if (industryData) {
          setIndustriesData(
            uniqBy([industryData, ...res?.industries?.data], 'id')
          );
        } else {
          const uniqueData = uniqBy(res?.industries?.data, 'id');
          handleChangeFilter(uniqueData?.[0]?.id, 'industry', uniqueData);
          setIndustriesData(uniqueData);
        }
        setIndustriesInitialCall(false);
      } else {
        setIndustriesData(
          uniqBy([...industriesData, ...res?.industries?.data], 'id')
        );
      }
      setIndustriesLoading(false);
    },
    onError() {
      setIndustriesLoading(false);
    }
  });

  const [lineOfBusinesses] = useLazyQuery(GET_LINE_OF_BUSINESSES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessesIsEnd(res?.lineOfBusinesses?.data?.length < LIMIT);
      if (lineOfBusinessesInitialCall) {
        const lineOfBusinessData = getData('lineOfBusiness');
        if (lineOfBusinessData) {
          setLineOfBusinessesData(
            uniqBy([lineOfBusinessData, ...res?.lineOfBusinesses?.data], 'id')
          );
        } else {
          const uniqueData = uniqBy(res?.lineOfBusinesses?.data, 'id');
          handleChangeFilter(uniqueData?.[0]?.id, 'lineOfBusiness', uniqueData);
          setLineOfBusinessesData(uniqueData);
        }
        setLineOfBusinessesInitialCall(false);
      } else {
        setLineOfBusinessesData(
          uniqBy(
            [...lineOfBusinessesData, ...res?.lineOfBusinesses?.data],
            'id'
          )
        );
      }
      setLineOfBusinessesLoading(false);
    },
    onError() {
      setLineOfBusinessesLoading(false);
    }
  });

  const [subAreas] = useLazyQuery(GET_SUB_AREAS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreasIsEnd(res?.subAreas?.data?.length < LIMIT);
      if (subAreasInitialCall) {
        const subAreaData = getData('subArea');
        if (subAreaData) {
          setSubAreasData(
            uniqBy([...subAreaData, ...res?.subAreas?.data], 'id')
          );
        } else {
          setSubAreasData(uniqBy(res?.subAreas?.data, 'id'));
        }
        setSubAreasInitialCall(false);
      } else {
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id')
        );
      }
      setSubAreasLoading(false);
    },
    onError() {
      setSubAreasLoading(false);
    }
  });

  const [regionsProductRankingConfigData] = useLazyQuery(
    GET_PRODUCT_RANKING_REGIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setRegionsIsEnd(
          res?.regionsProductRankingConfigData?.data?.length < LIMIT
        );
        if (regionsInitialCall) {
          const regionData = getData('region');
          if (regionData) {
            setRegionsData(
              uniqBy(
                [...regionData, ...res?.regionsProductRankingConfigData?.data],
                'id'
              )
            );
          } else {
            setRegionsData(
              uniqBy(res?.regionsProductRankingConfigData?.data, 'id')
            );
          }
          setRegionsInitialCall(false);
        } else {
          setRegionsData(
            uniqBy(
              [...regionsData, ...res?.regionsProductRankingConfigData?.data],
              'id'
            )
          );
        }
        setRegionsLoading(false);
      },
      onError() {
        setRegionsLoading(false);
      }
    }
  );

  const [questionnaires] = useLazyQuery(GET_QUESTIONNAIRES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setQuestionsIsEnd(res?.questionnaires?.data?.length < LIMIT);
      if (questionsInitialCall) {
        const questionData = getData('question');
        if (questionData) {
          setQuestionsData(
            uniqBy([...questionData, ...res?.questionnaires?.data], 'id')
          );
        } else {
          setQuestionsData(uniqBy(res?.questionnaires?.data, 'id'));
        }
        setQuestionsInitialCall(false);
      } else {
        setQuestionsData(
          uniqBy([...questionsData, ...res?.questionnaires?.data], 'id')
        );
      }
      setQuestionsLoading(false);
    },
    onError() {
      setQuestionsLoading(false);
    }
  });

  const [devices] = useLazyQuery(GET_DEVICES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setDevicesIsEnd(res?.devices?.data?.length < LIMIT);
      if (devicesInitialCall) {
        const deviceData = getData('device');
        if (deviceData) {
          setDevicesData(uniqBy([...deviceData, ...res?.devices?.data], 'key'));
        } else {
          setDevicesData(uniqBy(res?.devices?.data, 'key'));
        }
        setDevicesInitialCall(false);
      } else {
        setDevicesData(uniqBy([...devicesData, ...res?.devices?.data], 'key'));
      }
      setDevicesLoading(false);
    },
    onError() {
      setDevicesLoading(false);
    }
  });

  const [browsers] = useLazyQuery(GET_BROWSERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setBrowsersIsEnd(res?.browsers?.data?.length < LIMIT);
      if (browsersInitialCall) {
        const browserData = getData('browser');
        if (browserData) {
          setBrowsersData(
            uniqBy([...browserData, ...res?.browsers?.data], 'key')
          );
        } else {
          setBrowsersData(uniqBy(res?.browsers?.data, 'key'));
        }
        setBrowsersInitialCall(false);
      } else {
        setBrowsersData(
          uniqBy([...browsersData, ...res?.browsers?.data], 'key')
        );
      }
      setBrowsersLoading(false);
    },
    onError() {
      setBrowsersLoading(false);
    }
  });

  useEffect(() => {
    regionsProductRankingConfigData({
      variables: {
        filter: regionFilter
      }
    });
    questionnaires({
      variables: {
        filter: questionFilter,
        where: { isActive: true, type: [QUESTIONNAIRE_TYPE?.ROQ_BUILDER] }
      }
    });
    devices({
      variables: {
        filter: deviceFilter,
        where: { isActive: true }
      }
    });
    browsers({
      variables: {
        filter: browserFilter,
        where: { isActive: true }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardConfig?.timeOfDay) {
      industries({
        variables: {
          filter: industryFilter,
          where: { isActive: true }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardConfig?.timeOfDay]);

  useEffect(() => {
    if (dashboardConfig?.industry?.id) {
      setLineOfBusinessesLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            limit: LIMIT,
            industryId: dashboardConfig?.industry?.id
          },
          where: { isActive: true }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardConfig?.industry?.id]);

  useEffect(() => {
    if (dashboardConfig?.lineOfBusiness?.id) {
      setSubAreasLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            limit: LIMIT,
            industryId: dashboardConfig?.industry?.id,
            lobId: dashboardConfig?.lineOfBusiness?.id
          },
          where: { isActive: true }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardConfig?.lineOfBusiness?.id]);

  const onIndustryScroll = (event) => {
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industriesIsEnd) {
        setIndustriesLoading(true);
        setIndustriesDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industriesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const onLineOfBusinessScroll = (event) => {
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessesIsEnd) {
        setLineOfBusinessesLoading(true);
        setLineOfBusinessesDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              limit: LIMIT,
              skip: (lineOfBusinessesDebounceCall + 1) * LIMIT,
              industryId: dashboardConfig?.industry?.id
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);
  };

  const onSubAreaScroll = (event) => {
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreasIsEnd) {
        setSubAreasLoading(true);
        setSubAreasDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              limit: LIMIT,
              skip: (subAreasDebounceCall + 1) * LIMIT,
              industryId: dashboardConfig?.industry?.id,
              lobId: dashboardConfig?.lineOfBusiness?.id
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);
  };

  const onRegionScroll = (event) => {
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionsIsEnd) {
        setRegionsLoading(true);
        setRegionsDebounceCall((prevState) => prevState + 1);
        regionsProductRankingConfigData({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionsDebounceCall + 1) * LIMIT
            }
          }
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const onQuestionScroll = (event) => {
    if (questionScrollDebounceJob) {
      questionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    questionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !questionsIsEnd) {
        setQuestionsLoading(true);
        setQuestionsDebounceCall((prevState) => prevState + 1);
        questionnaires({
          variables: {
            filter: {
              ...questionFilter,
              skip: (questionsDebounceCall + 1) * LIMIT
            },
            where: { isActive: true, type: [QUESTIONNAIRE_TYPE?.ROQ_BUILDER] }
          }
        });
      }
    }, 500);

    questionScrollDebounceJob();
  };

  const onDeviceScroll = (event) => {
    if (deviceScrollDebounceJob) {
      deviceScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    deviceScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !devicesIsEnd) {
        setDevicesLoading(true);
        setDevicesDebounceCall((prevState) => prevState + 1);
        devices({
          variables: {
            filter: {
              ...deviceFilter,
              skip: (devicesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    deviceScrollDebounceJob();
  };

  const onBrowserScroll = (event) => {
    if (browserScrollDebounceJob) {
      browserScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    browserScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !browsersIsEnd) {
        setBrowsersLoading(true);
        setBrowsersDebounceCall((prevState) => prevState + 1);
        browsers({
          variables: {
            filter: {
              ...browserFilter,
              skip: (browsersDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    browserScrollDebounceJob();
  };

  const handleChangeTimeOfDay = (value) => {
    if (value?.length > 0) {
      const newConfig = {
        ...dashboardConfig,
        timeOfDay: {
          ...dashboardConfig?.timeOfDay,
          title: `${moment(value?.[0])?.format('HH:mm')} - ${moment(
            value[1]
          )?.format('HH:mm')}`,
          time: [
            moment(value?.[0])?.format('HH:mm'),
            moment(value?.[1])?.format('HH:mm')
          ]
        }
      };
      dispatch({
        type: 'SET_DASHBOARD_CONFIG',
        data: newConfig
      });
      updateData(newConfig);
    } else {
      const newConfig = {
        timeOfDay: {
          ...dashboardConfig?.timeOfDay,
          title: find(
            timeOfDayData,
            (data) => data?.id === dashboardConfig?.timeOfDay?.id
          )?.title,
          time: null
        }
      };
      dispatch({
        type: 'SET_DASHBOARD_CONFIG',
        data: newConfig
      });
      updateData(newConfig);
    }
  };

  const panelHeader = (title = '', showData) => {
    return (
      <div className="d-flex flex-vertical">
        <span>{title}</span>
        <span className="panel-description">
          {isArray(showData)
            ? map(
                showData,
                (item) => item?.label || item?.name || item?.title
              )?.join(', ')
            : showData?.label || showData?.name || showData?.title}
        </span>
      </div>
    );
  };

  return (
    <div className="dashboard-panel-component">
      <Collapse
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        className="common-collapse"
      >
        <Panel
          forceRender
          header={panelHeader('Industry', dashboardConfig?.industry)}
          key="INDUSTRY"
          collapsible={industriesLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={industriesLoading} />}
        >
          {industriesData?.length > 0 ? (
            <div className="panel-section" onScroll={onIndustryScroll}>
              <LoaderComponent spinning={industriesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(
                      event?.target?.value,
                      'industry',
                      industriesData
                    )
                  }
                  value={dashboardConfig?.industry?.id}
                >
                  <Space direction="vertical">
                    {map(industriesData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
        <Panel
          forceRender
          header={panelHeader(
            'Line of Business',
            dashboardConfig?.lineOfBusiness
          )}
          key="LOB"
          collapsible={lineOfBusinessesLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={lineOfBusinessesLoading} />}
        >
          {lineOfBusinessesData?.length > 0 ? (
            <div className="panel-section" onScroll={onLineOfBusinessScroll}>
              <LoaderComponent spinning={lineOfBusinessesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(
                      event?.target?.value,
                      'lineOfBusiness',
                      lineOfBusinessesData
                    )
                  }
                  value={dashboardConfig?.lineOfBusiness?.id}
                >
                  <Space direction="vertical">
                    {map(lineOfBusinessesData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              description="Select Industry"
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
        <Panel
          forceRender
          header={panelHeader('Service Type', dashboardConfig?.subArea)}
          key="SUBAREA"
          collapsible={subAreasLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={subAreasLoading} />}
        >
          {subAreasData?.length > 0 ? (
            <div className="panel-section" onScroll={onSubAreaScroll}>
              <LoaderComponent spinning={subAreasLoading}>
                <Checkbox.Group
                  onChange={(event) =>
                    handleChangeFilter(event, 'subArea', subAreasData)
                  }
                  value={map(dashboardConfig?.subArea, (item) => item?.id)}
                >
                  <Space direction="vertical">
                    {map(subAreasData, (item) => {
                      return (
                        <Checkbox
                          className="common-checkbox"
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.label}
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              description="Select Line Of Business"
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
        <Panel
          header={panelHeader('Region', dashboardConfig?.region)}
          forceRender
          key="REGION"
          collapsible={regionsLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={regionsLoading} />}
        >
          {regionsData?.length > 0 ? (
            <div className="panel-section" onScroll={onRegionScroll}>
              <LoaderComponent spinning={regionsLoading}>
                <Checkbox.Group
                  onChange={(event) => handleChangeFilter(event, 'region')}
                  value={map(dashboardConfig?.region, (item) => item?.id)}
                >
                  <Space direction="vertical">
                    {map(regionsData, (item) => (
                      <Checkbox
                        className="common-checkbox"
                        key={item?.id}
                        value={item?.id}
                      >
                        {item?.parentName
                          ? `${item?.parentName} / ${item?.name}`
                          : item?.name}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
        <Panel
          forceRender
          header={panelHeader('Questionnaires', dashboardConfig?.question)}
          key="QUESTIONNAIRES"
          collapsible={questionsLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={questionsLoading} />}
        >
          {questionsData?.length > 0 ? (
            <div className="panel-section" onScroll={onQuestionScroll}>
              <LoaderComponent spinning={questionsLoading}>
                <Checkbox.Group
                  onChange={(event) => handleChangeFilter(event, 'question')}
                  value={map(dashboardConfig?.question, (item) => item?.id)}
                >
                  <Space direction="vertical">
                    {map(questionsData, (item) => {
                      return (
                        <Checkbox
                          className="common-checkbox"
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.title}
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>

        <Panel
          forceRender
          header={panelHeader('Time Of Day', [dashboardConfig?.timeOfDay])}
          key="TIME_OF_DAY"
        >
          <div className="panel-section">
            <LoaderComponent spinning={questionsLoading}>
              <RangePicker
                className="common-range-picker fill-width"
                popupClassName="common-range-picker"
                use12Hours
                minuteStep={15}
                value={
                  dashboardConfig?.timeOfDay?.time?.length > 0
                    ? [
                        moment(dashboardConfig?.timeOfDay?.time?.[0], 'HH:mm'),
                        moment(dashboardConfig?.timeOfDay?.time?.[1], 'HH:mm')
                      ]
                    : null
                }
                format="h:mm a"
                onChange={handleChangeTimeOfDay}
              />
              <Divider>OR</Divider>
              <Radio.Group
                className="common-radio"
                onChange={(event) =>
                  handleChangeFilter(event?.target?.value, 'timeOfDay')
                }
                value={dashboardConfig?.timeOfDay?.id || '4'}
              >
                <Space direction="vertical">
                  {map(timeOfDayData, (item) => {
                    return (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.title}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </LoaderComponent>
          </div>
        </Panel>

        <Panel
          forceRender
          header={panelHeader('Devices', dashboardConfig?.device)}
          key="DEVICES"
          collapsible={devicesLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={devicesLoading} />}
        >
          {devicesData?.length > 0 ? (
            <div className="panel-section" onScroll={onDeviceScroll}>
              <LoaderComponent spinning={devicesLoading}>
                <Checkbox.Group
                  onChange={(event) => handleChangeFilter(event, 'device')}
                  value={map(dashboardConfig?.device, (item) => item?.key)}
                >
                  <Space direction="vertical">
                    {map(devicesData, (item) => {
                      return (
                        <Checkbox
                          className="common-checkbox"
                          key={item?.key}
                          value={item?.key}
                        >
                          {item?.label}
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
        <Panel
          forceRender
          header={panelHeader('Browsers', dashboardConfig?.browser)}
          key="BROWSERS"
          collapsible={browsersLoading && 'disabled'}
          extra={<ExtraLoaderComponent loading={browsersLoading} />}
        >
          {browsersData?.length > 0 ? (
            <div className="panel-section" onScroll={onBrowserScroll}>
              <LoaderComponent spinning={browsersLoading}>
                <Checkbox.Group
                  onChange={(event) => handleChangeFilter(event, 'browser')}
                  value={map(dashboardConfig?.browser, (item) => item?.key)}
                >
                  <Space direction="vertical">
                    {map(browsersData, (item) => {
                      return (
                        <Checkbox
                          className="common-checkbox"
                          key={item?.key}
                          value={item?.key}
                        >
                          {item?.label}
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default DashboardPanelComponent;
