import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './customerModule.less';
import CustomerContact from './pages/CustomerContact';
import CustomerCreate from './pages/CustomerCreate';
import CustomerEdit from './pages/CustomerEdit';
import CustomerList from './pages/CustomerList';
import CustomerProperty from './pages/CustomerProperty';
import CustomerView from './pages/CustomerView';

const CustomerWrapper = () => {
  // commented because permission not needed now
  // const { getCurrentUserRole } = useContext(AppContext);
  // const userRoles = getCurrentUserRole();
  // const { TENANT_ADMIN } = ROLE_KEYS;
  // const isTenantAdmin = userRoles?.includes(TENANT_ADMIN);

  return (
    <div className="customer-module">
      <Switch>
        {/* {isTenantAdmin && ( */}
        <>
          <Route exact path={ROUTES?.CUSTOMERS} component={CustomerList} />
          <Route path={`${ROUTES?.CUSTOMERS}/add`} component={CustomerCreate} />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/edit/:id`}
            component={CustomerEdit}
          />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/view/:id`}
            component={CustomerView}
          />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/view${ROUTES?.CONTACTS}/:id`}
            component={CustomerContact}
          />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/view${ROUTES?.PROPERTIES}/:id`}
            component={CustomerProperty}
          />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/edit${ROUTES?.CONTACTS}/:id`}
            component={CustomerContact}
          />
          <Route
            exact
            path={`${ROUTES?.CUSTOMERS}/edit${ROUTES?.PROPERTIES}/:id`}
            component={CustomerProperty}
          />
        </>
        {/* )} */}
      </Switch>
    </div>
  );
};

export default CustomerWrapper;
