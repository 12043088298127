import Icon from '@ant-design/icons';
import React from 'react';

const backIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      aria-label="Back Button"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="6" fill="#F1F3F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2762 12.0001L15.1382 16.862L14.1954 17.8048L8.39062 12.0001L14.1954 6.19531L15.1382 7.13812L10.2762 12.0001Z"
        fill="currentColor"
      />
    </svg>
  );
};

const BackIconComponent = (props) => (
  <Icon
    aria-label="Back Button"
    alt="Back Button"
    component={backIcon}
    {...props}
  />
);

export default BackIconComponent;
