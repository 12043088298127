import { Column } from '@ant-design/plots';
import { map } from 'lodash';
import React, { memo, useContext } from 'react';
import { AppContext } from '../AppContext';

const ColumnChartComponent = memo((props) => {
  const {
    state: { dashboardConfig }
  } = useContext(AppContext);
  const {
    configData = null,
    chartData = [],
    uniqueDateData = [],
    colors = [],
    xField = 'timePeriod',
    yField = 'count',
    seriesField = 'statsTitle',
    groupField = 'questionnaire',
    loading = false
  } = props;

  const config = {
    data: map(chartData, (item) => {
      return {
        ...item,
        statsTitle: `${item?.questionnaire}-${item?.statsTitle}`
      };
    }),
    xField,
    yField,
    seriesField,
    isGroup: true,
    isStack: true,
    meta: {
      timePeriod: {
        type: 'timeCat',
        mask:
          dashboardConfig?.timePeriodValue === 'PREVYEAR'
            ? 'MMMM YYYY'
            : 'MM-DD-YYYY'
      }
    },
    groupField,
    appendPadding: [20, 0, 0, 0],
    tooltip: {
      fields: ['questionnaire', 'statsTitle', 'count'],
      formatter: (data) => {
        return { name: data?.statsTitle, value: data?.count };
      }
    },
    rawFields: ['questionnaire', 'statsTitle'],
    minColumnWidth: 20,
    maxColumnWidth: 20,
    dodgePadding: uniqueDateData?.length > 1 ? 5 : null,
    legend: {
      position: 'top-left',
      layout: 'horizontal',
      itemWidth: 220,
      maxRow: 3
    },
    label: {
      position: 'middle',
      style: {
        fontStyle: 'bold'
      }
    },
    scrollbar: {
      categorySize: uniqueDateData?.length * 10,
      type: 'horizontal'
    },
    theme: {
      colors10: colors,
      colors20: colors,
      styleSheet: {
        fontFamily: 'Poppins'
      }
    },
    smooth: true,
    animateOption: {
      appear: {
        animation: 'path-in',
        duration: 5000
      }
    },
    ...configData
  };

  return <Column {...config} loading={loading} />;
});

export default ColumnChartComponent;
