import { gql } from '@apollo/client';

export const ROLES = gql`
  query roles($filter: RoleFilter!, $where: RolesWhereFilter) {
    roles(filter: $filter, where: $where) {
      count
      data {
        id
        label
        description
        order
        isActive
        key
        tenantId
        referenceId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        lobSaData {
          id
          label
          key
          isActive
        }
      }
    }
  }
`;

export const GET_ZIP_CODES = gql`
  query getZipCodes($filter: GetZipCodesFilter, $where: ZipCodesWhereInput) {
    getZipCodes(filter: $filter, where: $where) {
      states
      data {
        city
        state
        county
        stateCode
        zipCode
        longLat
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_LOCATION_TYPE = gql`
  query getLocationType($filter: USLocationTypeFilter!) {
    getLocationType(filter: $filter) {
      count
      data {
        id
        name
        state
        type
        createdAt
        updatedAt
      }
    }
  }
`;
