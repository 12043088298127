import { useLazyQuery, useMutation } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Checkbox, Col, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import checkCircleDark from '../../../assets/check-circle-dark.svg';
import { PLAN_DETAILS } from '../../../common/constants';
import { CREATE_TENANT_SUBSCRIPTION } from '../graphql/Mutation';
import { FETCH_PLAN } from '../graphql/Query';
import Signup from '../pages/Signup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// below code can be helpful in  stripe loading issue when there is not active internet connection but need to test this in local first
// let stripePromise;
// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
//   }
//   return stripePromise;
// };
const initialPlanFilter = {
  skip: 0,
  limit: 10,
  sortOn: 'createdAt',
  sortBy: 'DESC'
};

const Plan = () => {
  const [checked, setChecked] = useState(true);

  const [planId, setPlanId] = useState(0);

  const [loading, setLoading] = useState(false);

  const [subscriptionPlans] = useLazyQuery(FETCH_PLAN, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const defaultId = res?.subscriptionPlans?.data?.[0]?.id;
      setPlanId(defaultId);
    }
  });

  useEffect(() => {
    subscriptionPlans({
      variables: {
        filter: initialPlanFilter,
        where: {
          isActive: true
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [createTenantSubscription] = useMutation(CREATE_TENANT_SUBSCRIPTION);
  const onChange = () => {
    setChecked(!checked);
  };

  const handleResult = (result) => {
    if (!result.ok) {
      return result
        .json()
        .then(function (json) {
          if (json?.error?.message) {
            throw new Error(
              `${result?.url} ${result?.status} ${json?.error?.message}`
            );
          }
        })
        .catch(function (err) {
          message?.error(err);
          throw err;
        });
    }
    return result.json();
  };
  const changePage = async () => {
    try {
      const stripe = await stripePromise;
      setLoading(true);
      try {
        const response = await createTenantSubscription({
          variables: {
            id: planId
          }
        });

        if (response) {
          const stripeId = response?.data?.createTenantSubscription?.stripeId;
          const stripeResponse = await stripe?.redirectToCheckout({
            sessionId: stripeId
          });

          if (stripeResponse) {
            handleResult(stripeResponse);
          }
        }
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Signup>
      <Row justify="center">
        <Col align="middle" lg={24}>
          <div className="plan-space">
            <div className="plan-card d-flex flex-vertical justify-center card-select">
              <div className="plan-title">
                <span>{PLAN_DETAILS?.title}</span>
              </div>
              <div>
                <p className="plan-description">{PLAN_DETAILS?.description}</p>
              </div>
              <div className="bullet-points">
                <div>
                  <img src={checkCircleDark} alt="check-circle" />
                  <span>{PLAN_DETAILS?.firstBullet}</span>
                </div>
                <div>
                  <img src={checkCircleDark} alt="check-circle" />
                  <span>{PLAN_DETAILS?.secondBullet}</span>
                </div>
                <div>
                  <img src={checkCircleDark} alt="check-circle" />
                  <span>{PLAN_DETAILS?.thirdBullet}</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={[8, 24]}>
        <Col md={24} lg={24} align="middle">
          <Checkbox onChange={onChange}>
            <span className="terms-condition">
              I accept the Rules and Terms of Use
            </span>
          </Checkbox>
        </Col>
        <Col md={24} lg={24}>
          <Button
            type="primary"
            id="btn-plan"
            onClick={changePage}
            loading={loading}
            className="fill-width onboarding-btn mb-10"
            disabled={checked}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </Signup>
  );
};

export default withRouter(Plan);
