import React from 'react';
import PanelContent from '../../../../modules/productRanking/components/ProductRankingPanelComponent';
import '../productRankingPanel.less';
import PanelBtn from './PanelBtn';

const ProductRankingPanel = () => {
  return (
    <>
      <PanelBtn />
      <div className="side-panel-wrapper product-ranking-panel">
        <PanelContent />
      </div>
    </>
  );
};

export default ProductRankingPanel;
