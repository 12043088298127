import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_PRICING = gql`
  mutation updateProductPricing(
    $data: ProductPricingDataInput!
    $where: ProductItemWhereInput!
  ) {
    updateProductPricing(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_PRODUCT_AVAILABILITY = gql`
  mutation updateProductAvailability(
    $data: ProductAvailabilityData!
    $where: ProductAvailabilityUpdateWhere!
  ) {
    updateProductAvailability(data: $data, where: $where) {
      message
      status
    }
  }
`;
