import Icon from '@ant-design/icons';
import React from 'react';

const questionnaireIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99728 1.33317C4.4988 0.908487 5.22609 0.666504 6 0.666504C6.77391 0.666504 7.5012 0.908487 8.00272 1.33317H8.66667C9.40305 1.33317 10 1.93012 10 2.6665L10.6667 2.6665C11.403 2.6665 12 3.26346 12 3.99984V13.9998C12 14.7362 11.403 15.3332 10.6667 15.3332H1.33333C0.596954 15.3332 0 14.7362 0 13.9998V3.99984C0 3.26346 0.596954 2.6665 1.33333 2.6665L2 2.6665C2 1.93012 2.59695 1.33317 3.33333 1.33317H3.99728ZM8.66667 4.6665C9.16019 4.6665 9.59108 4.39837 9.82162 3.99984H10.6667V13.9998H1.33333V3.99984H2.17838C2.40892 4.39837 2.83981 4.6665 3.33333 4.6665H8.66667ZM3.33333 10.6665V9.33317H7.33333V10.6665H3.33333ZM3.33333 6.6665V7.99984H8.66667V6.6665H3.33333ZM4.57175 2.6665L4.7711 2.43676C4.99013 2.18433 5.45961 1.99984 6 1.99984C6.54039 1.99984 7.00987 2.18433 7.2289 2.43676L7.42826 2.6665H8.66667V3.33317H3.33333V2.6665H4.26756H4.57175Z"
        fill="currentColor"
      />
    </svg>
  );
};

const QuestionnaireIconComponent = (props) => (
  <Icon
    aria-label="Questionnaire Icon"
    component={questionnaireIcon}
    {...props}
  />
);

export default QuestionnaireIconComponent;
