import { Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import MapIcon from '../../../../../../assets/map.svg';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import NumberComponent from '../../../previewComponents/globalComponents/NumberComponent';
import SelectComponent from '../../../previewComponents/globalComponents/SelectComponent';

const { Option } = SelectComponent;

const MapEntry = ({ mapEntry = null }) => {
  return (
    <div>
      {mapEntry?.mapCheck && (
        <div className="map-entry-section">
          <img src={MapIcon} alt="import-icon" width={130} height={130} />
        </div>
      )}
      <Form.Item name="street">
        <InputComponent readOnly allowClear placeholder="Street*" />
      </Form.Item>
      <Form.Item name="state">
        <SelectComponent placeholder="State*" disabled>
          {map([], (state) => (
            <Option key={state?.id} value={state?.name}>
              {state?.name}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="city">
            <SelectComponent placeholder="City*" disabled>
              {map([], (city) => (
                <Option key={city?.id} value={city?.name}>
                  {city?.name}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="zipCode">
            <NumberComponent disabled allowClear placeholder="Postal Code*" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default MapEntry;
