import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PERMISSIONS = gql`
  query permissions($filter: PermissionFilter!) {
    permissions(filter: $filter) {
      count
      data {
        id
        feature
        featureLabel
        action
        roles {
          key
          name
          permission
        }
      }
    }
  }
`;
