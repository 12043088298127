import { gql } from '@apollo/client';

export const GET_REGIONS = gql`
  query regions($filter: RegionFilter!, $where: RegionsWhereFilter) {
    regions(filter: $filter, where: $where) {
      count
      data {
        id
        name
        isActive
        zipCodes
        regionData
        tenantId
        description
        managerId
        parentId
        contactNumber
        address
        officeName
        zipCodesCount
        parentName
      }
    }
  }
`;

export const GET_REGION = gql`
  query region($id: ID!) {
    region(where: { id: $id }) {
      id
      name
      isActive
      zipCodes
      regionData
      tenantId
      description
      managerId
      parentId
      contactNumber
      address
      officeName
      parentRegion {
        id
        name
      }
      manager {
        id
        firstName
        lastName
      }
      geoLocation {
        zipCode
        longitude
        latitude
      }
    }
  }
`;

export const REGION_FILTER = gql`
  query regionFilters($filter: RegionFilter!) {
    regionFilters(filter: $filter) {
      count
      data {
        name
        isActive
        zipCodes
        regionData
        tenantId
        description
        managerId
        parentId
        contactNumber
        address
        officeName
      }
    }
  }
`;
