import { Form } from 'antd';
import { filter, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import SelectComponent from '../../../../../../../../components/SelectComponent';
import TileGridComponent from '../../../../../previewComponents/globalComponents/TileGridComponent';

const { Option } = SelectComponent;

const SystemPickListWidgetComponent = ({ systemFieldData }) => {
  const displayStyle =
    systemFieldData?.config?.displayStyle ||
    systemFieldData?.displayStyle ||
    ' ';
  const images =
    systemFieldData?.config?.images || systemFieldData?.images || false;

  const allowMultiple =
    systemFieldData?.config?.multiSelection ||
    systemFieldData?.multiSelection ||
    false;

  const imagePreview =
    systemFieldData?.config?.imagePreview ||
    systemFieldData?.imagePreview ||
    false;

  const renderDescriptions =
    systemFieldData?.config?.optionDescriptions ||
    systemFieldData?.optionDescriptions ||
    false;

  const otherConfig =
    systemFieldData?.config?.otherConfig || systemFieldData?.otherConfig || ' ';

  const description =
    systemFieldData?.config?.description ||
    systemFieldData?.description ||
    false;

  const descriptionText =
    systemFieldData?.config?.descriptionText ||
    systemFieldData?.descriptionText ||
    ' ';

  const [selectOptions, setSelectOptions] = useState([...otherConfig]);

  useEffect(() => {
    if (displayStyle === 'DROPDOWN') {
      if (otherConfig?.length > 0) {
        const options = filter(otherConfig, (item) => item?.blockCheck);
        setSelectOptions([...selectOptions, ...options]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherConfig, displayStyle]);

  return (
    <div>
      {displayStyle === 'DROPDOWN' ? (
        <Form layout="vertical">
          <Form.Item name="pickListSelect">
            <SelectComponent
              className="fill-width pick-list-drop-down"
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              mode={allowMultiple ? 'multiple' : ''}
              optionLabelProp="label"
            >
              {map(
                filter(otherConfig, (item) => item?.blockCheck),
                (item) => {
                  return (
                    <Option
                      key={item?.key || item?.blockId}
                      value={item?.label}
                      label={item?.label}
                    >
                      <div
                        key={item?.key || item?.blockId}
                        className="dropdown-option"
                      >
                        <span className="option-title">{item?.label}</span>
                        {renderDescriptions && (
                          <span className="option-description">
                            {item?.blockDescription || item?.description}
                          </span>
                        )}
                      </div>
                    </Option>
                  );
                }
              )}
            </SelectComponent>
          </Form.Item>
        </Form>
      ) : (
        <TileGridComponent
          displayStyle={displayStyle}
          renderImages={images}
          imagePreview={imagePreview}
          imageObjectName="image"
          renderDescriptions={renderDescriptions}
          renderItems={map(
            filter(otherConfig, (item) => item?.blockCheck),
            (item) => {
              return {
                key: item?.blockKey,
                ...item
              };
            }
          )}
        />
      )}
      {description && <div className="mt-37">{descriptionText}</div>}
    </div>
  );
};

export default SystemPickListWidgetComponent;
