import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Checkbox, Form, Popover, Radio, Switch } from 'antd';
import React from 'react';
import { uploadImageNormalize } from '../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../components/InputComponent';

const TotalRiseForm = ({ form = null, handleChangeDefault }) => {
  const showTotalRiseImage = Form?.useWatch(
    ['widgetConfiguration', 'stairs', 'totalRise', 'imageCheck'],
    form
  );

  const showAdditionalOptionCheck = Form?.useWatch(
    ['widgetConfiguration', 'stairs', 'totalRise', 'additionalOptionCheck'],
    form
  );
  return (
    <>
      <Form.Item
        label="Title"
        name={['widgetConfiguration', 'stairs', 'totalRise', 'title']}
        rules={[
          {
            required: true,
            message: 'Please Enter Title'
          }
        ]}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'stairs', 'totalRise', 'defaultCheck']}
        valuePropName="checked"
      >
        <Radio
          className="common-radio"
          value="default"
          onChange={() => handleChangeDefault('TOTAL_RISE')}
        >
          Default
        </Radio>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">
          Image{' '}
          <Popover
            overlayClassName="pointer"
            content="Add an image icon for displaying alongside this content."
          >
            <QuestionCircleOutlined />
          </Popover>
        </span>
        <Form.Item
          name={['widgetConfiguration', 'stairs', 'totalRise', 'imageCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'stairs', 'totalRise', 'image']}
        hidden={!showTotalRiseImage}
        normalize={uploadImageNormalize}
        valuePropName="fileList"
      >
        <DraggerUploadComponent>
          <p className="icon-header">
            <DownloadOutlined />
          </p>
          <p className="upload-title">
            Click or drag file to this area to upload
          </p>
        </DraggerUploadComponent>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Additional Option</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'stairs',
            'totalRise',
            'additionalOptionCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'stairs',
          'totalRise',
          'additionalOptionText'
        ]}
        hidden={!showAdditionalOptionCheck}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Additional Option Text" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'stairs', 'totalRise', 'selectedCheck']}
        className="mb-0"
        valuePropName="checked"
        hidden={!showAdditionalOptionCheck}
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>
      <Form.Item
        label="Total Height"
        name={['widgetConfiguration', 'stairs', 'totalRise', 'totalHeight']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Total Height'
          }
        ]}
      >
        <InputComponent placeholder="Enter Total Height PlaceHolder" />
      </Form.Item>
      <Form.Item
        label="Total Steps"
        name={['widgetConfiguration', 'stairs', 'totalRise', 'totalSteps']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Total Steps'
          }
        ]}
      >
        <InputComponent placeholder="Enter Total Steps PlaceHolder" />
      </Form.Item>
      <Form.Item
        label="Total Volume"
        name={['widgetConfiguration', 'stairs', 'totalRise', 'volume']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Volume'
          }
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Volume" />
      </Form.Item>
    </>
  );
};

export default TotalRiseForm;
