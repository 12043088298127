import { filter, find, map } from 'lodash';
import React, { useState } from 'react';
import TileGridComponent from '../../../previewComponents/globalComponents/TileGridComponent';

const MyProjectWidgetComponent = ({
  widgetConfig = null,
  selectedLob = null
}) => {
  const [selectedServiceType, setSelectedServiceType] = useState(null);

  return (
    <div className="my-project-component">
      <TileGridComponent
        displayStyle={widgetConfig?.displayStyle}
        renderImages={widgetConfig?.images}
        imageObjectName="logo"
        imagePreview={widgetConfig?.imagePreview}
        renderDescriptions={widgetConfig?.optionDescriptions}
        selectedKey={selectedLob?.key}
        renderItems={map(
          filter(widgetConfig?.otherConfig, (item) => item?.blockCheck),
          (item) => {
            return {
              key: item?.blockKey,
              ...item
            };
          }
        )}
      />
      {selectedLob && (
        <TileGridComponent
          displayStyle="LIST"
          renderItems={filter(
            find(
              widgetConfig?.otherConfig,
              (item) => item?.blockKey === selectedLob?.blockKey
            )?.subAreas,
            (item) => item?.blockCheck
          )}
          selectedKey={selectedServiceType?.key}
          handleClick={setSelectedServiceType}
        />
      )}
      {widgetConfig?.description &&
        widgetConfig?.descriptionText?.length > 0 && (
          <div className="mt-37">{widgetConfig?.descriptionText}</div>
        )}
    </div>
  );
};

export default MyProjectWidgetComponent;
