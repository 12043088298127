import { Form, Radio } from 'antd';
import React from 'react';
import InputComponent from '../../../components/InputComponent';
import './RenderItems.less';

const RenderText = ({ textData = null }) => {
  const { key } = textData;
  return (
    <>
      <Form.Item
        initialValue="EQUAL"
        className="filter-form-item"
        name={['dynamicFields', key, 'filter']}
      >
        <Radio.Group
          name={['dynamicFields', key, 'filter']}
          className="common-radio fill-width d-flex flex-vertical align-start radio-filter-options "
        >
          <Radio value="EQUAL">Equals</Radio>
          <Radio value="NOT_EQUAL">Not Equals</Radio>
          <Radio value="CONTAINS">Contain</Radio>
          <Radio value="NOT_CONTAINS">Not Contain</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item initialValue="" name={['dynamicFields', key, 'value']}>
        <InputComponent />
      </Form.Item>
    </>
  );
};

export default RenderText;
