import { Button } from 'antd';
import React from 'react';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { MODAL_WIDTH } from '../../../common/constants';
import ModalComponent from '../../../components/ModalComponent';
import TableComponent from '../../../components/TableComponent';

const PropertyCreationModal = ({
  showModal = false,
  setShowModal,
  duplicateData = [],
  submitData,
  submitLoading = false,
  isEdit = false
}) => {
  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 300
    },
    {
      title: 'TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType',
      ellipsis: true,
      width: 200
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location) => {
        return `${location?.addressLine1} ${location?.city}, ${location?.state} ${location?.zipCode}`;
      }
    },
    {
      title: 'BUILT YEAR',
      dataIndex: 'builtYear',
      key: 'builtYear',
      ellipsis: true,
      width: 150
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 120,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    }
  ];

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="PROPERTY CREATION"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="property-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="property-table-discard-btn"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={submitLoading}
            size="small"
            className="common-button add-property-button"
            onClick={submitData}
          >
            {isEdit ? 'Edit' : 'Add New'} property
          </Button>
        </div>
      }
    >
      <div className="common-table property-table">
        <h4 className="extra-text">
          We have found some possible duplicate records as below,
        </h4>
        <h4 className="extra-text">
          Are you still want to create new property?
        </h4>
        <TableComponent
          fullHeight={false}
          columns={[...columns?.filter((item) => item !== false)]}
          data={duplicateData}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default PropertyCreationModal;
