import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, message, Row, Space } from 'antd';
import React, { useState } from 'react';
import CheckedIconComponent from '../../../app/components/iconComponents/CheckedIconComponent';
import notChecked from '../../../assets/images/notChecked.svg';
import { REGEX } from '../../../common/constants';
import {
  checkForSpecialCharsInPassword,
  formValidatorRules
} from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import ModalComponent from '../../../components/ModalComponent';
import { CHANGE_PASSWORD } from '../graphql/Mutations';

const { required } = formValidatorRules;

const ChangePasswordModal = ({ open, setVisible }) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [displayErrorList, setDisplayErrorList] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lengthValidation, setLengthValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialCharValidation, setSpecialCharValidation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [changePasswordMutate] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      setVisible(false);
    },
    onError() {
      setButtonLoading(false);
    }
  });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async ({ oldPassword, password }) => {
    setButtonLoading(true);
    if (
      numberValidation &&
      specialCharValidation &&
      upperCase &&
      lengthValidation
    ) {
      await changePasswordMutate({
        variables: {
          data: {
            oldPassword,
            newPassword: password
          }
        }
      });
    } else {
      setButtonLoading(false);
      message?.error('Your Password Does Not Meet Requirements.');
    }
  };

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;

    const isIncludedSpecialCharacters = checkForSpecialCharsInPassword([
      ...value
    ]);

    if (value?.match(REGEX?.UPPER_CASE)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    if (value?.length < 8 || value?.length > 16) {
      setLengthValidation(false);
    } else {
      setLengthValidation(true);
    }

    if (isIncludedSpecialCharacters) {
      setSpecialCharValidation(
        !(
          isIncludedSpecialCharacters &&
          value?.match(REGEX?.NOT_ALLOWED_CHARS_IN_PASSWORD)
        )
      );
    } else {
      setSpecialCharValidation(false);
    }

    if (value?.match(REGEX?.DIGIT)) {
      setNumberValidation(true);
    } else {
      setNumberValidation(false);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };
  return (
    <ModalComponent
      title="Change Password"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      wrapClassName="change-password-modal"
    >
      <Form
        name="change-password"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        className="gx-login-form gx-form-row0"
      >
        <Form.Item
          name="oldPassword"
          rules={[required]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <InputComponent.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Old Password"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[required]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <InputComponent.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Password"
            onChange={(e) => handleChange(e)}
            onFocus={() => setDisplayErrorList(true)}
          />
        </Form.Item>
        {displayErrorList && (
          <Row align="middle">
            <Col md={12} lg={12} xl={12}>
              <div className="password-warning">
                {upperCase ? (
                  <CheckedIconComponent className="checked-success-color font-size-22 mr-13" />
                ) : (
                  <img
                    alt="password validation"
                    className="mr-13"
                    src={notChecked}
                    height="22px"
                    width="22px"
                  />
                )}
                <span>1 or More Capital Characters</span>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="password-warning">
                {lengthValidation ? (
                  <CheckedIconComponent className="checked-success-color font-size-22 mr-13" />
                ) : (
                  <img
                    className="mr-13"
                    alt="password validation"
                    src={notChecked}
                    height="22px"
                    width="22px"
                  />
                )}
                <div>Password length should be between 8 to 16 characters</div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="password-warning">
                {numberValidation ? (
                  <CheckedIconComponent className="checked-success-color font-size-22 mr-13" />
                ) : (
                  <img
                    className="mr-13"
                    alt="password validation"
                    src={notChecked}
                    height="22px"
                    width="22px"
                  />
                )}
                <span>1 or More Numbers</span>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="password-warning">
                {specialCharValidation ? (
                  <CheckedIconComponent className="checked-success-color font-size-22 mr-13" />
                ) : (
                  <img
                    className="mr-13"
                    alt="password validation"
                    src={notChecked}
                    height="22px"
                    width="22px"
                  />
                )}
                <span>1 or More Special Characters (!@#$%^&*)</span>
              </div>
            </Col>
          </Row>
        )}
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          normalize={(value) => value?.split(' ')?.join('')}
          rules={[
            required,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value !== getFieldValue('password')) {
                  return Promise?.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
                return Promise?.resolve();
              }
            })
          ]}
        >
          <InputComponent.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Confirm password"
            onFocus={() => setDisplayErrorList(false)}
          />
        </Form.Item>
        <div className="d-flex">
          <Space>
            <Form.Item>
              <Button
                loading={buttonLoading}
                type="primary"
                htmlType="submit"
                className="common-button login-button font-size-14"
              >
                Change Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="common-button login-button discard-button font-size-14"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </ModalComponent>
  );
};

export default ChangePasswordModal;
