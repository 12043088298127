import { Col, Divider, Row } from 'antd';
import React from 'react';
import { formatPhoneNumber } from '../../../common/utils';

const CustomerDetails = ({ data = {} }) => {
  const {
    user = null,
    isActive = true,
    location = null,
    fax = '',
    tollFree = '',
    web = '',
    technician = '',
    industry = '',
    descriptionBusiness = '',
    csmObj = null,
    salesManagerObj = null,
    customerTypeData = null
  } = data;

  return (
    <div>
      <Row>
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">
              {user?.firstName} {user?.lastName}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact Number *</span>
            <span className="field-value">
              {formatPhoneNumber(user?.phoneNo)}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Email *</span>
            <span className="field-value">{user?.email}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Address *</span>
            <span className="field-value">{`${location?.addressLine1} ${location?.city} ${location?.state} ${location?.country} ${location?.zipCode}`}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Type</span>
            <span className="field-value">
              {customerTypeData?.label || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Fax</span>
            <span className="field-value">{formatPhoneNumber(fax) || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Toll Free</span>
            <span className="field-value">
              {formatPhoneNumber(tollFree) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Web</span>
            <span className="field-value">{web || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Customer Service Manager</span>
            <span className="field-value">
              {csmObj?.firstName
                ? `${csmObj?.firstName} ${csmObj?.lastName}`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Sales Manager</span>
            <span className="field-value">
              {salesManagerObj?.firstName
                ? `${salesManagerObj?.firstName} ${salesManagerObj?.lastName}`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Technician</span>
            <span className="field-value">{technician || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Industry</span>
            <span className="field-value">{industry || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Description of Business</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: descriptionBusiness || '-' }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDetails;
