import { find, forEach, kebabCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { QUESTIONNAIRE_TYPE } from '../../../../../common/constants';
import FooterSection from '../footerSection';
import GeneralSection from '../generalSection';
import WidgetSection from '../widgetSection';
import './CommonConfigComponent.less';

const PreviewComponent = ({
  formValues = null,
  pageSequencesData = [],
  selectedLob = null,
  selectedTab = '',
  selectedAppointmentKey = '',
  fieldObject = null,
  systemFieldData = null,
  setSelectedAppointmentKey,
  isNewPage = false,
  checkedList = [],
  selectedDimensionTab = '',
  selectedDimensionProject = '',
  tenantLogoDetails = null,
  tenantContactDetails = null,
  fetchTenantData
}) => {
  const [currentPageSequence, setCurrentPageSequence] = useState(null);
  const [previousPageObj, setPreviousPageObj] = useState(null);
  const [newPageSequenceCopy, setNewPageSequenceCopy] = useState(null);

  const location = useLocation();
  const { pathname = '', state = null } = location;

  useEffect(() => {
    if (state?.type === QUESTIONNAIRE_TYPE?.ROQ_PATH) {
      fetchTenantData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const currentPageSequenceCopy = find(pageSequencesData, (item) =>
      pathname?.includes(kebabCase(item?.pageKey))
    );
    forEach(pageSequencesData, (item, index) => {
      if (item?.order > 1 && currentPageSequenceCopy?.order === item?.order) {
        setPreviousPageObj(pageSequencesData?.[index - 1]);
      }
    });
    setCurrentPageSequence(currentPageSequenceCopy);
    if (isNewPage) {
      setNewPageSequenceCopy({
        ...pageSequencesData?.[0]
      });
    } else {
      setNewPageSequenceCopy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSequencesData]);

  return (
    <>
      <div className="preview-tag">Preview</div>
      <div className="common-config-component">
        <GeneralSection
          pageConfiguration={formValues}
          currentPageSequence={currentPageSequence}
          newPageSequenceCopy={newPageSequenceCopy}
          tenantLogoDetails={tenantLogoDetails}
        />
        <WidgetSection
          pageConfiguration={formValues}
          selectedTab={selectedTab}
          selectedLob={selectedLob}
          selectedAppointmentKey={selectedAppointmentKey}
          setSelectedAppointmentKey={setSelectedAppointmentKey}
          currentPageSequence={currentPageSequence}
          fieldObject={fieldObject}
          systemFieldData={systemFieldData}
          checkedList={checkedList}
          selectedDimensionTab={selectedDimensionTab}
          selectedDimensionProject={selectedDimensionProject}
        />
        <FooterSection
          pageConfiguration={formValues}
          currentPageSequence={currentPageSequence}
          newPageSequenceCopy={newPageSequenceCopy}
          previousPageObj={previousPageObj}
          tenantContactDetails={tenantContactDetails}
        />
      </div>
    </>
  );
};

export default withRouter(PreviewComponent);
