import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_USER_PERMISSION, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PermissionList from './pages/PermissionList';
import './permissionModule.less';

const PermissionWrapper = () => {
  return (
    <div className="permission-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PERMISSIONS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_USER_PERMISSION}
              showNoAccess
            >
              <PermissionList {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default PermissionWrapper;
