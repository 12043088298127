import React from 'react';
import { QUESTIONNAIRE_TYPE } from '../../../../common/constants';
import LeftSideContent from '../../../../modules/myQuestionnaire/components/LeftSideContent';
import '../cpqPanel.less';
import PanelBtn from './PanelBtn';

const CpqPanel = ({
  pageSequencesData = [],
  refetchPageSequence,
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER,
  refetchPageConfig
}) => {
  return (
    <>
      <PanelBtn className="questionnaire-btn" type={type} />
      <div className="side-panel-wrapper cpq-panel">
        <LeftSideContent
          pageSequencesData={pageSequencesData}
          refetchPageSequence={refetchPageSequence}
          type={type}
          refetchPageConfig={refetchPageConfig}
        />
      </div>
    </>
  );
};

export default CpqPanel;
