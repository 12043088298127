import { gql } from '@apollo/client';

export const GET_MANUFACTURERS = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        uuid
        name
        manufacturerCode
        location {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
        logo {
          url
          key
          name
          contentType
          extension
        }
        refData
        contactNumber
        isActive
        certification
        fax
        url
        tenantId
      }
    }
  }
`;

export const GET_MANUFACTURER = gql`
  query manufacturer($id: ID!) {
    manufacturer(where: { id: $id }) {
      id
      uuid
      name
      manufacturerCode
      location {
        id
        isActive
        addressLine1
        addressLine2
        addressLine3
        city
        county
        state
        country
        zipCode
        tenantId
        createdAt
        updatedAt
      }
      logo {
        url
        key
        name
        contentType
        extension
      }
      refData
      contactNumber
      isActive
      certification
      fax
      url
      tenantId
    }
  }
`;

export const MANUFACTURER_FILTER = gql`
  query manufacturerFilters($filter: ManufacturerFilter!) {
    manufacturerFilters(filter: $filter) {
      count
      data {
        name
        manufacturerCode
        logo {
          url
          key
          name
          contentType
          extension
        }
        refData
        contactNumber
        isActive
        certification
        fax
        url
        tenantId
      }
    }
  }
`;
