import React from 'react';
import PanelBtn from './PanelBtn';

const JobsPanel = () => {
  return (
    <>
      <PanelBtn />
      <div className="side-panel-wrapper job-panel">
        <h2>Jobs Panel</h2>
      </div>
    </>
  );
};

export default JobsPanel;
