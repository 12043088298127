import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ContactTabs from '../components/ContactTabs';
import CustomerModal from '../components/CustomerModal';
import CustomerTable from '../components/CustomerTable';
import { GET_CONTACT } from '../graphql/Queries';

const CustomerList = ({ match: { params: { id } = {} } = {} }) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [contactData, setContactData] = useState({});
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const [getContact] = useLazyQuery(GET_CONTACT, {
    onCompleted: (res) => {
      setContactData(res?.contact);
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    getContact({
      variables: { id }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="full-height-card card-body-padding">
      {location?.pathname?.includes('/view') ? (
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            id="contact-table-add-btn"
            type="primary"
            onClick={() =>
              history?.push(
                `${ROUTES?.CONTACTS}/edit${ROUTES?.CUSTOMERS}/${id}`
              )
            }
          >
            Edit
          </Button>
        </Portal>
      ) : (
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="contact-table-add-btn"
            type="primary"
            onClick={() => setShowModal(true)}
          >
            Add Customer
          </Button>
        </Portal>
      )}
      {showModal && (
        <CustomerModal
          showModal={showModal}
          setShowModal={setShowModal}
          contactId={id}
        />
      )}
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CONTACTS} />
          <span className="portal-header">
            {contactData?.userInfo?.firstName} {contactData?.userInfo?.lastName}
          </span>
        </div>
      </Portal>
      <ContactTabs>
        {loading ? <LoaderComponent /> : pageSize && <CustomerTable />}
      </ContactTabs>
    </Card>
  );
};

export default withRouter(CustomerList);
