import { Col, Divider, Form, Row } from 'antd';
import React from 'react';
import { REGEX } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import NumberComponent from '../../../components/NumberComponent';

const { number } = formValidatorRules;

const NumericFormData = ({ position = 'top', isDisabled = false }) => {
  return (
    <div className="numeric-form-data">
      {position === 'bottom' && (
        <>
          <span className="form-divider-text">FIELD SETTINGS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="defaultValue"
                rules={[
                  ({ getFieldValue }) => ({
                    required:
                      getFieldValue('required') &&
                      (getFieldValue('readOnly') || getFieldValue('hidden')),
                    message: 'Please Enter value'
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('minNumber') &&
                        getFieldValue('maxNumber') &&
                        Number(value) < getFieldValue('minNumber') &&
                        Number(value) > getFieldValue('maxNumber')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter value between ${getFieldValue(
                              'minNumber'
                            )} & ${getFieldValue('maxNumber')}`
                          )
                        );
                      }
                      if (
                        getFieldValue('minNumber') &&
                        Number(value) < getFieldValue('minNumber')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Minimum number should be ${getFieldValue(
                              'minNumber'
                            )}`
                          )
                        );
                      }
                      if (
                        getFieldValue('maxNumber') &&
                        Number(value) > getFieldValue('maxNumber')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Maximum number should be ${getFieldValue(
                              'maxNumber'
                            )}`
                          )
                        );
                      }
                      if (
                        value?.includes('.') &&
                        Number(getFieldValue('decimal')) <
                          value?.split('.')?.[1]?.length
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Maximum decimal should be ${getFieldValue(
                              'decimal'
                            )}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
                label="Default Value"
                className="label-with-tooltip"
                tooltip="Set the initial value here."
              >
                <NumberComponent
                  min={0}
                  allowClear
                  placeholder="Enter Default Value"
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={3} xxl={3}>
              <Form.Item
                name="decimal"
                label="Decimal Places"
                normalize={(value) => Number(value)}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Decimal Places'
                  },
                  number
                ]}
              >
                <NumberComponent
                  min={0}
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter Decimal Places"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={6} sm={6} md={6} lg={6} xl={3} xxl={3}>
              <Form.Item
                name="minNumber"
                label="Min Number"
                className="label-with-tooltip"
                tooltip="Smallest possible value, it becomes fixed after publishing."
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!REGEX?.NUMBER?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Should be a valid Number');
                      }
                      if (value && Number(value) < 0) {
                        return Promise?.reject(
                          new Error('Minimum number should be 0')
                        );
                      }
                      if (
                        getFieldValue('maxNumber') &&
                        value &&
                        Number(value) > getFieldValue('maxNumber')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter number less than ${getFieldValue(
                              'maxNumber'
                            )}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent
                  min={0}
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter Min Number"
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={3} xxl={3}>
              <Form.Item
                name="maxNumber"
                label="Max Number"
                className="label-with-tooltip"
                tooltip="Largest possible value, it becomes fixed after publishing."
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!REGEX?.NUMBER?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Should be a valid Number');
                      }
                      if (value && Number(value) < 0) {
                        return Promise?.reject(
                          new Error('Minimum number should be 0')
                        );
                      }
                      if (
                        getFieldValue('minNumber') &&
                        value &&
                        Number(value) < getFieldValue('minNumber')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter number greater than ${getFieldValue(
                              'minNumber'
                            )}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent
                  min={0}
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter Max Number"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default NumericFormData;
