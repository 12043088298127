import { Button } from 'antd';
import React from 'react';
import ModalComponent from '../../../previewComponents/globalComponents/ModalComponent';
import './dimensionWidget.less';

const MeasurementTipsModal = ({
  showModal = false,
  setShowModal,
  widgetConfig = null
}) => {
  return (
    <ModalComponent
      open={showModal}
      setOpen={setShowModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      destroyOnClose
      wrapClassName="measurement-tips-modal"
      getContainer="#centerContent"
      closable={false}
      keyboard={false}
    >
      <div className="measurement-tip-wrapper">
        <div className="title">{widgetConfig?.titleText}</div>
        <div
          className="editor-render tips-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: widgetConfig?.descriptionText || '-'
          }}
        />
        <div className="cta-button">
          <Button className="common-button-cpq" type="primary">
            {widgetConfig?.actionButtonLabel}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default MeasurementTipsModal;
