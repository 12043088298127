import { useLazyQuery } from '@apollo/client';
import { Checkbox, Col, Divider, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CheckInfo from '../../../assets/check-info.svg';
import { formatDate, formatPrice } from '../../../common/utils';
import { GET_DYNAMIC_FIELDS_WITH_LIST_ITEMS } from '../graphql/Queries';
import RenderUploadPreview from './RenderUploadPreview';

const ProductItemDetails = ({ data = {} }) => {
  const {
    name,
    description,
    quoteDescription,
    isActive,
    sku,
    industry,
    lineOfBusiness,
    subArea,
    defaultPrice,
    manufacturer,
    costPrice,
    unitForMeasureObj,
    unitInputParameterObj,
    addConversionRate,
    conversionRate,
    isSellable,
    manageInventory,
    productItemWarranty,
    productItemWarrantyUnitOfMeasure = 'years',
    labourWarranty,
    labourWarrantyUnitOfMeasure = 'years',
    productCategory,
    allowPriceChange,
    asset,
    stock = null,
    score = null,
    workOrderActivity = false,
    allowDiscount = false,
    allowCommission = false,
    createMaintenanceContract = false,
    maintenanceContract = null,
    supplier,
    taxable = false,
    taxCode,
    vendor,
    crmUniqueId,
    crmLastRefresh,
    dynamicFields,
    industryId = false,
    lineOfBusinessId = false
  } = data;

  const [customDynamicField, setCustomDynamicField] = useState([]);

  const [dynamicFieldsWithListItems] = useLazyQuery(
    GET_DYNAMIC_FIELDS_WITH_LIST_ITEMS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setCustomDynamicField(
          res?.dynamicFieldsWithListItems?.map((item) => {
            let dynamicValue = dynamicFields?.[item?.key] || '-';
            if (item?.fieldType === 'PICK_LIST') {
              dynamicValue =
                item?.dynamicFieldListItems?.filter(
                  (items) => items?.value === dynamicFields?.[item?.key]
                )?.[0]?.label || '-';
            }
            if (item?.fieldType === 'BOOLEAN') {
              dynamicValue =
                item?.config?.BOOLEAN[
                  dynamicFields?.[item?.key] === true
                    ? 'trueValue'
                    : 'falseValue'
                ];
            }

            if (item?.fieldType === 'DATE_TIME') {
              dynamicValue = formatDate(
                dynamicFields?.[item?.key]?.[0],
                item?.config?.DATE_TIME?.format
              );
              if (item?.config?.DATE_TIME?.dateConfig?.range) {
                dynamicValue = `${formatDate(
                  dynamicFields?.[item?.key]?.[0],
                  item?.config?.DATE_TIME?.format
                )} - ${formatDate(
                  dynamicFields?.[item?.key]?.[1],
                  item?.config?.DATE_TIME?.format
                )}`;
              }
            }

            if (item?.fieldType === 'UPLOAD') {
              dynamicValue = dynamicFields?.[item?.key] || [];
            }

            return {
              label: item?.label,
              value: dynamicValue,
              type: item?.fieldType,
              ...(item?.fieldType === 'UPLOAD' && {
                doctype: item?.config?.[item?.fieldType]?.fileType?.key
              })
            };
          })
        );
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (industryId && lineOfBusinessId) {
      dynamicFieldsWithListItems({
        variables: {
          where: {
            entityType: ['PRODUCT'],
            industry: [industryId],
            lineOfBusiness: [lineOfBusinessId]
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicFields]);

  return (
    <div>
      <Row className="required-row">
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">{name || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Manufacturer *</span>
            <span className="field-value">{manufacturer?.name || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">SKU *</span>
            <span className="field-value">{sku || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Industry *</span>
            <span className="field-value">{industry?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Line of Business *</span>
            <span className="field-value">{lineOfBusiness?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Service Type *</span>
            <span className="field-value">{subArea?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Cost *</span>
            <span className="field-value">
              {costPrice ? formatPrice(costPrice) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Default Price *</span>
            <span className="field-value">
              {defaultPrice ? formatPrice(defaultPrice) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Stock *</span>
            <span className="field-value">{stock || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Product Ranking *</span>
            <span className="field-value">{score || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">
              Quote Description *{' '}
              <Popover
                placement="top"
                content="This will be shown on the quote page."
              >
                <img src={CheckInfo} alt="check-info" className="check-info" />
              </Popover>
            </span>
            <span className="field-value">{quoteDescription || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={workOrderActivity === true}
                className="d-flex justify-center items-center"
              >
                Create Work Order Activity *
              </Checkbox>
            </span>
          </div>
        </Col>
      </Row>
      <span className="form-divider-text">UNIT OF MEASURE & CONVERSION</span>
      <Divider className="form-divider" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Unit Of Measure * </span>
            <span className="field-value">
              {unitForMeasureObj?.title || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Conversion Parameter * </span>
            <span className="field-value">
              {unitInputParameterObj?.name || '-'}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={addConversionRate === true}
                className="product-item-checkbox d-flex justify-center items-center"
              >
                Add Conversion Rate
              </Checkbox>
              <Popover
                overlayClassName="pointer common-tooltip"
                content="This is the conversion rate from the unit of the Input parameter to the units of measure for the products. Please provide the conversion rate in decimal format."
              >
                <img src={CheckInfo} alt="check-info" />
              </Popover>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Conversion Rate * </span>
            <span className="field-value">{conversionRate || '-'}</span>
          </div>
        </Col>
        {addConversionRate && (
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            xxl={6}
            className="d-flex align-center"
          >
            <span className="form-divider-text">
              1 {unitInputParameterObj?.name} = {conversionRate}{' '}
              {unitForMeasureObj?.title}
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Product Description</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description || '-' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Product Category</span>
            <span className="field-value">{productCategory?.title || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Product Warranty</span>
            <span className="field-value">
              {productItemWarranty
                ? `${productItemWarranty} ${productItemWarrantyUnitOfMeasure?.toLowerCase()}`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Labor Warranty</span>
            <span className="field-value">
              {labourWarranty
                ? `${labourWarranty} ${labourWarrantyUnitOfMeasure?.toLowerCase()}`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={allowDiscount === true}
                className="product-item-checkbox d-flex justify-center items-center"
              >
                Allow Discounts
              </Checkbox>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={allowCommission === true}
                className="product-item-checkbox d-flex justify-center items-center"
              >
                Allow Commissions
              </Checkbox>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={createMaintenanceContract === true}
                className="product-item-checkbox d-flex justify-center items-center"
              >
                Create Maintenance Contract
              </Checkbox>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Maintenance Contract</span>
            <span className="field-value">{maintenanceContract || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Supplier</span>
            <span className="field-value">{supplier || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label" />
            <span className="field-value">
              <Checkbox
                disabled
                checked={taxable === true}
                className="product-item-checkbox d-flex justify-center items-center"
              >
                Taxable
              </Checkbox>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">TaxCode</span>
            <span className="field-value">{taxCode || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Vendor</span>
            <span className="field-value">{vendor || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">CRM Unique Id</span>
            <span className="field-value">{crmUniqueId || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">CRM Last Refresh</span>
            <span className="field-value">
              {crmLastRefresh ? formatDate(crmLastRefresh) : '-'}
            </span>
          </div>
        </Col>
      </Row>

      {customDynamicField?.length > 0 && (
        <Row>
          <span className="form-divider-text">DYNAMIC FIELDS</span>
          <Divider className="form-divider" />
          {customDynamicField
            ?.filter((item) => item?.type !== 'UPLOAD')
            ?.map((item) => {
              return (
                <Col
                  key={item?.label}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                >
                  <div className="field-detail">
                    <span className="field-label">{item?.label}</span>
                    <span className="field-value">{item?.value || '-'}</span>
                  </div>
                </Col>
              );
            })}
          {customDynamicField
            ?.filter((item) => item?.type === 'UPLOAD')
            ?.map((item) => {
              return (
                <Col
                  key={item?.label}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  xxl={12}
                >
                  <div className="field-detail">
                    <span className="field-label">{item?.label}</span>
                    <RenderUploadPreview item={item} />
                  </div>
                </Col>
              );
            })}
        </Row>
      )}
      <span className="form-divider-text">ADDITIONAL</span>
      <Divider className="form-divider optional-divider" />
      <Row gutter={[16, 24]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={isSellable === true}
            className="product-item-checkbox"
          >
            Sellable
            <Popover content="If this option is checked then, This Product item will be available for sell.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={allowPriceChange === true}
            className="product-item-checkbox"
          >
            Allow Price Change
            <Popover content="If this option is checked then, Technicians can change the price when they visit at customer place.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={manageInventory === true}
            className="product-item-checkbox"
          >
            Manage Inventory
            <Popover content="If this option is checked then, Inventory will be managed for this item.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={asset === true}
            className="product-item-checkbox"
          >
            Asset
            <Popover content="If this option is checked then, This item will be considered as an Asset for the property where it is installed.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
      </Row>
    </div>
  );
};

export default ProductItemDetails;
