import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($data: [UserInput]!) {
    createUser(data: $data) {
      data {
        id
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
        tenantId
        emailVerified
        locations {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
      }
      status
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($where: UserWhereInput!, $data: UserInput!) {
    updateUser(where: $where, data: $data) {
      data {
        id
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
        tenantId
        emailVerified
        locations {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
      }
      status
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: changePasswordInput!) {
    changePassword(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($data: UserInput!) {
    updateProfile(data: $data) {
      message
      status
      data {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        isActive
        tenantId
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($where: UserWhereInput!) {
    deleteUser(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
