import { gql } from '@apollo/client';

export const UPDATE_PRICING_MODIFIER = gql`
  mutation updatePricingModifier(
    $where: PricingModifierWhereInput!
    $data: PricingModifierInput!
  ) {
    updatePricingModifier(where: $where, data: $data) {
      data {
        id
        title
      }
      status
      message
    }
  }
`;

export const CREATE_PRICING_MODIFIER = gql`
  mutation createPricingModifier($data: PricingModifierInput!) {
    createPricingModifier(data: $data) {
      data {
        id
        title
      }
      status
      message
    }
  }
`;

export const DELETE_PRICING_MODIFIER = gql`
  mutation deletePricingModifier($where: PricingModifierWhereInput!) {
    deletePricingModifier(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CHANGE_PRIORITY = gql`
  mutation changePriority($data: PriorityInput!) {
    changePriority(data: $data) {
      message
      status
      data {
        id
      }
    }
  }
`;
