import { useQuery } from '@apollo/client';
import { Button, Card, Empty } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DocumentComponent from '../../../components/DocumentComponent';
import GoBackButton from '../../../components/GoBackButton';
import { GET_ATTACHMENTS } from '../../../components/graphql/Query';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import CardWrapper from '../components/ProductItemTabs';
import { GET_PRODUCT_ITEM } from '../graphql/Queries';

const ProductItemDocument = (props) => {
  const [loading, setLoading] = useState(false);
  const [showRemoveIcon, setShowRemoveIcon] = useState(true);
  const { match: { params: { id } = {} } = {} } = props;
  const location = useLocation();
  const {
    state: { currentUser, permissions }
  } = useContext(AppContext);

  useEffect(() => {
    if (location?.pathname?.includes('/view')) {
      setShowRemoveIcon(false);
    } else {
      setShowRemoveIcon(true);
    }
  }, [location?.pathname]);

  const { data: { productItem } = {}, loading: productItemLoading } = useQuery(
    GET_PRODUCT_ITEM,
    {
      variables: { id },
      onCompleted: () => {
        setLoading(false);
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setLoading(false);
      }
    }
  );

  const {
    data: { attachments } = {},
    loading: attachmentLoading,
    refetch
  } = useQuery(GET_ATTACHMENTS, {
    variables: {
      filter: {
        type: 'DOCUMENT',
        referenceId: id,
        referenceKey: 'PRODUCT_ITEM',
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const files = map(attachments?.data, (item) => {
    return {
      id: item?.id,
      url: item?.url,
      key: item?.key,
      label: item?.label,
      uuid: productItem?.uuid
    };
  });
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          {productItem && (
            <span className="portal-header">{productItem?.name}</span>
          )}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_UPDATE']}>
        {location?.pathname?.includes('/view') && (
          <Portal portalId="header-right-content">
            <Button
              className="common-button"
              size="small"
              htmlType="submit"
              id="manufacturer-table-save-btn"
              type="primary"
              icon={<img src={EditIcon} alt="save-icon" width={12} />}
              onClick={() =>
                history?.push(`${ROUTES?.PRODUCTS}/edit/document/${id}`)
              }
            >
              Edit
            </Button>
          </Portal>
        )}
      </AccessControl>
      <CardWrapper>
        {loading || attachmentLoading || productItemLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {(location?.pathname?.includes('/view') ||
              !checkPermissions(permissions, ['FET_PRODUCT_ITEM_UPDATE'])) &&
            files?.length <= 0 ? (
              <div className="product-item-nodata">
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : (
              <DocumentComponent
                refetch={refetch}
                recordId={id}
                uuid={productItem?.uuid}
                tenantId={currentUser?.tenantId}
                module="product-item"
                moduleKey="PRODUCT_ITEM"
                fileListProps={files || []}
                isEdit={
                  showRemoveIcon &&
                  checkPermissions(permissions, ['FET_PRODUCT_ITEM_UPDATE'])
                }
              />
            )}
          </>
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(ProductItemDocument);
