import { Button } from 'antd';
import React from 'react';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { MODAL_WIDTH } from '../../../common/constants';
import { formatPhoneNumber } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import TableComponent from '../../../components/TableComponent';

const ContactModal = ({
  showModal,
  setShowModal,
  duplicateData,
  onOk,
  isEdit = false
}) => {
  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'userInfo',
      key: 'userInfo',
      className: 'max-width-column',
      render: (userInfo) => {
        return `${userInfo?.firstName} ${userInfo?.lastName}`;
      }
    },
    {
      title: 'CONTACT',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return (
          <span key={record?.id}>{`${
            formatPhoneNumber(record?.userInfo?.phoneNo) || '-'
          }`}</span>
        );
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      className: 'max-width-column',
      width: 250,
      render: (text, record) => {
        return <span key={record?.id}>{`${record?.userInfo?.email}`}</span>;
      }
    },
    {
      title: 'ADDRESS',
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      ellipsis: true,
      render: (location) => {
        return `${location?.addressLine1} ${location?.city},  ${location?.state} ${location?.zipCode}`;
      }
    }
  ];
  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="CONTACT CREATION"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="contact-creation-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            onClick={() => setShowModal(false)}
            size="small"
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="common-button contact-modal-btn"
            onClick={onOk}
          >
            {isEdit ? 'Update Contact' : 'Add New Contact'}
          </Button>
        </div>
      }
    >
      <div className="title-section">
        <span>
          We have found some possible duplicate records as below, Are you still
          want to create new customer?
        </span>
      </div>
      <div className="common-table contact-table">
        <TableComponent
          fullHeight={false}
          columns={[...columns?.filter((item) => item !== false)]}
          data={duplicateData || []}
          rowKey="id"
        />
      </div>
    </ModalComponent>
  );
};

export default ContactModal;
