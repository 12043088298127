import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import PickerIcon from '../assets/colorpicker.svg';
import InputComponent from './InputComponent';
import './styles/ColorInputComponent.less';

const ColorInputComponent = (props) => {
  const {
    color,
    onChange,
    placeholder,
    disabled = false,
    externalStateUpdate,
    ...rest
  } = props;
  const [internalColor, setInternalColor] = useState(color);

  useEffect(() => {
    setInternalColor(color);
  }, [color, externalStateUpdate]);

  const handleChange = (newColor) => {
    setInternalColor(newColor?.hex);

    if (onChange) {
      onChange(newColor?.hex);
    }
  };

  const items = [
    {
      label: (
        <div>
          <ChromePicker
            color={internalColor}
            disableAlpha
            onChange={handleChange}
          />
        </div>
      ),
      key: 'COLOR_PICKER'
    }
  ];

  return (
    <InputComponent
      className="input-picker"
      value={internalColor || ''}
      placeholder={placeholder || 'Enter Color'}
      onChange={(e) => {
        setInternalColor(e?.target?.value);
        if (onChange) {
          onChange(e?.target?.value);
        }
      }}
      disabled={disabled}
      prefix={
        internalColor && (
          <div
            style={{
              background: internalColor
            }}
            className="preview-color"
          />
        )
      }
      suffix={
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          disabled={disabled}
          overlayClassName="color-picker-overlay"
        >
          <img src={PickerIcon} alt="picker-icon" className="picker" />
        </Dropdown>
      }
      {...rest}
    />
  );
};

export default ColorInputComponent;
