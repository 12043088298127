import { LeftOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider } from 'antd';
import React from 'react';
import DeleteIcon from '../../../../../../assets/delete-red.svg';
import './cartWidget.less';

const { Panel } = Collapse;

const MyCartWidgetComponent = ({ widgetConfig = null }) => {
  return (
    <div className="my-cart-wrapper">
      <div className="fill-width d-flex justify-between align-center">
        <span className="bold-label package-title-quote-variant">
          Package Title : Quote Variant
        </span>
        <span className="ml-16">
          <img
            className="contact-delete-icon"
            src={DeleteIcon}
            alt="delete-icon"
          />
        </span>
      </div>
      <div className="fill-width d-flex sub-title">Package description</div>
      <Collapse
        defaultActiveKey={['DETAILS']}
        className="cart-detail-collapse"
        bordered={false}
      >
        <Panel header="Details" key="DETAILS">
          <div className="d-flex flex-vertical align-start panel-content">
            <p>User Input 1: XXX</p>
            <p>User Input 2: XXX</p>
            <p>User Input 3: XXX</p>
          </div>
        </Panel>
      </Collapse>
      <div className="fill-width d-flex justify-between align-center change-quote-section">
        <div className="back-link">
          <LeftOutlined className="arrow" />
          <span>{widgetConfig?.changeQuoteButtonLabel}</span>
        </div>
        <div className="amount-section">$ X,XXX.XX</div>
      </div>
      <Divider className="cart-divider" />
      <div className="d-flex flex-vertical align-end total-amount-section">
        <span>Total</span>
        <span className="amount">$ X,XXX.XX</span>
      </div>
      <div>
        <Button className="common-button-cpq add-new-project ">
          {widgetConfig?.addProjectButtonLabel}
        </Button>
      </div>
      <Divider className="cart-divider" />
    </div>
  );
};

export default MyCartWidgetComponent;
