import { Card } from 'antd';
import React from 'react';

const NotificationList = () => {
  return (
    <Card>
      <div className="d-flex align-items-center justify-content-between">
        <h1>Notification</h1>
      </div>
    </Card>
  );
};

export default NotificationList;
