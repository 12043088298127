import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Form, Popover, Radio, Switch } from 'antd';
import React from 'react';
import InputComponent from '../../../../../../components/InputComponent';

const MapEntryResult = ({ handleChangeDefaultForOtherTabs }) => {
  return (
    <>
      <Form.Item
        label="Title"
        name={['widgetConfiguration', 'common', 'mapEntry', 'title']}
        rules={[
          {
            required: true,
            message: 'Please Enter Title'
          }
        ]}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'common', 'mapEntry', 'default']}
        valuePropName="checked"
      >
        <Radio
          className="common-radio"
          value="default"
          onChange={() => handleChangeDefaultForOtherTabs('MAP_ENTRY')}
        >
          Default
        </Radio>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">
          Map{' '}
          <Popover
            overlayClassName="pointer"
            content="This setting toggles the map on or off."
          >
            <QuestionCircleOutlined />
          </Popover>
        </span>
        <Form.Item
          name={['widgetConfiguration', 'common', 'mapEntry', 'mapCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        label="Action Button Label"
        name={[
          'widgetConfiguration',
          'common',
          'mapEntry',
          'actionButtonLabelText'
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Continue Button Text'
          }
        ]}
      >
        <InputComponent placeholder="Enter Continue Button Text" />
      </Form.Item>
    </>
  );
};

export default MapEntryResult;
