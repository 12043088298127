import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoCollapsed from '../../../assets/logo-collapsed.svg';
import { ReactComponent as AdministratorIcon } from '../../../assets/onboarding/administrator.svg';
import { ReactComponent as BusinessInfoIcon } from '../../../assets/onboarding/business-info.svg';
import { ReactComponent as PlanIcon } from '../../../assets/onboarding/plan.svg';
import { ReactComponent as SuccessPageIcon } from '../../../assets/onboarding/success-page.svg';
import { STEPS } from '../../../common/constants';

const { Step } = Steps;

const Signup = ({ children }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (location?.pathname?.includes('/administrator')) {
      setCurrent(0);
    }
    if (location?.pathname?.includes('/business-info')) {
      setCurrent(1);
    }
    if (location?.pathname?.includes('/plan')) {
      setCurrent(2);
    }
    if (location?.pathname?.includes('/success')) {
      setCurrent(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSrc = (
    index = null,
    src = '',
    selectedSrc = '',
    completedSrc = ''
  ) => {
    if (index === 0) {
      if (current === index) {
        return selectedSrc;
      }
      return completedSrc;
    }
    if (index === 3) {
      if (current === index) {
        return selectedSrc;
      }
      return src;
    }
    if (current > index) {
      return completedSrc;
    }
    if (current === index) {
      return selectedSrc;
    }
    return src;
  };

  const steps = [
    {
      title: `${STEPS?.ADMINISTRATOR}`,
      icon: setSrc(
        0,
        '',
        <AdministratorIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />
      )
    },
    {
      title: `${STEPS?.BUSINESS_INFORMATION}`,
      icon: setSrc(
        1,
        <BusinessInfoIcon className="without-selected-icon" />,
        <BusinessInfoIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />
      )
    },
    {
      title: `${STEPS?.PLAN}`,
      icon: setSrc(
        2,
        <PlanIcon className="without-selected-icon" />,
        <PlanIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />
      )
    },
    {
      title: `${STEPS?.SUCCESS}`,
      icon: setSrc(
        3,
        <SuccessPageIcon className="without-selected-icon" />,
        <SuccessPageIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />
      )
    }
  ];

  return (
    <>
      <div className="pt-30 process-step">
        <Row justify="center" align="middle">
          <Col
            xxl={{ span: 7, pull: 0 }}
            xl={{
              span: 10,
              pull: 0
            }}
            lg={{ span: 12, pull: 0 }}
            md={{ span: 16, pull: 0 }}
            sm={{ span: 16, pull: 0 }}
            xs={{ span: 16, pull: 0 }}
          >
            <div className="fill-width d-flex justify-center">
              <img src={logoCollapsed} alt="logo" className="signup-logo" />
            </div>
            <Steps labelPlacement="vertical" current={current}>
              {steps?.map((item) => (
                <Step
                  key={item?.title}
                  description={item?.title}
                  icon={item?.icon}
                />
              ))}
            </Steps>
          </Col>
        </Row>
      </div>
      <div className="signup-steps-content d-flex justify-center">
        <div className="onboard-flow">{children}</div>
      </div>
    </>
  );
};

export default Signup;
