import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  DYNAMIC_FIELDS_DATA,
  PERMISSION_DYNAMIC_FIELDS,
  ROUTES
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './dynamicFieldsModule.less';
import DynamicFieldsCreate from './pages/DynamicFieldsCreate';
import DynamicFieldsEdit from './pages/DynamicFieldsEdit';
import DynamicFieldsList from './pages/DynamicFieldsList';

const DynamicFieldsWrapper = () => {
  const [selectedDynamicKey, setSelectedDynamicKey] = useState(
    DYNAMIC_FIELDS_DATA?.[0]?.key
  );
  return (
    <div className="dynamic-fields-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.DYNAMIC_FIELDS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_DYNAMIC_FIELDS}
              showNoAccess
            >
              <DynamicFieldsList
                {...props}
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.DYNAMIC_FIELDS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_DYNAMIC_FIELD_CREATE']}
              showNoAccess
            >
              <DynamicFieldsCreate
                {...props}
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.DYNAMIC_FIELDS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_DYNAMIC_FIELD_UPDATE',
                'FET_DYNAMIC_FIELD_VIEW'
              ]}
              showNoAccess
            >
              <DynamicFieldsEdit
                {...props}
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default DynamicFieldsWrapper;
