import { Upload } from 'antd';
import React, { useState } from 'react';
import { getBase64 } from '../common/utils';
import PreviewModal from './PreviewModal';
import './styles/DraggerUploadComponent.less';

const { Dragger } = Upload;

const DraggerUploadComponent = (props) => {
  const { className = '', fileList = [], children, ...rest } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      fileObj?.name ||
        fileObj?.title ||
        fileObj?.url?.substring(fileObj?.url?.lastIndexOf('/') + 1)
    );
    setPreviewImage(fileObj?.url || preview);
  };

  return (
    <div className="common-upload">
      <PreviewModal
        previewImage={previewImage}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
      />
      <Dragger
        className={`${fileList?.length > 0 ? 'hide-border' : ''} ${className}`}
        fileList={fileList}
        listType="picture-card"
        beforeUpload={() => {
          return false; // For stopping the default upload action of antd
        }}
        onPreview={handlePreview}
        maxCount={1}
        {...rest}
      >
        {children}
      </Dragger>
    </div>
  );
};

export default DraggerUploadComponent;
