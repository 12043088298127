import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Empty, InputNumber, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import deleteIcon from '../../../../assets/delete-red.svg';
import SelectComponent from '../../../../components/SelectComponent';
import TableComponent from '../../../../components/TableComponent';

const { Option } = SelectComponent;
const SelectedProduct = ({
  selectedProduct,
  setSelectedProduct,
  removeSelectedProduct,
  setIsFormValuesChanged
}) => {
  const [showPopConfirmCheckBtn, setShowPopConfirmCheckBtn] = useState(false);
  const location = useLocation();

  const handleChangeQualityValue = (value, index, type) => {
    setShowPopConfirmCheckBtn(true);
    if (!value) {
      return;
    }
    const dataCopy = [...selectedProduct];
    if (type === 'qualities') {
      dataCopy[index].tempQuality = value;
    } else if (type === 'min') {
      dataCopy[index].tempMin = value;
    } else if (type === 'max') {
      dataCopy[index].tempMax = value;
    }
    setSelectedProduct(dataCopy);
  };

  const handleChangeQuality = (value, index, type) => {
    if (!value) {
      return;
    }
    setShowPopConfirmCheckBtn(false);
    const dataCopy = [...selectedProduct];
    if (type === 'qualities') {
      dataCopy[index].editable.qualities = false;
      dataCopy[index].qualities = value?.tempQuality;
    } else if (type === 'min') {
      dataCopy[index].editable.min = false;
      dataCopy[index].min = value?.tempMin;
    } else if (type === 'max') {
      dataCopy[index].editable.max = false;
      dataCopy[index].max = value?.tempMax;
    }

    setIsFormValuesChanged(true);
    setSelectedProduct(dataCopy);
  };

  const handleCloseEdit = (record, index, type) => {
    setShowPopConfirmCheckBtn(false);
    const dataCopy = [...selectedProduct];
    if (type === 'qualities') {
      dataCopy[index].editable.qualities = false;
      dataCopy[index].tempQuality = record?.qualities;
    } else if (type === 'min') {
      dataCopy[index].editable.min = false;
      dataCopy[index].tempMin = record?.min;
    } else if (type === 'max') {
      dataCopy[index].editable.max = false;
      dataCopy[index].tempMax = record?.max;
    }
    setSelectedProduct(dataCopy);
  };

  const handleEdit = (index, type) => {
    const dataCopy = [...selectedProduct];
    dataCopy[index].editable = { [type]: true };
    if (type === 'qualities') {
      dataCopy[index].tempQuality = dataCopy?.[index]?.qualities;
    } else if (type === 'min') {
      dataCopy[index].tempMin = dataCopy?.[index]?.min;
    } else if (type === 'max') {
      dataCopy[index].tempMax = dataCopy?.[index]?.max;
    }
    setSelectedProduct(dataCopy);
  };

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      fixed: 'left'
    },
    {
      title: 'PRODUCT',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      fixed: 'left'
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      ellipsis: true,
      render: (industryId, record = {}) => {
        return <span>{record?.industry?.label}</span>;
      }
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      width: 190,
      render: (lineOfBusinessId, record = {}) => (
        <div>{record?.lineOfBusiness?.label}</div>
      )
    },
    {
      title: 'MIN',
      dataIndex: 'min',
      key: 'min',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (min = 1, record, index) => {
        const { max } = record;
        if (!record?.editable?.min) {
          return (
            <div>
              <span className="quantity-text">{min}</span>
              <EditOutlined
                className="edit-icon"
                onClick={() => handleEdit(index, 'min')}
              />
            </div>
          );
        }
        return (
          <div>
            <InputNumber
              type="number"
              className="quantity-component"
              value={record?.tempMin}
              onChange={(value) =>
                handleChangeQualityValue(value, index, 'min')
              }
              min={1}
              max={max}
              precision={0}
            />
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempMin < 1}
                className="check-icon"
                onClick={() => handleChangeQuality(record, index, 'min')}
              />
            ) : (
              <Popconfirm
                title="This change will affect the add on product's min quantity; do you like to save these changes?"
                onConfirm={() => handleChangeQuality(record, index, 'min')}
                onCancel={() => handleCloseEdit(record, index, 'min')}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempMin < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseEdit(record, index, 'min')}
            />
          </div>
        );
      }
    },
    {
      title: 'MAX',
      dataIndex: 'max',
      key: 'max',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (max = 1, record, index) => {
        const { min } = record;
        if (!record?.editable?.max) {
          return (
            <div>
              <span className="quantity-text">{max}</span>
              <EditOutlined
                className="edit-icon"
                onClick={() => handleEdit(index, 'max')}
              />
            </div>
          );
        }
        return (
          <div>
            <InputNumber
              type="number"
              className="quantity-component"
              value={record?.tempMax}
              onChange={(value) =>
                handleChangeQualityValue(value, index, 'max')
              }
              min={min}
              precision={0}
            />
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempMax < 1}
                className="check-icon"
                onClick={() => handleChangeQuality(record, index, 'max')}
              />
            ) : (
              <Popconfirm
                title="This change will affect the add on product's max quantity; do you like to save these changes?"
                onConfirm={() => handleChangeQuality(record, index, 'max')}
                onCancel={() => handleCloseEdit(record, index, 'max')}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempMax < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseEdit(record, index, 'max')}
            />
          </div>
        );
      }
    },
    {
      title: 'QUALITY',
      dataIndex: 'qualities',
      key: 'qualities',
      ellipsis: true,
      width: 130,
      render: (qualities, record, index) => {
        if (!record?.editable?.qualities) {
          return (
            <div>
              <span className="quantity-text">{qualities?.join(',')}</span>
              <EditOutlined
                className="edit-icon"
                onClick={() => handleEdit(index, 'qualities')}
              />
            </div>
          );
        }

        return (
          <div className="dynamic-render-select">
            <SelectComponent
              mode="multiple"
              allowClear={false}
              placeholder="Select Qualities"
              value={record?.tempQuality}
              notFoundContent={<Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
              onChange={(e) => handleChangeQualityValue(e, index, 'qualities')}
              getPopupContainer={null}
              popupClassName="add-on-table-select"
            >
              <Option key="GOOD" value="GOOD">
                Good
              </Option>
              <Option key="BETTER" value="BETTER">
                Better
              </Option>
              <Option key="BEST" value="BEST">
                Best
              </Option>
            </SelectComponent>
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempQuality < 1}
                className="check-icon"
                onClick={() => handleChangeQuality(record, index, 'qualities')}
              />
            ) : (
              <Popconfirm
                title="This change will affect the add on product's quality; do you like to save these changes?"
                onConfirm={() =>
                  handleChangeQuality(record, index, 'qualities')
                }
                onCancel={() => handleCloseEdit(record, index, 'qualities')}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempQuality < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseEdit(record, index, 'qualities')}
            />
          </div>
        );
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              removeSelectedProduct(record);
            }}
            okText="Yes"
            cancelText="No"
          >
            <img
              src={deleteIcon}
              alt="delete-icon"
              className={
                !location?.pathname?.includes('/view')
                  ? 'product-item-delete pointer'
                  : 'display-none'
              }
            />
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <h5>Selected Product</h5>
      <div className="common-table product-item-table suggested-product">
        <TableComponent
          columns={[...columns?.filter((item) => item !== false)]}
          fullHeight={false}
          scroll={{ x: 'max-content' }}
          data={selectedProduct || []}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </div>
  );
};

export default SelectedProduct;
