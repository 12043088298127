import { Input } from 'antd';
import React from 'react';
import './styles/NumberComponent.less';

const NumberComponent = (props) => {
  const { name = '', className = '', min = 0, ...rest } = props;

  return (
    <Input
      className={`number-component-cpq ${className}`}
      type="number"
      allowClear
      placeholder={`Enter ${name || ''}`}
      min={min}
      {...rest}
    />
  );
};

export default NumberComponent;
