import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import './authModule.less';
import { TOKEN_VALIDATION } from './graphql/Mutations';

const VerifyEmail = (props) => {
  const {
    getToken,
    initializeAuth,
    state: { currentUser }
  } = useContext(AppContext);

  const { history, history: { location: { search } = {} } = {} } = props;

  const getQuery = () => {
    return new URLSearchParams(search);
  };

  const token = getQuery()?.get('auth_token');
  const id = getQuery()?.get('uid');

  const [tokenValidationMutate] = useMutation(TOKEN_VALIDATION, {
    async onCompleted() {
      const accessToken = getToken();
      if (accessToken) {
        await initializeAuth(accessToken, {
          ...currentUser,
          emailVerified: true
        });
      }
      history?.replace(ROUTES?.MAIN);
    },
    onError() {
      history?.push(ROUTES?.MAIN);
    }
  });

  useEffect(() => {
    if (token && id) {
      tokenValidationMutate({
        variables: {
          data: {
            id,
            token,
            emailVerification: true
          }
        }
      });
    } else {
      history?.replace(ROUTES?.MAIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderComponent />;
};

export default VerifyEmail;
