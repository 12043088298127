import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

const PanelHeader = ({
  title = '',
  formSelectedKey = '',
  previewSelectedKey = '',
  setPreviewSelectedKey
}) => {
  const handleClickPreview = (e, selectedKey) => {
    e?.stopPropagation();
    if (setPreviewSelectedKey) {
      setPreviewSelectedKey(selectedKey);
    }
  };

  return (
    <div className="d-flex flex-vertical align-start">
      <span>{title}</span>
      <Button
        onClick={(e) => handleClickPreview(e, formSelectedKey)}
        className="pl-0"
        type="link"
        icon={formSelectedKey !== previewSelectedKey && <EyeOutlined />}
      >
        {formSelectedKey === previewSelectedKey
          ? 'In Preview'
          : 'Show in Preview'}
      </Button>
    </div>
  );
};

export default PanelHeader;
