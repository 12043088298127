import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';
import InputComponent from '../../../../../../../../components/InputComponent';

const SystemNumberFieldForm = ({ form }) => {
  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'toolTipCheck'],
    form
  );

  return (
    <div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Required</span>
        <Form.Item
          name={['widgetConfiguration', 'required']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'label']}
        label="Label"
        rules={[
          {
            required: true,
            message: 'Please Enter Label'
          }
        ]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'toolTipCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'toolTipText']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value'
          }
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
    </div>
  );
};

export default SystemNumberFieldForm;
