import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined
} from '@ant-design/icons';
import { Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getBase64, getPublicUrl } from '../../../common/utils';
import PreviewModal from '../../../components/PreviewModal';
import './RenderUploadPreview.less';

function RenderUploadPreview({ item = [] }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [doctype, setDocType] = useState('');

  const getPublicImages = async (imageArray) => {
    const images = await getPublicUrl({ logoObject: imageArray });
    setFileList(images);
    if (isArray(images) && images?.length > 0) {
      if (
        ['.jpg', '.jpeg', '.png', '.gif']?.includes(
          images?.[0]?.extension?.toLowerCase()
        )
      ) {
        setDocType('IMAGE');
      }
    }
    return images;
  };

  useEffect(() => {
    if (item?.value?.filter((doc) => doc)?.length > 0) {
      if (isArray(item?.value) && item?.value?.length > 0) {
        getPublicImages(item?.value);
      }
      if (item?.doctype) {
        setDocType(item?.doctype);
      }
    }
  }, [item]);

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      fileObj?.name ||
        fileObj?.label ||
        fileObj?.url?.substring(fileObj?.url?.lastIndexOf('/') + 1)
    );
    setPreviewImage(fileObj?.url || preview);
  };

  const customOrigin = (originNode, file) => {
    const {
      props: nodeProps,
      props: { children }
    } = originNode;
    let ext;
    if (file?.name) {
      ext = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    } else if (file?.fileList?.length > 0) {
      ext = file?.fileList
        ?.map((items) => items?.uid === file?.file?.uid)?.[0]
        ?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    } else {
      ext = '';
    }

    const newChildren = [children?.[1], children?.[2]];
    let icon = '';

    switch (ext) {
      case 'xlsx':
      case 'xls':
      case 'csv':
        icon = <FileExcelOutlined className="file-type" />;
        break;
      case 'pdf':
        icon = <FilePdfOutlined className="file-type" />;
        break;
      case 'ppt':
      case 'pptx':
        icon = <FilePptOutlined className="file-type" />;
        break;
      case 'doc':
      case 'docx':
        icon = <FileWordOutlined className="file-type" />;
        break;
      case 'txt':
        icon = <FileTextOutlined className="file-type" />;
        break;
      case 'mp4':
        icon = <FileOutlined className="file-type" />;
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return originNode;
      default:
        icon = <FileOutlined className="file-type" />;
        break;
    }

    return (
      <div className={nodeProps?.className}>
        <div className="ant-upload-list-item-info">{icon}</div>
        {newChildren}
      </div>
    );
  };

  return (
    <div className="product-item-detail">
      {fileList?.length > 0 ? (
        <Upload
          showUploadList={{
            showDownloadIcon: false,
            showPreviewIcon: true,
            showRemoveIcon: false
          }}
          listType="picture-card"
          disabled
          onPreview={doctype === 'IMAGE' && handlePreview}
          fileList={fileList}
          itemRender={(originNode, file) => (
            <div>
              {customOrigin(originNode, file)}
              <div title={file?.label} className="render-upload-label">
                {file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label}
              </div>
            </div>
          )}
        />
      ) : (
        '-'
      )}

      <PreviewModal
        previewImage={previewImage}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
      />
    </div>
  );
}

export default RenderUploadPreview;
