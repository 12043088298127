import { Card } from 'antd';
import React from 'react';
import LineOfBusinessDetails from '../components/LineOfBusinessDetails';
import SubAreaTable from '../components/SubAreaTable';

const LineOfBusinessView = () => {
  return (
    <div className="split-screen-section">
      <Card className="mb-24 semi-height-card card-body-padding">
        <LineOfBusinessDetails />
      </Card>
      <Card className="semi-height-card card-body-padding">
        <SubAreaTable />
      </Card>
    </div>
  );
};

export default LineOfBusinessView;
