import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PropertyDetails from '../components/PropertyDetails';
import CardWrapper from '../components/PropertyTabs';
import { GET_PROPERTY } from '../graphql/Queries';

function PropertyView(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);

  const { data: { property } = {} } = useQuery(GET_PROPERTY, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  if (!id) {
    return <Redirect to={ROUTES?.PROPERTIES} />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROPERTIES} />
          {property && <span className="portal-header">{property?.name}</span>}
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<img src={EditIcon} alt="save-icon" width={12} />}
          size="small"
          type="primary"
          onClick={() => history?.push(`${ROUTES?.PROPERTIES}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <PropertyDetails data={property} />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(PropertyView);
