import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_CATEGORY, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductCategoryCreate from './pages/ProductCategoryCreate';
import ProductCategoryEdit from './pages/ProductCategoryEdit';
import ProductCategoryList from './pages/ProductCategoryList';
import './productCategoryModule.less';

const ProductCategoryWrapper = () => {
  return (
    <div className="product-category-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PRODUCT_CATEGORIES}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_CATEGORY}
              showNoAccess
            >
              <ProductCategoryList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCT_CATEGORIES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_CATEGORY_CREATE']}
              showNoAccess
            >
              <ProductCategoryCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCT_CATEGORIES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_PRODUCT_CATEGORY_UPDATE',
                'FET_PRODUCT_CATEGORY_VIEW'
              ]}
              showNoAccess
            >
              <ProductCategoryEdit {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ProductCategoryWrapper;
