export const pricingOperationOptions = [
  { label: 'Add', value: 'ADD' },
  { label: 'Subtract', value: 'SUB' }
];

export const pricingValueTypeOptions = [
  { label: '$', value: '$' },
  { label: '%', value: '%' }
];

export const modifierOnOptions = [
  { label: 'Base Product', value: 'BASE' },
  { label: 'Base Package and Add-Ons', value: 'PREV' }
];

export const addItemAsOptions = [
  { label: 'Add to Base Package', value: 'MAIN' },
  { label: 'Add as Separate Line Item', value: 'OTHER' }
];

export const billingAsOptions = [
  { label: 'Billing as Product', value: 'PRODUCT' },
  { label: 'Billing as Service', value: 'SERVICE' }
];
