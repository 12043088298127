import { LeftOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Divider } from 'antd';
import React from 'react';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';

const { Panel } = Collapse;

const ContactWidgetComponent = ({ widgetConfig = null }) => {
  return (
    <div className="contact-page-wrapper">
      <div className="d-flex flex-vertical">
        <span className="bold-label package-title-quote-variant">
          Package Title: Quote Variant
        </span>
        <span className="sub-title">Package Description</span>
      </div>
      {widgetConfig?.orderSettings?.cartSummary && (
        <>
          <Collapse
            defaultActiveKey={['DETAILS']}
            className="contact-detail-collapse"
            bordered={false}
          >
            <Panel header="Details" key="DETAILS">
              <div className="d-flex flex-vertical align-start panel-content">
                <p>User Input 1: XXX</p>
                <p>User Input 2: XXX</p>
                <p>User Input 3: XXX</p>
              </div>
            </Panel>
          </Collapse>
          <Divider className="contact-divider" />
        </>
      )}
      <div className="fill-width d-flex justify-between align-center change-quote-section">
        <div className="back-link">
          <LeftOutlined className="arrow" />
          <span>Change Quote</span>
        </div>
        <div className="amount-section">$ X,XXX.XX</div>
      </div>
      <div className="form-section fill-width">
        <span className="optional-comment">Optional comments</span>

        <InputComponent
          readOnly
          placeholder={
            widgetConfig?.contactFromSettings?.optionalComments?.labelText
          }
          className="mt-30"
        />

        {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelCheck ||
        widgetConfig?.contactFromSettings
          ?.sendConfirmationEmailCheckboxCheck ? (
          <div className="fill-width mt-30 d-flex justify-center flex-vertical">
            {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelCheck && (
              <Checkbox
                checked={
                  widgetConfig?.contactFromSettings
                    ?.urgencyCheckboxLabelSelected
                }
                className="mt-20 common-checkbox"
              >
                {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelText}
              </Checkbox>
            )}
            {widgetConfig?.contactFromSettings
              ?.sendConfirmationEmailCheckboxCheck && (
              <Checkbox
                checked={
                  widgetConfig?.contactFromSettings
                    ?.sendConfirmationEmailCheckboxSelected
                }
                className={`${
                  widgetConfig?.contactFromSettings
                    ?.urgencyCheckboxLabelCheck && `mt-20`
                } common-checkbox`}
              >
                {
                  widgetConfig?.contactFromSettings
                    ?.sendConfirmationEmailCheckboxText
                }
              </Checkbox>
            )}
          </div>
        ) : null}
        <div>
          <InputComponent
            readOnly
            placeholder={` ${widgetConfig?.contactFromSettings?.name?.label} ${
              widgetConfig?.contactFromSettings?.name?.required ? '*' : ''
            }`}
            className="mt-15"
          />
          <InputComponent
            readOnly
            placeholder={` ${widgetConfig?.contactFromSettings?.email?.labelText} *`}
            className="mt-15"
          />
          <InputComponent
            readOnly
            placeholder={` ${widgetConfig?.contactFromSettings?.phone?.labelText} * `}
            className="mt-15"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactWidgetComponent;
