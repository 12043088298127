import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import BrandDetails from '../components/BrandDetails';
import { GET_BRAND } from '../graphql/Queries';

const BrandsView = (props) => {
  const {
    match: { params: { id } = {} } = {},
    history = null,
    location = null
  } = props;

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: { brand } = {} } = useQuery(GET_BRAND, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.brand?.logo?.url) {
        setLogoUrl({
          url: res?.brand?.logo?.url,
          title: res?.brand?.logo?.url?.split('_')?.[1]
        });
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (!id) {
    return <Redirect to={ROUTES?.RETAILERS} />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          {brand && <span className="portal-header">{brand?.name}</span>}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_BRAND_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="brand-table-save-btn"
            type="primary"
            onClick={() =>
              history?.push(`${ROUTES?.RETAILERS}/edit/${id}`, {
                ...location?.state
              })
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <BrandDetails data={brand} logoUrl={logoUrl} />
      )}
    </Card>
  );
};

export default withRouter(BrandsView);
