import { gql } from '@apollo/client';

export const GET_PROPERTIES = gql`
  query properties($filter: PropertyFilter!, $where: PropertyWhereFilter) {
    properties(filter: $filter, where: $where) {
      count
      data {
        id
        name
        builtYear
        isActive
        propertyValue
        floor
        historyDistrict
        elevator
        internalSqFt
        lotSqFt
        cornerLot
        lotsFrontWidth
        lotsRightWidth
        lotsLeftWidth
        lotsBackWidth
        parking
        taxParcel
        pinNumber
        centralAir
        centralHeat
        heatSource
        noBaths
        noHalfBaths
        propertyLastConfirmed
        lockBoxCode
        propertyManagementCompany
        propertyManagerPhone
        thirdPartyProvider
        propertyType
        propertyFinishedBasement
        propertyFinishedCeiling
        tenantId
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

export const GET_PROPERTY = gql`
  query property($id: ID!) {
    property(where: { id: $id }) {
      id
      name
      builtYear
      isActive
      propertyValue
      floor
      historyDistrict
      elevator
      internalSqFt
      lotSqFt
      cornerLot
      lotsFrontWidth
      lotsRightWidth
      lotsLeftWidth
      lotsBackWidth
      parking
      taxParcel
      pinNumber
      centralAir
      centralHeat
      heatSource
      heatSourceData {
        label
      }
      waterHookUp
      waterHookUpData {
        label
      }
      noBaths
      noHalfBaths
      propertyLastConfirmed
      lockBoxCode
      propertyManagementCompany
      propertyManagerPhone
      thirdPartyProvider
      propertyType
      propertyTypeData {
        label
      }
      propertyFinishedBasement
      propertyFinishedBasementData {
        label
      }
      propertyFinishedCeiling
      propertyFinishedCeilingData {
        label
      }
      tenantId
      location {
        addressLine1
        city
        state
        country
        zipCode
      }
    }
  }
`;

export const PROPERTY_FILTER = gql`
  query propertyFilters($filter: PropertyFilter!) {
    propertyFilters(filter: $filter) {
      count
      data {
        name
        builtYear
        isActive
        propertyValue
        floor
        historyDistrict
        elevator
        internalSqFt
        lotSqFt
        cornerLot
        lotsFrontWidth
        lotsRightWidth
        lotsLeftWidth
        lotsBackWidth
        parking
        taxParcel
        pinNumber
        centralAir
        centralHeat
        heatSource
        waterHookUp
        noBaths
        noHalfBaths
        propertyLastConfirmed
        lockBoxCode
        propertyManagementCompany
        propertyManagerPhone
        thirdPartyProvider
        propertyType
        propertyFinishedBasement
        propertyFinishedCeiling
        tenantId
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

export const PROPERTY_TYPES_FILTER = gql`
  query propertyTypes(
    $filter: PropertyTypeFilter!
    $where: PropertyTypeWhereFilter
  ) {
    propertyTypes(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const PROPERTY_HEAT_SOURCES = gql`
  query propertyHeatSources(
    $filter: PropertyHeatSourceFilter!
    $where: PropertyHeatSourceWhereFilter
  ) {
    propertyHeatSources(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const PROPERTY_WATER_HOOKUPS = gql`
  query propertyWaterHookUps(
    $filter: PropertyWaterHookUpFilter!
    $where: PropertyWaterHookUpWhereFilter
  ) {
    propertyWaterHookUps(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const PROPERTY_FINISHED_BASEMENTS = gql`
  query propertyFinishedBasements(
    $filter: PropertyFinishedBasementFilter!
    $where: PropertyFinishedBasementWhereFilter
  ) {
    propertyFinishedBasements(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const PROPERTY_FINISHED_BASEMENTS_CEILINGS = gql`
  query propertyFinishedBasementCeilings(
    $filter: PropertyFinishedBasementCeilingFilter!
    $where: PropertyFinishedBasementCeilingWhereFilter
  ) {
    propertyFinishedBasementCeilings(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query contacts(
    $filter: ContactFilter!
    $where: ContactWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    contacts(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        homePhone
        mainPhone
        userInfo {
          firstName
          lastName
          email
          phoneNo
        }
        location {
          addressLine1
          city
          state
          zipCode
        }
        primaryContact
        authorizedServiceContact
        web
        isActive
        preferredContact
        contactType
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query customers(
    $filter: CustomerFilter!
    $where: CustomerWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    customers(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        user {
          id
          firstName
          lastName
          isActive
          phoneNo
          email
        }
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
        customerType
        fax
        userId
        tollFree
        web
        csmId
        salesManagerId
        technician
        industry
        descriptionBusiness
        isActive
        tenantId
      }
    }
  }
`;
