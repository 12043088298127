import Icon from '@ant-design/icons';
import React from 'react';

const checkedIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM14.293 7.29297L9.00008 12.5859L6.70718 10.293L5.29297 11.7072L9.00008 15.4143L15.7072 8.70718L14.293 7.29297Z"
        fill="currentColor"
      />
    </svg>
  );
};

const CheckedIconComponent = (props) => (
  <Icon component={checkedIcon} {...props} />
);

export default CheckedIconComponent;
