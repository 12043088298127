import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_DASHBOARD, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './dashboard.less';
import DashboardPage from './pages/DashboardPage';

const DashboardWrapper = () => {
  return (
    <div className="dashboard-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.DASHBOARD}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_DASHBOARD}
              showNoAccess
            >
              <DashboardPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default DashboardWrapper;
