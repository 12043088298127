import { LineChartOutlined } from '@ant-design/icons';
import React from 'react';

const NoChartData = () => {
  return (
    <div className="no-chart-data">
      <LineChartOutlined className="no-data-icon" />
      <span className="no-data-text">No Data</span>
      <span className="no-data-description">
        The display of data for analysis occurs after the collection of the
        first data as a response from customers
      </span>
    </div>
  );
};

export default NoChartData;
