import { gql } from '@apollo/client';

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($where: CampaignWhereInput!, $data: CampaignInput!) {
    updateCampaign(where: $where, data: $data) {
      data {
        name
        type
        status
        code
        id
        industryId
        lineOfBusinessId
        subAreaId
      }
      status
      message
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($data: CampaignInput!) {
    createCampaign(data: $data) {
      data {
        name
        type
        status
        code
        id
        industryId
        lineOfBusinessId
        subAreaId
      }
      status
      message
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($where: CampaignWhereInput!) {
    deleteCampaign(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
