import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, isEmpty, map, omit, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../assets/filter.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, strippedString } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import { UPDATE_INDUSTRY } from '../graphql/Mutation';
import { GET_INDUSTRIES, INDUSTRY_FILTER } from '../graphql/Queries';

let scrollDebounce = null;

const IndustryTable = () => {
  const {
    state: { pageSize, filterData, permissions }
  } = useContext(AppContext);
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const initialIndustryFilter = {
    skip: 0,
    limit: pageSize
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [industryFilter, setIndustryFilter] = useState(initialIndustryFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [filters, setFilters] = useState(filterData);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [searchValue, setSearchValue] = useState(null);

  const [fetchIndustries, { data, loading }] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.industries?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [updateIndustry] = useMutation(UPDATE_INDUSTRY, {
    onError() {}
  });

  useEffect(() => {
    const whereFilter = history?.location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    fetchIndustries({
      variables: {
        filter: parsedObject?.industryFilter ?? industryFilter,
        ...(parsedObject && { where: parsedObject?.filters })
      }
    });
    if (!isEmpty(parsedObject)) {
      setFilters({ ...parsedObject?.filters });
      setFiltersCopyState({
        ...parsedObject?.filters
      });

      setSearchValue(parsedObject?.industryFilter?.search);
      const sorter = {
        order:
          parsedObject?.industryFilter?.sortBy === 'ASC' ? 'ascend' : 'descend',
        columnKey: parsedObject?.industryFilter?.sortOn
      };
      setSortedInfo({ ...sorter });
      setIndustryFilter({ ...parsedObject?.industryFilter });
      setPaginationProp({ ...parsedObject?.paginationProp });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.INDUSTRIES)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  useEffect(() => {
    if (filterVisible) {
      setFilterLoading(true);
      setFilterList([]);
      switch (filterIndex) {
        default:
          industryFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleStatus = async (editIndustryData) => {
    const editData = {
      ...editIndustryData,
      isActive: !editIndustryData?.isActive,
      logo: { ...editIndustryData?.logo, __typename: undefined }
    };
    const dataToSend = omit(editData, [
      'referenceId',
      'tenantId',
      'industryCode',
      'refData',
      'id',
      'key',
      '__typename'
    ]);
    const response = await updateIndustry({
      variables: {
        data: dataToSend,
        where: {
          id: editIndustryData?.id
        }
      }
    });
    if (response?.data?.updateIndustry) {
      fetchIndustries({
        variables: {
          filter: industryFilter,
          ...(filterData && { where: filterData })
        }
      });
    }
  };

  const handleIndustryActions = (record, operation = 'edit') => {
    const otherFilters = { industryFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    history?.push(`${ROUTES?.INDUSTRIES}/${operation}/${record?.id}`, {
      stringifyObject
    });
  };

  const renderActionButtons = (recordData) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl allowedPermissions={['FET_INDUSTRY_UPDATE']}>
          <Button
            id="industry-table-edit-btn"
            className="b-0"
            onClick={() => handleIndustryActions(recordData, 'edit')}
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_INDUSTRY_VIEW']}>
          <Button
            id="industry-table-view-btn"
            className="b-0"
            onClick={() => handleIndustryActions(recordData, 'view')}
          >
            View
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_INDUSTRY_UPDATE']}>
          <Popconfirm
            title={`Are you sure to ${
              recordData?.isActive ? 'Mark Inactive' : 'Mark Active'
            }?`}
            onConfirm={() => handleStatus(recordData)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="fuelSource-table-status-btn" className="b-0">
              {recordData?.isActive ? 'Mark Inactive' : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setIndustryFilter({
        ...industryFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchIndustries({
        variables: {
          filter: {
            ...industryFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setIndustryFilter({
        ...industryFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'id',
        sortBy: 'DESC'
      });
      fetchIndustries({
        variables: {
          filter: {
            ...industryFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'id',
            sortBy: 'DESC'
          }
        }
      });
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(trim(value));
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        industryFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: trim(value),
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchIndustries({
      variables: {
        filter: {
          ...industryFilter,
          skip: 0,
          sortOn: 'id',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setIndustryFilter({
      ...industryFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const getFilterData = (confirm) => {
    fetchIndustries({
      variables: {
        filter: { ...industryFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setIndustryFilter({
      ...industryFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            industryFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    {
      title: 'INDUSTRIES',
      key: 'label',
      dataIndex: 'label',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'label' && sortedInfo?.order,
      ...filterPopup('label'),
      width: 250
    },
    {
      title: 'DESCRIPTION',
      key: 'description',
      dataIndex: 'description',
      className: 'max-width-column',
      ellipsis: true,
      render: (description = '') => {
        return strippedString(description) || '-';
      }
    },
    {
      title: 'STATUS',
      key: 'isActive',
      dataIndex: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      ...filterPopup('isActive'),
      width: 120,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    checkPermissions(permissions, [
      'FET_INDUSTRY_UPDATE',
      'FET_INDUSTRY_VIEW'
    ]) && {
      dataIndex: 'id',
      align: 'center',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setIndustryFilter({
      ...industryFilter,
      skip: value ? 0 : industryFilter?.limit * (paginationProp?.current - 1),
      search: trim(value)
    });
    fetchIndustries({
      variables: {
        filter: {
          ...industryFilter,
          skip: value
            ? 0
            : industryFilter?.limit * (paginationProp?.current - 1),
          search: trim(value)
        },
        ...(filters && { where: filters })
      }
    });
  };

  return (
    <div>
      <AccessControl allowedPermissions={['FET_INDUSTRY_LIST']} showNoAccess>
        <div className="fill-width search-checkbox mb-16">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Industry name or other detail..."
            name="Industry"
            getData={onSearchChange}
            value={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading}
              data={data?.industries?.data || []}
              columns={[...columns?.filter((item) => item !== false)]}
              rowKey={(obj) => obj?.id}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default IndustryTable;
