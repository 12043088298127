import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import { ROUTES } from '../../common/constants';
import AddLogo from './components/AddLogo';
import ColorPalette from './components/ColorPalette';
import Industries from './components/Industries';
import InviteTeam from './components/InviteTeam';
import Region from './components/Region';
import SuccessPage from './components/SuccessPage';
import './onboarding.less';

const OnBoardingWrapper = () => {
  return (
    <div className="steps">
      <Switch>
        <PrivateRoute
          exact
          path={`${ROUTES?.ONBOARDING}`}
          render={() => <Redirect to={`${ROUTES?.ONBOARDING}/add-logo`} />}
        />
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/add-logo`}
          component={AddLogo}
        />
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/color-palette`}
          component={ColorPalette}
        />
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/industry`}
          component={Industries}
        />
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/region`}
          component={Region}
        />
        {/* currently we are no showing package screen in onboarding flow, but kept this code for future use.check  https://app.clickup.com/t/606124/DRF-7106 */}
        {/* <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/packages`}
          component={Products}
        /> */}
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/invite`}
          component={InviteTeam}
        />
        <PrivateRoute
          path={`${ROUTES?.ONBOARDING}/success`}
          component={SuccessPage}
        />
      </Switch>
    </div>
  );
};

export default OnBoardingWrapper;
