import { gql } from '@apollo/client';

export const GENERATE_API_TOKEN = gql`
  mutation generateRestApiToken {
    generateRestApiToken {
      message
    }
  }
`;

export const ACTIVE_DEACTIVE_SERVICE = gql`
  mutation activeInactiveRestApiService(
    $data: activeInactiveRestApiServiceInput!
  ) {
    activeInactiveRestApiService(data: $data) {
      message
    }
  }
`;
