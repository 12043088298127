import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import areaImage from '../../../../../../assets/images/area.png';
import ModalComponent from '../../../previewComponents/globalComponents/ModalComponent';
import './dimensionWidget.less';

const HighlightYourAreaModal = ({
  showHightLightYourAreaModal = false,
  setShowHightLightYourAreaModal,
  widgetConfig = null,
  tenantLogo = null
}) => {
  return (
    <ModalComponent
      width={600}
      open={showHightLightYourAreaModal}
      setOpen={setShowHightLightYourAreaModal}
      onCancel={() => setShowHightLightYourAreaModal(false)}
      footer={null}
      destroyOnClose
      wrapClassName="highlight-your-area-modal"
      getContainer="#centerContent"
      closable={false}
      keyboard={false}
    >
      <div className="parent-div">
        <div className="area-show top-section d-flex flex-vertical justify-center align-center">
          <div className="dimension-logo-section">
            <img src={tenantLogo} alt="tenant" />
          </div>
          <div className="sub-title-text">{widgetConfig?.title}</div>
          {widgetConfig?.descriptionCheck && (
            <div
              className="description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: widgetConfig?.descriptionText || '-'
              }}
            />
          )}
        </div>
        <img src={areaImage} alt="area" className="area-image" />
        <div className="area-show bottom-section d-flex flex-vertical justify-center align-center">
          <div className="d-flex flex-vertical text-center fill-width">
            <span className="hint">Hint :&nbsp;{widgetConfig?.hint}</span>
            <span className="area-label">{widgetConfig?.resultLabel}</span>
            <span className="area-value">Total Area 342 Sq/ft</span>
            <div className="d-flex justify-between align-center">
              <Button
                className="common-button-cpq action-buttons"
                type="primary"
              >
                {widgetConfig?.actionButtonLabel}
              </Button>
              <Button className="common-button-cpq action-buttons">
                Clear
              </Button>
            </div>
          </div>
          <div className="measurement-tip-link">
            {widgetConfig?.tipsButtonLabel}
          </div>
          <div className="d-flex justify-center">
            <Button icon={<LeftOutlined />} className="back-btn">
              Back
            </Button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default HighlightYourAreaModal;
