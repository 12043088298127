import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_PRICING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductPricingList from './pages/ProductPricingList';
import './productPricingModule.less';

const ProductPricingWrapper = () => {
  return (
    <div className="product-pricing-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PRODUCT_PRICING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_PRICING}
              showNoAccess
            >
              <ProductPricingList {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ProductPricingWrapper;
