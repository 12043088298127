import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import {
  formatPhoneNumberWithoutMask,
  uploadFile
} from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import ManufacturerForm from '../components/ManufacturerForm';
import { CREATE_MANUFACTURER } from '../graphql/Mutations';

function ManufacturerCreate(props) {
  const { history = null, location = null } = props;
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [createManufacturerMutate] = useMutation(CREATE_MANUFACTURER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = async (formValues) => {
    const uuid = uuidv4();
    let logo = null;
    setSubmitLoading(true);
    let locationObj;
    if (
      formValues?.addressLine1 ||
      formValues?.city ||
      formValues?.state ||
      formValues?.country ||
      formValues?.zipCode
    ) {
      locationObj = {
        addressLine1: formValues?.addressLine1 || null,
        city: formValues?.city || null,
        state: formValues?.state || null,
        country: formValues?.country || null,
        zipCode: formValues?.zipCode || null
      };
    }

    if (formValues?.logo) {
      logo = await uploadFile({
        logoObject: formValues?.logo,
        currentUser,
        uuid,
        folder: 'manufacturer'
      });
    }

    const newFormValues = {
      ...formValues,
      ...(logo && { logo }),
      contactNumber: formatPhoneNumberWithoutMask(formValues?.contactNumber),
      fax: formatPhoneNumberWithoutMask(formValues?.fax),
      uuid,
      location: locationObj
    };
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = newFormValues;

    try {
      const response = await createManufacturerMutate({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createManufacturer) {
        history?.push(ROUTES?.MANUFACTURERS, {
          ...location?.state
        });
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    certification: '',
    country: 'USA'
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.MANUFACTURERS} />
          <span className="portal-header">Add Manufacturer</span>
        </div>
      </Portal>
      <ManufacturerForm
        manufacturerData={initialValues}
        handleSubmit={handleSubmit}
        isSubmit={submitLoading}
      />
    </Card>
  );
}

export default withRouter(ManufacturerCreate);
