import { Button } from 'antd';
import React from 'react';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { MODAL_WIDTH } from '../../../common/constants';
import { formatPhoneNumber } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import TableComponent from '../../../components/TableComponent';

const CustomerCreationModal = ({
  showModal = false,
  setShowModal,
  duplicateData = [],
  submitData,
  submitLoading = false,
  isEdit = false
}) => {
  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 200,
      dataIndex: 'user',
      key: 'user',
      className: 'max-width-column',
      render: (user) => {
        return `${user?.firstName} ${user?.lastName}`;
      }
    },
    {
      title: 'CONTACT',
      width: 200,
      ellipsis: true,
      dataIndex: 'phoneNo',
      className: 'max-width-column',
      key: 'phoneNo',
      render: (phoneNo, record) => {
        return formatPhoneNumber(record?.user?.phoneNo) || '-';
      }
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      width: 300,
      dataIndex: 'email',
      key: 'email',
      className: 'max-width-column',
      render: (email, record) => {
        return `${record?.user?.email}`;
      }
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location) => {
        return `${location?.addressLine1} ${location?.city} ${location?.state} ${location?.country} ${location?.zipCode}`;
      }
    }
  ];

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="CUSTOMER CREATION"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="customer-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="customer-table-discard-btn"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={submitLoading}
            size="small"
            className="common-button add-customer-button"
            onClick={submitData}
          >
            {isEdit ? 'Edit' : 'Add New'} Customer
          </Button>
        </div>
      }
    >
      <div className="common-table customer-table">
        <h4 className="extra-text">
          We have found some possible duplicate records as below,
        </h4>
        <h4 className="extra-text">
          Are you still want to create new customer?
        </h4>
        <TableComponent
          fullHeight={false}
          columns={[...columns?.filter((item) => item !== false)]}
          data={duplicateData}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default CustomerCreationModal;
