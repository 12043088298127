import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_TENANT = gql`
  mutation updateTenant($data: TenantUpdateInput) {
    updateTenant(data: $data) {
      message
      status
    }
  }
`;
