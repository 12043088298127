import { Checkbox } from 'antd';
import React from 'react';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import './supportWidget.less';

const SupportWidgetComponent = ({ widgetConfig = null }) => {
  return (
    <div className="support-page-wrapper">
      {widgetConfig?.supportFromSettings?.errorMessage?.check && (
        <div
          className="editor-render d-flex justify-center error-message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: widgetConfig?.supportFromSettings?.errorMessage?.text || '-'
          }}
        />
      )}
      <div className="form-section fill-width">
        <span className="optional-comment">Optional comments</span>

        <InputComponent
          readOnly
          placeholder={
            widgetConfig?.supportFromSettings?.optionalComments?.labelText
          }
          className="mt-30"
        />

        {widgetConfig?.supportFromSettings?.urgencyCheckboxLabelCheck ||
        widgetConfig?.supportFromSettings
          ?.sendConfirmationEmailCheckboxCheck ? (
          <div className="fill-width mt-30 d-flex justify-center flex-vertical">
            {widgetConfig?.supportFromSettings?.urgencyCheckboxLabelCheck && (
              <Checkbox
                checked={
                  widgetConfig?.supportFromSettings
                    ?.urgencyCheckboxLabelSelected
                }
                className="mt-20 common-checkbox"
              >
                {widgetConfig?.supportFromSettings?.urgencyCheckboxLabelText}
              </Checkbox>
            )}
            {widgetConfig?.supportFromSettings
              ?.sendConfirmationEmailCheckboxCheck && (
              <Checkbox
                checked={
                  widgetConfig?.supportFromSettings
                    ?.sendConfirmationEmailCheckboxSelected
                }
                className={`${
                  widgetConfig?.supportFromSettings?.urgencyCheckboxLabelCheck
                    ? `mt-20`
                    : ''
                } common-checkbox`}
              >
                {
                  widgetConfig?.supportFromSettings
                    ?.sendConfirmationEmailCheckboxText
                }
              </Checkbox>
            )}
          </div>
        ) : null}
        <div>
          <InputComponent
            readOnly
            placeholder={`${widgetConfig?.supportFromSettings?.name?.label} ${
              widgetConfig?.supportFromSettings?.name?.required ? '*' : ''
            }`}
            className="mt-15"
          />
          <InputComponent
            readOnly
            placeholder={`${widgetConfig?.supportFromSettings?.email?.labelText} *`}
            className="mt-15"
          />
          <InputComponent
            readOnly
            placeholder={`${widgetConfig?.supportFromSettings?.phone?.labelText} * `}
            className="mt-15"
          />
        </div>
      </div>
    </div>
  );
};

export default SupportWidgetComponent;
