import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PropertyContact from './pages/PropertyContact';
import PropertyCreate from './pages/PropertyCreate';
import PropertyCustomer from './pages/PropertyCustomer';
import PropertyEdit from './pages/PropertyEdit';
import PropertyList from './pages/PropertyList';
import PropertyView from './pages/PropertyView';
import './propertyModule.less';

const PropertyWrapper = () => {
  return (
    <div className="property-module">
      <Switch>
        <>
          <Route exact path={ROUTES?.PROPERTIES} component={PropertyList} />
          <Route
            path={`${ROUTES?.PROPERTIES}/add`}
            component={PropertyCreate}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/edit/:id`}
            component={PropertyEdit}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/view/:id`}
            component={PropertyView}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/view${ROUTES?.CUSTOMERS}/:id`}
            component={PropertyCustomer}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/view${ROUTES?.CONTACTS}/:id`}
            component={PropertyContact}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/edit${ROUTES?.CUSTOMERS}/:id`}
            component={PropertyCustomer}
          />
          <Route
            exact
            path={`${ROUTES?.PROPERTIES}/edit${ROUTES?.CONTACTS}/:id`}
            component={PropertyContact}
          />
        </>
      </Switch>
    </div>
  );
};

export default PropertyWrapper;
