import { Col, Divider, Form, Radio, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { DYNAMIC_BOOLEAN_DEFAULT_CHECKED } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';

const { required } = formValidatorRules;

const BooleanFormData = ({ position = 'top', isDisabled = false }) => {
  return (
    <div className="boolean-form-data">
      {position === 'bottom' && (
        <>
          <span className="form-divider-text">FIELD SETTINGS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="trueValue"
                label="True"
                rules={[{ ...required, message: 'Please Enter True' }]}
              >
                <InputComponent
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter True"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="falseValue"
                label="False"
                rules={[{ ...required, message: 'Please Enter False' }]}
              >
                <InputComponent
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter False"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="booleanDefaultValue">
                <Radio.Group className="common-radio fill-width d-flex">
                  {map(DYNAMIC_BOOLEAN_DEFAULT_CHECKED, (item) => (
                    <Col
                      key={item?.key}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={6}
                      xxl={6}
                    >
                      <Radio key={item?.key} value={item?.key}>
                        {item?.label}
                      </Radio>
                    </Col>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default BooleanFormData;
