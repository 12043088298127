import { gql } from '@apollo/client';

export const GET_MANUFACTURERS = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        uuid
        name
        manufacturerCode
        location {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
        logo {
          url
          key
          name
          contentType
          extension
        }
        refData
        contactNumber
        isActive
        certification
        fax
        url
        tenantId
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query productCategories(
    $filter: ProductCategoryFilter!
    $where: ProductCategoriesWhereFilter
  ) {
    productCategories(filter: $filter, where: $where) {
      count
      data {
        id
        title
        productCategoryCode
        description
        isActive
      }
    }
  }
`;

export const GET_DYNAMICS_FIELDS = gql`
  query dynamicFieldsWithSelectedValuesAndListItems(
    $filter: DynamicFieldFilter!
    $where: DynamicFieldWhereFilter!
  ) {
    dynamicFieldsWithSelectedValuesAndListItems(
      filter: $filter
      where: $where
    ) {
      count
      data {
        id
        fieldType
        key
        sortOrder
        lineOfBusinesses {
          id
          label
          key
        }
        industry {
          id
          label
          key
        }
        status
        name
        label
        tooltip
        config
        dynamicFieldListItems {
          id
          fieldId
          uuid
          name
          label
          value
          default
          isActive
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_STATIC_FIELDS = gql`
  query SystemFieldMappingsWithConfigurations(
    $where: SystemFieldMappingsWithConfigurationWhereInput!
  ) {
    systemFieldMappingsWithConfigurations(where: $where) {
      id
      key: fieldName
      fieldType
      label
      isAllowed
      entityName
      dynamicFieldListItems: staticFieldListItems {
        id
        key
        name
        label
        description
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_LINE_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_SUB_AREAS = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lineOfBusinessId
      }
    }
  }
`;

export const GET_PRODUCT_RANKING_REGIONS = gql`
  query regionsProductRankingConfigData(
    $filter: RegionFilter!
    $where: RegionsWhereFilter
  ) {
    regionsProductRankingConfigData(filter: $filter, where: $where) {
      count
      data {
        id
        name
        parentName
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const QRV_RANKING_QUALITY_LISTING = gql`
  query QrvListing($where: QRVFilter!) {
    qrvListing(where: $where) {
      good {
        id
        sku
        stock
        title
        manufacturer
        sellingPrice
        productItemWarranty
        costPerDay
        rank
        productQuality
        highlighted
        configId
        score
      }
      better {
        id
        sku
        stock
        title
        manufacturer
        sellingPrice
        productItemWarranty
        costPerDay
        rank
        productQuality
        highlighted
        configId
        score
      }
      best {
        id
        sku
        stock
        title
        manufacturer
        sellingPrice
        productItemWarranty
        costPerDay
        rank
        productQuality
        highlighted
        configId
        score
      }
    }
  }
`;

export const RANKING_QUALITY_LISTING = gql`
  query productRankingQualityListing($where: RankingConfigInput) {
    productRankingQualityListing(where: $where) {
      good {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
      better {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
      best {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
    }
  }
`;

export const DEFAULT_CONFIG = gql`
  query defaultConfig {
    defaultConfig {
      industry {
        id
        label
        key
        lineOfBusinesses {
          id
          label
          key
          subAreas {
            id
            label
            key
          }
        }
      }
      region {
        id
        name
        zipCodes
        subRegions {
          id
          name
          zipCodes
        }
      }
      brand {
        id
        name
      }
    }
  }
`;
