import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import gallerySvg from '../../../assets/gallery.svg';
import { ROUTES } from '../../../common/constants';

const ContactTabs = ({ match: { params: { id } = {} } = {}, children }) => {
  const location = useLocation();
  const history = useHistory();

  const [activeKey, setActiveKey] = useState('INFORMATION');
  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );

  const customerTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Customer</span>
    </div>
  );

  const propertyTitle = (
    <div>
      <img src={gallerySvg} alt="info-icon" />
      <span className="card-title">Properties</span>
    </div>
  );

  useEffect(() => {
    if (
      location?.pathname?.includes('/edit') ||
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/add')
    ) {
      setActiveKey('INFORMATION');
    }
    if (location?.pathname?.includes('customer')) {
      setActiveKey('CUSTOMERS');
    }
    if (location?.pathname?.includes('properties')) {
      setActiveKey('PROPERTIES');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.CONTACTS}/edit/${id}`);
        } else {
          history?.push(`${ROUTES?.CONTACTS}/view/${id}`);
        }
        break;

      case 'CUSTOMERS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.CONTACTS}/edit${ROUTES?.CUSTOMERS}/${id}`);
        } else {
          history?.push(`${ROUTES?.CONTACTS}/view${ROUTES?.CUSTOMERS}/${id}`);
        }
        break;

      case 'PROPERTIES':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.CONTACTS}/edit${ROUTES?.PROPERTIES}/${id}`);
        } else {
          history?.push(`${ROUTES?.CONTACTS}/view${ROUTES?.PROPERTIES}/${id}`);
        }
        break;

      default:
        break;
    }
  };

  const tabItems = [
    {
      label: infoTitle,
      key: 'INFORMATION',
      children
    },
    !location?.pathname?.includes('/add') && {
      label: customerTitle,
      key: 'CUSTOMERS',
      children
    },
    !location?.pathname?.includes('/add') && {
      label: propertyTitle,
      key: 'PROPERTIES',
      children
    }
  ];

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={activeKey}
        onChange={handleSelectTab}
        items={tabItems}
      />
    </div>
  );
};

export default withRouter(ContactTabs);
