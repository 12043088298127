import { gql } from '@apollo/client';

export const GET_PRICING_MODIFIERS = gql`
  query pricingModifiers(
    $filter: PricingModifierFilter!
    $where: PricingModifiersWhereFilter
  ) {
    pricingModifiers(filter: $filter, where: $where) {
      count
      data {
        id
        title
        order
        conditions
        brands {
          id
          name
        }
        industry
        lineOfBusiness
        subArea
        manufacturers {
          id
          name
        }
        regions {
          name
          zipCodes
          all
        }
        status
        lineItemName
        billingAs
        addItemAs
        modifierOn
        pricingValue
        pricingValueBest
        pricingValueBetter
        pricingOperation
        pricingValueType
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PRICING_MODIFIER = gql`
  query pricingModifier($id: ID!) {
    pricingModifier(where: { id: $id }) {
      id
      title
      order
      conditions
      brands {
        id
        name
      }
      industry
      lineOfBusiness
      subArea
      regions {
        id
        all
        name
        zipCodes
      }
      manufacturers {
        id
        name
      }
      status
      modifierOn
      pricingValue
      pricingValueBest
      pricingValueBetter
      description
      pricingOperation
      startDate
      endDate
      pricingValueType
      addItemAs
      billingAs
      lineItemName
      tenantId
    }
  }
`;

export const PRICING_MODIFIER_FILTER = gql`
  query pricingModifierFilters($filter: PricingModifierFilter!) {
    pricingModifierFilters(filter: $filter) {
      count
      data {
        title
        order
        conditions
        status
        modifierOn
        pricingValue
        description
        startDate
        endDate
        lineItemName
        tenantId
      }
    }
  }
`;

export const PRICING_MODIFIER_CONDITIONS = gql`
  query pricingModifierConditions(
    $filter: PricingModifierConditionFilter!
    $where: PricingModifierConditionWhereFilter
  ) {
    pricingModifierConditions(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        hasAttributes
      }
    }
  }
`;

export const PRICING_MODIFIER_STATUS = gql`
  query pricingModifierStatuses(
    $filter: PricingModifierStatusFilter!
    $where: PricingModifierStatusWhereFilter
  ) {
    pricingModifierStatuses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
        logo {
          url
          key
          name
          extension
          contentType
        }
        isActive
      }
    }
  }
`;

export const GET_PRICING_MODIFIER_REGIONS = gql`
  query regionPricingModifier($filter: RegionFilter!) {
    regionPricingModifier(filter: $filter) {
      count
      isValidZipCode
      data {
        id
        name
        isActive
        zipCodes
        subRegions {
          id
          name
          isActive
          zipCodes
        }
      }
    }
  }
`;

export const GET_MANUFACTURERS = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        name
        isActive
        logo {
          url
          key
          name
          contentType
          extension
        }
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobSaData {
          id
          label
          key
          subAreas {
            id
            label
            key
          }
        }
      }
    }
  }
`;

export const LINES_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        subAreas {
          id
          label
          key
          isActive
        }
      }
    }
  }
`;

export const CONVERSION_PARAMETER_LIST = gql`
  query unitInputParameter(
    $filter: UnitInputParameterFilter
    $where: UnitInputParameterWhere!
  ) {
    unitInputParameter(filter: $filter, where: $where) {
      count
      data {
        id
        name
        key
        industryId
        lineOfBusinessId
        unitsOfMeasure {
          id
          title
          key
        }
      }
    }
  }
`;
