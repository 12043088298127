import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { MODAL_WIDTH } from '../../../common/constants';
import { formatPhoneNumber } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { CREATE_SALES_JUNCTION } from '../graphql/Mutations';
import { GET_CUSTOMERS } from '../graphql/Queries';

const CustomerModal = ({ showModal, setShowModal, contactId }) => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialCustomerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [filters, setFilters] = useState(null);

  const [fetchCustomerData, { loading, data }] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.customers?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [attachCustomer] = useMutation(CREATE_SALES_JUNCTION, {
    onError() {},
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
        variables: {
          salesWhere: {
            joinedFrom: 'CONTACT',
            id: contactId
          },
          filter: initialCustomerFilter
        }
      }
    ]
  });

  useEffect(() => {
    fetchCustomerData({
      variables: {
        filter: initialCustomerFilter
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id
      });
    }
  };
  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'user',
      key: 'user',
      className: 'max-width-column',
      render: (user) => {
        return `${user?.firstName} ${user?.lastName}`;
      }
    },
    {
      title: 'CONTACT',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return (
          <span key={record?.id}>
            {`${formatPhoneNumber(record?.user?.phoneNo) || '-'}`}
          </span>
        );
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 250,
      className: 'max-width-column',
      render: (text, record) => {
        return <span key={record?.id}>{`${record?.user?.email}`}</span>;
      }
    },
    {
      title: 'ADDRESS',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      className: 'max-width-column',
      render: (location) => {
        return `${location?.addressLine1} ${location?.city},  ${location?.state} ${location?.zipCode}`;
      }
    }
  ];

  const handleAttacheUser = () => {
    if (selectedKeys?.length > 0) {
      try {
        const response = attachCustomer({
          variables: {
            data: {
              salesIds: selectedKeys,
              joinedTo: 'CUSTOMER',
              joinedFrom: 'CONTACT'
            },
            where: {
              id: contactId
            }
          }
        });
        if (response) {
          setShowModal(false);
        }
      } catch (error) {
        setShowModal(false);
      }
    } else {
      message?.error('Please Select Customers');
    }
  };

  const onSearchChange = (value) => {
    setCustomerFilter({
      ...customerFilter,
      skip: value ? 0 : customerFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchCustomerData({
      variables: {
        filter: {
          ...customerFilter,
          skip: value
            ? 0
            : customerFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };
  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="CUSTOMER CREATION"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="customer-creation-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            onClick={() => setShowModal(false)}
            size="small"
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleAttacheUser}
            className="common-button add-customer-btn"
            disabled={!selectedKeys?.length}
          >
            Attach
            {selectedKeys?.length > 0 && ` ${selectedKeys?.length} Customer(s)`}
          </Button>
        </div>
      }
    >
      <div className="mb-16 fill-width search-checkbox">
        <SearchComponent
          className="customer-search-box modal-search"
          id="search-container-id"
          placeholder="Customer or other detail.."
          name="Customers"
          getData={onSearchChange}
        />
      </div>
      <div className="common-table customer-table">
        {pageSize && (
          <TableComponent
            loadingData={loading}
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.customers?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowSelection={rowSelection}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </ModalComponent>
  );
};

export default withRouter(CustomerModal);
