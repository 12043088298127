import {
  ArrowDownOutlined,
  CheckOutlined,
  CreditCardOutlined,
  PhoneOutlined,
  UpOutlined
} from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row
} from 'antd';
import locale from 'antd/es/locale/en_US';
import { find, forEach, lowerCase, map, upperCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { ReactComponent as CalendarDates } from '../../../../../../assets/calendar-dates.svg';
import { ReactComponent as LocationSvg } from '../../../../../../assets/location-secondary.svg';
import {
  DEFAULT_DATE_FORMAT_CPQ,
  DaysOfWeek
} from '../../../../../../common/constants';
import CardSection from '../../../previewComponents/globalComponents/CardSection';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import NumberComponent from '../../../previewComponents/globalComponents/NumberComponent';
import SelectComponent from '../../../previewComponents/globalComponents/SelectComponent';
import './AppointmentWidgetComponent.less';

const { Option } = SelectComponent;

const { Panel } = Collapse;

const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY);

// below code can be helpful in  stripe loading issue when there is not active internet connection but need to test this in local first
// let stripePromise;
// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
//   }
//   return stripePromise;
// };

const AppointmentWidgetComponent = ({
  widgetConfig = null,
  selectedAppointmentKey = '',
  setSelectedAppointmentKey,
  currentPageSequence = null
}) => {
  const [form] = Form?.useForm();

  const [locationObj, setLocationObj] = useState({
    completed: false,
    visible: true
  });
  const [timeObj, setTimeObj] = useState({
    completed: false,
    visible: false
  });
  const [appointmentObj, setAppointmentObj] = useState({
    completed: false,
    visible: false
  });

  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime] = useState();
  const [showTimer, setShowTimer] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [holidayTitle, setHolidayTitle] = useState('');
  const [holidayMessage, setHolidayMessage] = useState('');

  const [addressUrl, setAddressUrl] = useState(null);
  const [availabilityUrl, setAvailabilityUrl] = useState(null);
  const [reserveUrl, setReserveUrl] = useState(null);
  const [creditUrl, setCreditUrl] = useState(null);
  const [phoneCallUrl, setPhoneCallUrl] = useState(null);

  const [weekData, setWeekData] = useState([]);

  useEffect(() => {
    if (
      widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting?.enable &&
      !widgetConfig?.reserveAppointment?.contactInfo?.contactSetting?.enable
    ) {
      setSelectedStep(1);
    } else if (
      widgetConfig?.reserveAppointment?.contactInfo?.contactSetting?.enable &&
      !widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting?.enable
    ) {
      setSelectedStep(2);
    } else {
      setSelectedStep(null);
    }

    if (widgetConfig?.address?.icon?.length > 0) {
      if (widgetConfig?.address?.icon?.[0]?.url) {
        setAddressUrl(widgetConfig?.address?.icon?.[0]?.url);
      } else {
        setAddressUrl(
          URL?.createObjectURL(widgetConfig?.address?.icon?.[0]?.originFileObj)
        );
      }
    } else {
      setAddressUrl(widgetConfig?.address?.icon?.url);
    }

    if (widgetConfig?.availability?.icon?.length > 0) {
      if (widgetConfig?.availability?.icon?.[0]?.url) {
        setAvailabilityUrl(widgetConfig?.availability?.icon?.[0]?.url);
      } else {
        setAvailabilityUrl(
          URL?.createObjectURL(
            widgetConfig?.availability?.icon?.[0]?.originFileObj
          )
        );
      }
    } else {
      setAvailabilityUrl(widgetConfig?.availability?.icon?.url);
    }

    if (widgetConfig?.reserveAppointment?.icon?.length > 0) {
      if (widgetConfig?.reserveAppointment?.icon?.[0]?.url) {
        setReserveUrl(widgetConfig?.reserveAppointment?.icon?.[0]?.url);
      } else {
        setReserveUrl(
          URL?.createObjectURL(
            widgetConfig?.reserveAppointment?.icon?.[0]?.originFileObj
          )
        );
      }
    } else {
      setReserveUrl(widgetConfig?.reserveAppointment?.icon?.url);
    }

    if (widgetConfig?.reserveAppointment?.creditCard?.icon?.length > 0) {
      if (widgetConfig?.reserveAppointment?.creditCard?.icon?.[0]?.url) {
        setCreditUrl(
          widgetConfig?.reserveAppointment?.creditCard?.icon?.[0]?.url
        );
      } else {
        setCreditUrl(
          URL?.createObjectURL(
            widgetConfig?.reserveAppointment?.creditCard?.icon?.[0]
              ?.originFileObj
          )
        );
      }
    } else {
      setCreditUrl(widgetConfig?.reserveAppointment?.creditCard?.icon?.url);
    }

    if (widgetConfig?.reserveAppointment?.contactInfo?.icon?.length > 0) {
      if (widgetConfig?.reserveAppointment?.contactInfo?.icon?.[0]?.url) {
        setPhoneCallUrl(
          widgetConfig?.reserveAppointment?.contactInfo?.icon?.[0]?.url
        );
      } else {
        setPhoneCallUrl(
          URL?.createObjectURL(
            widgetConfig?.reserveAppointment?.contactInfo?.icon?.[0]
              ?.originFileObj
          )
        );
      }
    } else {
      setPhoneCallUrl(widgetConfig?.reserveAppointment?.contactInfo?.icon?.url);
    }
    if (
      (!widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting
        ?.enable &&
        selectedAppointmentKey === 'CREDIT') ||
      (!widgetConfig?.reserveAppointment?.contactInfo?.contactSetting?.enable &&
        selectedAppointmentKey === 'CONTACT_SETTING')
    ) {
      setSelectedAppointmentKey('RESERVE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);

  useEffect(() => {
    const todayDay = lowerCase(moment()?.format('dddd'));
    if (currentPageSequence?.cutOffTime?.[todayDay]?.checked) {
      if (
        moment(currentPageSequence?.cutOffTime?.[todayDay]?.time, [
          'h:mm A'
        ])?.isAfter(moment())
      ) {
        setShowTimer(true);
      }
    }
    form?.setFieldsValue({
      zipCode: 92618,
      state: 'Irvine',
      city: 'CA'
    });
    setLocationObj({
      ...locationObj,
      completed: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSequence]);

  const disabledDateFun = (current) => {
    let weekOfDay = [];
    const holiday = [];
    forEach(currentPageSequence?.holidayList, (item) => {
      if (!currentPageSequence?.daysOff?.allowBookingOnHolidays) {
        holiday?.push(item?.date);
      }
    });
    if (!currentPageSequence?.daysOff?.allowBookingOnHolidays) {
      weekOfDay = map(
        currentPageSequence?.daysOff?.days,
        (item) => DaysOfWeek?.[item]
      );
    }
    if (showTimer) {
      // Can not select days before today
      return (
        weekOfDay?.includes(moment(current)?.day()) ||
        find(
          holiday,
          (date) => date === moment(current)?.format(DEFAULT_DATE_FORMAT_CPQ)
        ) ||
        current < moment()?.startOf('day')
      );
    }
    return (
      weekOfDay?.includes(moment(current)?.day()) ||
      find(
        holiday,
        (date) => date === moment(current)?.format(DEFAULT_DATE_FORMAT_CPQ)
      ) ||
      current < moment()?.add(1, 'day')?.startOf('day')
    );
  };

  useEffect(() => {
    if (timeObj?.visible && !selectedDate) {
      if (
        widgetConfig?.availability?.displayType?.displayStyle === 'CALENDAR'
      ) {
        const dateCheck = setInterval(function () {
          // eslint-disable-next-line no-undef
          const firstDate = document?.querySelector(
            'td:not(.ant-picker-cell-disabled)'
          );
          if (firstDate) {
            if (
              moment(firstDate?.title)?.format(DEFAULT_DATE_FORMAT_CPQ) ===
              moment()?.format(DEFAULT_DATE_FORMAT_CPQ)
            ) {
              setSelectedDate(moment()); // if date is today then, we need current time to block slots which start time is passed
            } else {
              setSelectedDate(moment(firstDate?.title));
            }
            // this specific block if first date is holiday and allowBookingOnHoliday  is on, then set title and message
            map(currentPageSequence?.holidayList, (item) => {
              if (
                item?.date ===
                moment(firstDate?.title)?.format(DEFAULT_DATE_FORMAT_CPQ)
              ) {
                setHolidayTitle(item?.title);
                setHolidayMessage(item?.message);
              }
            });
            clearInterval(dateCheck);
          }
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeObj]);

  useEffect(() => {
    switch (selectedAppointmentKey) {
      case 'ADDRESS':
        setLocationObj({
          ...locationObj,
          visible: true
        });
        setTimeObj({
          ...timeObj,
          visible: false
        });
        setAppointmentObj({
          ...appointmentObj,
          visible: false
        });
        setSelectedStep(null);
        break;

      case 'AVAILABILITY':
        setTimeObj({
          ...timeObj,
          visible: true
        });

        setLocationObj({
          ...locationObj,
          visible: false
        });
        setAppointmentObj({
          ...appointmentObj,
          visible: false
        });
        setSelectedStep(null);
        if (
          widgetConfig?.availability?.displayType?.displayStyle === 'WEEKDAYS'
        ) {
          const today = moment();
          const weekDataCopy = [];
          if (!weekData?.length) {
            const isTodayDisabled = disabledDateFun(today);
            if (!isTodayDisabled) {
              weekDataCopy?.push({
                originalDate: today,
                title: today?.format('dddd'),
                key: today?.format('dddd')
              });
            }
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i < (isTodayDisabled ? 8 : 7); i++) {
              weekDataCopy?.push({
                originalDate: today?.clone()?.add(i, 'day'),
                title: today?.clone()?.add(i, 'day')?.format('dddd'),
                key: today?.clone()?.add(i, 'day')?.format('dddd')
              });
            }
            setWeekData(weekDataCopy);
          }
        }
        break;

      case 'RESERVE':
        setAppointmentObj({
          ...appointmentObj,
          visible: true
        });
        setTimeObj({
          completed: true,
          visible: false
        });
        setLocationObj({
          completed: true,
          visible: false
        });
        setSelectedStep(null);

        break;

      case 'CREDIT':
        setAppointmentObj({
          ...appointmentObj,
          visible: true
        });
        setTimeObj({
          completed: true,
          visible: false
        });
        setLocationObj({
          completed: true,
          visible: false
        });
        setSelectedStep(1);

        break;

      case 'CONTACT_SETTING':
        setAppointmentObj({
          ...appointmentObj,
          visible: true
        });
        setTimeObj({
          completed: true,
          visible: false
        });
        setLocationObj({
          completed: true,
          visible: false
        });
        setSelectedStep(2);

        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentKey, widgetConfig]);

  const getClassName = (value) => {
    let className;
    map(currentPageSequence?.holidayList, (item) => {
      if (item?.date === moment(value)?.format(DEFAULT_DATE_FORMAT_CPQ)) {
        className = 'single-date holiday';
      }
    });
    if (
      moment(selectedDate)?.format(DEFAULT_DATE_FORMAT_CPQ) ===
      moment(value)?.format(DEFAULT_DATE_FORMAT_CPQ)
    ) {
      className = 'single-date selected-date';
    }
    return className || 'single-date';
  };

  const dateFullCellRender = (value) => {
    const className = getClassName(value);
    return <span className={className}>{value?.date()}</span>;
  };

  const timeSlotActive = currentPageSequence?.appointmentWindow?.active;

  const PanelHeader = ({ item = null }) => {
    return (
      <Checkbox
        disabled={disabledDateFun(item?.originalDate)}
        onClick={(e) => e?.stopPropagation()}
        className={`common-checkbox-cpq ${
          !timeSlotActive ? 'checkbox-margin' : ''
        }`}
        checked={item?.originalDate === selectedDate}
      >
        <span onClick={(e) => e?.stopPropagation()} className="font-size-12">
          {item?.title}
        </span>
      </Checkbox>
    );
  };

  moment?.updateLocale('en', {
    week: {
      dow:
        DaysOfWeek?.[
          upperCase(widgetConfig?.availability?.displayType?.startOfTheWeek)
        ] /// Date offset
    }
  });

  return (
    <div className="scheduled-appointment-page">
      <div className="mt-25">
        <div className="single-project">
          <span className="single-item">Package Title 1 : Good</span>
          <span className="single-item">$XXX.XX</span>
        </div>
        <div className="single-project">
          <span className="single-item">Package Title 2 : Better</span>
          <span className="single-item">$XXXX.XX</span>
        </div>
        <div className="single-project">
          <span className="single-item">Package Title 3 : Best</span>
          <span className="single-item">$XXX.XX</span>
        </div>
        <Divider className="mx-12" />
        <div className="total">
          <span>$X,XXX.XX</span>
        </div>
      </div>
      <Form form={form} layout="vertical" autoComplete="off">
        <div className="location-section">
          {locationObj?.visible ? (
            <>
              <span className="location-title">
                {widgetConfig?.address?.title}
              </span>
              <Form.Item name="street">
                <InputComponent readOnly allowClear placeholder="Street*" />
              </Form.Item>
              <Form.Item name="state">
                <SelectComponent placeholder="State*" disabled>
                  {map([], (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="city">
                    <SelectComponent placeholder="City*" disabled>
                      {map([], (city) => (
                        <Option key={city?.id} value={city?.name}>
                          {city?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="zipCode">
                    <NumberComponent
                      disabled
                      allowClear
                      placeholder="Postal Code*"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {widgetConfig?.address?.descriptionCheck &&
                widgetConfig?.address?.descriptionText?.length > 0 && (
                  <div className="step-description">
                    {widgetConfig?.address?.descriptionText}
                  </div>
                )}
              <ArrowDownOutlined className="down-arrow d-flex justify-center" />
            </>
          ) : (
            <div className="info-card">
              {!addressUrl ? (
                <LocationSvg />
              ) : (
                <img alt="location" src={addressUrl} width={18} />
              )}
              <span className="info-title">
                1 Technology Dr Irvine, CA 92618
              </span>
              <Button type="link" className="edit-btn">
                edit
              </Button>
            </div>
          )}
        </div>
        <div className="time-section">
          {!timeObj?.visible ? (
            <>
              {!timeObj?.completed ? (
                <div
                  className={`info-card ${
                    locationObj?.completed ? 'click-enabled' : 'click-disabled'
                  }`}
                >
                  {!availabilityUrl ? (
                    <CalendarDates />
                  ) : (
                    <img alt="location" src={availabilityUrl} width={18} />
                  )}
                  <span className="info-title">
                    {widgetConfig?.availability?.title}
                  </span>
                </div>
              ) : (
                <div
                  className={`info-card ${
                    !locationObj?.completed && 'click-disabled'
                  }`}
                >
                  {!availabilityUrl ? (
                    <CalendarDates />
                  ) : (
                    <img alt="location" src={availabilityUrl} width={18} />
                  )}
                  <span className="info-title">
                    {`${moment(selectedDate)?.calendar(null, {
                      lastDay: '[Yesterday]',
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      lastWeek: '[last] dddd',
                      nextWeek: 'dddd',
                      sameElse: 'L'
                    })} Morning ${
                      currentPageSequence?.appointmentWindow?.showTime
                        ? `${moment(
                            selectedTime?.from || moment()?.startOf('day')
                          )?.format('LT')} -
                          ${moment(
                            selectedTime?.to || moment()?.endOf('day')
                          )?.format('LT')}`
                        : ''
                    }`}
                  </span>
                  {locationObj?.completed && (
                    <>
                      <Button type="link" className="edit-btn">
                        edit
                      </Button>
                      <Button type="link" className="edit-btn">
                        add to calendar
                      </Button>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <span className="time-title">
                {widgetConfig?.availability?.title}
              </span>
              {widgetConfig?.availability?.displayType?.displayStyle ===
                'WEEKDAYS' && (
                <>
                  {timeSlotActive ? (
                    <Collapse
                      className="week-panel"
                      ghost
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <UpOutlined rotate={isActive ? 0 : 180} />
                      )}
                    >
                      {map(weekData, (panel, panelIndex) => (
                        <Panel
                          collapsible={
                            disabledDateFun(panel?.originalDate) && 'disabled'
                          }
                          header={<PanelHeader item={panel} />}
                          key={panel?.key}
                        >
                          {timeSlotActive && (
                            <Row>
                              {map(
                                currentPageSequence?.appointmentWindow?.slots,
                                (item) => {
                                  return (
                                    <Col key={item?.from} span={24}>
                                      <Checkbox
                                        checked={
                                          item?.from === selectedTime?.from &&
                                          panelIndex ===
                                            selectedTime?.panelIndex
                                        }
                                        className="common-checkbox-cpq inside-time-checkbox"
                                        disabled={
                                          moment()?.format('L') ===
                                          moment(panel?.originalDate)?.format(
                                            'L'
                                          )
                                            ? moment(
                                                moment(item?.from)?.format(
                                                  'LT'
                                                ),
                                                ['h:mm:ss A']
                                              )?.isBefore(
                                                moment(moment()?.format('LT'), [
                                                  'h:mm:ss A'
                                                ])
                                              )
                                            : false
                                        }
                                      >
                                        {currentPageSequence?.appointmentWindow
                                          ?.showTime
                                          ? `${item?.label} ${moment(
                                              item?.from
                                            )?.format('LT')} -
                            ${moment(item?.to)?.format('LT')}`
                                          : item?.label}
                                      </Checkbox>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          )}
                        </Panel>
                      ))}
                    </Collapse>
                  ) : (
                    map(weekData, (panel) => (
                      <PanelHeader key={panel?.key} item={panel} />
                    ))
                  )}
                </>
              )}
              {widgetConfig?.availability?.displayType?.displayStyle ===
                'CALENDAR' && (
                <>
                  <div id="expanded-date-picker">
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        inputReadOnly
                        value={selectedDate}
                        dateRender={dateFullCellRender}
                        popupClassName="calendar-picker"
                        open
                        disabledDate={disabledDateFun}
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      />
                    </ConfigProvider>
                  </div>

                  {timeSlotActive && (
                    <Radio.Group className="time-slots" value={selectedTime}>
                      {map(
                        currentPageSequence?.appointmentWindow?.slots,
                        (item) => {
                          return (
                            <Radio.Button
                              key={item?.from}
                              value={item}
                              disabled={
                                moment(selectedDate)?.format('L') ===
                                moment()?.format('L')
                                  ? moment(moment(item?.from)?.format('LT'), [
                                      'h:mm:ss A'
                                    ])?.isBefore(
                                      moment(
                                        moment(selectedDate)?.format('LT'),
                                        ['h:mm:ss A']
                                      )
                                    )
                                  : false
                              }
                            >
                              {currentPageSequence?.appointmentWindow?.showTime
                                ? `${item?.label} ${moment(item?.from)?.format(
                                    'LT'
                                  )} -
                            ${moment(item?.to)?.format('LT')}`
                                : item?.label}
                            </Radio.Button>
                          );
                        }
                      )}
                    </Radio.Group>
                  )}
                </>
              )}
              {holidayTitle && (
                <span className="holiday-title holiday-bold">
                  {holidayTitle}
                </span>
              )}
              {holidayMessage && (
                <span className="holiday-title">{holidayMessage}</span>
              )}
              {widgetConfig?.availability?.descriptionCheck &&
                widgetConfig?.availability?.descriptionText?.length > 0 && (
                  <div className="step-description">
                    {widgetConfig?.availability?.descriptionText}
                  </div>
                )}
              <ArrowDownOutlined className="down-arrow" />
            </>
          )}
        </div>

        <div className="appointment-section">
          {!appointmentObj?.visible ? (
            <>
              {!appointmentObj?.completed && (
                <div
                  className={`info-card ${
                    (!timeSlotActive || (timeObj?.completed && selectedTime)) &&
                    locationObj?.completed &&
                    selectedTime
                      ? 'click-enabled'
                      : 'click-disabled'
                  }`}
                >
                  {!reserveUrl ? (
                    <CheckOutlined />
                  ) : (
                    <img alt="location" src={reserveUrl} width={18} />
                  )}
                  <span className="info-title">
                    {widgetConfig?.reserveAppointment?.title}
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              <span className="appointment-title">
                {widgetConfig?.reserveAppointment?.title}
              </span>
              {!selectedStep ? (
                <>
                  {widgetConfig?.reserveAppointment?.creditCard
                    ?.creditCardSetting?.enable && (
                    <div className="info-card appointment-card">
                      {!creditUrl ? (
                        <CreditCardOutlined />
                      ) : (
                        <img alt="location" src={creditUrl} width={18} />
                      )}
                      <span className="info-title appointment-description">
                        {widgetConfig?.reserveAppointment?.creditCard?.title}
                      </span>
                      <Button
                        className="common-button-cpq half-width"
                        htmlType="submit"
                        id="next-btn"
                        type="primary"
                      >
                        {
                          widgetConfig?.reserveAppointment?.creditCard
                            ?.actionLabel
                        }
                      </Button>
                    </div>
                  )}
                  <Divider className="divider" />
                  {widgetConfig?.reserveAppointment?.contactInfo?.contactSetting
                    ?.enable && (
                    <div className="info-card appointment-card">
                      {!phoneCallUrl ? (
                        <PhoneOutlined rotate={90} />
                      ) : (
                        <img alt="location" src={phoneCallUrl} width={18} />
                      )}
                      <span className="info-title appointment-description">
                        {widgetConfig?.reserveAppointment?.contactInfo?.title}
                      </span>
                      <Button
                        className="common-button-cpq half-width"
                        htmlType="submit"
                        id="next-btn"
                        type="primary"
                      >
                        {
                          widgetConfig?.reserveAppointment?.contactInfo
                            ?.actionLabel
                        }
                      </Button>
                    </div>
                  )}
                  {widgetConfig?.reserveAppointment?.descriptionCheck &&
                    widgetConfig?.reserveAppointment?.descriptionText?.length >
                      0 && (
                      <div className="step-description mt-0">
                        {widgetConfig?.reserveAppointment?.descriptionText}
                      </div>
                    )}
                </>
              ) : (
                <>
                  {selectedStep === 1 && (
                    <>
                      {widgetConfig?.reserveAppointment?.creditCard
                        ?.creditCardSetting?.descriptionCheck && (
                        <span className="tab-description">
                          {
                            widgetConfig?.reserveAppointment?.creditCard
                              ?.creditCardSetting?.descriptionText
                          }
                        </span>
                      )}
                      <Form.Item
                        className="email-margin"
                        name="cardHolderEmail"
                      >
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder="Your Email*"
                        />
                      </Form.Item>
                      <Form.Item name="cardHolderName">
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder="Your Cardholder Name*"
                        />
                      </Form.Item>
                      <div className="card-section">
                        <Elements stripe={stripePromise}>
                          <CardSection />{' '}
                        </Elements>
                      </div>
                      <Form.Item name="creditCardNotes">
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder={
                            widgetConfig?.reserveAppointment?.creditCard
                              ?.creditCardSetting?.optionalComments?.labelText
                          }
                        />
                      </Form.Item>
                      {widgetConfig?.reserveAppointment?.contactInfo
                        ?.contactSetting?.enable && (
                        <span className="change-step">
                          {widgetConfig?.reserveAppointment?.contactInfo?.title}
                        </span>
                      )}
                    </>
                  )}
                  {selectedStep === 2 && (
                    <>
                      {widgetConfig?.reserveAppointment?.contactInfo
                        ?.contactSetting?.descriptionCheck && (
                        <span className="tab-description">
                          {
                            widgetConfig?.reserveAppointment?.contactInfo
                              ?.contactSetting?.descriptionText
                          }
                        </span>
                      )}
                      <Form.Item name="emailMeName" className="top-margin">
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder="Your Name*"
                        />
                      </Form.Item>
                      <Form.Item name="callMePhoneNo">
                        <ReactInputMask
                          mask="(999) 999-9999"
                          placeholder="Your Phone*"
                          allowClear
                          readOnly
                        >
                          {(inputProps) => <InputComponent {...inputProps} />}
                        </ReactInputMask>
                      </Form.Item>
                      <Form.Item name="emailMeEmail">
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder="Your Email*"
                        />
                      </Form.Item>
                      <Form.Item name="emailMeNotes">
                        <InputComponent
                          readOnly
                          allowClear
                          placeholder={
                            widgetConfig?.reserveAppointment?.contactInfo
                              ?.contactSetting?.optionalComments?.labelText
                          }
                        />
                      </Form.Item>
                      {widgetConfig?.reserveAppointment?.creditCard
                        ?.creditCardSetting?.enable && (
                        <div className="change-step">
                          {widgetConfig?.reserveAppointment?.creditCard?.title}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AppointmentWidgetComponent;
