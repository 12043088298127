import { gql } from '@apollo/client';

export const UPDATE_LINE_OF_BUSINESS = gql`
  mutation updateLineOfBusiness(
    $where: LineOfBusinessWhereInput!
    $data: UpdateLOBAndSAInput!
  ) {
    updateLineOfBusiness(where: $where, data: $data) {
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
      message
    }
  }
`;

export const CREATE_LINE_OF_BUSINESS = gql`
  mutation createLineOfBusiness($data: LineOfBusinessInput!) {
    createLineOfBusiness(data: $data) {
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
      status
      message
    }
  }
`;

export const DELETE_LINE_OF_BUSINESS = gql`
  mutation deleteLineOfBusiness($where: LineOfBusinessWhereInput!) {
    deleteLineOfBusiness(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const UPDATE_SUB_AREA = gql`
  mutation updateSubArea(
    $where: LineOfBusinessWhereInput!
    $data: UpdateLOBAndSAInput!
  ) {
    updateSubArea(where: $where, data: $data) {
      data {
        id
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
      message
    }
  }
`;

export const CREATE_SUB_AREA = gql`
  mutation createSubArea($data: SubAreaInput!) {
    createSubArea(data: $data) {
      data {
        id
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
      status
      message
    }
  }
`;

export const DELETE_SUB_AREA = gql`
  mutation deleteSubArea($where: SubAreaWhereInput!) {
    deleteSubArea(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
