import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_ITEM, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductItemCreate from './pages/ProductItemCreate';
import ProductItemDocument from './pages/ProductItemDocument';
import ProductItemEdit from './pages/ProductItemEdit';
import ProductItemGallery from './pages/ProductItemGallery';
import ProductItemList from './pages/ProductItemList';
import ProductItemView from './pages/ProductItemView';
import './productItemModule.less';

const ProductItemWrapper = () => {
  return (
    <div className="product-item-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PRODUCTS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_ITEM}
              showNoAccess
            >
              <ProductItemList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_ITEM_CREATE']}
              showNoAccess
            >
              <ProductItemCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_PRODUCT_ITEM_UPDATE',
                'FET_PRODUCT_ITEM_VIEW'
              ]}
              showNoAccess
            >
              <ProductItemEdit {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/view/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
              showNoAccess
            >
              <ProductItemView {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/edit${ROUTES?.GALLERY}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_PRODUCT_ITEM_UPDATE',
                'FET_PRODUCT_ITEM_VIEW'
              ]}
              showNoAccess
            >
              <ProductItemGallery {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/edit/document/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_PRODUCT_ITEM_UPDATE',
                'FET_PRODUCT_ITEM_VIEW'
              ]}
              showNoAccess
            >
              <ProductItemDocument {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/view${ROUTES?.GALLERY}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
              showNoAccess
            >
              <ProductItemGallery {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PRODUCTS}/view/document/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
              showNoAccess
            >
              <ProductItemDocument {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ProductItemWrapper;
