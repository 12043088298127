import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';
import NumberComponent from '../../../../../../components/NumberComponent';

const { required, requiredWhiteSpaceAllowed, number } = formValidatorRules;

const AddOnWidgetForm = () => {
  return (
    <div className="mt-16">
      <Form.Item
        rules={[{ ...required, message: 'Please Enter Title' }]}
        name={['widgetConfiguration', 'title']}
        label="Title"
        initialValue={'Title'}
      >
        <InputComponent allowClear placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        rules={[{ ...required, message: 'Please Enter Sub Title' }]}
        name={['widgetConfiguration', 'subtitle']}
        label="SubTitle"
        initialValue={'SubTitle'}
      >
        <InputComponent
          allowClear
          placeholder="Enter Sub Title"
          defaultValue="SubTitle"
        />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'description']}
        label="Description"
        rules={[{ ...required, message: 'Please Enter Description' }]}
        initialValue="Description"
      >
        <InputComponent placeholder="Enter Description" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Can Customer Skip</span>
        <Form.Item
          name={['widgetConfiguration', 'customerCanSkipPage']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <Form.Item
        name={['widgetConfiguration', 'minProductsToOffer']}
        dependencies={[['widgetConfiguration', 'maxProductsToOffer']]}
        rules={[
          number,
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Max number of product is required'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && Number(value) < 1) {
                return Promise?.reject(
                  new Error('Minimum Number of Character should be 1')
                );
              }

              if (
                getFieldValue(['widgetConfiguration', 'maxProductsToOffer']) &&
                value &&
                Number(value) >
                  getFieldValue(['widgetConfiguration', 'maxProductsToOffer'])
              ) {
                return Promise?.reject(
                  new Error(
                    `Please Enter value less than ${getFieldValue([
                      'widgetConfiguration',
                      'maxProductsToOffer'
                    ])}`
                  )
                );
              }
              return Promise?.resolve();
            }
          })
        ]}
        label="Min. Number of Products to be suggested"
      >
        <NumberComponent
          min={1}
          allowClear
          placeholder="Enter Number of Products"
        />
      </Form.Item>
      <Form.Item
        rules={[
          number,
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Min number of product is required'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && Number(value) < 1) {
                return Promise?.reject(
                  new Error('Minimum Number of Products should be 1')
                );
              }
              if (
                getFieldValue(['widgetConfiguration', 'minProductsToOffer']) &&
                value &&
                Number(value) <
                  getFieldValue(['widgetConfiguration', 'minProductsToOffer'])
              ) {
                return Promise?.reject(
                  new Error(
                    `Please Enter value greater than ${getFieldValue([
                      'widgetConfiguration',
                      'minProductsToOffer'
                    ])}`
                  )
                );
              }
              return Promise?.resolve();
            }
          })
        ]}
        name={['widgetConfiguration', 'maxProductsToOffer']}
        label="Max. Number of Products to be suggested"
        dependencies={[['widgetConfiguration', 'minProductsToOffer']]}
      >
        <NumberComponent
          min={1}
          allowClear
          placeholder="Enter Number of Products"
        />
      </Form.Item>

      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Allow Duplicate Product </span>
        <Form.Item
          name={['widgetConfiguration', 'allowDuplicateProductsFromPackage']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default AddOnWidgetForm;
