import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Divider } from 'antd';
import { every, filter, forEach, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { fetchStep } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { UPDATE_INDUSTRY_LOB } from '../graphql/Mutations';
import { GET_INDUSTRIES } from '../graphql/Queries';
import StepProcess from '../pages/StepProcess';

const CheckboxGroup = Checkbox.Group;

const Industry = () => {
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  const { data } = useQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        sortOn: 'id',
        sortBy: 'ASC'
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const checkboxArray = [];

      forEach(res?.industries?.data, (industry) => {
        const activeData = filter(
          industry?.lobSaData,
          (item) => item?.isActive
        );
        checkboxArray?.push({
          indeterminate:
            activeData?.length > 0
              ? !!(industry?.lobSaData?.length !== activeData?.length)
              : false,
          checkedAll: !!(industry?.lobSaData?.length === activeData?.length),
          checkedChildren: map(industry?.lobSaData, (lob) => {
            if (lob?.isActive === true) {
              return lob?.id;
            }
          }),
          id: industry?.id
        });
      });

      setCheckBoxArray(checkboxArray);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [updateIndustryLOB, { loading: mutationLoading }] = useMutation(
    UPDATE_INDUSTRY_LOB,
    {
      onCompleted: () => {
        fetchStep({ dispatch, setLoading: false, changeRoute: false });
        history?.push(`${ROUTES?.ONBOARDING}/region`);
      },
      onError: () => {}
    }
  );

  const onChange = (list, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === industry?.lobSaData?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }
    setCheckBoxArray(checkboxArrayCopy);
  };

  const onCheckAllChange = (e, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(industry?.lobSaData, (area) => checked?.push(area?.id));
    }
    checkboxArrayCopy[index].indeterminate = false;
    checkboxArrayCopy[index].checkedAll = e?.target?.checked;
    checkboxArrayCopy[index].checkedChildren = checked;
    setCheckBoxArray(checkboxArrayCopy);
  };

  const sendIndustry = () => {
    const sendData = [];
    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        sendData?.push({
          id: item?.id,
          lineOfBusinessIds: item?.checkedChildren
        });
      }
    });
    updateIndustryLOB({
      variables: {
        data: {
          selectedIndustries: sendData
        }
      }
    });
  };

  return (
    <StepProcess>
      {loading ? (
        <LoaderComponent spinning={loading} />
      ) : (
        <div className="industry">
          <span className="steps-content-title">Select your Industry(ies)</span>
          <span className="steps-content-description">
            Please select Industry & Line Of Business which support for this
            brand.
          </span>
          {data?.industries?.data?.length > 0 && (
            <div className="d-flex justify-center flex-wrap block-parent">
              {map(data?.industries?.data, (industry, index) => {
                if (industry?.isActive) {
                  return (
                    <div className="industry-block" key={industry?.id}>
                      <Checkbox
                        className="common-checkbox"
                        id={industry?.id}
                        indeterminate={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.indeterminate
                        }
                        onChange={(e) => onCheckAllChange(e, industry, index)}
                        checked={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.checkedAll
                        }
                      >
                        <span className="bold-label">{industry?.label}</span>
                      </Checkbox>
                      <Divider />
                      <div className="d-flex">
                        <CheckboxGroup
                          options={map(industry?.lobSaData, (area) => {
                            return {
                              label: area?.label,
                              value: area?.id
                            };
                          })}
                          value={
                            checkBoxArray?.length > 0 &&
                            checkBoxArray?.[index]?.checkedChildren
                          }
                          className="common-checkbox subarea-group"
                          onChange={(list) => onChange(list, industry, index)}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <Button
            type="primary"
            className="industry-button submit-btn"
            loading={mutationLoading}
            disabled={every(
              checkBoxArray,
              (item) => item?.checkedChildren?.length < 1
            )}
            onClick={sendIndustry}
          >
            Continue to Region Creation
          </Button>
        </div>
      )}
    </StepProcess>
  );
};

export default Industry;
