import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Row, Switch } from 'antd';
import React, { useEffect } from 'react';
import { REGEX } from '../../../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../../../common/utils';
import InputComponent from '../../../../../../../../components/InputComponent';
import NumberComponent from '../../../../../../../../components/NumberComponent';
import PriceComponent from '../../../../../../../../components/PriceComponent';

const { number } = formValidatorRules;

const CustomNumberFieldForm = ({ form }) => {
  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form
  );

  const showDefaultValue = Form?.useWatch(
    ['widgetConfiguration', 'config', 'defaultCheck'],
    form
  );
  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form
  );

  const isReadOnly = Form?.useWatch(
    ['widgetConfiguration', 'config', 'rules'],
    form
  )?.readOnly;

  const maxNumber = Form?.useWatch(
    ['widgetConfiguration', 'config', 'range', 'maxNumber'],
    form
  );

  const minNumber = Form?.useWatch(
    ['widgetConfiguration', 'config', 'range', 'minNumber'],
    form
  );

  const decimalPlace = Form?.useWatch(
    ['widgetConfiguration', 'config', 'decimalPlace'],
    form
  );

  useEffect(() => {
    if (isReadOnly) {
      form?.setFieldsValue({
        widgetConfiguration: {
          config: {
            defaultCheck: true
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadOnly]);

  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'label']}
            label="Label"
            rules={[{ required: true, message: 'Please Enter Label' }]}
          >
            <InputComponent name="label" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'decimalPlace']}
            label="Decimal Places"
            normalize={(value) => Number(value)}
            rules={[
              {
                required: true,
                message: 'Please Enter Decimal Places'
              },
              number
            ]}
          >
            <NumberComponent
              min={0}
              allowClear
              placeholder="Enter Decimal Places"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'range', 'minNumber']}
            label="Min Number"
            className="label-with-tooltip"
            dependencies={[
              ['widgetConfiguration', 'config', 'range', 'maxNumber']
            ]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 0) {
                    return Promise?.reject(
                      new Error('Minimum number should be 0')
                    );
                  }
                  if (
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'range',
                      'maxNumber'
                    ]) &&
                    value &&
                    Number(value) >
                      getFieldValue([
                        'widgetConfiguration',
                        'config',
                        'range',
                        'maxNumber'
                      ])
                  ) {
                    return Promise?.reject(
                      new Error(
                        `Please Enter number less than ${getFieldValue([
                          'widgetConfiguration',
                          'config',
                          'range',
                          'maxNumber'
                        ])}`
                      )
                    );
                  }
                  return Promise?.resolve();
                }
              })
            ]}
          >
            <NumberComponent
              min={0}
              allowClear
              placeholder="Enter Min Number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'range', 'maxNumber']}
            label="Max Number"
            dependencies={[
              ['widgetConfiguration', 'config', 'range', 'minNumber']
            ]}
            className="label-with-tooltip"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 0) {
                    return Promise?.reject(
                      new Error('Minimum number should be 0')
                    );
                  }
                  if (
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'range',
                      'minNumber'
                    ]) &&
                    value &&
                    Number(value) <
                      getFieldValue([
                        'widgetConfiguration',
                        'config',
                        'range',
                        'minNumber'
                      ])
                  ) {
                    return Promise?.reject(
                      new Error(
                        `Please Enter number greater than ${getFieldValue([
                          'widgetConfiguration',
                          'config',
                          'range',
                          'minNumber'
                        ])}`
                      )
                    );
                  }
                  return Promise?.resolve();
                }
              })
            ]}
          >
            <NumberComponent
              min={0}
              allowClear
              placeholder="Enter Max Number"
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Default Value</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'defaultCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'default']}
        hidden={!showDefaultValue}
        rules={[
          {
            required: showDefaultValue || isReadOnly,
            message: 'Please Enter Default Value'
          },
          () => ({
            validator(_, value) {
              if (value) {
                if (minNumber && maxNumber) {
                  if (!(value >= minNumber && value <= maxNumber)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be between ${minNumber} to ${maxNumber}`
                    );
                  }
                } else if (minNumber && !maxNumber) {
                  if (!(value >= minNumber)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be greater than ${minNumber}`
                    );
                  }
                } else if (maxNumber && !minNumber) {
                  if (!(value <= maxNumber)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(`should be less than ${maxNumber}`);
                  }
                }
              }
              return Promise?.resolve();
            }
          })
        ]}
      >
        <PriceComponent
          decimalValue={decimalPlace}
          maxValue={maxNumber}
          minValue={minNumber}
          isPrice={false}
          prefix=""
          placeholder="Enter Default Value"
        />
      </Form.Item>

      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltip']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'tooltipValue']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value'
          }
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'descriptionValue']}
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description'
          }
        ]}
      >
        <InputComponent.TextArea
          className="common-textarea"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Enter Description"
        />
      </Form.Item>
    </div>
  );
};

export default CustomNumberFieldForm;
