import React from 'react';
import { DYNAMIC_FIELDS_DATA_KEYS } from '../../../../../../common/constants';
import SystemBooleanPreview from '../component/boolean/preview';
import SystemNumberPreviewForm from '../component/number/preview';
import SystemPickListWidgetComponent from '../component/pickList/preview';
import SystemTextPreviewForm from '../component/text/preview';

const SystemWidgetComponent = ({
  widgetConfig,
  fieldObject,
  systemFieldData
}) => {
  const fieldType =
    widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldType ||
    fieldObject?.selectedSystemFieldType;

  return (
    <div>
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
        <SystemPickListWidgetComponent systemFieldData={systemFieldData} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.ENUM_PICK_LIST && (
        <SystemPickListWidgetComponent systemFieldData={systemFieldData} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.TEXT && (
        <SystemTextPreviewForm
          systemFieldData={systemFieldData}
          widgetConfig={widgetConfig}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN && (
        <SystemBooleanPreview systemFieldData={systemFieldData} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER && (
        <SystemNumberPreviewForm
          systemFieldData={systemFieldData}
          widgetConfig={widgetConfig}
        />
      )}
    </div>
  );
};

export default SystemWidgetComponent;
