import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation webLogin($data: loginInput!) {
    webLogin(data: $data) {
      authToken
      user {
        id
        firstName
        lastName
        roles
        email
        phoneNo
        tenantId
        profileImage {
          url
          key
          name
          contentType
          extension
        }
        tenantId
        emailVerified
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation webForgotPassword($email: EmailAddress!) {
    webForgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: resetPasswordInput!) {
    resetPassword(data: $data) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation verifyToken($data: verifyTokenInput!) {
    verifyToken(data: $data) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser($auth_token: String) {
    logoutUser(data: { auth_token: $auth_token }) {
      status
      message
    }
  }
`;
