import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, message } from 'antd';
import { capitalize, map, startCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import TableComponent from '../../../components/TableComponent';
import { DELETE_FILE_ITEM, MARK_AS_VERIFIED } from '../graphql/Mutations';
import { IMPORT_ITEMS } from '../graphql/Queries';

const VerifyTable = () => {
  const params = useParams();
  const history = useHistory();
  const allColumns = [];
  const {
    state: { pageSize }
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });

  const { data, refetch } = useQuery(IMPORT_ITEMS, {
    variables: {
      limit: pageSize,
      skip: 0,
      sortBy: 'DESC',
      where: {
        importFileId: params?.fileId
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const paginationProps = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.importItems?.count
      };
      setPaginationProp(paginationProps);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [markAsVerified] = useMutation(MARK_AS_VERIFIED, {
    onCompleted: async () => {
      if (
        !selectedKeys?.length ||
        data?.importItems?.importItems?.length === selectedKeys?.length
      ) {
        return history?.push(`${ROUTES?.IMPORTS}`);
      }
      setSelectedKeys([]);
      try {
        const response = await refetch({
          variables: {
            limit: pageSize,
            skip: (paginationProp?.current - 1) * 10,
            sortBy: 'DESC',
            where: {
              importFileId: params?.fileId
            }
          }
        });
        setPaginationProp((prevState) => {
          const newState = {
            ...prevState,
            total: response?.data?.importItems?.count
          };
          return newState;
        });
        setLoading(false);
      } catch {
        setLoading(false);
        message?.error('Error while fetching data');
      }
    },
    onError: () => {
      setLoading(false);
    }
  });

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.importStatus === true
    }),
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id
      });
    }
  };

  const handleMark = () => {
    setLoading(true);
    markAsVerified({
      variables: {
        id: selectedKeys?.length ? selectedKeys : undefined,
        importedFileId: params?.fileId
      }
    });
  };

  const [deleteFileItem] = useMutation(DELETE_FILE_ITEM, {
    onCompleted: async () => {
      if (data?.importItems?.importItems?.length === 1) {
        return history?.push(`${ROUTES?.IMPORTS}`);
      }
      try {
        const response = await refetch({
          variables: {
            limit: pageSize,
            skip: (paginationProp?.current - 1) * 10,
            sortBy: 'DESC',
            where: {
              importFileId: params?.fileId
            }
          }
        });
        setPaginationProp((prevState) => {
          const newState = {
            ...prevState,
            total: response?.data?.importItems?.count
          };
          return newState;
        });
        setLoading(false);
      } catch {
        setLoading(false);
        message?.error('Error while fetching data');
      }
    },
    onError: () => {
      setLoading(false);
    }
  });

  const handleDelete = async (record) => {
    setLoading(true);
    await deleteFileItem({
      variables: {
        id: record?.id
      }
    });
  };

  const handleEdit = (record) => {
    history?.push(`${ROUTES?.IMPORTS}/${params?.fileId}/edit/${record?.id}`);
  };

  if (data?.importItems?.importItems?.length > 0) {
    const objectKeys = Object?.keys(
      data?.importItems?.importItems?.[0]?.importedData
    );
    objectKeys.forEach((item) => {
      const columnsData = {
        title: startCase(item),
        dataIndex: item,
        key: item,
        ellipsis: true,
        width: 100,
        render: (text) => {
          if (text === true) {
            return 'Yes';
          }
          if (text === false) {
            return 'No';
          }
          if (!text) {
            return (
              <div
                style={{
                  textAlign: 'center'
                }}
              >
                -
              </div>
            );
          }
          return capitalize(text);
        }
      };
      allColumns?.push(columnsData);
    });
  }

  // commented for now because verified record will be deleted.
  /* const statusColumn = {
    title: 'Status',
    dataIndex: 'importStatus',
    ellipsis: true,
    key: 'importStatus',
    width: 100,
    render: (importStatus) => {
      if (importStatus === true) {
        return <span>Verified</span>;
      }
      return null;
    }
  };
  allColumns?.push(statusColumn); */

  const actionColumn = {
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (text, record) => {
      if (record?.importStatus !== true) {
        return (
          <div>
            <Button onClick={() => handleEdit(record)}>
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure you want to delete"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              placement="left"
              cancelText="No"
            >
              <Button className="error-file">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  };
  allColumns?.push(actionColumn);

  const handleTableChange = async (pagination) => {
    setLoading(true);
    const { current = '', pageSize: paginationPageSize } = pagination;
    setPaginationProp({ ...paginationProp, ...pagination });
    try {
      await refetch({
        limit: paginationPageSize,
        skip: (current - 1) * pageSize,
        where: {
          importFileId: params?.fileId
        }
      });
      setLoading(false);
    } catch {
      setLoading(false);
      message?.error('Error while fetching data');
    }
  };

  return (
    <div className="verify-import-table">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="portal-header">
            {data?.importItems?.importItems?.[0]?.importType}
          </span>
        </div>
      </Portal>
      {data?.importItems?.importItems?.length > 0 && (
        <Portal portalId="header-right-content">
          {!selectedKeys?.length ? (
            <Popconfirm
              title="Are you sure you want to verify all the records of csv?"
              onConfirm={handleMark}
              okText="Yes"
              placement="left"
              cancelText="No"
            >
              <Button type="primary">Mark As Verified</Button>
            </Popconfirm>
          ) : (
            <Button type="primary" onClick={handleMark}>
              Mark As Verified
            </Button>
          )}
        </Portal>
      )}
      <TableComponent
        columns={allColumns}
        data={map(data?.importItems?.importItems, (item) => {
          return {
            id: item?.id,
            importStatus: item?.importStatus,
            ...item?.importedData
          };
        })}
        loadingData={loading}
        rowKey={(record) => record?.id}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowSelection={rowSelection}
        isSearch={false}
      />
    </div>
  );
};

export default VerifyTable;
