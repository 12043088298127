import { gql } from '@apollo/client';

export const MARK_AS_VERIFIED = gql`
  mutation markAsVerified($id: [ID], $importedFileId: ID!) {
    markAsVerified(where: { id: $id, importedFileId: $importedFileId }) {
      id
      importedFileId
      importedData
      importStatus
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_FILE_ITEM = gql`
  mutation deleteFileItem($id: ID!) {
    deleteFileItem(where: { id: $id }) {
      id
      importedFileId
      importedData
      importStatus
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_FILE_ITEM = gql`
  mutation updateFileItem(
    $data: UpdateImportItemInput
    $where: ImportItemWhereInput!
  ) {
    updateFileItem(where: $where, data: $data) {
      id
      importedFileId
      importedData
      importStatus
      tenantId
      createdAt
      updatedAt
    }
  }
`;
