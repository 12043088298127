import { Layout } from 'antd';
import React from 'react';

const { Header } = Layout;

const AppHeader = () => {
  return (
    <Header>
      <div className="header-portal-wrapper d-flex justify-between align-center fill-width">
        <div id="header-left-content" />
        <div id="header-right-content" />
      </div>
    </Header>
  );
};
export default AppHeader;
