import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query customers($filter: CustomerFilter!, $where: CustomerWhereFilter) {
    customers(filter: $filter, where: $where) {
      count
      data {
        id
        user {
          id
          firstName
          lastName
          isActive
          phoneNo
          email
        }
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
        customerType
        fax
        userId
        tollFree
        web
        csmId
        salesManagerId
        technician
        industry
        descriptionBusiness
        isActive
        tenantId
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customer($id: ID!) {
    customer(where: { id: $id }) {
      id
      user {
        id
        firstName
        lastName
        isActive
        phoneNo
        email
      }
      location {
        addressLine1
        city
        state
        country
        zipCode
      }
      customerType
      customerTypeData {
        label
      }
      fax
      userId
      tollFree
      web
      csmId
      csmObj {
        firstName
        lastName
      }
      salesManagerId
      salesManagerObj {
        firstName
        lastName
      }
      technician
      industry
      descriptionBusiness
      isActive
      tenantId
    }
  }
`;

export const CUSTOMER_FILTER = gql`
  query customerFilters($filter: CustomerFilter!) {
    customerFilters(filter: $filter) {
      count
      data {
        user {
          id
          firstName
          lastName
          isActive
          phoneNo
          email
        }
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
        customerType
        fax
        userId
        tollFree
        web
        csmId
        salesManagerId
        technician
        industry
        descriptionBusiness
        isActive
        tenantId
      }
    }
  }
`;

export const USER_FILTER = gql`
  query userFilters($filter: UserFilter!) {
    userFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
        tenantId
        createdAt
        updatedAt
        emailVerified
        locations {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($filter: UserFilter!, $where: UsersWhereFilter) {
    users(filter: $filter, where: $where) {
      count
      data {
        id
        firstName
        lastName
        isActive
        roles
        roleValues {
          id
          key
          label
        }
      }
    }
  }
`;

export const CUSTOMER_TYPES = gql`
  query customerTypes(
    $filter: CustomerTypeFilter!
    $where: CustomerTypeWhereFilter
  ) {
    customerTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
      }
    }
  }
`;

export const GET_PROPERTIES = gql`
  query properties(
    $filter: PropertyFilter!
    $where: PropertyWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    properties(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        name
        builtYear
        isActive
        propertyValue
        floor
        historyDistrict
        elevator
        internalSqFt
        lotSqFt
        cornerLot
        lotsFrontWidth
        lotsRightWidth
        lotsLeftWidth
        lotsBackWidth
        parking
        taxParcel
        pinNumber
        centralAir
        centralHeat
        heatSource
        waterHookUp
        noBaths
        noHalfBaths
        finishedBasement
        finishedBasementCeiling
        propertyLastConfirmed
        lockBoxCode
        propertyManagementCompany
        propertyManagerPhone
        thirdPartyProvider
        propertyType
        propertyFinishedBasement
        propertyFinishedCeiling
        tenantId
        location {
          addressLine1
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query contacts(
    $filter: ContactFilter!
    $where: ContactWhereFilter
    $salesWhere: SalesWhereFetchInput
  ) {
    contacts(filter: $filter, where: $where, salesWhere: $salesWhere) {
      count
      data {
        id
        homePhone
        mainPhone
        userInfo {
          firstName
          lastName
          email
          phoneNo
        }
        location {
          addressLine1
          city
          state
          zipCode
        }
        primaryContact
        authorizedServiceContact
        web
        isActive
        preferredContact
        contactType
      }
    }
  }
`;

export const PROPERTY_TYPES_FILTER = gql`
  query propertyTypes(
    $filter: PropertyTypeFilter!
    $where: PropertyTypeWhereFilter
  ) {
    propertyTypes(filter: $filter, where: $where) {
      count
      data {
        label
        key
      }
    }
  }
`;
