import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Button,
  Carousel,
  Col,
  List,
  Popover,
  Row,
  Tabs,
  Typography
} from 'antd';
import { map } from 'lodash';
import React from 'react';
import placeHolderImage from '../../../../../../assets/images/place-holder-image-master.svg';
import './QuoteWidgetComponent.less';

const { Paragraph } = Typography;

const ListDataSource = [
  {
    name: 'Line Item',
    quantity: 1,
    price: 'x,xxx.xx'
  },
  {
    name: 'Line Item',
    quantity: 1,
    price: 'x,xxx.xx'
  },
  {
    name: 'Line Item',
    quantity: 1,
    price: 'x,xxx.xx'
  }
];

const QuoteWidgetComponent = ({ widgetConfig = null, selectedTab = '' }) => {
  const { productQuantityCheck = false } = widgetConfig?.packageSettings;

  const renderProducts = (item = null) => {
    let productNameSpan;
    if (widgetConfig?.packageSettings?.itemizedPricing) {
      if (!productQuantityCheck) {
        productNameSpan = 18;
      } else {
        productNameSpan = 16;
      }
    } else {
      productNameSpan = 22;
    }

    return (
      <List.Item>
        <Row className="fill-width" align="middle">
          {productQuantityCheck && (
            <Col span={2}>
              <span className="quantity">{item?.quantity}x</span>
            </Col>
          )}
          <Col span={productNameSpan}>
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: 'Read More'
              }}
              className="product-name"
              title={item?.name}
            >
              {item?.name}
            </Paragraph>
          </Col>
          {widgetConfig?.packageSettings?.itemizedPricing && (
            <Col span={6} className="price-col">
              <span className="product-price">${item?.price}</span>
            </Col>
          )}
        </Row>
      </List.Item>
    );
  };

  const tabContents = (quoteLabel = null) => {
    let priceIcon;
    let marketingIcon;
    if (quoteLabel?.price?.icon?.length > 0) {
      if (quoteLabel?.price?.icon?.[0]?.url) {
        priceIcon = quoteLabel?.price?.icon?.[0]?.url;
      } else {
        priceIcon = URL?.createObjectURL(
          quoteLabel?.price?.icon?.[0]?.originFileObj
        );
      }
    } else {
      priceIcon = quoteLabel?.price?.icon?.url;
    }
    if (quoteLabel?.marketingBlock?.icon?.length > 0) {
      if (quoteLabel?.marketingBlock?.icon?.[0]?.url) {
        marketingIcon = quoteLabel?.marketingBlock?.icon?.[0]?.url;
      } else {
        marketingIcon = URL?.createObjectURL(
          quoteLabel?.marketingBlock?.icon?.[0]?.originFileObj
        );
      }
    } else {
      marketingIcon = quoteLabel?.marketingBlock?.icon?.url;
    }
    return (
      <>
        {widgetConfig?.compareCheck && (
          <Button size="large" className="compare-quote-btn">
            {widgetConfig?.compareSection?.inputButton}
          </Button>
        )}
        <Carousel
          className="common-carousel"
          dots={false}
          arrows
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
        >
          {widgetConfig?.packageSettings?.packageImage &&
            (quoteLabel?.attachments?.length > 0 ? (
              map(quoteLabel?.attachments, (item) => (
                <div key={item?.id} className="product-image">
                  <img alt={item?.label} src={item?.url} />
                </div>
              ))
            ) : (
              <div className="product-image">
                <img alt="no-product" src={placeHolderImage} />
              </div>
            ))}
        </Carousel>
        <div className="product-label">
          {widgetConfig?.packageSettings?.packageTitle && 'Better Product Name'}
        </div>
        <div
          className="editor-render package-description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: quoteLabel?.description }}
        />
        {widgetConfig?.packageSettings?.showProducts && (
          <List dataSource={ListDataSource} renderItem={renderProducts} />
        )}
        <div className="price-card">
          <div className="price-section">
            <div className="price-row">
              {priceIcon && (
                <img alt="price-icon" src={priceIcon} className="price-icon" />
              )}
              <span className="price">$x,xxx.xx</span>
            </div>
            {quoteLabel?.price?.costPerDay && (
              <div>
                <span className="cost-per-day">Cost per day: $x.xx</span>
              </div>
            )}
          </div>
        </div>
        <div className="price-description">
          {quoteLabel?.price?.descriptionCheck &&
            quoteLabel?.price?.descriptionText?.length > 0 && (
              <span>{quoteLabel?.price?.descriptionText}</span>
            )}{' '}
          <span className="price-factor">
            <Popover
              overlayClassName="overlay-text"
              placement="topLeft"
              content={
                <span>
                  {quoteLabel?.price?.tooltipDescriptionCheck &&
                    quoteLabel?.price?.tooltipDescriptionText?.length > 0 &&
                    quoteLabel?.price?.tooltipDescriptionText}
                </span>
              }
            >
              {quoteLabel?.price?.toolTipCheck &&
                quoteLabel?.price?.toolTipText?.length > 0 && (
                  <span>{quoteLabel?.price?.toolTipText}</span>
                )}
            </Popover>
          </span>
        </div>
        <div className="marketing-section">
          <div className="marketing-card customer-card">
            {marketingIcon && (
              <img
                alt="marketing-block-icon"
                className="marketing-icon"
                src={marketingIcon}
              />
            )}
            {quoteLabel?.marketingBlock?.title?.length > 0 && (
              <span className="marketing-title">
                {quoteLabel?.marketingBlock?.title}
              </span>
            )}
            {quoteLabel?.marketingBlock?.description?.length > 0 && (
              <span className="marketing-description">
                {quoteLabel?.marketingBlock?.description}
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  const tabLabel = (configOption = 'lowCostOption') => {
    let imageUrl;
    if (widgetConfig?.[configOption]?.quoteVariantIcon?.length > 0) {
      if (widgetConfig?.[configOption]?.quoteVariantIcon?.[0]?.url) {
        imageUrl = widgetConfig?.[configOption]?.quoteVariantIcon?.[0]?.url;
      } else {
        imageUrl = URL?.createObjectURL(
          widgetConfig?.[configOption]?.quoteVariantIcon?.[0]?.originFileObj
        );
      }
    } else {
      imageUrl = widgetConfig?.[configOption]?.quoteVariantIcon?.url;
    }
    return (
      <div className="d-flex flex-vertical align-center">
        {widgetConfig?.icons && (
          <img
            alt="quote-icon"
            className="quote-icon"
            src={imageUrl || placeHolderImage}
          />
        )}
        <span>{widgetConfig?.[configOption]?.title}</span>
      </div>
    );
  };

  const tabItems = [
    widgetConfig?.lowCostOption?.enable && {
      label: tabLabel('lowCostOption'),
      key: 'lowCostOption',
      children: tabContents(widgetConfig?.lowCostOption)
    },
    widgetConfig?.recommendedOption?.enable && {
      label: tabLabel('recommendedOption'),
      key: 'recommendedOption',
      children: tabContents(widgetConfig?.recommendedOption)
    },
    widgetConfig?.premiumOption?.enable && {
      label: tabLabel('premiumOption'),
      key: 'premiumOption',
      children: tabContents(widgetConfig?.premiumOption)
    }
  ];

  return (
    <div className="quotes-page">
      {widgetConfig?.brandInfo?.logo?.url && (
        <div className="logo-section">
          <img alt="logo" src={widgetConfig?.brandInfo?.logo?.url} />
        </div>
      )}
      <div className="tab-section">
        <Tabs
          className={`product-tabs ${
            selectedTab === 'recommendedOption' ? 'recommended-transform' : ''
          } ${widgetConfig?.icons ? 'label-with-icon' : ''}`}
          animated
          activeKey={selectedTab}
          size="large"
          centered
          items={tabItems}
        />
      </div>
    </div>
  );
};

export default QuoteWidgetComponent;
