import { Form, Radio } from 'antd';
import { map } from 'lodash';
import React from 'react';
import SelectComponent from '../../../components/SelectComponent';
import './RenderItems.less';

const { Option } = SelectComponent;

const RenderSelect = ({ selectData = null }) => {
  const { key = '', dynamicFieldListItems = [] } = selectData;
  return (
    <>
      <Form.Item
        initialValue="EQUAL"
        className="filter-form-item"
        name={['dynamicFields', key, 'filter']}
      >
        <Radio.Group
          name={['dynamicFields', key, 'filter']}
          className="common-radio fill-width d-flex flex-vertical align-start radio-filter-options "
        >
          <Radio value="EQUAL">Equals</Radio>
          <Radio value="NOT_EQUAL">Not Equals</Radio>
          <Radio value="CONTAINS">Contain</Radio>
          <Radio value="NOT_CONTAINS">Not Contain</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['dynamicFields', key, 'value']}>
        <SelectComponent
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children ?? '')
              ?.toLowerCase()
              ?.includes(input?.toLowerCase())
          }
        >
          {map(dynamicFieldListItems, (item) => (
            <Option key={item?.id} value={item?.value}>
              {item?.label}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>
    </>
  );
};

export default RenderSelect;
