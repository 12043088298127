import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { objectWithoutKey, uploadFile } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import BrandForm from '../components/BrandForm';
import { UPDATE_BRAND } from '../graphql/Mutations';
import { GET_BRAND } from '../graphql/Queries';

function BrandEdit(props) {
  const {
    history = null,
    match: { params: { id } = {} } = {},
    location = null
  } = props;
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const { data: { brand } = {} } = useQuery(GET_BRAND, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.brand?.logo?.url) {
        setLogoUrl({
          ...res?.brand?.logo
        });
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const logo = await uploadFile({
      logoObject: formValues?.logo,
      currentUser,
      uuid: brand?.uuid,
      folder: 'brand'
    });

    const newFormValues = {
      ...formValues,
      uuid: brand?.uuid,
      logo: formValues?.logo?.url
        ? { ...brand?.logo, __typename: undefined }
        : logo
    };

    const variables = { data: newFormValues, where: { id: brand?.id } };

    try {
      const response = await updateBrand({
        variables: { ...variables }
      });
      if (response?.data?.updateBrand) {
        setSubmitLoading(false);
        history?.push(ROUTES?.RETAILERS, { ...location?.state });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.RETAILERS} />;
  }

  const initialValues = {
    ...(brand && { ...objectWithoutKey(brand, 'logo') }),
    primaryColor: brand?.primaryColor || undefined,
    ...(brand?.logo?.url && {
      logo: { ...brand?.logo, ...logoUrl }
    }),
    secondaryColors: brand?.secondaryColors || undefined,
    tertiaryColors: brand?.tertiaryColors || undefined,
    description: brand?.description || '',
    waiverText: brand?.waiverText || '',
    priceGuarantee: brand?.priceGuarantee || '',
    offerText: brand?.offerText || '',
    productDetails: brand?.productDetails || '',
    isActive: brand?.isActive
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          {brand && <span className="portal-header">{brand?.name}</span>}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <BrandForm
          isEdit
          brandData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default withRouter(BrandEdit);
