import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($where: UserWhereInput!, $data: UserInput!) {
    updateUser(where: $where, data: $data) {
      status
      message
      data {
        id
        firstName
        lastName
        roles
        email
        phoneNo
        tenantId
        profileImage {
          url
          key
          name
          contentType
          extension
        }
        tenantId
        emailVerified
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: changePasswordInput!) {
    changePassword(data: $data) {
      status
      message
    }
  }
`;
