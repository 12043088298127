import { Card } from 'antd';
import React from 'react';
import Portal from '../../../components/Portal';
import ApiSettingForm from '../components/ApiSettingForm';

const ApiSettingPage = () => {
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">API Setting</span>
        </div>
      </Portal>
      <ApiSettingForm />
    </Card>
  );
};

export default ApiSettingPage;
