import { Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import SelectComponent from '../../../../../../components/SelectComponent';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import NumberComponent from '../../../previewComponents/globalComponents/NumberComponent';
import './customerInfoWidget.less';

const { Option } = SelectComponent;

const CustomerInfoComponent = ({ widgetConfig = null }) => {
  return (
    <div className="content-page">
      <InputComponent
        allowClear
        placeholder={`${widgetConfig?.personalInfo?.name?.labelText} *`}
        readOnly
        className="mt-15"
      />

      <InputComponent
        allowClear
        placeholder={`${widgetConfig?.personalInfo?.email?.labelText} *`}
        readOnly
        className="mt-15"
      />
      {widgetConfig?.personalInfo?.phone?.enable && (
        <InputComponent
          readOnly
          allowClear
          placeholder={`${widgetConfig?.personalInfo?.phone?.labelText} *`}
          className="mt-15"
        />
      )}

      {widgetConfig?.addressInfo?.enable && (
        <>
          <div className="location-title mt-15">
            {widgetConfig?.addressInfo?.title?.labelText}
          </div>
          <Form.Item name="street">
            <InputComponent allowClear placeholder="Street*" readOnly />
          </Form.Item>

          <Form.Item name="zipCode">
            <NumberComponent
              disabled
              allowClear
              placeholder="Postal Code*"
              readOnly
            />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="state">
                <SelectComponent placeholder="State*" disabled readOnly>
                  {map([], (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city">
                <SelectComponent placeholder="City*" disabled readOnly>
                  {map([], (city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          {widgetConfig?.addressInfo?.description?.enable &&
            widgetConfig?.addressInfo?.description?.labelText?.length > 0 && (
              <div
                className="step-description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    widgetConfig?.addressInfo?.description?.labelText || '-'
                }}
              />
            )}
        </>
      )}
    </div>
  );
};

export default CustomerInfoComponent;
