import { Form, Tabs } from 'antd';
import React from 'react';
import ManualEntryForm from './ManualEntryForm';
import MapEntry from './MapEntry';

const OtherConfigsForm = ({
  widgetConfig = null,
  selectedDimensionTab = null
}) => {
  const items = [
    {
      key: 'MAP_ENTRY',
      label: widgetConfig?.mapEntry?.title,
      children: <MapEntry mapEntry={widgetConfig?.mapEntry} />
    },
    {
      key: 'MANUAL_ENTRY',
      label: widgetConfig?.manualEntry?.title,
      children: <ManualEntryForm manualEntry={widgetConfig?.manualEntry} />
    }
  ];

  return (
    <Form initialValues={{ depth: 0.42 }}>
      <Tabs items={items} activeKey={selectedDimensionTab} centered />
    </Form>
  );
};

export default OtherConfigsForm;
