import { Card } from 'antd';
import React from 'react';
import JobList from '../components/JobList';

const JobDetail = () => {
  return (
    <Card>
      <Card>
        <div className="top-view">
          <h2>Basic Job Details</h2>
        </div>
      </Card>
      <Card>
        <div className="bottom-view">
          <JobList />
        </div>
      </Card>
    </Card>
  );
};

export default JobDetail;
