import { Col, Divider, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';

const BrandDetails = ({ data = {}, logoUrl = '' }) => {
  const colorPalleteOptions = [
    {
      key: 'PRIMARY',
      value: 'Just Primary Color'
    },
    {
      key: 'CUSTOM',
      value: 'Custom'
    },
    {
      key: 'CONSERVATIVE',
      value: 'Conservative'
    },
    {
      key: 'MIDDLE_GROUND',
      value: 'Middle Ground'
    },
    {
      key: 'COLORFUL',
      value: 'Colorful'
    }
  ];

  const {
    name = '',
    primaryColor = '',
    isActive = true,
    secondaryColors = '',
    tertiaryColors = '',
    colorPallete = '',
    description = '',
    waiverText = '',
    priceGuarantee = '',
    offerText = '',
    productDetails = ''
  } = data;

  return (
    <div className="content-section">
      <Row className="required-row">
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Logo *</span>
            <img
              src={logoUrl?.url || placeholderImage}
              alt="logo"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="field-logo"
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Title *</span>
            <span className="field-value">{name}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail brand-preview-color">
            <span className="field-label">Primary Color *</span>
            <span className="field-value">
              <div className="brand-preview-color">
                <div
                  style={{
                    background: primaryColor
                  }}
                  className="preview-color"
                />
                {primaryColor}
              </div>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Color Palette</span>
            <span className="field-value">
              {colorPallete ? (
                map(colorPalleteOptions, (option) => {
                  if (option?.key === colorPallete) {
                    return (
                      <span key={option?.key}>{option?.value || '-'}</span>
                    );
                  }
                })
              ) : (
                <span>-</span>
              )}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Secondary Color</span>
            <span className="field-value">
              <div className="brand-preview-color">
                <div
                  style={{
                    background: secondaryColors
                  }}
                  className="preview-color"
                />
                {secondaryColors || '-'}
              </div>
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Tertiary Color</span>
            <span className="field-value">
              <div className="brand-preview-color">
                <div
                  style={{
                    background: tertiaryColors
                  }}
                  className="preview-color"
                />
                {tertiaryColors || '-'}
              </div>
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Description</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description || '-' }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Wavier Text</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: waiverText || '-' }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Offer Text</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: offerText || '-' }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Price Guarantee</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: priceGuarantee || '-' }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Product Detail</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: productDetails || '-' }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BrandDetails;
