import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { ROUTES } from '../../../common/constants';

const QuestionnaireTabs = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('PRIMARY');
  const primaryTab = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">ROQ Builder</span>
    </div>
  );
  const secondaryTab = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">ROQ Path</span>
    </div>
  );

  useEffect(() => {
    if (location?.pathname?.includes('/builder')) {
      setActiveKey('PRIMARY');
    }
    if (location?.pathname?.includes('/path')) {
      setActiveKey('SECONDARY');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    switch (key) {
      case 'PRIMARY':
        history?.push(ROUTES?.QUESTIONNAIRES_PRIMARY);
        break;
      case 'SECONDARY':
        history?.push(ROUTES?.QUESTIONNAIRES_SECONDARY);
        break;
      default:
        break;
    }
  };

  const tabItems = [
    {
      label: primaryTab,
      key: 'PRIMARY',
      children
    },
    {
      label: secondaryTab,
      key: 'SECONDARY',
      children
    }
  ];

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={activeKey}
        onChange={handleSelectTab}
        destroyInactiveTabPane
        items={tabItems}
      />
    </div>
  );
};

export default withRouter(QuestionnaireTabs);
