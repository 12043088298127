import React from 'react';
import { DYNAMIC_FIELDS_DATA_KEYS } from '../../../../../../common/constants';
import CustomBooleanPreview from '../components/boolean/preview';
import CustomDateTimePreview from '../components/dateTime/preview';
import CustomNumberPreview from '../components/number/preview';
import CustomPickListPreview from '../components/pickList/preview';
import CustomTextPreview from '../components/text/preview';
import CustomUploadPreview from '../components/upload/preview';

const CustomWidgetPreview = ({ widgetConfig, checkedList }) => {
  const fieldType = widgetConfig?.fieldType;
  return (
    <div>
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.TEXT && (
        <CustomTextPreview widgetConfig={widgetConfig} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN && (
        <CustomBooleanPreview widgetConfig={widgetConfig} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
        <CustomPickListPreview widgetConfig={widgetConfig} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD && (
        <CustomUploadPreview
          widgetConfig={widgetConfig}
          checkedList={checkedList}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER && (
        <CustomNumberPreview widgetConfig={widgetConfig} />
      )}
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.DATE_TIME && (
        <CustomDateTimePreview widgetConfig={widgetConfig} />
      )}
    </div>
  );
};

export default CustomWidgetPreview;
