import { useLazyQuery, useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import { formatPhoneNumber } from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import { REMOVE_CUSTOMER_REFERENCE } from '../graphql/Mutations';
import { GET_CUSTOMERS } from '../graphql/Queries';

const PropertyCustomerTable = ({
  match: { params: { id } = {} } = {},
  showModal = false
}) => {
  const {
    state: { pageSize }
  } = useContext(AppContext);

  const location = useLocation();

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialCustomerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);

  const [removeCustomerReference] = useMutation(REMOVE_CUSTOMER_REFERENCE, {
    onError() {}
  });

  const [fetchCustomerData, { loading, data }] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.customers?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchCustomerData({
      variables: {
        filter: customerFilter,
        salesWhere: {
          joinedFrom: 'PROPERTY',
          id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showModal) {
      fetchCustomerData({
        variables: {
          filter: customerFilter,
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    } else {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
  };

  const handleDeleteCustomer = async (editContactData) => {
    const response = await removeCustomerReference({
      variables: {
        where: { id: editContactData?.id },
        salesWhere: {
          joinedFrom: 'PROPERTY',
          id
        }
      }
    });
    if (response?.data?.removeCustomerReference) {
      fetchCustomerData({
        variables: {
          filter: customerFilter,
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (firstName, record) => {
        return `${record?.user?.firstName} ${record?.user?.lastName}`;
      }
    },
    {
      title: 'CONTACT',
      width: 130,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phoneNo, record) => {
        return formatPhoneNumber(record?.user?.phoneNo) || '-';
      }
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      width: 130,
      dataIndex: 'email',
      key: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (email, record) => {
        return `${record?.user?.email}`;
      }
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      fixed: location?.pathname?.includes('/view') && 'right',
      key: 'location',
      render: (locationObj) => {
        return `${locationObj?.addressLine1} ${locationObj?.city}, ${locationObj?.state} ${locationObj?.zipCode}`;
      }
    },
    !location?.pathname?.includes('/view') && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (action, record = {}) => {
        return (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteCustomer(record)}
            okText="Yes"
            cancelText="No"
          >
            <img src={deleteIcon} alt="delete-icon" className="item-delete" />
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <div className="common-table tab-table">
        {pageSize && (
          <TableComponent
            isSearch={false}
            setHeight={282}
            loadingData={loading}
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.customers?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PropertyCustomerTable);
