import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import DocumentIconComponent from '../../../app/components/iconComponents/DocumentIconComponent';
import GalleryIconComponent from '../../../app/components/iconComponents/GalleryIconComponent';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { MODULES, ROUTES } from '../../../common/constants';

const ProductTabs = ({
  match: { params: { id } = {} } = {},
  history = null,
  location = null,
  children
}) => {
  const [activeKey, setActiveKey] = useState('INFORMATION');
  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );

  const infoTitleProductItems = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">{MODULES?.PRODUCTS}</span>
    </div>
  );

  const infoTitleGallery = (
    <div>
      <GalleryIconComponent className="tab-icons" />
      <span className="card-title">Gallery</span>
    </div>
  );

  const infoTitleDocuments = (
    <div>
      <DocumentIconComponent className="tab-icons" />
      <span className="card-title">Documents</span>
    </div>
  );

  useEffect(() => {
    if (
      location?.pathname?.includes('/edit') ||
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/add')
    ) {
      setActiveKey('INFORMATION');
    }
    if (location?.pathname?.includes(ROUTES?.PRODUCTS)) {
      setActiveKey('PRODUCTS');
    }
    if (location?.pathname?.includes(ROUTES?.GALLERY)) {
      setActiveKey('GALLERY');
    }
    if (location?.pathname?.includes(ROUTES?.DOCUMENTS)) {
      setActiveKey('DOCUMENTS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/add')) {
          history?.push(`${ROUTES?.PRODUCTS}/add`, {
            ...location?.state
          });
        } else if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit/${id}`, {
            ...location?.state
          });
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view/${id}`, {
            ...location?.state
          });
        }
        break;

      case 'PRODUCTS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/${id}`, {
            ...location?.state
          });
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.PRODUCTS}/${id}`, {
            ...location?.state
          });
        }
        break;

      case 'GALLERY':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.GALLERY}/${id}`, {
            ...location?.state
          });
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.GALLERY}/${id}`, {
            ...location?.state
          });
        }
        break;

      case 'DOCUMENTS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.DOCUMENTS}/${id}`, {
            ...location?.state
          });
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.DOCUMENTS}/${id}`, {
            ...location?.state
          });
        }
        break;

      default:
        break;
    }
  };

  const tabItems = [
    {
      label: infoTitle,
      key: 'INFORMATION',
      children
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleProductItems,
      key: 'PRODUCTS',
      children
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleGallery,
      key: 'GALLERY',
      children
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleDocuments,
      key: 'DOCUMENTS',
      children
    }
  ];

  return (
    <div className="card-container">
      <Tabs
        type="card"
        items={tabItems}
        activeKey={activeKey}
        onChange={handleSelectTab}
      />
    </div>
  );
};

export default withRouter(ProductTabs);
