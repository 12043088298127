import { CheckOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { TinyColor } from '@ctrl/tinycolor';
import { Button, Checkbox, Radio } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { fetchStep } from '../../../common/utils';
import ColorPickerComponent from '../../../components/ColorPickerComponent';
import history from '../../../historyData';
import { UPDATE_TENANT } from '../graphql/Mutations';
import StepProcess from '../pages/StepProcess';

const ColorPalette = () => {
  const { dispatch, getOnboardingData } = useContext(AppContext);
  const [selectedColor, setSelectedColor] = useState(null);
  const [show, setShow] = useState(false);
  const [showSecondary, setShowSecondary] = useState({
    visible: false,
    index: null
  });
  const data = getOnboardingData();
  const [value, setValue] = useState('COLORFUL');
  const [colors, setColors] = useState([
    '#e74c3c',
    '#e67e22',
    '#f1c40f',
    '#1abc9c',
    '#3498db',
    '#9b59b6',
    '#1a1c1f'
  ]);
  const [secondColors, setSecondColors] = useState([
    '#ffffff',
    '#f2f2f2',
    '#000000',
    '#00427f',
    '#1890ff',
    '#1890ff',
    '#1abc9c',
    '#de3838'
  ]);

  const options = [
    { label: 'Colorful', value: 'COLORFUL' },
    { label: 'Middle-Ground', value: 'MIDDLE_GROUND' },
    { label: 'Conservative', value: 'CONSERVATIVE' }
  ];

  const [updateTenant, { loading: colorLoading }] = useMutation(UPDATE_TENANT, {
    onCompleted: () => {
      fetchStep({ dispatch, setLoading: false, changeRoute: false });
      history?.push(`${ROUTES?.ONBOARDING}/industry`);
    },
    onError: () => {}
  });

  const sendColor = () => {
    updateTenant({
      variables: {
        data: {
          tenantInput: {
            colourPallette: {
              primaryColour: selectedColor,
              secondaryColour: secondColors,
              key: value,
              isAgain: !!data?.data?.colourPallette
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (data?.data?.colourPallette) {
      const {
        data: {
          colourPallette: { primaryColour = '', secondaryColour = [], key = '' }
        }
      } = data;
      setSelectedColor(primaryColour);
      setSecondColors(secondaryColour);
      setValue(key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const secondColorsCopy = [...secondColors];
    if (e?.target?.value === 'COLORFUL') {
      const triadic = new TinyColor(selectedColor)
        ?.triad()
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        triadic?.[0],
        triadic?.[1],
        triadic?.[2]
      );
    }
    if (e?.target?.value === 'MIDDLE_GROUND') {
      const analogous = new TinyColor(selectedColor)
        ?.analogous(3)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        analogous?.[0],
        analogous?.[1],
        analogous?.[2]
      );
    }
    if (e?.target?.value === 'CONSERVATIVE') {
      const monoChromatic = new TinyColor(selectedColor)
        ?.monochromatic(2)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(3, 3, monoChromatic?.[0], monoChromatic?.[1]);
    }
    setSecondColors(secondColorsCopy);
    setValue(e?.target?.value);
    setShowSecondary({ visible: false, index: null });
  };

  const showEdit = (index) => {
    if (value !== 'CONSERVATIVE' && [3, 4, 5].includes(index)) {
      return true;
    }
    if (value === 'CONSERVATIVE' && [3, 4].includes(index)) {
      return true;
    }
    return false;
  };

  const handleSelectColor = (color) => {
    const secondColorsCopy = [...secondColors];
    if (value === 'MIDDLE_GROUND') {
      const analogous = new TinyColor(color)
        ?.analogous(3)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        analogous?.[0],
        analogous?.[1],
        analogous?.[2]
      );
    } else if (value === 'CONSERVATIVE') {
      const monoChromatic = new TinyColor(color)
        ?.monochromatic(2)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        monoChromatic?.[0],
        monoChromatic?.[1]
      );
    } else {
      const triadic = new TinyColor(color)
        ?.triad()
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        triadic?.[0],
        triadic?.[1],
        triadic?.[2]
      );
    }
    setSecondColors(secondColorsCopy);
    setShow(false);
    setSelectedColor(color);
  };

  const handlePickColor = (color) => {
    const colorsCopy = [...colors];
    const secondColorsCopy = [...secondColors];

    if (value === 'MIDDLE_GROUND') {
      const analogous = new TinyColor(color)
        ?.analogous(3)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        analogous?.[0],
        analogous?.[1],
        analogous?.[2]
      );
    } else if (value === 'CONSERVATIVE') {
      const monoChromatic = new TinyColor(color)
        ?.monochromatic(2)
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        monoChromatic?.[0],
        monoChromatic?.[1]
      );
    } else {
      const triadic = new TinyColor(color)
        ?.triad()
        ?.map((item) => item?.toHexString());
      secondColorsCopy?.splice(
        3,
        value === 'CONSERVATIVE' ? 2 : 3,
        triadic?.[0],
        triadic?.[1],
        triadic?.[2]
      );
    }

    colorsCopy?.splice(7, 1, color);
    setSecondColors(secondColorsCopy);
    setColors(colorsCopy);
    setSelectedColor(color);
    setShow(false);
  };

  return (
    <StepProcess>
      <div className="color-palette">
        <style>
          {`.ant-checkbox-checked .ant-checkbox-inner { background-color:${selectedColor};border-color:${selectedColor}; }
            .common-radio .ant-radio-checked .ant-radio-inner { border: 6px solid ${selectedColor}; }
            }\
          `}
        </style>
        {!selectedColor ? (
          <>
            <span className="steps-content-title">
              Select the Primary Color for your brand
            </span>
            <span className="steps-content-description">
              The primary color sets the overall feeling for your brand
              appearance. It applies to all of your primary action design
              elements, such as buttons, links, and highlights in the Tenant
              Portal.
            </span>
          </>
        ) : (
          <span className="primary-color color-top-space">Primary Color</span>
        )}
        <div
          className={`d-flex justify-center ${
            !selectedColor ? 'color-list' : 'small-color-list'
          }`}
        >
          {map(colors, (color) => (
            <div
              key={color}
              className={`${
                !selectedColor ? 'color-box' : 'small-color-box slide-up'
              }`}
              onClick={() => handleSelectColor(color)}
              style={{ backgroundColor: color }}
            >
              {selectedColor === color && (
                <CheckOutlined className="check-color" />
              )}
            </div>
          ))}
          <div className="color-section">
            <div
              className={`add-color d-flex justify-center align-center ${
                !selectedColor ? 'color-box' : 'small-color-box slide-up'
              }`}
              onClick={() => setShow(true)}
            >
              <PlusOutlined className="add-icon" />
            </div>
            {show ? (
              <div className="sketch">
                <ColorPickerComponent
                  onOk={handlePickColor}
                  onCancel={(showPicker) => setShow(showPicker)}
                />
              </div>
            ) : null}
          </div>
        </div>
        {selectedColor && (
          <div className="fade-in">
            <span className="steps-content-title">Color Palette</span>
            <span className="color-info steps-content-description">
              Based on your selection and logo, we generated a few color
              palettes for you. You can customize them now, or do it later in
              the settings.
            </span>
            <Radio.Group
              size="large"
              className="radio-button"
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />

            <div className="d-flex justify-center color-list-second">
              {map(secondColors, (color, index) => (
                <div
                  key={`${color}${index}`}
                  className="d-flex justify-center align-center color-box"
                  onClick={() => {
                    if (showEdit(index)) {
                      setShowSecondary({ visible: true, index });
                    }
                  }}
                  style={{
                    backgroundColor: color,
                    border: index < 2 ? '1.5px solid rgba(0, 0, 0, 0.25)' : ''
                  }}
                >
                  {showEdit(index) && <EditOutlined className="edit-icon" />}
                </div>
              ))}
              {showSecondary?.visible ? (
                <div className="second-sketch">
                  <ColorPickerComponent
                    initialColor={secondColors?.[showSecondary.index]}
                    onOk={(color) => {
                      const colorsCopy = [...secondColors];
                      colorsCopy?.splice(showSecondary?.index, 1, color);
                      if (showSecondary?.index === 3) {
                        setSelectedColor(color);
                      }
                      setSecondColors(colorsCopy);
                      setShowSecondary({ visible: false, index: null });
                    }}
                    onCancel={(showPicker) =>
                      setShowSecondary({ visible: showPicker, index: null })
                    }
                  />
                </div>
              ) : null}
            </div>

            <div className="preview-div">
              <span className="preview-text">Preview</span>
              <div className="d-flex justify-between">
                <div className="d-flex flex-vertical justify-around preview-columns">
                  <div className="preview-blocks">
                    <span style={{ color: '#A3A3A3' }}>Placeholder</span>
                  </div>
                  <div className="preview-blocks">
                    <span
                      title={`My name is ${data?.user?.firstName}`}
                      className="line-clamp-text"
                    >
                      My name is {data?.user?.firstName}
                    </span>
                  </div>
                  <div className="preview-blocks error-block">
                    <span>Error</span>
                  </div>
                </div>
                <div className="d-flex flex-vertical justify-around preview-columns">
                  <div
                    className="preview-blocks"
                    style={{
                      backgroundColor: selectedColor,
                      color: '#FFFFFF',
                      fontWeight: 'bold'
                    }}
                  >
                    <span>Primary Button</span>
                  </div>
                  <div
                    className="preview-blocks"
                    style={{
                      backgroundColor: '#F1F3F7',
                      color: selectedColor,
                      fontWeight: 'bold'
                    }}
                  >
                    <span>Secondary Button</span>
                  </div>
                  <div
                    className="preview-link"
                    style={{ color: selectedColor }}
                  >
                    <span>Tertiary Action</span>
                  </div>
                </div>
                <div className="d-flex flex-vertical justify-around preview-columns">
                  <div className="gx-text-left">
                    <Checkbox disabled />
                    <Checkbox checked>Checkbox</Checkbox>
                  </div>
                  <div>
                    <Radio className="common-radio" checked disabled />
                    <Radio checked className="common-radio">
                      Radio Button
                    </Radio>
                  </div>
                  <div>
                    <span className="positive-msg">Positive message</span>
                  </div>
                  <div>
                    <span className="negative-msg">Negative message</span>
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="primary"
              className="color-palette-button submit-btn"
              loading={colorLoading}
              onClick={sendColor}
            >
              Continue to Industry
            </Button>
          </div>
        )}
      </div>
    </StepProcess>
  );
};

export default ColorPalette;
