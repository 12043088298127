import { useLazyQuery } from '@apollo/client';
import { kebabCase, split } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import CpqPanel from '../../../app/components/sidepanel/components/CpqPanel';
import { QUESTIONNAIRE_TYPE } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import BuilderFormComponent from '../components/BuilderFormComponent';
import CpqTemplate from '../components/CpqTemplate';
import PreviewComponent from '../components/previewComponents/previewComponent';
import {
  GET_PAGE_CONFIG,
  GET_TENANT_DATA,
  GET_WIDGETS
} from '../graphql/Queries';

const QuestionnairePageBuilderPage = ({
  history = null,
  match: { params: { id = null, pageKey = null } = {} } = {},
  location = null,
  location: { pathname = {} } = {},
  pageSequencesData = [],
  isNewPage = false,
  refetchPageSequence,
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER
}) => {
  let pageKeyValue = pageKey;
  if (pageKey?.includes('create')) {
    pageKeyValue = null;
  } else if (pageKey?.includes('?sp')) {
    pageKeyValue = split(pageKey, '?sp')?.[0];
  } else {
    pageKeyValue = pageKey;
  }

  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [pageConfigState, setPageConfigState] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [fieldObject, setFieldObject] = useState(null);
  const [systemFieldData, setSystemFieldData] = useState(null);
  // quote preview show/hide
  const [selectedTab, setSelectedTab] = useState('recommendedOption');
  const [selectedLob, setSelectedLob] = useState('');
  const [checkedList, setCheckedList] = useState([]);
  const [selectedAppointmentKey, setSelectedAppointmentKey] = useState(
    'ADDRESS'
  );
  const [refetchSystemFields, setRefetchSystemFields] = useState(false);
  // dimension preview show/hide
  const [selectedDimensionProject, setSelectedDimensionProject] = useState(
    'common'
  );
  const [selectedDimensionTab, setSelectedDimensionTab] = useState(null);
  const [tenantLogoDetails, setTenantLogoDetails] = useState(null);
  const [tenantContactDetails, setTenantContactDetails] = useState(null);

  const [formLoading, setFormLoading] = useState(false);

  const [pageConfig] = useLazyQuery(GET_PAGE_CONFIG, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      setFormLoading(true);
      const pageKeyCopy = res?.pageConfig?.pageKey || res?.pageConfig?.title;
      let newPageKey;
      if (res?.pageConfig?.pageKey) {
        if (type !== QUESTIONNAIRE_TYPE?.ROQ_BUILDER) {
          newPageKey = pageKeyCopy?.slice(2, pageKeyCopy?.length); // remove p- from pagekey in edit page
        } else {
          newPageKey = pageKeyCopy;
        }
      } else {
        newPageKey = kebabCase(pageKeyCopy);
      }

      setPageConfigState({ ...res?.pageConfig, pageKey: newPageKey });
      setFormValues({ ...res?.pageConfig, pageKey: newPageKey });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [fetchTenantData] = useLazyQuery(GET_TENANT_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTenantLogoDetails(res?.fetchTenantBrand);
      setTenantContactDetails(res?.getProfile);
    },
    onError: () => {}
  });

  const [widgets, { data: widgetData, loading: widgetLoading }] = useLazyQuery(
    GET_WIDGETS,
    {
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  const refetchPageConfig = () => {
    setLoading(true);
    dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: true });

    pageConfig({
      variables: {
        where: {
          ...(!pageKeyValue && { defaultConfig: true }),
          id,
          pageKey: pageKeyValue
        }
      }
    });
  };

  useEffect(() => {
    widgets({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: true });
    pageConfig({
      variables: {
        where: {
          ...(!pageKeyValue && { defaultConfig: true }),
          id,
          pageKey: pageKeyValue
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="questionnaire-page">
      <Portal portalId="page-sequence-content">
        <CpqPanel
          pageSequencesData={pageSequencesData}
          refetchPageSequence={refetchPageSequence}
          type={type}
          refetchPageConfig={refetchPageConfig}
        />
      </Portal>
      <CpqTemplate
        setRefetchSystemFields={setRefetchSystemFields}
        centerContent={
          !loading && pageConfigState ? (
            <PreviewComponent
              formValues={formValues}
              isNewPage={isNewPage}
              pageSequencesData={pageSequencesData}
              selectedLob={selectedLob}
              selectedTab={selectedTab}
              selectedAppointmentKey={selectedAppointmentKey}
              fieldObject={fieldObject}
              systemFieldData={systemFieldData}
              setSelectedAppointmentKey={setSelectedAppointmentKey}
              checkedList={checkedList}
              selectedDimensionTab={selectedDimensionTab}
              selectedDimensionProject={selectedDimensionProject}
              tenantLogoDetails={tenantLogoDetails}
              setTenantLogoDetails={setTenantLogoDetails}
              tenantContactDetails={tenantContactDetails}
              setTenantContactDetails={setTenantContactDetails}
              fetchTenantData={fetchTenantData}
            />
          ) : (
            <LoaderComponent spinning={loading} setHeight={85} />
          )
        }
        refetchPageSequence={refetchPageSequence}
        type={type}
        rightSideContent={
          !loading && pageConfigState ? (
            <BuilderFormComponent
              refetchPageSequence={refetchPageSequence}
              refetchPageConfig={refetchPageConfig}
              pageConfigProp={pageConfigState}
              widgetData={widgetData}
              widgetLoading={widgetLoading}
              setFormValues={setFormValues}
              setSelectedLob={setSelectedLob}
              selectedLob={selectedLob}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              selectedAppointmentKey={selectedAppointmentKey}
              setSelectedAppointmentKey={setSelectedAppointmentKey}
              formValues={formValues}
              fieldObject={fieldObject}
              setFieldObject={setFieldObject}
              systemFieldData={systemFieldData}
              setSystemFieldData={setSystemFieldData}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              setSelectedDimensionTab={setSelectedDimensionTab}
              selectedDimensionTab={selectedDimensionTab}
              pageSequencesData={pageSequencesData}
              refetchSystemFields={refetchSystemFields}
              setRefetchSystemFields={setRefetchSystemFields}
              setSelectedDimensionProject={setSelectedDimensionProject}
              selectedDimensionProject={selectedDimensionProject}
              fetchTenantData={fetchTenantData}
              type={type}
              formLoading={formLoading}
              setFormLoading={setFormLoading}
            />
          ) : (
            <LoaderComponent spinning={loading} setHeight={85} />
          )
        }
      />
    </div>
  );
};

export default withRouter(QuestionnairePageBuilderPage);
