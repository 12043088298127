import { gql } from '@apollo/client';

export const UPDATE_REGION = gql`
  mutation updateRegion($where: RegionWhereInput!, $data: UpdateRegionInput!) {
    updateRegion(where: $where, data: $data) {
      data {
        id
        name
        isActive
        zipCodes
        regionData
        tenantId
        description
        managerId
        parentId
        contactNumber
        address
        officeName
      }
      status
      message
    }
  }
`;

export const CREATE_REGION = gql`
  mutation createRegion($data: RegionInput!) {
    createRegion(data: $data) {
      data {
        id
        name
        isActive
        zipCodes
        regionData
        tenantId
        description
        managerId
        parentId
        contactNumber
        address
        officeName
      }
      status
      message
    }
  }
`;

export const DELETE_REGION = gql`
  mutation deleteRegion($where: RegionWhereInput!) {
    deleteRegion(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
