import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($where: CustomerWhereInput!, $data: CustomerInput!) {
    updateCustomer(where: $where, data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($data: CustomerInput!) {
    createCustomer(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($where: CustomerWhereInput!) {
    deleteCustomer(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CREATE_SALES_JUNCTION = gql`
  mutation createSalesJunction(
    $data: SalesJunctionInput!
    $where: PropertyWhereInput!
  ) {
    createSalesJunction(data: $data, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const REMOVE_PROPERTY_REFERENCE = gql`
  mutation removePropertyReference(
    $salesWhere: SalesWhereFetchInput!
    $where: PropertyWhereInput!
  ) {
    removePropertyReference(salesWhere: $salesWhere, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const REMOVE_CONTACT_REFERENCE = gql`
  mutation removeContactReference(
    $salesWhere: SalesWhereFetchInput!
    $where: ContactWhereInput!
  ) {
    removeContactReference(salesWhere: $salesWhere, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const UPDATE_PRIMARY_AUTHORIZE_CONTACT = gql`
  mutation updatePrimaryAndAuthorizedContact(
    $where: PrimaryAndAuthorizedWhereInput!
  ) {
    updatePrimaryAndAuthorizedContact(where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;
