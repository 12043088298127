import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query users($filter: UserFilter!, $where: UsersWhereFilter) {
    users(filter: $filter, where: $where) {
      count
      data {
        id
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
        roleValues {
          id
          key
          label
        }
        emailVerified
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(where: { id: $id }) {
      id
      firstName
      lastName
      email
      phoneNo
      isActive
      roles
      roleValues {
        id
        key
        label
      }
      tenantId
      emailVerified
      locations {
        id
        isActive
        addressLine1
        addressLine2
        addressLine3
        city
        county
        state
        country
        zipCode
        tenantId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      firstName
      lastName
      email
      phoneNo
      roles
      isActive
      tenantId
      emailVerified
      locations {
        id
        isActive
        addressLine1
        addressLine2
        addressLine3
        city
        county
        state
        country
        zipCode
        tenantId
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_FILTER = gql`
  query userFilters($filter: UserFilter!) {
    userFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
        tenantId
        createdAt
        updatedAt
        emailVerified
        locations {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          tenantId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ROLE_FILTER = gql`
  query roleFilters($filter: RoleFilter!) {
    roleFilters(filter: $filter) {
      count
      data {
        label
        description
        order
        isActive
        key
        tenantId
        referenceId
        createdAt
        updatedAt
      }
    }
  }
`;
