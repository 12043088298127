import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Error404 from '../../../Error404';
import client from '../../../apollo';
import { QUESTIONNAIRE_TYPE, ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_PAGE_SEQUENCES } from '../graphql/Queries';
import QuestionnairePageBuilderPage from './QuestionnairePageBuilder';

const DynamicRouteWrapper = ({
  match: { params: { id = null } = {} },
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER
}) => {
  const [loading, setLoading] = useState(false);
  const [pageSequencesData, setPageSequencesData] = useState([]);

  const questionnaireRoute =
    type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER
      ? ROUTES?.QUESTIONNAIRES_PRIMARY
      : ROUTES?.QUESTIONNAIRES_SECONDARY;

  const getPageSequences = async () => {
    try {
      const res = await client?.query({
        query: GET_PAGE_SEQUENCES,
        variables: {
          where: {
            id
          }
        },
        fetchPolicy: 'network-only'
      });
      setPageSequencesData(res?.data?.pageSequences?.data);
      return res;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    async function getInitialCall() {
      setLoading(true);
      await getPageSequences();
      setLoading(false);
    }
    getInitialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="questionnaire-module">
      <Switch>
        <Route
          exact
          path={`${questionnaireRoute}/:id${ROUTES?.PAGE}/create`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <QuestionnairePageBuilderPage
                {...props}
                isNewPage
                pageSequencesData={pageSequencesData}
                refetchPageSequence={getPageSequences}
                type={type}
              />
            </AccessControl>
          )}
        />
        {map(pageSequencesData, (_, index) => {
          return (
            <Route
              exact
              // given key as while drag and drop we are using index and it rerenders whole component if we aren't giving index here
              key={index}
              path={`${questionnaireRoute}/:id${ROUTES?.PAGE}/:pageKey`}
              render={(props) => (
                <AccessControl
                  allowedPermissions={[
                    'FET_QUESTIONNAIRE_UPDATE',
                    'FET_QUESTIONNAIRE_VIEW'
                  ]}
                  showNoAccess
                >
                  <QuestionnairePageBuilderPage
                    {...props}
                    pageSequencesData={pageSequencesData}
                    refetchPageSequence={getPageSequences}
                    type={type}
                  />
                </AccessControl>
              )}
            />
          );
        })}
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default withRouter(DynamicRouteWrapper);
