import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CustomerDetails from '../components/CustomerDetails';
import CardWrapper from '../components/CustomerTabs';
import { GET_CUSTOMER } from '../graphql/Queries';

function CustomerView(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);

  const { data: { customer } = {} } = useQuery(GET_CUSTOMER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  if (!id) {
    return <Redirect to={ROUTES?.CUSTOMERS} />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CUSTOMERS} />
          {customer && (
            <span className="portal-header">
              {customer?.user?.firstName} {customer?.user?.lastName}
            </span>
          )}
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<img src={EditIcon} alt="save-icon" width={12} />}
          size="small"
          type="primary"
          onClick={() => history?.push(`${ROUTES?.CUSTOMERS}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <CustomerDetails data={customer} />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(CustomerView);
