import { Select } from 'antd';
import React from 'react';
import './styles/SelectComponent.less';

const SelectComponent = (props) => {
  const { children, className = '', placeholder = '', ...rest } = props;

  return (
    <Select
      showAction={['focus']}
      className={`select-width ${className}`}
      placeholder={placeholder}
      optionFilterProp="children"
      getPopupContainer={(trigger) => trigger?.parentNode}
      showSearch
      allowClear
      maxTagCount="responsive"
      {...rest}
      aria-label={placeholder}
    >
      {children}
    </Select>
  );
};

export default SelectComponent;

SelectComponent.Option = Select?.Option;
SelectComponent.OptGroup = Select?.OptGroup;
