import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ContactForm from '../components/ContactForm';
import ContactModal from '../components/ContactModal';
import CardWrapper from '../components/ContactTabs';
import { UPDATE_CONTACT } from '../graphql/Mutations';
import { GET_CONTACT, GET_CONTACTS } from '../graphql/Queries';

const initialContactFilter = {
  sortOn: 'createdAt',
  sortBy: 'DESC'
};

function ContactEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [duplicateData, setDuplicateData] = useState([]);

  const { data: { contact } = {} } = useQuery(GET_CONTACT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const sendToDB = async () => {
    const newFormValues = {
      userInput: {
        firstName: formValues?.firstName,
        lastName: formValues?.lastName,
        phoneNo: formValues?.phoneNo,
        email: formValues?.email,
        isActive: formValues?.isActive
      },
      homePhone: formatPhoneNumberWithoutMask(formValues?.homePhone),
      mainPhone: formatPhoneNumberWithoutMask(formValues?.mainPhone),
      web: formValues?.web,
      preferredContact: formValues?.preferredContact || null,
      contactType: formValues?.contactType || null,
      isActive: formValues?.isActive,
      locationInput: {
        addressLine1: formValues?.addressLine1,
        city: formValues?.city,
        state: formValues?.state,
        zipCode: formValues?.zipCode,
        country: formValues?.country
      }
    };

    const variables = { data: newFormValues, where: { id } };

    try {
      const response = await updateContact({
        variables: { ...variables }
      });
      if (response?.data?.updateContact) {
        setSubmitLoading(false);
        history?.push(ROUTES?.CONTACTS);
      }
    } catch (error) {
      return error;
    }
  };

  const [fetchContactData] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.contacts?.data?.length > 0) {
        setShowModal(true);
        setDuplicateData(res?.contacts?.data);
        setSubmitLoading(false);
      } else {
        setShowModal(false);
        sendToDB();
      }
    },
    onError() {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    setFormValues(values);

    fetchContactData({
      variables: {
        filter: { ...initialContactFilter, skip: 0 },
        where: {
          duplicateCheck: true,
          id: [id],
          name: [`${values?.firstName} ${values?.lastName}`],
          email: [values?.email],
          isActive: [values?.isActive],
          homePhone: [values?.homePhone],
          mainPhone: [values?.mainPhone],
          web: [values?.web],
          preferredContact: [values?.preferredContact],
          contactType: [values?.contactType]
        }
      }
    });
  };

  if (!id) {
    return <Redirect to={ROUTES?.CONTACTS} />;
  }

  const initialValues = {
    ...contact,
    firstName: contact?.userInfo?.firstName,
    lastName: contact?.userInfo?.lastName,
    email: contact?.userInfo?.email,
    phoneNo: contact?.userInfo?.phoneNo,
    addressLine1: contact?.location?.addressLine1,
    zipCode: contact?.location?.zipCode,
    city: contact?.location?.city,
    state: contact?.location?.state,
    country: contact?.location?.country,
    isActive: contact?.isActive
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CONTACTS} />
          {contact && (
            <span className="portal-header">
              {contact?.userInfo?.firstName} {contact?.userInfo?.lastName}
            </span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {showModal && (
          <ContactModal
            showModal={showModal}
            setShowModal={setShowModal}
            duplicateData={duplicateData}
            onOk={sendToDB}
            isEdit
          />
        )}
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ContactForm
            contactData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ContactEdit);
