import { gql } from '@apollo/client';

export const FETCH_BUSINESS_HOURS = gql`
  query businessHours($filter: BusinessHourFilter!) {
    businessHours(filter: $filter) {
      count
      data {
        id
        title
        checked
        startTime
        endTime
      }
    }
  }
`;

export const FETCH_HOLIDAYS = gql`
  query holidays($filter: HolidayFilter!) {
    holidays(filter: $filter) {
      data {
        id
        name
        date
        allDayChecked
        open
        close
        cutOfTimeChecked
        cutOfTime
        holidayMessage
      }
    }
  }
`;
