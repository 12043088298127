import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PLAN_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PlanSettingPage from './pages/PlanSettingPage';
import './planSettingModule.less';

const PlanSettingWrapper = () => {
  return (
    <div className="plan-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PLAN_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PLAN_SETTING}
              showNoAccess
            >
              <PlanSettingPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default PlanSettingWrapper;
