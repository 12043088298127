import { QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Col, Popover, Row } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import { GET_SUB_AREA } from '../graphql/Queries';

const SubAreaView = () => {
  const { id: industryId, lobId, subId } = useParams();
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data } = useQuery(GET_SUB_AREA, {
    variables: {
      id: subId
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.subArea?.logo?.url) {
        setLogoUrl({
          url: res?.subArea?.logo?.url,
          title: res?.subArea?.logo?.url?.split('_')?.[1]
        });
      }
      setLoading(false);
    },
    onError() {}
  });

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="portal-header">
            {data?.subArea?.lineOfBusiness?.label}
          </span>
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_INDUSTRY_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            type="primary"
            onClick={() =>
              history?.push(
                `${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}/edit/${subId}`
              )
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      {loading ? (
        <LoaderComponent setHeight={40} />
      ) : (
        <div className="content-section">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Service Type *</span>
                <span className="field-value">{data?.subArea?.label}</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  Alias{' '}
                  <Popover
                    placement="top"
                    content="This will be alias for label"
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <span className="field-value">
                  {data?.subArea?.alias || '-'}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Status *</span>
                <span className="field-value">
                  {data?.subArea?.isActive ? 'Active' : 'InActive'}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Reference Id</span>
                <span className="field-value">
                  {data?.subArea?.referenceId || '-'}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Industry *</span>
                <span className="field-value">
                  {data?.subArea?.industry?.label}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={16}>
              <div className="field-detail">
                <span className="field-label">Line Of Business *</span>
                <span className="field-value">
                  {data?.subArea?.lineOfBusiness?.label}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Icon *</span>
                <img
                  src={logoUrl?.url || placeholderImage}
                  alt="logo"
                  onError={(e) => {
                    e.target.src = placeholderImage;
                  }}
                  className="field-logo"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Description</span>
                <div
                  className="editor-render"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: data?.subArea?.description || '-'
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

export default SubAreaView;
