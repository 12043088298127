import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_INDUSTRY = gql`
  mutation updateIndustry($data: IndustryInput!, $where: IndustryWhereInput!) {
    updateIndustry(data: $data, where: $where) {
      message
      status
      data {
        label
        alias
        industryCode
        refData
        tenantId
        key
        referenceId
        description
        isActive
        id
      }
    }
  }
`;
