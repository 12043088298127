import Icon from '@ant-design/icons';
import React from 'react';

const userIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70333 15.3346C3.80325 15.3346 0.641602 12.0514 0.641602 8.0013C0.641602 3.95121 3.80325 0.667969 7.70333 0.667969C11.6034 0.667969 14.7651 3.95121 14.7651 8.0013C14.7651 12.0514 11.6034 15.3346 7.70333 15.3346ZM12.4537 11.4167C13.1016 10.4471 13.4813 9.26961 13.4813 8C13.4813 4.68629 10.8945 2 7.70356 2C4.51258 2 1.92578 4.68629 1.92578 8C1.92578 9.26935 2.30536 10.4466 2.95307 11.4161C3.6757 10.4253 5.37339 10 7.70314 10C10.0334 10 11.7313 10.4255 12.4537 11.4167ZM11.5324 12.4935C11.3448 11.7917 9.99493 11.3333 7.70314 11.3333C5.41202 11.3333 4.06226 11.7914 3.87405 12.4929C4.89353 13.4307 6.23452 14 7.70356 14C9.17228 14 10.513 13.4309 11.5324 12.4935ZM7.70364 4C6.14949 4 5.13574 5.17051 5.13574 6.66667C5.13574 8.95158 6.26617 10 7.70364 10C9.1276 10 10.2715 8.98641 10.2715 6.8C10.2715 5.28105 9.2535 4 7.70364 4ZM6.41895 6.66927C6.41895 8.18211 6.94422 8.66927 7.7029 8.66927C8.45895 8.66927 8.98685 8.20153 8.98685 6.8026C8.98685 5.96955 8.48332 5.33594 7.7029 5.33594C6.88999 5.33594 6.41895 5.87982 6.41895 6.66927Z"
        fill="currentColor"
      />
    </svg>
  );
};

const UserIconComponent = (props) => (
  <Icon aria-label="User Icon" component={userIcon} {...props} />
);

export default UserIconComponent;
