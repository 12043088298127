import { useLazyQuery, useMutation } from '@apollo/client';
import { Popconfirm, Radio } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import { formatPhoneNumber } from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import {
  REMOVE_CONTACT_REFERENCE,
  UPDATE_PRIMARY_AUTHORIZE_CONTACT
} from '../graphql/Mutations';
import { GET_CONTACTS } from '../graphql/Queries';

const PropertyContactTable = ({
  match: { params: { id } = {} } = {},
  showModal = false
}) => {
  const {
    state: { pageSize }
  } = useContext(AppContext);

  const location = useLocation();

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialContactFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [contactFilter, setContactFilter] = useState(initialContactFilter);

  const [removeContactReference] = useMutation(REMOVE_CONTACT_REFERENCE, {
    onError() {}
  });

  const [updatePrimaryAndAuthorizedContact] = useMutation(
    UPDATE_PRIMARY_AUTHORIZE_CONTACT,
    {
      onError() {}
    }
  );

  const [fetchContactData, { loading, data }] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.properties?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchContactData({
      variables: {
        filter: contactFilter,
        salesWhere: {
          joinedFrom: 'PROPERTY',
          id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showModal) {
      fetchContactData({
        variables: {
          filter: contactFilter,
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    } else {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
  };

  const handleDeleteContact = async (editContactData) => {
    const response = await removeContactReference({
      variables: {
        where: { id: editContactData?.id },
        salesWhere: {
          joinedFrom: 'PROPERTY',
          id
        }
      }
    });
    if (response?.data?.removeContactReference) {
      fetchContactData({
        variables: {
          filter: contactFilter,
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
  };

  const handleChangeRadio = async (record, key) => {
    const response = await updatePrimaryAndAuthorizedContact({
      variables: {
        where: {
          [key]: record?.id,
          id,
          joinedFrom: 'PROPERTY'
        }
      }
    });
    if (response?.data?.updatePrimaryAndAuthorizedContact) {
      fetchContactData({
        variables: {
          filter: contactFilter,
          salesWhere: {
            joinedFrom: 'PROPERTY',
            id
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'CONTACT NAME',
      ellipsis: true,
      width: 200,
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) => {
        return `${record?.userInfo?.firstName} ${record?.userInfo?.lastName}`;
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      width: 250,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (text, record) => {
        return `${record?.userInfo?.email}`;
      }
    },
    {
      title: 'MOBILE',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      width: 200,
      render: (phoneNo, record) => {
        return formatPhoneNumber(record?.userInfo?.phoneNo) || '-';
      }
    },
    {
      title: 'HOME PHONE',
      dataIndex: 'homePhone',
      key: 'homePhone',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'homePhone' && sortedInfo?.order,
      width: 200,
      render: (homePhone) => {
        return formatPhoneNumber(homePhone) || '-';
      }
    },
    {
      title: 'MAIN PHONE',
      dataIndex: 'mainPhone',
      key: 'mainPhone',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'mainPhone' && sortedInfo?.order,
      width: 200,
      render: (mainPhone) => {
        return formatPhoneNumber(mainPhone) || '-';
      }
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'primaryContact',
      key: 'primaryContact',
      ellipsis: true,
      fixed: location?.pathname?.includes('/view') && 'right',
      align: 'center',
      width: 200,
      render: (primaryContact = false, record) => {
        return (
          <Radio
            disabled={location?.pathname?.includes('/view')}
            className="common-radio"
            checked={primaryContact}
            onChange={() => handleChangeRadio(record, 'primaryContactId')}
          />
        );
      }
    },
    {
      title: 'AUTHORIZED SERVICE CONTACT',
      dataIndex: 'authorizedServiceContact',
      key: 'authorizedServiceContact',
      ellipsis: true,
      fixed: location?.pathname?.includes('/view') && 'right',
      align: 'center',
      width: 250,
      render: (authorizedServiceContact = false, record) => {
        return (
          <Radio
            disabled={location?.pathname?.includes('/view')}
            className="common-radio"
            checked={authorizedServiceContact}
            onChange={() =>
              handleChangeRadio(record, 'authorizedServiceContactId')
            }
          />
        );
      }
    },

    !location?.pathname?.includes('/view') && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (action, record = {}) => {
        return (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteContact(record)}
            okText="Yes"
            cancelText="No"
          >
            <img src={deleteIcon} alt="delete-icon" className="item-delete" />
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <div className="common-table tab-table">
        {pageSize && (
          <TableComponent
            isSearch={false}
            setHeight={282}
            loadingData={loading}
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.contacts?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PropertyContactTable);
