import Icon from '@ant-design/icons';
import React from 'react';

const lobIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66634 0H8.33301C9.06939 0 9.66634 0.596954 9.66634 1.33333V2H12.333C13.0694 2 13.6663 2.59695 13.6663 3.33333V10.6667C13.6663 11.403 13.0694 12 12.333 12H1.66634C0.929961 12 0.333008 11.403 0.333008 10.6667V3.33333C0.333008 2.59695 0.929961 2 1.66634 2H4.33301V1.33333C4.33301 0.596954 4.92996 0 5.66634 0ZM1.66634 3.33333H4.33301H9.66634H12.333V6.66667H7.66634H6.33301H1.66634V3.33333ZM1.66634 10.6667V8H6.33301V8.66667H7.66634V8H12.333V10.6667H1.66634ZM8.33301 1.33333V2H5.66634V1.33333H8.33301Z"
        fill="currentColor"
      />
    </svg>
  );
};

const LobIconComponent = (props) => (
  <Icon aria-label="Lob Icon" component={lobIcon} {...props} />
);

export default LobIconComponent;
