import { gql } from '@apollo/client';

export const UPDATE_CONTACT = gql`
  mutation updateContact($where: ContactWhereInput!, $data: ContactInput!) {
    updateContact(where: $where, data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($data: ContactInput!) {
    createContact(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation deleteContact($where: ContactWhereInput!) {
    deleteContact(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const CREATE_SALES_JUNCTION = gql`
  mutation createSalesJunction(
    $data: SalesJunctionInput!
    $where: PropertyWhereInput!
  ) {
    createSalesJunction(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const REMOVE_CUSTOMER = gql`
  mutation removeCustomerReference(
    $where: CustomerWhereInput!
    $salesWhere: SalesWhereFetchInput!
  ) {
    removeCustomerReference(where: $where, salesWhere: $salesWhere) {
      message
      status
    }
  }
`;

export const REMOVE_PROPERTY = gql`
  mutation removePropertyReference(
    $where: PropertyWhereInput!
    $salesWhere: SalesWhereFetchInput!
  ) {
    removePropertyReference(where: $where, salesWhere: $salesWhere) {
      message
      status
    }
  }
`;
