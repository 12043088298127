import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_CAMPAIGNS, ROUTES } from '../../common/constants';

import Error404 from '../../Error404';
import AccessControl from '../../components/AccessControl';
import CampaignCreate from './pages/CampaignCreate';
import CampaignEdit from './pages/CampaignEdit';
import CampaignList from './pages/CampaignList';

const CampaignViewerWrapper = () => {
  return (
    <div className="quote-viewer-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.CAMPAIGNS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_CAMPAIGNS}
              showNoAccess
            >
              <CampaignList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.CAMPAIGNS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_CAMPAIGN_CREATE']}
              showNoAccess
            >
              <CampaignCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.CAMPAIGNS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_CAMPAIGN_UPDATE', 'FET_CAMPAIGN_VIEW']}
              showNoAccess
            >
              <CampaignEdit {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default CampaignViewerWrapper;
