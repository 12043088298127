import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { ROLES } from '../../onboarding/graphql/Queries';
import { FETCH_STATE_CITY } from '../../signup/graphql/Query';

let stateScrollDebounce;
let cityScrollDebounceJob;
let rolesScrollDebounceJob;

const {
  required,
  email,
  requiredWhiteSpaceAllowed,
  zipCode,
  firstName,
  lastName,
  address
} = formValidatorRules;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC'
};

const roleFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  distinct: true,
  getDBField: ['label', 'key']
};

const { Option } = SelectComponent;

const UserForm = (props) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const location = useLocation();
  const { userData = null, handleSubmit, isSubmit, isEdit = false } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [rolesSearchFlag, setRolesSearchFlag] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [callAsync, setCallAsync] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);

  const [roles] = useLazyQuery(ROLES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.roles?.data?.length < SKIP_RECORD);
      if (rolesSearchFlag) {
        setRolesData([...res?.roles?.data]);
      } else {
        if (isEdit) {
          const tempRoles = [...userData?.roleValues, ...res?.roles?.data];
          setRolesData(uniqBy([...rolesData, ...tempRoles], 'key'));
          return;
        }
        setRolesData([...rolesData, ...res?.roles?.data]);
      }
      setRolesLoading(false);
    },
    onError() {
      setRolesLoading(false);
    }
  });
  const [form] = Form?.useForm();
  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    }
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: userData?.state
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    if (!userData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (userData?.country) {
      setDisableState(false);
    }
    if (userData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(userData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (userData?.profileImage && !fileList?.length) {
      setFileList([
        {
          url: userData?.profileImage?.url,
          title: userData?.profileImage?.title
        }
      ]);
    }
    roles({
      variables: {
        filter: roleFilter,
        where: { isActive: true }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleRolesBlur = () => {
    setSearchValue('');
    setIsEnd(false);
    setDebounceCall(0);
    setRolesSearchFlag(true);
    roles({
      variables: {
        filter: roleFilter,
        where: { isActive: true }
      }
    });
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState
        }
      }
    });
  };

  const onRolesScroll = (event) => {
    setRolesSearchFlag(false);
    if (rolesScrollDebounceJob) {
      rolesScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    rolesScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setRolesLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        roles({
          variables: {
            filter: {
              ...roleFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    rolesScrollDebounceJob();
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE'
            }
          }
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState
            }
          }
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value
          }
        }
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value
          }
        }
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE'
          }
        }
      });
    }
  };

  const handleRoleChange = (value) => {
    setSearchValue(value);
    setRolesSearchFlag(true);
    if (value) {
      setRolesLoading(true);
      roles({
        variables: {
          filter: {
            ...roleFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setRolesLoading(true);
      roles({
        variables: {
          filter: {
            ...roleFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  const handleSelectCountry = (value) => {
    if (value) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state')
          }
        }
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state')
          }
        }
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null
    });
    setDisableState(true);
    setDisableCity(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRoleHandler = useCallback(debounce(handleRoleChange, 500), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleRoleClear = () => {
    form?.setFieldsValue({
      roles: null
    });
    setRolesData([]);
    roles({ variables: { filter: roleFilter, where: { isActive: true } } });
  };

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter
      }
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={isEdit ? ['FET_USER_UPDATE'] : ['FET_USER_CREATE']}
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="user-table-discard-btn"
            onClick={() =>
              history?.push(`${ROUTES?.USERS}`, { ...location?.state })
            }
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="user-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={userData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_USER_UPDATE'] : ['FET_USER_CREATE']
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter First Name' },
                  firstName
                ]}
                name="firstName"
                label="First Name"
              >
                <InputComponent allowClear placeholder="Enter First name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Last Name' },
                  lastName
                ]}
                name="lastName"
                label="Last Name"
              >
                <InputComponent allowClear placeholder="Enter Last Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Contact"
                name="phoneNo"
                rules={[
                  { ...required, message: 'Please Enter Contact' },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <ReactInputMask
                  mask="(999) 999-9999"
                  placeholder="(___) ___-____"
                  allowClear
                >
                  {(inputProps) => <InputComponent {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Email' }, email]}
                name="email"
                label="Email"
              >
                <InputComponent allowClear placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { required, message: 'Please Select Roles', type: 'array' }
                ]}
                name="roles"
                label="Roles"
              >
                <SelectComponent
                  placeholder="Select Roles"
                  allowClear
                  mode="multiple"
                  notFoundContent={
                    rolesLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleRolesBlur}
                  onSearch={debouncedRoleHandler}
                  onClear={handleRoleClear}
                  onPopupScroll={onRolesScroll}
                >
                  {map(rolesData, (role) => (
                    <Option key={role?.key} value={role?.key}>
                      {role?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <span className="form-divider-text optional-divider">OPTIONAL</span>
            <Divider className="form-divider optional-divider" />
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="addressLine1" label="Address" rules={[address]}>
                <InputComponent allowClear placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="country" label="Country">
                <SelectComponent
                  placeholder="Select Country"
                  onSelect={handleSelectCountry}
                  onClear={handleCountryClear}
                  allowClear
                >
                  <Option key="USA" value="USA">
                    USA
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="state" label="State">
                <SelectComponent
                  placeholder="Select State"
                  disabled={disableState}
                  allowClear
                  notFoundContent={
                    stateLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedStateHandler}
                  onClear={handleStateClear}
                  onSelect={handleStateSelect}
                  onPopupScroll={onStateScroll}
                  onBlur={handleStateBlur}
                >
                  {map(states, (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="city" label="City">
                <SelectComponent
                  placeholder="Select City"
                  disabled={disableCity}
                  notFoundContent={
                    cityLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedCityHandler}
                  onPopupScroll={onCityScroll}
                  onClear={handleCityClear}
                  onBlur={handleCityBlur}
                >
                  {cities?.map((city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="zipCode" rules={[zipCode]} label="Postal Code">
                <NumberComponent placeholder="Enter Postal code" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default UserForm;
