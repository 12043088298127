import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import { omit } from 'lodash';
import React, { useContext, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { objectWithoutKey, uploadFile } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import LineOfBusinessForm from '../components/LineOfBusinessForm';
import SubAreaTable from '../components/SubAreaTable';
import { UPDATE_LINE_OF_BUSINESS } from '../graphql/Mutations';
import { GET_LINE_OF_BUSINESS } from '../graphql/Queries';

function LineOfBusinessEdit(props) {
  const {
    match: { params: { lobId, id: industryId } = {} } = {},
    history
  } = props;
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  const { data: { lineOfBusiness } = {} } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: { id: lobId },
    onCompleted: (res) => {
      if (res?.lineOfBusiness?.logo?.url) {
        setLogoUrl({
          ...res?.lineOfBusiness?.logo
        });
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateLineOfBusinessMutate] = useMutation(UPDATE_LINE_OF_BUSINESS, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = lineOfBusiness?.uuid || uuidv4();

    const logo = await uploadFile({
      logoObject: formValues?.logo,
      currentUser,
      uuid,
      folder: 'lob'
    });
    const newFormValues = omit(formValues, [
      'lobCode',
      'referenceId',
      'industry'
    ]);

    const variables = {
      data: {
        ...newFormValues,
        logo: formValues?.logo?.url
          ? { ...lineOfBusiness?.logo, __typename: undefined }
          : logo
      },
      where: { id: lineOfBusiness?.id }
    };

    try {
      const response = await updateLineOfBusinessMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateLineOfBusiness) {
        setSubmitLoading(false);
        history?.push(`${ROUTES?.INDUSTRIES}/edit/${industryId}`);
      }
    } catch (error) {
      return error;
    }
  };

  if (!lobId) {
    return <Redirect to={ROUTES?.LINE_OF_BUSINESSES} />;
  }

  const initialValues = {
    ...(lineOfBusiness && { ...objectWithoutKey(lineOfBusiness, 'logo') }),
    description: lineOfBusiness?.description || '',
    isActive: lineOfBusiness?.isActive,
    ...(lineOfBusiness?.logo?.url && {
      logo: { ...lineOfBusiness?.logo, ...logoUrl }
    })
  };

  return (
    <div>
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.INDUSTRIES}/edit/${industryId}`}
          />
          {lineOfBusiness && (
            <span className="portal-header">
              {lineOfBusiness?.industry?.label}
            </span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <div className="split-screen-section">
          <Card className="mb-24 semi-height-card card-body-padding">
            <LineOfBusinessForm
              isEdit
              lineOfBusinessData={initialValues}
              handleSubmit={handleSubmit}
              isSubmit={submitLoading}
              industryId={industryId}
            />
          </Card>
          <Card className="semi-height-card card-body-padding">
            <SubAreaTable />
          </Card>
        </div>
      )}
    </div>
  );
}

export default withRouter(LineOfBusinessEdit);
