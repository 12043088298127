import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_MANUFACTURER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './manufacturerModule.less';
import ManufacturerCreate from './pages/ManufacturerCreate';
import ManufacturerEdit from './pages/ManufacturerEdit';
import ManufacturerList from './pages/ManufacturerList';
import ManufacturerView from './pages/ManufacturerView';

const ManufacturerWrapper = () => {
  return (
    <div className="manufacturer-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.MANUFACTURERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_MANUFACTURER}
              showNoAccess
            >
              <ManufacturerList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.MANUFACTURERS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MANUFACTURER_CREATE']}
              showNoAccess
            >
              <ManufacturerCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.MANUFACTURERS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_MANUFACTURER_UPDATE',
                'FET_MANUFACTURER_VIEW'
              ]}
              showNoAccess
            >
              <ManufacturerEdit {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.MANUFACTURERS}/view/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MANUFACTURER_VIEW']}
              showNoAccess
            >
              <ManufacturerView {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ManufacturerWrapper;
