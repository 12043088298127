import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_RETAILER_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './brandSettingModule.less';
import BrandSettingPage from './pages/BrandSettingPage';

const BrandSettingWrapper = () => {
  return (
    <div className="brand-setting-wrapper">
      <Switch>
        <Route
          exact
          path={ROUTES?.BUSINESS_INFORMATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER_SETTING}
              showNoAccess
            >
              <BrandSettingPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default BrandSettingWrapper;
