import { CheckCircleFilled } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { cpqRetailUrl } from '../../../common/utils';
import history from '../../../historyData';
import StepProcess from '../pages/StepProcess';

const SuccessPage = () => {
  const { getOnboardingData, getCurrentUser } = useContext(AppContext);

  const onBoardingData = getOnboardingData();

  const handleChangeRoute = (page) => {
    const userData = getCurrentUser();
    if (!userData?.emailVerified) {
      message?.destroy();
      message?.warn('Please verify your e-mail');
    }
    switch (page) {
      case 'dashboard':
        history?.push(ROUTES?.DASHBOARD);
        break;

      case 'cpq':
        // eslint-disable-next-line no-undef
        window
          ?.open(
            `https://${cpqRetailUrl(onBoardingData?.data?.subDomain)}/${
              onBoardingData?.data?.slug
            }`,
            '_blank'
          )
          ?.focus();
        break;

      default:
        history?.push(ROUTES?.MAIN);
        break;
    }
  };
  return (
    <StepProcess>
      <div className="success-page">
        <CheckCircleFilled className="check-icon" />
        <span className="steps-content-title success-space">
          You are all set!
        </span>
        <span className="steps-content-description">
          Tenant Portal is free-to-use.
        </span>
        <Button
          type="primary"
          className="continue-button submit-btn"
          onClick={() => handleChangeRoute('dashboard')}
        >
          Continue to your Dashboard
        </Button>
        {/* commented this button for now as per DRF-7107 */}
        {/* <Button
          className="cpq-button"
          disabled={!onBoardingData?.data?.subDomain}
          onClick={() => handleChangeRoute('cpq')}
        >
          View your CPQ (Questionnaire)
        </Button> */}
      </div>
    </StepProcess>
  );
};

export default SuccessPage;
