import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, map, some } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { ROUTES, SKIP_RECORD, exportOptions } from '../../../common/constants';
import { formatPhoneNumber, handleExportCommon } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_CONTACT, UPDATE_CONTACT } from '../graphql/Mutations';
import { CONTACT_FILTER, GET_CONTACTS, USER_FILTER } from '../graphql/Queries';

let scrollDebounce = null;

const ContactTable = () => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialContactFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [contactFilter, setContactFilter] = useState(initialContactFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [updateContactMutate, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onError() {}
    }
  );

  const [deleteContact, { loading: deleteContactLoading }] = useMutation(
    DELETE_CONTACT,
    {
      onError() {}
    }
  );

  const [fetchContactData, { loading, data }] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.contacts?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [contactFilters] = useLazyQuery(CONTACT_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.contactFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.contactFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.contactFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [userFilters] = useLazyQuery(USER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];

        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.contactFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    fetchContactData({
      variables: {
        filter: contactFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchContactData({
      variables: {
        filter: { ...contactFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setContactFilter({
      ...contactFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'name':
          userFilters({
            variables: {
              filter: {
                sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField:
                  filterIndex === 'name'
                    ? ['firstName', 'lastName']
                    : filterIndex,
                showCurrentUser: false
              }
            }
          });
          break;

        default:
          contactFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'name':
            userFilters({
              variables: {
                filter: {
                  sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField:
                    filterIndex === 'name'
                      ? ['firstName', 'lastName']
                      : filterIndex
                }
              }
            });
            break;

          default:
            contactFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 38,
    selectedRowKeys: selectedKeys,
    onSelect: (record) => {
      const isAlready = some(selectedKeys, (item) => item === record?.id);
      if (isAlready) {
        const rowsCopy = filter(selectedKeys, (item) => item !== record?.id);
        setSelectedKeys(rowsCopy);
      } else {
        const rowsCopy = [...selectedKeys];
        rowsCopy?.push(record?.id);
        setSelectedKeys(rowsCopy);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const rowsCopy = [
          ...selectedKeys,
          ...map(changeRows, (item) => item?.id)
        ];
        setSelectedKeys(rowsCopy);
      } else {
        const remainingObject = selectedKeys?.filter(
          (elem) => !changeRows?.find(({ id: changeId }) => elem === changeId)
        );
        setSelectedKeys(remainingObject);
      }
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id
      });
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchContactData({
      variables: {
        filter: {
          ...contactFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setContactFilter({
      ...contactFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditContact = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.CONTACTS}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.CONTACTS}/add`);
    }
  };

  const handleViewContact = (record) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.CONTACTS}/view/${record.id}`);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setContactFilter(initialContactFilter);
    setSortedInfo({});
    fetchContactData({
      variables: { filter: initialContactFilter }
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]:
          filtersCopyState && filtersCopyState?.[dataIndex]
            ? [...filtersCopyState?.[dataIndex], value]
            : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'name':
        userFilters({
          variables: {
            filter: {
              sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField:
                filterIndex === 'name' ? ['firstName', 'lastName'] : filterIndex
            }
          }
        });
        break;

      default:
        contactFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteContact = async (editContactData) => {
    const response = await deleteContact({
      variables: { where: { id: editContactData?.id } }
    });
    if (response?.data?.deleteContact) {
      fetchContactData({
        variables: { filter: contactFilter, where: filters || undefined }
      });
    }
  };

  const handleContactStatus = async (editContactData) => {
    const editData = {
      userInput: {
        firstName: editContactData?.userInfo?.firstName,
        lastName: editContactData?.userInfo?.lastName,
        phoneNo: editContactData?.userInfo?.phoneNo,
        email: editContactData?.userInfo?.email
      },
      homePhone: editContactData?.homePhone,
      mainPhone: editContactData?.mainPhone,
      web: editContactData?.web,
      isActive: !editContactData?.isActive,
      locationInput: {}
    };

    const response = await updateContactMutate({
      variables: { data: { ...editData }, where: { id: editContactData?.id } }
    });
    if (response?.data?.updateContact) {
      fetchContactData({
        variables: { filter: contactFilter, where: filters || undefined }
      });
    }
  };

  const renderActionButtons = (editContactData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="contact-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditContact(editContactData)}
        >
          Edit
        </Button>
        <Button
          id="contact-table-edit-btn"
          className="b-0"
          onClick={() => handleViewContact(editContactData)}
        >
          View
        </Button>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteContact(editContactData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="contact-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to ${
            editContactData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handleContactStatus(editContactData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="contact-table-status-btn" className="b-0">
            {editContactData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (name, record) => {
        return `${record?.userInfo?.firstName} ${record?.userInfo?.lastName}`;
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      width: 250,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (text, record) => {
        return `${record?.userInfo?.email}`;
      }
    },
    {
      title: 'MOBILE',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      width: 200,
      render: (text, record) => {
        return `${formatPhoneNumber(record?.userInfo?.phoneNo) || '-'}`;
      }
    },
    {
      title: 'ADDRESS',
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      ellipsis: true,
      render: (location) => {
        return `${location?.addressLine1} ${location?.city},  ${location?.state} ${location?.zipCode}`;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setContactFilter({
      ...contactFilter,
      skip: value ? 0 : contactFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchContactData({
      variables: {
        filter: {
          ...contactFilter,
          skip: value
            ? 0
            : contactFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'CONTACT'
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="contacts"
        folder="CONTACT"
      />
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<PlusOutlined />}
          size="small"
          id="contact-table-add-btn"
          type="primary"
          onClick={handleAddEditContact}
        >
          Add Contact
        </Button>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Contact or other detail.."
            name="Contacts"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            size="small"
            className="common-button import-button"
            icon={<img src={ImportIcon} alt="import-icon" width={11} />}
            id="contact-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            menu={{ items: exportOptions, onClick: handleExport }}
            placement="bottom"
          >
            <Button
              className="common-button export-button"
              size="small"
              icon={<img src={ExportIcon} alt="export-icon" width={11} />}
              id="contact-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={
              loading || updateContactLoading || deleteContactLoading
            }
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.contacts?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowSelection={rowSelection}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default ContactTable;
