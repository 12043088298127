import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Form } from 'antd';
import React from 'react';
import BooleanChecked from '../../../../../../../../app/components/iconComponents/BooleanChecked';
import BooleanUnchecked from '../../../../../../../../app/components/iconComponents/BooleanUnchecked';

const SystemBooleanPreview = ({ systemFieldData }) => {
  const label = systemFieldData?.config?.label || systemFieldData?.label || ' ';
  const required =
    systemFieldData?.config?.required || systemFieldData?.required;
  const toolTipChecked =
    systemFieldData?.config?.toolTipCheck || systemFieldData?.toolTipCheck;
  const toolTipValue =
    systemFieldData?.config?.toolTipText || systemFieldData?.toolTipText;
  const trueValue =
    systemFieldData?.config?.trueValue || systemFieldData?.trueValue;
  const falseValue =
    systemFieldData?.config?.falseValue || systemFieldData?.falseValue;
  const booleanDefaultValue =
    systemFieldData?.config?.booleanDefaultValue ||
    systemFieldData?.booleanDefaultValue;

  return (
    <div className="mt-37 tile-grid">
      <Form layout="vertical">
        <Form.Item
          name="boolean"
          label={label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          <Card>
            <Card.Grid
              className={`grid-card tile-view ${
                !booleanDefaultValue ? 'card-selected' : ''
              }`}
              hoverable
            >
              <div className="d-flex flex-vertical align-center">
                <BooleanUnchecked selected={!booleanDefaultValue} />
                <span className="card-title" title={falseValue}>
                  {falseValue}
                </span>
              </div>
            </Card.Grid>
            <Card.Grid
              className={`grid-card tile-view ${
                booleanDefaultValue ? 'card-selected' : ''
              }`}
              hoverable
            >
              <div className="d-flex flex-vertical align-center">
                <BooleanChecked selected={booleanDefaultValue} />
                <span className="card-title break-word" title={trueValue}>
                  {trueValue}
                </span>
              </div>
            </Card.Grid>
          </Card>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SystemBooleanPreview;
