import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../AppContext';

function LocationListener({ location }) {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    const data = {};
    new URLSearchParams(location?.search)?.forEach(function (value, key) {
      data[key] = value;
    });
    dispatch({ type: 'SET_PARAMS', data });
  }, [dispatch, location?.search]);
  return <div />;
}

export default withRouter(LocationListener);
