import { Card } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import RegionForm from '../components/RegionForm';

function RegionCreate(props) {
  const { history = null, location = null } = props;

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.REGIONS} />
          <span className="portal-header">Add Region</span>
        </div>
      </Portal>
      <RegionForm />
    </Card>
  );
}

export default withRouter(RegionCreate);
