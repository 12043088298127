import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  PERMISSION_HOURS_OF_OPERATION_SETTING,
  ROUTES
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './hoursOfOperationModule.less';
import HoursOfOperationPage from './pages/HoursOfOperationPage';

const HoursOfOperationWrapper = () => {
  return (
    <div className="hours-of-operation-wrapper">
      <Switch>
        <Route
          exact
          path={ROUTES?.HOURS_OF_OPERATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_HOURS_OF_OPERATION_SETTING}
              showNoAccess
            >
              <HoursOfOperationPage {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default HoursOfOperationWrapper;
