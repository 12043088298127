import { Col, Divider, Row } from 'antd';
import React from 'react';

const ContactDetail = ({ data = {} }) => {
  const {
    userInfo,
    homePhone,
    mainPhone,
    web,
    location,
    preferredContactData,
    contactTypeData
  } = data;
  return (
    <div>
      <span className="form-divider-text">MANDATORY</span>
      <Divider className="form-divider " />
      <Row gutter={16} className="required-row">
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">
              {userInfo?.firstName} {userInfo?.lastName}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Email *</span>
            <span className="field-value">{userInfo?.email}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Mobile *</span>
            <span className="field-value">{userInfo?.phoneNo}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Home Phone *</span>
            <span className="field-value">{homePhone}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Main Phone *</span>
            <span className="field-value">{mainPhone}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Web *</span>
            <span className="field-value">{web}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Address *</span>
            <span className="field-value">
              {location?.addressLine1} {location?.city},{location?.state}{' '}
              {location?.zipCode}
            </span>
          </div>
        </Col>
      </Row>
      <span className="form-divider-text">OPTIONAL</span>
      <Divider className="form-divider optional-divider" />
      <Row gutter={16}>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Preferred Contact *</span>
            <span className="field-value">
              {preferredContactData?.label || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact Type *</span>
            <span className="field-value">{contactTypeData?.label || '-'}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ContactDetail;
