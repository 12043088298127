import { gql } from '@apollo/client';

export const UPDATE_BRAND_LOB = gql`
  mutation updateBrandLineOfBusiness(
    $data: [SelectLineOfBusiness!]!
    $where: BrandWhereInput!
  ) {
    updateBrandLineOfBusiness(data: $data, where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const UPDATE_DEFAULT_BRAND = gql`
  mutation updateDefaultBrand($data: BrandInput!) {
    updateDefaultBrand(data: $data) {
      data {
        id
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
      }
      status
      message
    }
  }
`;
