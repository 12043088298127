import React, { useEffect, useState } from 'react';

const GeneralSection = ({
  pageConfiguration,
  currentPageSequence = null,
  newPageSequenceCopy = null,
  tenantLogoDetails = null
}) => {
  const {
    showLogo = false,
    showTaglineAbove = false,
    taglineAbove = '',
    showTaglineBelow = false,
    taglineBelow = '',
    showHeaderImage = false,
    showTitle = false,
    title = '',
    headerImage = null
  } = pageConfiguration || {};

  const [imageUrl, setImageUrl] = useState(null);
  const [firstLogo, setFirstLogo] = useState(null);
  const [secondLogo, setSecondLogo] = useState(null);

  useEffect(() => {
    const globalHeaderImage =
      currentPageSequence?.brandConfig?.headerImage?.url ||
      newPageSequenceCopy?.brandConfig?.headerImage?.url;
    if (headerImage?.length > 0) {
      if (headerImage?.[0]?.url) {
        setImageUrl(headerImage?.[0]?.url);
      } else {
        setImageUrl(URL?.createObjectURL(headerImage?.[0]?.originFileObj));
      }
    } else if (headerImage?.url) {
      setImageUrl(headerImage?.url);
    } else setImageUrl(globalHeaderImage);

    const logoType =
      currentPageSequence?.brandConfig?.logo ||
      newPageSequenceCopy?.brandConfig?.logo;

    switch (logoType) {
      case 'TENANT':
        setFirstLogo(
          currentPageSequence?.tenantLogo?.url ||
            newPageSequenceCopy?.tenantLogo?.url
        );
        setSecondLogo(null);
        break;

      case 'BRAND':
        setFirstLogo(
          currentPageSequence?.logo?.url || newPageSequenceCopy?.logo?.url
        );
        setSecondLogo(null);
        break;

      case 'BOTH':
        setFirstLogo(
          currentPageSequence?.logo?.url || newPageSequenceCopy?.logo?.url
        );
        setSecondLogo(
          currentPageSequence?.tenantLogo?.url ||
            newPageSequenceCopy?.tenantLogo?.url
        );
        break;

      default:
        setSecondLogo(tenantLogoDetails?.logo?.url || null);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageConfiguration,
    currentPageSequence,
    newPageSequenceCopy,
    tenantLogoDetails
  ]);

  return (
    <div className="general-section">
      {showHeaderImage && imageUrl && (
        <div className="full-width-image">
          <img
            alt="header-img"
            height={250}
            className="header-image"
            src={imageUrl}
          />
        </div>
      )}
      {showLogo && (
        <>
          {firstLogo?.length > 0 && (
            <div className="logo-section">
              <img alt="logo" src={firstLogo} />
            </div>
          )}
          {secondLogo?.length > 0 && (
            <div className="logo-section">
              <img className="second-logo" alt="logo" src={secondLogo} />
              <span className="logo-text">
                Managed By{' '}
                {currentPageSequence?.tenantName ||
                  newPageSequenceCopy?.tenantName ||
                  tenantLogoDetails?.name}
              </span>
            </div>
          )}
        </>
      )}
      {showTaglineAbove && taglineAbove?.length > 0 && (
        <div className="thin-label mt-25">
          <span>{taglineAbove}</span>
        </div>
      )}
      {pageConfiguration?.widgetType === 'POSTAL' &&
      pageConfiguration?.widgetConfiguration?.zipCodeWidget
        ?.replaceTitleWithLocation ? (
        <div className="title-section mt-4">
          <span className="thin-label">Your installation location is</span>
          <span className="bold-label">Chicago, IL</span>
        </div>
      ) : (
        showTitle &&
        title?.length > 0 && (
          <div className="bold-label mt-4">
            <span>{title}</span>
          </div>
        )
      )}
      {showTaglineBelow && taglineBelow?.length > 0 && (
        <div className="thin-label mt-4">
          <span>{taglineBelow}</span>
        </div>
      )}
    </div>
  );
};

export default GeneralSection;
