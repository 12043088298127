import { Checkbox, Form } from 'antd';
import React from 'react';

const RenderCheckBox = ({ checkBoxData }) => {
  const { key, label } = checkBoxData;
  return (
    <Form.Item name={['dynamicFields', key]} valuePropName="checked">
      <Checkbox className="common-checkbox">{label}</Checkbox>
    </Form.Item>
  );
};

export default RenderCheckBox;
