import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './import.less';
import EditItem from './pages/EditItem';
import ImportList from './pages/ImportList';
import UploadFile from './pages/UploadFile';
import VerifyImport from './pages/VerifyImport';

const ImportWrapper = () => {
  return (
    <div className="import">
      <Switch>
        <Route exact path={ROUTES?.IMPORTS} component={ImportList} />
        <Route path={`${ROUTES?.IMPORTS}/upload`} component={UploadFile} />
        <Route
          path={`${ROUTES?.IMPORTS}/:fileId/edit/:itemId`}
          component={EditItem}
        />
        <Route path={`${ROUTES?.IMPORTS}/:fileId`} component={VerifyImport} />
      </Switch>
    </div>
  );
};

export default ImportWrapper;
