import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import DynamicFieldsForm from '../components/DynamicFieldsForm';
import { GET_DYNAMIC_FIELD } from '../graphql/Queries';

function DynamicFieldsEdit(props) {
  const history = useHistory();
  const {
    match: { params: { id } = {} } = {},
    setSelectedDynamicKey,
    selectedDynamicKey = null,
    location = null
  } = props;

  const [loading, setLoading] = useState(true);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') {
        history?.push(history?.pathname, {
          ...location?.state
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const [dynamicField, { data }] = useLazyQuery(GET_DYNAMIC_FIELD, {
    onCompleted: (res) => {
      setSelectedDynamicKey(res?.dynamicField?.fieldType);
      setLoading(false);
      if (res?.dynamicField?.status !== 'DRAFT') {
        setIsFieldDisabled(true);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    dynamicField({ variables: { where: { id } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return <Redirect to={ROUTES?.DYNAMIC_FIELDS} />;
  }

  const timeDefaultValue =
    data?.dynamicField?.config?.[selectedDynamicKey]?.timeDefaultValue;

  const initialValues = {
    ...data?.dynamicField,
    ...data?.dynamicField?.config?.[selectedDynamicKey],
    ...data?.dynamicField?.config?.[selectedDynamicKey]?.rules,
    ...data?.dynamicField?.config?.[selectedDynamicKey]?.textLength,
    ...data?.dynamicField?.config?.[selectedDynamicKey]?.range,
    fileTypeArray: data?.dynamicField?.config?.[selectedDynamicKey]?.fileType,
    fileType: data?.dynamicField?.config?.[selectedDynamicKey]?.fileType?.key,
    dateType: data?.dynamicField?.config?.[selectedDynamicKey]?.dateConfig
      ?.exactDate
      ? 'EXACT'
      : 'RANGE',
    ...(data?.dynamicField?.config?.[selectedDynamicKey]?.dateDefaultValue && {
      dateTimeBooleanValue: true
    }),
    dateDefaultValue:
      data?.dynamicField?.config?.[selectedDynamicKey]?.dateDefaultValue ||
      'TODAY',
    ...(timeDefaultValue?.length > 0 && {
      timeDefaultValue: [
        moment(timeDefaultValue?.[0]),
        moment(timeDefaultValue?.[1])
      ]
    }),
    fieldType: selectedDynamicKey,
    ...(selectedDynamicKey === 'NUMBER' && {
      defaultValue: String(
        data?.dynamicField?.config?.[selectedDynamicKey]?.default
      )
    })
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.DYNAMIC_FIELDS} />
          <span className="portal-header">Edit Dynamic Field</span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <DynamicFieldsForm
          isEdit
          dynamicFieldsData={initialValues}
          setSelectedDynamicKey={setSelectedDynamicKey}
          selectedDynamicKey={selectedDynamicKey}
          isFieldDisabled={isFieldDisabled}
          refetchEditData={() => {
            setLoading(true);
            dynamicField({ variables: { where: { id } } });
          }}
        />
      )}
    </Card>
  );
}

export default withRouter(DynamicFieldsEdit);
