import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { map } from 'lodash';
import React from 'react';
import DraggerUploadComponent from '../../../../../../../../components/DraggerUploadComponent';

const CustomUploadPreview = ({ widgetConfig, checkedList }) => {
  const maxFile = widgetConfig?.config?.maxFile;
  const size = widgetConfig?.config?.size;
  const perPictureSize = Number(size) / Number(maxFile);
  const label = widgetConfig?.config?.label || ' ';
  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const descriptionCheck = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;

  const allowedTypes = () => {
    return map(checkedList, (item, index) => {
      return `${item}${
        checkedList?.length > 1 && index !== checkedList?.length - 1 ? ', ' : ''
      }`;
    });
  };

  return (
    <div className="mt-37">
      <Form layout="vertical">
        <Form.Item
          name="upload"
          label={hideLabel ? ' ' : label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          <DraggerUploadComponent disabled>
            <p className="icon-header">
              <DownloadOutlined />
            </p>
            <p className="upload-title">
              Drag a picture here or browse your files
            </p>
            <p className="upload-description">
              Max {maxFile || 0} File(s) |{' '}
              {size && maxFile ? perPictureSize : 0}MB per picture{' '}
              {checkedList?.length > 0 && '| '}
              {allowedTypes()}
            </p>
          </DraggerUploadComponent>
        </Form.Item>
      </Form>
      {descriptionCheck && descriptionValue && (
        <span className="thin-label">{descriptionValue}</span>
      )}
    </div>
  );
};

export default CustomUploadPreview;
